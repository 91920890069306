import React, { Fragment } from "react";

import { NaeSSchemaMap } from "../../../../_generated/_custom/config/NaeSSchema";
import { useTranslation } from "react-i18next";
import { useSalesOrderHookNae } from "../../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { PropsId } from "../../../../_generated/_custom/models-cache-data/types";
import { useSalesOrderPriceChangeHookNae, ISalesOrderPriceChangeModelNae } from '../../../../_generated/_custom/hooks/useSalesOrderPriceChangeHookNae';
import {
  checkUserAdmin,
  CheckUserPermissionComponent,
  ENaeSPermissions,
} from "../../../../_generated/_custom/config/NaeSPermissions";
import { ICargoModelNae } from "../../../../_generated/_custom/hooks/useCargoHookNae";
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoNae, SelectorSalesOrderPriceChangeNae } from "../../../../_generated/_custom/models/ormSelectors";
import CreditInvoice from "./components/so-toolbar-change/CreditInvoice";
import Invoice from "./components/so-toolbar-change/Invoice";
import { useInvoiceOutgoingPriceChangeHookNae } from "../../../../_generated/_custom/hooks/useInvoiceOutgoingPriceChangeHookNae";
import { useInvoiceOutgoingHookNae } from "../../../../_generated/_custom/hooks/useInvoiceOutgoingHookNae";
import SalesOrderActWidget from "./components/so/SalesOrderActWidget";
import { useConvert, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { ToolbarButtonWithMenu } from "@newageerp/v3.bundles.buttons-bundle";
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";

export default function SoToolbarPriceChange(props: PropsId) {
  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const element = useSalesOrderHookNae(props.id);

  const elementPriceChanges = element ? element.priceChanges.map(el => el.id) : [-1];
  // @ts-ignore
  const priceChanges: ISalesOrderPriceChangeModelNae[] = useSelector(state => SelectorSalesOrderPriceChangeNae(state, elementPriceChanges));
  const activePriceChanges = priceChanges.filter(i => !!i && i.status === 0);

  const elementCargos = element ? element.cargos.map((el) => el.id) : [-1];
  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  const { t } = useTranslation();
  const [openPopup] = useConvert({
    from: "sales-order",
    to: NaeSSchemaMap.SalesOrderPriceChange.schema,
    id: props.id,
    inPopup: true,
  });

  if (!element) {
    return <Fragment />;
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />;
  }

  const cargoCalculationCompleted =
    cargos.filter(
      (f) => f.status !== 900 && f.cargoCalculationCompleted === false
    ).length === 0;
  const canChangePrice =
    isAdmin ||
    (element.status > 0 && element.status < 20);

  const tryToChangePrice = () => {
    openPopup();
  };

  let changePriceDisabled = false;
  let changePriceTooltip = '';
  if (activePriceChanges.length > 0) {
    changePriceDisabled = true;
    changePriceTooltip = t('The price change is already waiting for approval');
  }

  if (!cargoCalculationCompleted) {
    changePriceDisabled = true;
    changePriceTooltip = t('Final expenses are being counted. Please, wait!');
  }

  let disabledToolbarButton = false;
  if (element.status >= 20 && !isAdmin) {
    disabledToolbarButton = true;
  }


  return (
    <ToolbarButtonWithMenu
      button={
        {
          iconName: "euro-sign",
          title: t('Financial settings'),
          disabled: disabledToolbarButton
        }
      }
      menu={{
        children: <Fragment>
          {canChangePrice && (
            <MenuItem iconName={"edit"} onClick={tryToChangePrice} isDisabled={changePriceDisabled} tooltipContent={changePriceTooltip}>
              {t("Change the order price")}
            </MenuItem>
          )}

          <SalesOrderActWidget id={element.id} />


          {element.priceChanges.map((item: PropsId) => {
            return <PriceChangeProp key={`price-change-${item.id}`} id={item.id} />;
          })}
          {element.invoicePriceChanges.map((item: PropsId) => {
            return <InvoicePriceChangeProp key={`invoice-price-change-${item.id}`} id={item.id} />;
          })}

          <Invoice id={element.id} />
          <CreditInvoice id={element.id} />
        </Fragment>
      }
      }
    />

  );
}

const PriceChangeProp = (props: PropsId) => {
  const { t } = useTranslation();

  const element = useSalesOrderPriceChangeHookNae(props.id);

  const [saveChange] = useUSave(
    NaeSSchemaMap.SalesOrderPriceChange.schema
  );

  const accept = () => {
    saveChange(
      {
        status: 10,
      },
      props.id
    );
  };
  const decline = () => {
    saveChange(
      {
        status: 20,
      },
      props.id
    );
  };

  if (!element) {
    return <Fragment />;
  }

  if (element.status !== 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
        ]}
      >
        <MenuItem
          confirmation={true}
          onClick={accept}
          textColor={"sky"}
          iconName={"check-double"}
        >
          {t("Confirm the new price")}
        </MenuItem>
      </CheckUserPermissionComponent>
      <MenuItem confirmation={true} onClick={decline} iconName={"ban"}>
        {t("Cancel price change")}
      </MenuItem>
    </Fragment>
  );
};

const InvoicePriceChangeProp = (props: PropsId) => {
  const { t } = useTranslation();

  const element = useInvoiceOutgoingPriceChangeHookNae(props.id);
  const invoice = useInvoiceOutgoingHookNae(
    element ? element.invoiceOutgoing.id : -1
  );

  const [saveChange] = useUSave(
    NaeSSchemaMap.InvoiceOutgoingPriceChange.schema
  );

  const accept = () => {
    saveChange(
      {
        status: 10,
      },
      props.id
    );
  };
  const decline = () => {
    saveChange(
      {
        status: 20,
      },
      props.id
    );
  };

  if (!element || !invoice) {
    return <Fragment />;
  }

  if (element.status !== 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserFinances,
        ]}
      >
        <MenuItem
          confirmation={true}
          onClick={accept}
          textColor={"sky"}
          iconName={"check-double"}
        >
          {t(`Confirm ${invoice.serialNumber} the new price`)}
        </MenuItem>
      </CheckUserPermissionComponent>
      <MenuItem confirmation={true} onClick={decline} iconName={"ban"}>
        {t(`Cancel ${invoice.serialNumber} price change`)}
      </MenuItem>

    </Fragment>
  );
};
