import { PdfWidgetItem } from "@newageerp/v3.bundles.widgets-bundle";
import React from "react";

interface Props {
  id: number;
  schema: string;
  pdfIndex?: number;
}

export default function CargoPoSendBtn(props: Props) {
  const { id, schema } = props;

  return (<PdfWidgetItem id={id} schema={schema} template={'pdf'} title={"PDF"} />)
}
