import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import React, { Fragment } from "react";
import NEWidgetFiles from "../../core/components/widgets/NEWidgetFiles";
import { checkUserAdmin, checkUserHeadOfLogisticsHelper, checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain } from "../../_generated/_custom/config/NaeSPermissions";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useKzCarriersOrderHookNae } from "../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";

export default function KzCarriersOrdersFilesWidget(props: PropsId) {
  const { userState } = useTemplatesCore()
  const element = useKzCarriersOrderHookNae(props.id);
  if (!element) {
    return <Fragment />
  }

  const isFtlLogistic = checkUserLogisticFtl(userState);

  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const isHeadOfLogisticsHelper = checkUserHeadOfLogisticsHelper(userState);

  const isAdmin = checkUserAdmin(userState);

  const show10 = true;
  const show20 = true;
  const show30 = !isFtlLogistic && (!(isLogisticSea || isLogisticTrain) || element.transportType === 20);

  const forceReadOnly = isHeadOfLogisticsHelper;

  return (
    <Fragment>
      {show10 && (
        <NEWidgetFiles
          schema={NaeSSchemaMap.KzCarriersOrder.schema}
          element={{ id: props.id }}
          options={{
            type: 10,
            title: "Order",
            hideDeletedFiles: !isAdmin,
            readOnly: element.status >= 40 || forceReadOnly
          }}
        />
      )}
      {/* {show20 && (
        <NEWidgetFiles
          schema={props.schema}
          element={props.element}
          options={{
            type: 20,
            title: "Sąskaita",
            hideDeletedFiles: !isAdmin,
            readOnly: true,
          }}
        />
      )} */}
      {show30 && (
        <NEWidgetFiles
          schema={NaeSSchemaMap.KzCarriersOrder.schema}
          element={{ id: props.id }}
          options={{
            type: 30,
            title: "Photos",
            hideDeletedFiles: !isAdmin,
            readOnly: element.status >= 40 || forceReadOnly, 
          }}
        />
      )}
      {/* {show40 && (
        <NEWidgetFiles
          schema={props.schema}
          element={props.element}
          options={{
            type: 40,
            title: "SMGS",
            hideDeletedFiles: !isAdmin,
          }}
        />
      )}
      {show50 && (
        <NEWidgetFiles
          schema={props.schema}
          element={props.element}
          options={{
            type: 50,
            title: "AWB",
            hideDeletedFiles: !isAdmin,
          }}
        />
      )} */}
      {/* {(element.transportType === 10 || element.transportType === 50) && (
        <NEWidgetFiles
          schema={props.schema}
          element={props.element}
          options={{
            type: 60,
            title: "Waybill (after delivery)",
          }}
        />
      )} */}

      <NEWidgetFiles
        schema={NaeSSchemaMap.KzCarriersOrder.schema}
        element={{ id: props.id }}
        options={{
          type: 'additional-docs',
          title: "Additional documents",
          hideDeletedFiles: !isAdmin,
          readOnly: element.status >= 40 || forceReadOnly,
        }}
      />
    </Fragment>
  );
}
