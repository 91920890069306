import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorPaymentNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IPaymentModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
  }

export const IPaymentFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber"
];

export function usePaymentHookNae(id: number) : IPaymentModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorPaymentNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "payment",
        fields: IPaymentFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
