import { TableSize } from "../../../../core/old-ui/OldTable";

const t = (s: string) => s;

export const caclculationTheadColumns = {
    serial: { content: t('Quotation'), props: { className: "text-left", size: TableSize.sm } },
    cargosSerial: { content: t('Cargo'), props: { className: "text-left", size: TableSize.sm } },
    client: { content: t('Client'), props: { className: "text-left", size: TableSize.sm } },
    loading: { content: t('Loading'), props: { className: "text-left w-1/4", size: TableSize.sm } },
    unloading: { content: t('Unloading'), props: { className: "text-left w-1/4", size: TableSize.sm } },
    actions: { content: t(''), props: { className: "text-left", size: TableSize.sm } },
}
