import React, { Fragment, useEffect } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';

import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { SelectorSalesOrderPaymentDatesNae } from '../../../_generated/_custom/models/ormSelectors';
import { useSelector } from '@newageerp/v3.templates.templates-core';
import { ISalesOrderPaymentDatesModelNae } from '../../../_generated/_custom/hooks/useSalesOrderPaymentDatesHookNae';
import { useTranslation } from 'react-i18next';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';

import { getPropertyTitleForSchema } from '../../../_generated/v3/utils';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { AlertWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function PaymentGraphComponent(props: PropsId) {
    const { t } = useTranslation();

    const element = useSalesOrderHookNae(props.id);

    const [paymentTerms, paymentTermsData] = useUList<PropsId>(NaeSSchemaMap.SalesOrderPaymentDates.schema, ['id']);
    const ids = paymentTermsData.data.data.map(p => p.id);

    // @ts-ignore
    const paymentTermsDataRs: ISalesOrderPaymentDatesModelNae[] = useSelector(state => SelectorSalesOrderPaymentDatesNae(state, ids));
    const isPaymentTermsDataRs = paymentTermsDataRs.filter((p: any) => p === null).length === 0;

    const getData = () => {
        paymentTerms(
            [{
                "and": [
                    ['i.salesOrder', '=', props.id, true]
                ]
            }],
        )
    }

    useEffect(getData, [props.id, element?.paymentTermType]);

    const openEditPopup = (id: number) => {
        SFSOpenEditModalWindowProps({
            schema: NaeSSchemaMap.SalesOrderPaymentDates.schema,
            type: "percent",
            id,
        })
    }

    if (!element) {
        return <Fragment />
    }
    if (element.paymentTermType !== 40) {
        return <Fragment />
    }

    const percentsCount = paymentTermsDataRs.filter(p => p !== null).map(p => p.percent).reduce((a, b) => a + b, 0);

    return (
        <div>
            {!!isPaymentTermsDataRs && (percentsCount < 100 || percentsCount > 100) && <AlertWidget color='danger' className='w-full'>
                {t('Distribute 100%. Now') + ` ${percentsCount} / 100`}
            </AlertWidget>}
            <Table
                thead={<thead>
                    <tr>
                        <Th>{getPropertyTitleForSchema(NaeSSchemaMap.SalesOrderPaymentDates.schema, 'paymentTermType')}</Th>
                        <Th>{getPropertyTitleForSchema(NaeSSchemaMap.SalesOrderPaymentDates.schema, 'paymentTermDate')}</Th>
                        <Th textAlignment={"text-right"}>{getPropertyTitleForSchema(NaeSSchemaMap.SalesOrderPaymentDates.schema, 'percent')}</Th>
                    </tr>
                </thead>}
                tbody={<tbody>
                    {paymentTermsData.data.data.map((item: PropsId) => {
                        return (<tr key={`item-${item.id}`}>
                            <Td>
                                <DfValueView path="sales-order-payment-dates.paymentTermType" id={item.id} />
                            </Td>
                            <Td>
                                <DfValueView path="sales-order-payment-dates.paymentTermDate" id={item.id} />
                            </Td>
                            <Td textAlignment={"text-right"} className={"flex gap-2 items-center justify-end"}>
                                <p><DfValueView path="sales-order-payment-dates.percent" id={item.id} /></p>
                                <ToolbarButton iconName={"edit"} onClick={() => openEditPopup(item.id)} />
                            </Td>
                        </tr>)
                    })}
                </tbody>}
            />
        </div>
    )
}
