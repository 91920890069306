import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkUserManager } from "../../_generated/_custom/config/NaeSPermissions";
import moment from "moment";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { useUserHookNae } from "../../_generated/_custom/hooks/useUserHookNae";
import { getPropertyEnumLabel, ITableLocationStateDates } from "../../_generated/v3/utils";
import { StatusWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTable, { TableSize, usePrint, useQuery } from "../../core/old-ui/OldTable";
import OldThead from "../../core/old-ui/OldThead";
import OldTbody from "../../core/old-ui/OldTbody";
import { defaultStrippedRowClassName } from "../../core/old-ui/OldTrow";
import { FieldDateRange } from '@newageerp/v3.bundles.form-bundle'

import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { RsButton, ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { useAppData } from "@newageerp/v3.app.app-data";


interface IData {
  id: number;
  creator: {
    id: number;
    fullName: string;
  };
  purpose: number;
  createdAt: string;
  serialNumber: string;
  client: {
    id: number;
    name: string;
  };
  status: number;
  updatedAt: string;
  statusComment: string;
  total: number;
  currency: {
    currency: string;
  };
  cargoRelType: number;
}

const defObject: IData = {
  id: 0,
  creator: {
    id: 0,
    fullName: "",
  },
  purpose: 0,
  createdAt: "",
  serialNumber: "",
  client: {
    id: 0,
    name: "",
  },
  status: 0,
  updatedAt: "",
  statusComment: "",
  total: 0,
  currency: {
    currency: "",
  },
  cargoRelType: 0,
};

export default function CalculationReportsStatusComment() {
  const { t } = useTranslation();
  const appData = useAppData();

  const isPrint = usePrint();

  const query = useQuery();
  const queryManager = query.get("manager");

  const statusSet = query.get("set");

  const { userState } = useTemplatesCore()
  const isManager = checkUserManager(userState) || !!queryManager;
  const userId = queryManager ? queryManager : userState.id;
  const user = useUserHookNae(userId);

  const [printed, setPrinted] = useState(false);

  const managerId = queryManager ? queryManager : "CURRENT_USER";

  const dateNow = moment().format("YYYY-MM-DD");
  const defaultDates = {
    dateFrom: dateNow,
    dateTo: dateNow,
  };
  if (query.get("dateFrom")) {
    // @ts-ignore
    defaultDates.dateFrom = query.get("dateFrom");
  }
  if (query.get("dateTo")) {
    // @ts-ignore
    defaultDates.dateTo = query.get("dateTo");
  }

  const [dates, setDates] = React.useState<ITableLocationStateDates>(defaultDates);

  const [getCalculationData, getCalculationDataParams] = useUList<IData[]>("calculation", getKeysFromObject(defObject));

  useEffect(() => {
    if (!!dates) {
      let statuses = [22, 30, 60];
      if (statusSet === "2") {
        statuses = [20, 24, 40, 50];
      } else if (statusSet === "3") {
        statuses = [10];
      }
      getCalculationData(
        [
          {
            classicMode: true,
            and: isManager
              ? [
                ["i.updatedAt", "dgte", dates.dateFrom],
                ["i.updatedAt", "dlte", dates.dateTo],
                ["i.creator", "=", managerId],
                ["i.status", "in", statuses],
              ]
              : [
                ["i.updatedAt", "dgte", dates.dateFrom],
                ["i.updatedAt", "dlte", dates.dateTo],
                ["i.status", "in", statuses],
              ],
          },
        ],
        1,
        10000,
        [
          {
            key: "i.id",
            value: "DESC",
          },
        ]
      );
    }
  }, [dates]);

  const WrapCard = isPrint ? OldTransparentCardCompact : WhiteCard;

  const groupData = groupMap(
    getCalculationDataParams.data.data,
    (el) => el.creator.fullName
  );

  const parseStatusCol = (status: number) => {
    const _schema = "calculation";
    const _type = "status";
    const activeStatus = appData.status.status(_schema, _type, status);

    if (activeStatus) {
      return isPrint ? (
        activeStatus.text
      ) : (
        <StatusWidget
          color={activeStatus.variant ? activeStatus.variant : 'slate'}
          smallPadding={true}
        >
          {activeStatus.text}
        </StatusWidget>
      );
    }
    return <Fragment />;
  };

  useEffect(() => {
    if (getCalculationDataParams.data.data.length > 0 && isPrint && !printed) {
      setTimeout(() => {
        window.print();
      }, 400);
      setPrinted(true);
    }
  }, [isPrint, getCalculationDataParams.data, printed]);

  const doPrint = (userId: number) => {
    const link = `/c/reports/calculation-status-comment?print=true&dateFrom=${dates?.dateFrom}&dateTo=${dates?.dateTo}&manager=${userId}`;

    window.open(link, "_blank");
  };

  const defTextSize = isPrint ? TableSize.sm : TableSize.base;

  return (
    <Fragment>
      <MainToolbarTitle title={t("Calculation conclusion")} />


      <div className={"space-y-2"}>
        {isPrint && isManager && !!user && (
          <TextCardTitle>
            {user.fullName}
          </TextCardTitle>
        )}

        {!isPrint && (
          <WrapCard>
            <TextCardTitle>{t("Datos")}</TextCardTitle>
            <FieldDateRange dates={dates} setDates={setDates} />
          </WrapCard>
        )}

        {Object.keys(groupData).map((owner) => {
          const ownerData = groupData[owner];
          const groupByPurpose = groupMap(ownerData, (el) =>
            el.purpose.toString()
          );
          return (
            <WrapCard>
              {!isPrint && (
                <div className="flex items-center gap-4">
                  <TextCardTitle>{owner}</TextCardTitle>

                  <ToolbarButton
                    iconName="print"
                    onClick={() => {
                      doPrint(ownerData[0].creator.id);
                    }}
                  />
                </div>
              )}

              {Object.keys(groupByPurpose).map((p) => {
                return (
                  <Fragment>
                    <p className="font-bold text-gray-500">
                      {getPropertyEnumLabel(
                        "calculation",
                        "purpose",
                        parseInt(p)
                      )}
                    </p>

                    <OldTable
                      thead={
                        <OldThead
                          className="table-header-group"
                          columns={[
                            {
                              content: t("Number"),
                              props: {
                                className: "text-left",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Statuso keitimo data"),
                              props: {
                                className: "text-left w-1/12",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Client"),
                              props: {
                                className: "text-left w-3/12",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Statusas"),
                              props: {
                                className: "text-left w-2/12",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Transportation type"),
                              props: {
                                className: "text-left w-1/12",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Statuso komentaras"),
                              props: {
                                className: "text-left w-4/12",
                                size: defTextSize,
                              },
                            },
                            {
                              content: t("Price"),
                              props: {
                                className: "text-right w-1/12",
                                size: defTextSize,
                              },
                            },
                          ]}
                        />
                      }
                      tbody={
                        <OldTbody
                          data={groupByPurpose[p]}
                          callback={(item: IData, index) => {
                            return {
                              columns: [
                                {
                                  content: (
                                    <RsButton
                                      schema={"calculation"}
                                      id={item.id}
                                      defaultClick={"modal"}
                                      button={{
                                        children: item.serialNumber,
                                        color: "white",
                                        skipPadding: true,
                                      }}
                                    />
                                  ),
                                  props: {
                                    className: "text-left",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content: moment(item.updatedAt).format(
                                    "YYYY-MM-DD"
                                  ),
                                  props: {
                                    className: "text-left whitespace-nowrap",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content: (
                                    <RsButton
                                      schema={NaeSSchemaMap.Client.schema}
                                      id={item.client.id}
                                      defaultClick={"modal"}
                                      button={{
                                        children: item.client.name,
                                        color: "white",
                                        skipPadding: true,
                                      }}
                                    />
                                  ),
                                  props: {
                                    className: "text-left",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content: parseStatusCol(item.status),
                                  props: {
                                    className: "text-left",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content:
                                    getPropertyEnumLabel(
                                      "calculation",
                                      "cargoRelType",
                                      item.cargoRelType
                                    ),
                                  props: {
                                    className: "text-left",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content: item.statusComment,
                                  props: {
                                    className: "text-left",
                                    size: defTextSize,
                                  },
                                },
                                {
                                  content: (
                                    <Fragment>
                                      {item.total.toFixed(2)}{" "}
                                      {item.currency.currency}
                                    </Fragment>
                                  ),
                                  props: {
                                    className: "text-right whitespace-nowrap",
                                    size: defTextSize,
                                  },
                                },
                              ],
                              className:
                                defaultStrippedRowClassName(index),
                            };
                          }}
                        />
                      }
                    />
                  </Fragment>
                );
              })}
            </WrapCard>
          );
        })}
      </div>
    </Fragment>
  );
}


const OldTransparentCardCompact = (props: any) => {
  return <WhiteCard bgColor="transparent" {...props} />
}