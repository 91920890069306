import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzCarriersOrderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IKzCarriersOrderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      loadDate: string,
      carNumber: string,
      trip: string,
      status: number,
      cargos: ChildId[],
      transportType: number,
      trueUnloadDate: string,
      cargoNotes: string,
      internalComment: string,
      allLdm: number,
      allCbm: number,
      allWeight: number,
      needFtlSupport: number,
      client: {
              id: number,
            },
      contact: {
              id: number,
            },
      notesForFtlCalculation: string,
      driver: string,
      cargoUnloadAddress: string,
      transportAutoType: number,
      transportGuaranteeType: number,
      location: string,
      locationUpdateDateDanger: boolean,
      locationUpdateDate: string,
      status20Date: string,
      status30Date: string,
      contactedCarrier: boolean,
      arrivedAtLoad: boolean,
      allCargoValue: number,
      extraTotalPenalty: number,
      extraTotalDowntime: number,
      extraTotalCustomsInspection: number,
      extraTotalSvh: number,
      extraTotalDeliveryClient: number,
      extraTotalTemperatureMode: number,
      status15Date: string,
      unloadDate: string,
      ftlCalculations: ChildId[],
      allCargoValueWithoutInsured: number,
      company: {
              id: number,
                    name: string,
            },
      creator: {
              id: number,
            },
      responseDocsFinish: boolean,
      total: number,
      currency: {
              id: number,
                    currency: string,
            },
      additionalGarantCost: number,
      kzInspection: {
              id: number,
                    needPhotos: boolean,
            },
      updatedAt: string,
      isFtlDirect: boolean,
      doer: {
              id: number,
            },
      userDps: {
              id: number,
            },
      dpsNotes: string,
      loadingCountry: string,
  }

export const IKzCarriersOrderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "loadDate",
    "carNumber",
    "trip",
    "status",
    "cargos:id",
    "transportType",
    "trueUnloadDate",
    "cargoNotes",
    "internalComment",
    "allLdm",
    "allCbm",
    "allWeight",
    "needFtlSupport",
    "client.id",
    "contact.id",
    "notesForFtlCalculation",
    "driver",
    "cargoUnloadAddress",
    "transportAutoType",
    "transportGuaranteeType",
    "location",
    "locationUpdateDateDanger",
    "locationUpdateDate",
    "status20Date",
    "status30Date",
    "contactedCarrier",
    "arrivedAtLoad",
    "allCargoValue",
    "extraTotalPenalty",
    "extraTotalDowntime",
    "extraTotalCustomsInspection",
    "extraTotalSvh",
    "extraTotalDeliveryClient",
    "extraTotalTemperatureMode",
    "status15Date",
    "unloadDate",
    "ftlCalculations:id",
    "allCargoValueWithoutInsured",
    "company.id",
    "company.name",
    "creator.id",
    "responseDocsFinish",
    "total",
    "currency.id",
    "currency.currency",
    "additionalGarantCost",
    "kzInspection.id",
    "kzInspection.needPhotos",
    "updatedAt",
    "isFtlDirect",
    "doer.id",
    "userDps.id",
    "dpsNotes",
    "loadingCountry"
];

export function useKzCarriersOrderHookNae(id: number) : IKzCarriersOrderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorKzCarriersOrderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "kz-carriers-order",
        fields: IKzCarriersOrderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
