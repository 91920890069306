import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import moment from 'moment';
import { PropsDiffWith, PropsDiffWithExtraData } from './CalculationCostHistory';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'
import { RecordProvider, useNaeRecord } from '@newageerp/v3.app.mvc.record-provider';
import { TextMultiline } from '@newageerp/v3.bundles.data-bundle';

interface ICalculation {
    id: number,
    cost: string,
    serialNumber: string,
    cargos: ICargo[]
}

interface ICargo {
    id: number,
    serialNumber: string,
    places: string,
    dimensions: string,
    weight: string,

    allCargoPlaces: number,
    cbm: number,
    allCargoWeight: number,

}

const defObject: ICalculation = {
    id: 0,
    cost: '',
    serialNumber: '',
    cargos: [
        {
            id: 0,
            serialNumber: '',
            places: '',
            dimensions: '',
            weight: '',

            allCargoPlaces: 0,
            cbm: 0,
            allCargoWeight: 0,
        }
    ]
}

interface Props extends PropsId {
    diffWith: PropsDiffWith,
}

export default function CalculationCostHistoryPopup(props: Props) {
    return (
        <RecordProvider
            id={props.id}
            schema={"calculation"}
            fieldsToReturn={getKeysFromObject(defObject)}
            viewId={"CalculationCostHistoryPopup" + props.id}
        >
            <CalculationCostHistoryPopupInner {...props} />
        </RecordProvider>
    )
}

const CalculationCostHistoryPopupInner = (props: Props) => {
    const { diffWith } = props;
    const element: ICalculation = useNaeRecord().element;

    return (
        <div className={"grid gap-3 grid-flow-col"}>
            <WhiteCard isCompact={true} className={"border-2 border-green-200"} title={element.serialNumber}>
                <div>
                    <TextMultiline
                        text={element.cost}
                        initShowAll={true}
                        isMarkdown={true}
                    />
                </div>


                <div>
                    {element.cargos.map((cargo: ICargo) => {
                        return (
                            <Fragment key={"cargo-d" + cargo.id}>
                                <div>
                                    <strong>{cargo.serialNumber}</strong>
                                </div>
                                <div className={"grid grid-cols-3 gap-2"}>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-pallet mr-2"} />
                                            <strong>Colli quantity</strong>
                                        </div>
                                        <div>{nl2p(cargo.places, "places")}</div>
                                    </div>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-ruler-vertical mr-2"} />
                                            <strong>Dimensions</strong>
                                        </div>
                                        <div>
                                            <div>{nl2p(cargo.dimensions, "dimensions")}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-weight mr-2"} />
                                            <strong>Weight (kg)</strong>
                                        </div>
                                        <div>{nl2p(cargo.weight, "weight")}</div>
                                    </div>
                                </div>

                                <div className={"grid grid-cols-3 gap-2 border-t border-gray-300"}>
                                    <div>
                                        <strong>{cargo.allCargoPlaces} Cll</strong>
                                    </div>
                                    <div>
                                        <strong>{cargo.cbm} CBM</strong>
                                    </div>
                                    <div>
                                        <strong>{cargo.allCargoWeight} kg</strong>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            </WhiteCard>
            <WhiteCard isCompact={true} className={"border-2 border-red-200"} title={moment(diffWith.createdAt).format("YYYY-MM-DD HH:mm")}>
                <div>
                    <TextMultiline
                        text={diffWith.cost}
                        initShowAll={true}
                        isMarkdown={true}
                    />
                </div>
                <div>
                    {diffWith.extraData.map((cargo: PropsDiffWithExtraData) => {
                        return (
                            <Fragment key={"cargo-d" + cargo.serialNumber}>
                                <div>
                                    <strong>{cargo.serialNumber}</strong>
                                </div>
                                <div className={"grid grid-cols-3 gap-2"}>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-pallet mr-2"} />

                                            <strong>Colli quantity</strong>
                                        </div>
                                        <div>{nl2p(cargo.places, "places")}</div>
                                    </div>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-ruler-vertical mr-2"} />

                                            <strong>Dimensions</strong>
                                        </div>
                                        <div>
                                            <div>{nl2p(cargo.dimensions, "dimensions")}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <i className={"fad fa-fw fa-weight mr-2"} />

                                            <strong>Weight (kg)</strong>
                                        </div>
                                        <div>{nl2p(cargo.weight, "weight")}</div>
                                    </div>
                                </div>

                                <div className={"grid grid-cols-3 gap-2 border-t border-gray-300"}>
                                    <div>
                                        <strong>{cargo.all.places} Cll</strong>
                                    </div>
                                    <div>
                                        <strong>{cargo.all.dimensions} CBM</strong>
                                    </div>
                                    <div>
                                        <strong>{cargo.all.weight} kg</strong>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            </WhiteCard>
        </div>
    )
}

