import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useRecoilState } from 'recoil';
import { useContactHookNae } from '../../hooks/useContactHookNae';
import { naePhoneState } from '@newageerp/v3.bundles.voximplant-bundle';

export default function ContactPhonesWithLink(props: PropsId) {
    const [, setPhoneDial] = useRecoilState(naePhoneState);

    const element = useContactHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.phones || element.phones.length === 0) {
        return <Fragment />
    }
    return (
        <div className={"space-y-1"}>
            {element.phones
                .filter((phone: string) => !!phone)
                .map((phone: string, index: number) => {
                    return (
                        <p key={"contact-phone-" + element.id + "-" + index}>
                            <i className={"mr-1 fa-fw fad fa-mobile"} /> <button onClick={() => setPhoneDial(phone)}>{phone}</button>
                        </p>
                    );
                })}
        </div>
    )
}
