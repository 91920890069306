import React, { Fragment } from "react";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { useClientHookNae } from '../../../_generated/_custom/hooks/useClientHookNae';
import { useTranslation } from 'react-i18next';
import { MenuItemWithEdit } from "@newageerp/v3.bundles.modal-bundle";

export default function SalesOrderManagerClientFilial(props: PropsId) {
  const { t } = useTranslation();
  const element = useSalesOrderHookNae(props.id);
  const client = useClientHookNae(element ? element.client.id : -1);

  if (!element || !client || element.status <= 0) {
    return <Fragment />
  }

  if (client.filialsCount === 0) {
    return <Fragment />;
  }

  return (
    <MenuItemWithEdit
      elementId={props.id}
      schema={"sales-order"}
      forcePopup={true}
      type={"client-filial"}
      iconName="edit"
    >
      {t("Update alternative name")}
    </MenuItemWithEdit>
  )
}
