import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CargoLoadingAddressWithIndex from '../Cargo/CargoLoadingAddressWithIndex';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationCargosLoadingAddressWithIndex(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.cargos.map((cargo, index: number) => {
                return (
                    <p key={"cargo-" + cargo.id}><CargoLoadingAddressWithIndex index={index + 1} id={cargo.id} /></p>
                );
            })}
        </Fragment>
    )
}
