import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';

interface Props {
    elementId: number,
}

export default function CargoLogisticSendToOperation(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const [doReq, doReqParams] = useURequest(NaePathsMap.post.AppPluginsCargoOperationConfirmationCreate)

    const doSendForProcessing = () => {
        doReq({ id: element.id })
    }

    const { t } = useTranslation();

    let disabledMessage = '';
    let disabledColor: any = undefined;

    if (!element) {
        return <Fragment />
    }
    const incomingDocExists = !!element.files["10"] && element.files["10"] > 0;
    
    if (!incomingDocExists) {
        disabledMessage = t('Seller\'s documents were not uploaded');
        disabledColor = 'warning';
    }
    if (element.operationConfirmation) {
        disabledMessage = t('Already sent for processing');
        disabledColor = 'success';
    }
    if (element.minimumSpecificationStatus >= 0 && element.minimumSpecificationStatus < 30) {
        disabledMessage = t('Waiting for specification');
        disabledColor = 'info';
    }
    if (element.needEx1T1File && !(!!element.files["20"] && element.files["20"] > 0)) {
        disabledMessage = t('EX/T1 documents were not uploaded');
        disabledColor = 'warning';
    }

    let disabled = !!disabledMessage;

    return (
        <MainButton
            confirmation={true}
            iconName={"file"}
            color={"sky"}
            disabled={disabled}
            disabledContent={{
                children: disabledMessage,
                color: disabledColor
            }}
            className={"w-full"}
            onClick={doSendForProcessing}
            loading={doReqParams.loading}
        >
            {t("Send for processing")}
        </MainButton>
    )

}
