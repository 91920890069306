import React, { useState, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useRecoilState } from 'recoil';
import { LogoLoader, UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { ShowOnScreen } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { useUList, useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { FieldInput } from '@newageerp/v3.bundles.form-bundle';

interface IData {
    id: number,

    debt: number,
    resetDebt: boolean,

}

const defObject: IData = {
    id: 0,

    debt: 0,
    resetDebt: false,

}


export default function DashboardAccountingPayments() {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)

    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const openEditPopup = (soId: number, type: string) => {
        SFSOpenEditModalWindowProps({
            id: "new",
            schema: NaeSSchemaMap.Payment.schema,
            options: {
                createOptions: {
                    convert: {
                        schema: "sales-order",
                        element: { id: soId },
                        type
                    },
                },
            },
            onSaveCallback: (_el, back) => {
                loadData();
                back();
            }
        })
    }

    const [getData, getDataParams] = useUList<IData>("sales-order", getKeysFromObject(defObject));
    const [saveData, saveDataParams] = useUSave("sales-order");

    const loadData = () => {
        if (!!search) {
            getData(
                [{
                    "and": [
                        ['i.status', '!=', 900, true],
                        {
                            "or": [
                                ['i.serialNumber', 'contains', search, true],
                                ['i.client.name', 'contains', search, true],
                                ['i.clientFilial.name', 'contains', search, true],
                                ['i.invoices.serialNumber', 'contains', search, false]
                            ]
                        }
                    ]
                }
                ],
                1,
                1000,
                [
                    {
                        key: "i.date",
                        value: "DESC",
                    }
                ]

            )
        }
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            loadData();
        }
    }

    const resetDebt = (id: number, reset: boolean) => {
        if (saveDataParams.loading) return;
        saveData(
            {
                resetDebt: reset,
            },
            id
        ).then(() => {
            loadData();
        });
    }

    const isData = !getDataParams.loading && !!getDataParams && !!getDataParams.data && getDataParams.data.data.length > 0;
    const data = isData ? getDataParams.data.data : [];

    useEffect(() => {
        setToolbarTitle('Payment adjustment');
    }, []);

    return (
        <Fragment>
            <div className={"grid -gap-2"}>

                <div className={"flex items-center gap-4"}>
                    <FieldInput
                        iconName="search"
                        className={"w-full"}
                        placeholder={t("Enter the order number and press ENTER")}
                        onKeyDown={handleKeyDown}
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                    />

                </div>

                {(getDataParams.loading) && <LogoLoader />}
                {data.map((order: IData) => {
                    return (
                        <div key={"order-" + order.id}>
                            <div className={"flex gap-4"}>
                                <div className={"flex-grow grid gap-1"}>

                                    <WhiteCard isCompact={true}>
                                        <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                                            {({ isVisible }: any) => isVisible ?

                                                <Fragment>
                                                    <TemplatesLoader
                                                        templateName='InlineViewContent'
                                                        data={{
                                                            schema: "sales-order",
                                                            type: 'payment-rel',
                                                            id: order.id,
                                                            isCompact: true,
                                                        }}
                                                    />

                                                    {/* {(order.debt > 0 || order.resetDebt) &&
                                                        <span>
                                                            <MainButton confirmation={true} onClick={() => resetDebt(order.id, !order.resetDebt)} color={"amber"}>
                                                                {t(order.resetDebt ? 'Restore written off debt' : 'Write off the debt')}
                                                            </MainButton>
                                                        </span>
                                                    } */}
                                                </Fragment>
                                                : <Fragment></Fragment>
                                            }
                                        </ShowOnScreen>

                                    </WhiteCard>
                                </div>

                                <div className={"flex gap-4"}>
                                    <div className='space-y-2' style={{ width: 500 }}>
                                        <TextCardTitle className='flex-grow'>{t('Invoices')}</TextCardTitle>

                                        <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                                            {({ isVisible }: any) => isVisible ?
                                                <TemplatesLoader
                                                    templateName="ListRelContent"
                                                    data={{
                                                        schema: 'invoice-outgoing',
                                                        type: 'so-rel-accounting',
                                                        rel: 'salesOrder',
                                                        id: order.id,
                                                        wrap: 0,
                                                    }}
                                                />
                                                :
                                                <LogoLoader />
                                            }
                                        </ShowOnScreen>
                                    </div>

                                    <div className='space-y-2' style={{ width: 500 }}>
                                        <div className='flex items-center gap-2'>
                                            <TextCardTitle className='flex-grow'>{t('Payments')}</TextCardTitle>
                                            <ToolbarButton

                                                onClick={() => openEditPopup(order.id, '')}

                                                iconName={"plus"}
                                                small={true}
                                                title='Create payment'

                                            />
                                            {(order.debt > 0) &&
                                                <ToolbarButton
                                                    onClick={() => openEditPopup(order.id, 'write-off')}
                                                    bgColor={"bg-amber-50"}
                                                    textColor={'text-amber-600'}
                                                    iconName={"octagon-minus"}
                                                    title={t('Write off debt')}
                                                    small={true}
                                                />
                                            }
                                        </div>

                                        <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                                            {({ isVisible }: any) => isVisible ?
                                                <TemplatesLoader
                                                    templateName="ListRelContent"
                                                    data={{
                                                        schema: 'payment',
                                                        type: 'so-rel-accounting',
                                                        rel: 'salesOrder',
                                                        id: order.id,
                                                        wrap: 0,
                                                    }}
                                                />
                                                :
                                                <LogoLoader />
                                            }
                                        </ShowOnScreen>
                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}
