import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorLeadSourceNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ILeadSourceModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      category: {
              id: number,
            },
      priority: number,
  }

export const ILeadSourceFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "category.id",
    "priority"
];

export function useLeadSourceHookNae(id: number) : ILeadSourceModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorLeadSourceNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "lead-source",
        fields: ILeadSourceFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
