import React, { Fragment } from "react";

import {
  checkUserAccounting,
  checkUserAdmin,
  checkUserLogistic,
  checkUserLogisticFtl,
  checkUserLogisticSea,
  checkUserLogisticTrain,
  checkUserOperational,
  checkUserOperationalPro,
} from "../../_generated/_custom/config/NaeSPermissions";
import BottomWidgetResponsiblePersons from "../../UserComponents/Cargo/BottomWidget/BottomWidgetResponsiblePersons";
import CargoBottomNotes from './CargoBottomComponents/CargoBottomNotes';
import CargoBadges from "./CargoBottomComponents/CargoBadges";
import CargoSellerBlock from './CargoBottomComponents/CargoSellerBlock';
import CargoFromSellerBlock from './CargoBottomComponents/CargoFromSellerBlock';
import CargoTerminalBlock from "./CargoBottomComponents/CargoTerminalBlock";
import CargoUnloadBlock from "./CargoBottomComponents/CargoUnloadBlock";
import CargoFromTerminalBlock from './CargoBottomComponents/CargoFromTerminalBlock';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../_generated/_custom/hooks/useCalculationHookNae';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import CargoFilesWidget from "./CargoFilesWidget";
import { Template, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import classNames from "classnames";

type Props = {
  afterFiles: Template[],
} & PropsId;

export default function CargoBottomWidget(props: Props) {
  const elementId = props.id;
  const element = useCargoHookNae(elementId);
  const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);

  const { userState } = useTemplatesCore()

  if (!element) {
    return <Fragment />;
  }

  const seller = element.sellerJson;

  const terminal = element.terminal;

  const isOperational =
    checkUserOperational(userState) || checkUserOperationalPro(userState);
  const isLogistic = checkUserLogistic(userState);
  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const isUserAccounting = checkUserAccounting(userState);

  const isAdmin = checkUserAdmin(userState);

  const canSeeSellerInfo = !!seller;
  const canSeeTerminalInfo = !!terminal;
  let canSeeClientInfo = true;

  // if (isLogistic && element.status >= 10) {
  //   canSeeClientInfo = false;
  // }

  const canSeeUnloadInfo =
    isLogisticFtl || isLogisticSea || isLogisticTrain || isAdmin || isOperational || isUserAccounting;

  let skipUnloadInfo = false;
  if (isLogistic) {
    skipUnloadInfo = true;
  }
  if (isLogisticFtl && !((element.typeIn === 'ftl' && element.typeOut === 'ftl') && element.ftlType === 10)) {
    skipUnloadInfo = true;
  }
  if (
    isLogisticSea &&
    element.typeOut !== "avia"
  ) {
    skipUnloadInfo = true;
  }

  const canViewResponsiblePersons = isAdmin || isOperational || !!calculation && calculation.status >= 10;

  const blockClassName = 'w-full px-2 pb-2 pt-6 space-y-4';
  const blockClassNameGray = `${blockClassName} bg-gray-50`;

  const gridCols = element.needTerminal ? 'grid-cols-5' : 'grid-cols-3';

  return (
    <Fragment>
      <WhiteCard isCompact={true}>
        <CargoBadges skipUnloadInfo={skipUnloadInfo} id={element.id} />
      </WhiteCard>
      <WhiteCard isCompact={true} className={"text-sm"} style={{
        paddingTop: 0
      }}>
        <div className={`grid ${gridCols} border-b border-slate-300`}>
          <div className={classNames(blockClassName)}>
            <CargoSellerBlock id={element.id}/>
          </div>
          <div className={blockClassNameGray}>
            <CargoFromSellerBlock id={element.id}/>
          </div>
          {element.needTerminal && (
            <Fragment>
              <div className={blockClassName}>
                <CargoTerminalBlock id={element.id}/>
              </div>

              <div className={blockClassNameGray}>
                <CargoFromTerminalBlock id={element.id} />
              </div>

            </Fragment>
          )}

          <div className={blockClassName}>
            <CargoUnloadBlock id={element.id} />
          </div>

        </div>
        <CargoBottomNotes id={element.id} canSeeUnloadInfo={canSeeUnloadInfo} />

        {canViewResponsiblePersons && <BottomWidgetResponsiblePersons id={element.id} />}
      </WhiteCard>
      <CargoFilesWidget id={props.id} afterFiles={props.afterFiles} />
    </Fragment>
  );
}
