import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useUserHookNae } from '../../hooks/useUserHookNae';

export default function UserFullName(props: PropsId) {
    const element = useUserHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.fullName}
        </Fragment>
    )
}
