import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import { useContactHookNae } from '../../hooks/useContactHookNae';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function ContactFullNameWithLink(props: PropsId) {
    const element = useContactHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            <RsButton
                id={element.id}
                schema={NaeSSchemaMap.Contact.schema}
                button={{
                    children: element.fullName,
                    color: "white",
                    skipPadding: true,
                }}
                defaultClick="modal"
            />
        </Fragment>
    )
}
