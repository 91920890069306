import { useUSave } from '@newageerp/v3.bundles.hooks-bundle'
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import React, { Fragment } from 'react'
import { NaeSSchema, NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import moment from 'moment'

export default function SoFinishBtn(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);

    const [doReq, reqParams] = useUSave("sales-order");
    const doFinish = () => {
        doReq({
            status: 20,
            finishDate: moment().format('YYYY-MM-DD')
        }, props.id)
    }

    if (element.status >= 20) {
        return <Fragment />
    }

    return (
        <MenuItem
            confirmation={true}
            onClick={doFinish}
            iconLoading={reqParams.loading}
            iconName='check'
            textColor='sky'
        >
            Finish
        </MenuItem>
    )
}
