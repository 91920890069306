import React, { Fragment } from 'react'
import CarriersOrderClientName from '../../../../_generated/_custom/models-cache-data/CarriersOrder/CarriersOrderClientName';
import CarriersOrderContactNameWithLink from '../../../../_generated/_custom/models-cache-data/CarriersOrder/CarriersOrderContactNameWithLink';
import CarriersOrderCarNumber from '../../../../_generated/_custom/models-cache-data/CarriersOrder/CarriersOrderCarNumber';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserLogisticFtl } from '../../../../_generated/_custom/config/NaeSPermissions';

export default function LogistiCargoCarriersContact(props: PropsId) {
    const {userState} = useTemplatesCore()
    const isLogisticFtl = checkUserLogisticFtl(userState);

    const element = useCargoHookNae(props.id);

    if (!element || !element.activePoOrders) {
        return <Fragment />
    }

    return (
        <Fragment>
            {element.activePoOrders.map((co) => {
                return (
                    <div key={"carrier-order-" + co.id}>
                        <div><CarriersOrderClientName id={co.id} /></div>
                        <CarriersOrderContactNameWithLink id={co.id} />
                        {isLogisticFtl && <Fragment><hr /><div><CarriersOrderCarNumber id={co.id} /></div></Fragment>}
                    </div>
                );
            })}
        </Fragment>
    )
}
