import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  groupMap,
} from "../../../core/components/utils/mapUtils";
import CargoSerialNumberWithLink from "../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLink";
import CalculationSerialNumberWithLink from "../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberWithLink";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface IGroupedData {
  [key: string]: ICalculationData[];
}

interface ICalculationData {
  id: number;
  operationalPoints: number;
  serialNumber: string;
  loadingAddress: string;
  operational: {
    fullName: string;
  };
  calculationJson: {
    id: number;
    serialNumber: string;
    creatorFullName: string;
  };
  soUnloadAddressJson: {
    fullAddress: string;
  };
  terminalJson: {
    fullAddress: string;
  };
}

interface IPoints {
  [key: string]: number;
}

const defObject: ICalculationData = {
  operational: {
    fullName: "",
  },
  operationalPoints: 0,
  id: 0,
  serialNumber: "",
  loadingAddress: "",
  calculationJson: {
    id: 0,
    serialNumber: "",
    creatorFullName: "",
  },
  soUnloadAddressJson: {
    fullAddress: "",
  },
  terminalJson: {
    fullAddress: "",
  },
};

let reloadTimeout: any = -1;

export default function AdminOperativeCalculation() {
  const { t } = useTranslation();
  const [getData, getDataParams] = useUList<ICalculationData>(
    "cargo",
    getKeysFromObject(defObject)
  );

  const loadData = () => {
    getData(
      [
        {
          or: [
            {
              and: [
                ["i.operationConfirmation.id", "IS_NOT_NULL", true, true],
                ["i.operationConfirmation.confirmed", "=", false, true],
              ],
            },
            {
              and: [
                ["i.specifications.status", "in", [0, 20], false],
              ],
            },
            ["i.operationConfirmation.headActiveChanges", "=", true, true],
          ],
        },
      ],
      1,
      5000,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("calc-cargo-dashboard-all");
    return () => {
      SocketService.unsubscribe("calc-cargo-dashboard-all");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "calc-cargo-dashboard-all",
      "calc-cargo-dashboard-all",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calc-cargo-dashboard-all",
        "calc-cargo-dashboard-all"
      );
    };
  }, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  const data: ICalculationData[] = isData ? getDataParams.data.data : [];

  let groupedData: IGroupedData = {};
  if (isData) {
    groupedData = groupMap(data, (el: ICalculationData) =>
      el.operational ? el.operational.fullName : "-"
    );
  }

  const points: IPoints = {};
  if (isData) {
    data.forEach((el: ICalculationData) => {
      const k = el.operational ? el.operational.fullName : "-";
      points[k] = 0;
    });
  }

  return (
    <Fragment>
      <div className={"row mb-2"}>
        <div className={"col"}>
          <TextCardTitle>{t("Processing")}</TextCardTitle>
        </div>
      </div>
      <div className="space-y-2">
        {Object.keys(groupedData).map((groupKey: string, index: number) => {
          const lines: ICalculationData[] = groupedData[groupKey];
          return (
            <Fragment key={"calcs-" + index}>
              <WhiteCard isCompact={true} title={groupKey}>

                
                  {isData && (
                    <table className={"table table-striped table-sm"}>
                      <thead>
                        <tr>
                          <th>{t("Cargo")}</th>
                          <th>{t("Quotation")}</th>
                          <th>{t("Responsible person")}</th>
                          <th>{t("Loading")}</th>
                          <th>{t("Unloading")}</th>
                          <th className={"text-right"}>{t("Points")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lines
                          .filter(
                            (cargo: ICalculationData) =>
                              cargo.operationalPoints > 0
                          )
                          .map((cargo: ICalculationData) => {
                            const calculation = cargo.calculationJson;
                            const unloadAddress = cargo.soUnloadAddressJson;

                            const terminal = cargo.terminalJson;

                            points[groupKey] += cargo.operationalPoints;

                            return (
                              <tr key={"cargo-el-" + cargo.id}>
                                <td>
                                  <CargoSerialNumberWithLink id={cargo.id} />
                                </td>
                                <td>
                                  <CalculationSerialNumberWithLink
                                    id={calculation.id}
                                  />
                                </td>
                                <td>{calculation.creatorFullName}</td>

                                <td>{cargo.loadingAddress}</td>
                                <td>
                                  {!!terminal
                                    ? terminal.fullAddress
                                    : unloadAddress
                                    ? unloadAddress.fullAddress
                                    : ""}
                                </td>
                                <td className={"text-right"}>
                                  {cargo.operationalPoints}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
              </WhiteCard>
            </Fragment>
          );
        })}

        <WhiteCard isCompact={true} title={t("Summary")}>
          <Table
            thead={
              <thead>
                <tr>
                  <Th>User</Th>
                  <Th className={"text-right"}>Points</Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {Object.keys(points)
                  .sort((a: string, b: string) => {
                    return points[a] > points[b]
                      ? 1
                      : points[a] < points[b]
                      ? -1
                      : 0;
                  })
                  .filter((groupKey: string) => points[groupKey] > 0)
                  .map((groupKey: string, index: number) => {
                    return (
                      <tr key={"points-" + index}>
                        <Td>{groupKey}</Td>
                        <Td className={"text-right"}>{points[groupKey]}</Td>
                      </tr>
                    );
                  })}
              </tbody>
            }
          />
        </WhiteCard>
      </div>
    </Fragment>
  );
}
