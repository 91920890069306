import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type FileLineProps = {
  title: string;
  onView?: {
    link: string,
    ext: string,
    id: number,
  }
  onClick?: () => void;
  onDownload?: () => void;
  isApproved?: boolean;
  onApprove?: () => void;
  onRemove?: () => void;
  onSend?: () => void;
};

const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export function OldFileLine(props: FileLineProps) {
  const { t } = useTranslation();
  const onRemove = () => {
    // SFSShowConfirmation
    const event = new CustomEvent('SFSShowConfirmation', {
      detail: {
        onYes: props.onRemove
      }
    });
    window.dispatchEvent(event);
  }

  return (
    <div className={'flex gap-2 items-center py-1'}>
      <button
        className={classNames(
          'text-slate-700',
          'flex-grow',
          'text-left',
          `hover:bg-sky-100`,
          'rounded-md',
          'pl-1',
          'py-1',
          'text-sm',
          'flex',
          'items-center'
        )}
        title={props.title}
        onClick={props.onClick}
      >
        <i className='fa fa-regular fa-file text-sky-500 text-base mr-1'/>
        
        {truncateString(props.title, 20)}
      </button>
      <div className={'grid gap-2 grid-flow-col'}>
        {!!props.onDownload &&
          <button
            title={t('Download')}
            className={classNames(
              'hover:bg-sky-100',
              'p-[4px]',
              'rounded',
              'hover:text-sky-500'
            )}
            onClick={props.onDownload}
          >
            <i className='fa fa-regular fa-download text-slate-400'/>
          </button>
        }
        {!!props.onSend &&
          <button
            title={t('Send')}
            className={classNames(
              'hover:bg-sky-100',
              'p-[4px]',
              'rounded',
              'hover:text-sky-500'
            )}
            onClick={props.onSend}
          >
            <i className='fa fa-regular fa-paper-plane text-slate-400'/>
          </button>
        }
        {!!props.onApprove &&
          <button
            title={t('Accept')}
            className={classNames(
              'hover:bg-sky-100',
              'p-[4px]',
              'rounded',
              'hover:text-sky-500'
            )}
            onClick={props.onApprove}
          >
            <i className={
                classNames('fa fa-regular fa-check', {'text-slate-400': !props.isApproved, 'text-teal-500': props.isApproved})}
                />
          </button>
        }
        {!!props.onRemove &&
          <button
            title={t('Remove')}
            className={classNames(
              'hover:bg-sky-100',
              'p-[4px]',
              'rounded',
              'hover:text-sky-500'
            )}
            onClick={onRemove}
          >
            <i className='fa fa-regular fa-trash text-slate-400'/>
          </button>
        }
      </div>
    </div>
  );
}
