import React, { Fragment } from "react";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { SelectorCargoNae } from "../../../_generated/_custom/models/ormSelectors";
import { useKzCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { uniqueArray } from "../../../core/components/utils/mapUtils";
import { ICargoModelNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

export default function KzCargoTerminals(props: PropsId) {
  const element = useKzCarriersOrderHookNae(props.id);
  const elementCargos = element ? element.cargos.map((el) => el.id) : [-1];

  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  if (!element) {
    return <Fragment />;
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />;
  }

  const terminals = uniqueArray(
    cargos
      .map((e: ICargoModelNae) => (e.terminal ? e.terminal.id : -1))
      .filter((n) => n > 0)
  );

  return (
    <div className="space-y-1">
      {terminals.map((t: number) => {
        return <p key={`t-${t}`}><DfValueView path="terminal.name" id={t} /></p>;
      })}
    </div>
  );
}
