import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Tag } from '@newageerp/v3.bundles.badges-bundle';

interface Props extends PropsId {
    disableLink?: boolean;
}

export default function CargoSerialNumberWithLinkAndIcons(props: Props) {
    const { id, disableLink } = props;
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            {!disableLink ? (
                <RsButton
                    id={id}
                    schema={"cargo"}
                    defaultClick={"modal"}
                    button={{
                        children: <div className='flex gap-2 items-center'>
                            <DfValueView path={"cargo.serialNumber"} id={id} />
                            {element.status === 10 && (
                                <i className={"ml-1 fa-truck-moving fad"} />
                            )}
                            {element.status === 15 && (
                                <i className={"ml-1 fa-warehouse fad"} />
                            )}
                            {element.cargoDocumentsCollected === 0 && <Tag size='sm' color='red' title='Documents were not collected'/>}
                            {element.cargoDocumentsCollected === 10 && <Tag size='sm' color='teal' title='Documents were collected'/>}
                            
                            
                        </div>,
                        color: "white",
                        skipPadding: true,
                    }}
                />
            ) : (
                <DfValueView path='cargo.serialNumber' id={id} />
            )}

        </Fragment>
    );
}
