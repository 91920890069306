import { Model, fk, many, attr } from "redux-orm";
import { NaeSSchemaMap } from "../config/NaeSSchema";
import { ICargoFieldsNae } from "../hooks/useCargoHookNae";
import { IKzFtlCalculationFieldsNae } from "../hooks/useKzFtlCalculationHookNae";
import { addToQueueModel } from "./QueueModel";

class KzCarriersOrderModel extends Model {
  static reducer(action, KzCarriersOrderModel, session) {
    switch (action.type) {
      case "UPSERT_" + NaeSSchemaMap.KzCarriersOrder.schema:
        action.payload.forEach((payload) => {
          const dbData = KzCarriersOrderModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          }

          if (payload.cargos) {
            payload.cargos.forEach((c) => {
              const cData = session.CargoModel.withId(c.id);
              if (!cData) {
                addToQueueModel(
                  {
                    id: "cargo" + "-" + c.id,
                    elementId: c.id,
                    schema: "cargo",
                    fields: ICargoFieldsNae,
                  },
                  session.QueueModel,
                );
              }
            });
          }
          if (payload.ftlCalculations) {
            payload.ftlCalculations.forEach((c) => {
              const cData = session.KzFtlCalculationModel.withId(c.id);
              if (!cData) {
                addToQueueModel(
                  {
                    id: NaeSSchemaMap.KzFtlCalculation.schema + "-" + c.id,
                    elementId: c.id,
                    schema: NaeSSchemaMap.KzFtlCalculation.schema,
                    fields: IKzFtlCalculationFieldsNae,
                  },
                  session.QueueModel,
                );
              }
            });
          }

          KzCarriersOrderModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.id}`;
  }
  // Declare any static or instance methods you need.
}
KzCarriersOrderModel.modelName = "KzCarriersOrderModel";

KzCarriersOrderModel.fields = {
  id: attr(),
};

export default KzCarriersOrderModel;
