import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';

moment.locale('lt', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment);

interface CalendarDate {
    start: string;
    end: string;
}

interface IKzOrder {
    id: number;
    serialNumber: string;
    status: number;
    loadDate: string;
}

export default function KzCalendar() {
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)

    const [getOrders, ordersData] = useUList<IKzOrder>(
        NaeSSchemaMap.KzCarriersOrder.schema,
        ["id", "loadDate", "serialNumber", "status"]
    );

    const [events, setEvents] = useState<any[]>([]);

    const [range, setRange] = useState<CalendarDate>({
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
    });

    useEffect(() => {
        getOrders(
            [
                {
                    and: [
                        ["i.loadDate", "dgte", range.start, true],
                        ["i.loadDate", "dlte", range.end, true],
                        ["i.status", "!=", 900, true],
                        ["i.transportType", "=", 10, true],
                        ["i.creator.permissionGroup", '!=', 'user-logistic-ftl', true],
                        // ["i.creator", "=", "CURRENT_USER", true],
                    ],
                },
            ],
            1,
            10000,
            [{ key: "i.id", value: "DESC" }]
        );
    }, [range]);

    useEffect(() => {

        const _events: any[] = [];

        ordersData.data.data.map((d: IKzOrder) => {
            _events.push({
                start: moment(d.loadDate).toDate(),
                end: moment(d.loadDate).toDate(),
                title: d.serialNumber,
                id: d.id,
                status: d.status,
            });

        });
        setEvents(_events);
    }, [ordersData.data.data]);

    useEffect(() => {
        setToolbarTitle("");
    }, []);

    return (
        <div className='flex gap-4'>
            <div className="w-full">
                <Calendar
                    onSelectEvent={(event) => SFSOpenViewModalWindowProps({ id: event.id, schema: NaeSSchemaMap.KzCarriersOrder.schema })}
                    defaultView={"month"}
                    views={["month", "week"]}
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 900 }}
                    onRangeChange={(e: any) => {
                        if (Array.isArray(e)) {
                            setRange({
                                start: moment(e[0]).format("YYYY-MM-DD"),
                                end: moment(e[e.length - 1]).format("YYYY-MM-DD"),
                            })
                        } else {
                            setRange({
                                start: moment(e.start).format("YYYY-MM-DD"),
                                end: moment(e.end).format("YYYY-MM-DD"),
                            })
                        }
                    }
                    }
                    popup={true}
                    
                />
            </div>
            {/* <div className="w-4/12">
                <WhiteCardWithTitle title={t('Planuojami užsakymai')}>
                    <div className='overflow-x-auto w-full'>
                        <KzCarriersOrderCalendarTableDataSource disableSetTitle={true} />
                    </div>
                </WhiteCardWithTitle>
            </div> */}
        </div>
    );
}
