import React, { Fragment } from 'react'
import { PropsId } from '../types';
import ClientFilialName from '../ClientFilial/ClientFilialName';
import ClientNameWithLink from '../Client/ClientNameWithLink';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

interface Props extends PropsId {
    textColor?: string,
}

export default function SalesOrderClientWithLinkFilialName(props: Props) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.client && <ClientNameWithLink textColor={props.textColor} id={element.client.id} />}
            {element.clientFilial && <Fragment> (<ClientFilialName id={element.clientFilial.id} />)</Fragment>}
        </Fragment>
    )
}
