import React, { Fragment } from "react";
import RelatedCargosLine from '../../UserComponents/Cargo/Components/RelatedCargosLine';
import {
  checkIsAnyLogistic,
} from "../CaspianHelperFunctions";

import { checkUserLogistic, checkUserLogisticFtl, checkUserOperational, checkUserOperationalPro } from "../../_generated/_custom/config/NaeSPermissions";
import CargoSerialNumberWithLinkAndNotes from '../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndNotes';
import CargoNeedSpecificationBadge from '../../_generated/_custom/models-cache-data/Cargo/CargoNeedSpecificationBadge';
import CargoType50Badge from '../../_generated/_custom/models-cache-data/Cargo/CargoType50Badge';
import CargoOutgoingDocumentsChangeBadge from '../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeBadge';
import CargoTypeInFtlBadge from "../../_generated/_custom/models-cache-data/Cargo/CargoTypeInFtlBadge";
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

export default function CargoLogisticLink(props: PropsId) {
  const {userState} = useTemplatesCore()
  const isAnyLogistic = checkIsAnyLogistic(userState);
  const isOperational = checkUserOperational(userState) || checkUserOperationalPro(userState);
  const isLogistic = checkUserLogistic(userState);
  const isLogisticFtl = checkUserLogisticFtl(userState);

  return (
    <Fragment>
      <CargoSerialNumberWithLinkAndNotes id={props.id} />

      <div className={"flex flex-wrap gap-1"}>
        {isAnyLogistic &&
          <CargoNeedSpecificationBadge id={props.id} />
        }
        {!isOperational && <CargoType50Badge id={props.id} />}



        {!isLogisticFtl && (
          <CargoOutgoingDocumentsChangeBadge id={props.id} showOnlyOnDirect={true} />
        )}

        {isLogistic && <CargoTypeInFtlBadge id={props.id} />}

      </div>

      <RelatedCargosLine id={props.id} />

    </Fragment>
  );
}
