import React from "react";
import OldFilesWidget from "../../old-ui/OldFilesWidget";
export interface WidgetFilesProps {
  schema: string;
  element: any;
  options: any;
}

export default function NEWidgetFiles(props: WidgetFilesProps) {
  return <OldFilesWidget {...props} />;
}
