import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorInvoiceOutgoingNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IInvoiceOutgoingModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      total: number,
      currency: {
              id: number,
                    currency: string,
            },
      invoices: ChildId[],
  }

export const IInvoiceOutgoingFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "total",
    "currency.id",
    "currency.currency",
    "invoices:id"
];

export function useInvoiceOutgoingHookNae(id: number) : IInvoiceOutgoingModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorInvoiceOutgoingNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "invoice-outgoing",
        fields: IInvoiceOutgoingFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
