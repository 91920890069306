import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CargoSerialNumber from './CargoSerialNumber';

interface Props extends PropsId {
    disableLink?: boolean;
}

export default function CargoSerialNumberWithLink(props: Props) {
    const { id, disableLink } = props;

    return (
        <Fragment>
            {!disableLink ? (
                <RsButton
                    id={id}
                    schema={"cargo"}
                    defaultClick="modal"
                    button={{
                        children: <CargoSerialNumber id={id} />,
                        color: "white",
                        skipPadding: true,
                    }}
                />
            ) : (
                <CargoSerialNumber id={id} />
            )}

        </Fragment>
    );
}
