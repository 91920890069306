import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserManager } from "../../../_generated/_custom/config/NaeSPermissions";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";

interface Props extends PropsId {
}

export default function CalculationPriceWidget(props: Props) {
  const element = useCalculationHookNae(props.id);

  const { t } = useTranslation();

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);

  if (!element) {
    return <Fragment />;
  }
  if (!(isAdmin || isManager)) {
    return <Fragment />;
  }
  if (element.status < 10) {
    return <Fragment />;
  }
  if (element.status === 900) {
    return <Fragment />;
  }

  const hasPrice = element.total > 0;

  return (
    <MainButton
      className={"w-full"}
      color={hasPrice ? "bluelight" : "red"}
      iconName='circle-dollar'
      onClick={() => SFSOpenEditModalWindowProps({
        id: element.id,
        schema: "calculation",
        type: "widget-price"
      })}
    >
      {hasPrice ? t("Change price") : t("Set price")}
    </MainButton>
  );
}
