import React, { Fragment } from 'react'
import { PropsId } from '../types';
import UnloadAddressFullAddress from '../UnloadAddress/UnloadAddressFullAddress';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationUnloadAddressFullAddress(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element || !element.address) {
        return <Fragment />
    }

    return (
        <UnloadAddressFullAddress id={element.address.id} />
    )
}
