import React, { Fragment } from 'react'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import NotesLine from '../../../../UserComponents/Notes/NotesLine';
import { PropsId } from '../types';

interface Props extends PropsId {
    disableLink?: boolean;
}

export default function CargoSerialNumberWithLinkAndNotes(props: Props) {
    const { id, disableLink } = props;

    return (
        <Fragment>
            <DfValueView id={id} path={"cargo.serialNumber"} link={!disableLink} />

            <NotesLine parentId={id} parentSchema={"cargo"} />
        </Fragment>
    );
}
