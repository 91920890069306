import React, {Fragment} from 'react'
import { PropsId } from '../types';
import { useSenderHookNae } from '../../hooks/useSenderHookNae';

export default function SenderName(props: PropsId) {
    const element = useSenderHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    return (
        <Fragment>
            {element.name}
        </Fragment>
    )
}
