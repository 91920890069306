import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

export default function CartoLtlToFtl(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const salesOrder = useSalesOrderHookNae(element && element.salesOrder ? element.salesOrder.id : -1);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);

    const { t } = useTranslation();

    const [saveDataCalculation, saveDataCalculationParams] = useUSave("calculation")
    const [saveDataSo, saveDataSoParams] = useUSave("sales-order")

    const doSave = () => {
        if (saveDataCalculationParams.loading) return;
        if (saveDataSoParams.loading) return;
        if (element.salesOrder) {
            saveDataSo(
                {
                    allCargosOneUnload: 10
                },
                element.salesOrder.id
            );
        } else if (element.calculation) {
            saveDataCalculation(
                {
                    allCargosOneUnload: 10
                },
                element.calculation.id
            );
        }
    }

    const allCargosOneUnload = salesOrder ? salesOrder.allCargosOneUnload : (calculation ? calculation.allCargosOneUnload : 0);

    if (!element) {
        return <Fragment />
    }
    if (!(element.typeIn === 'ltl' && element.typeOut === 'ltl')) {
        return <Fragment />
    }
    if (allCargosOneUnload === 10) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />
    }

    return (
        <MenuItem
            onClick={doSave}
            confirmation={
                {
                    onYes: () => { },
                    isPopup: true,
                    children: <Fragment>
                        <p>{t('Pasirinkus šį variantą')}</p>
                        <p>{t('krovinys bus išvežtas iš sandėlio atskiroje mašinoje.')}</p>
                        <p>{t('Ar tikrai to norite?')}</p>
                    </Fragment>
                }
            } iconName={"truck"} iconLoading={saveDataCalculationParams.loading || saveDataSoParams.loading}>
            {t('Ship cargo from our warehouse in a separate vehicle')}
        </MenuItem>
    )
}
