import React from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useHistory } from "@newageerp/v3.templates.templates-core";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function CalculationCreateOrder(props: PropsId) {
  const { t } = useTranslation();
  const history = useHistory();
  const [saveCalc] = useUSave("calculation");

  const onCreate = () => {
    saveCalc(
      {
        status: 25,
      },
      props.id
    ).then(() => {
      history.push("/u/sales-order/main/edit/new", {
        createOptions: {
          convert: {
            schema: "calculation",
            element: { id: props.id }
          },
        },
      });
    });
  };

  return (
    <MainButton
      className={"w-full"}
      iconName={"exchange-alt"}
      onClick={onCreate}
      color={"sky"}
    >
      {t("Convert to Sales Order")}
    </MainButton>
  );
}
