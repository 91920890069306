import React from 'react'
import VoximplantAudioPlayer from '../../../../../Plugins/Voximplant/VoximplantAudioPlayer'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function AudioPlayer(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    return (
        <VoximplantAudioPlayer url={value} />
    )
}
