import React from 'react'
import { PropsId } from '../types';
import ClientName from './ClientName';
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

interface Props extends PropsId {
    textColor?: string,
    linksInline?: boolean
}

export default function ClientNameWithLink(props: Props) {
    return (
        <RsButton
            id={props.id}
            schema={NaeSSchemaMap.Client.schema}

            defaultClick={"modal"}
            button={
                {
                    children: <ClientName id={props.id} />,
                    skipPadding: true,
                    color: props.textColor ? "whiteandpurple" : "white",

                    className: `w-full`,
                }
            }
        />

    )
}
