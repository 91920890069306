import React, { Fragment } from 'react'
import { useUnloadAddressHookNae } from '../../hooks/useUnloadAddressHookNae';
import { PropsId } from '../types';


export default function UnloadAddressCountryWithCityRu(props: PropsId) {
    const unloadAddress = useUnloadAddressHookNae(props.id);
    if (!unloadAddress) {
        return <Fragment />;
    }
    return <Fragment>{unloadAddress.countryWithCityRu}</Fragment>;
}
