import React, { Fragment, useState } from "react";
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";

export default function KzOrderLocationHistory(props: PropsId) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div>
      <button onClick={() => setShowPopup(true)} className="text-left text-blue-500">Show history</button>

      <Popup onClick={() => setShowPopup(false)} title={""} isPopup={showPopup}>
        <TemplatesLoader
          templateName="PageInlineList"
          data={{
            schema: "kz-carriers-order-location",
            type: "main",
            extraFilters: [{
              "and": [
                ['i.kzCarriersOrder.id', 'in', props.id, true]
              ]
            }]
          }}
        />
      </Popup>
    </div>
  );
}
