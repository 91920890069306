import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function SalesOrderSerialNumberButtonLink(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <RsButton
            id={props.id}
            schema={"sales-order"}
            
            button={{
                color: "blue",
                children: element.serialNumber,
                className:"w-full text-left",
            }}
        />
    )
}
