import { Model, fk, many, attr } from "redux-orm";
import { NaeSSchemaMap } from "../config/NaeSSchema";

class TerminalModel extends Model {
  static reducer(action, TerminalModel, session) {
    switch (action.type) {
      case "UPSERT_" + NaeSSchemaMap.Terminal.schema:
        action.payload.forEach((payload) => {
          const dbData = TerminalModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          }

          TerminalModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.id}`;
  }
  // Declare any static or instance methods you need.
}
TerminalModel.modelName = "TerminalModel";

TerminalModel.fields = {
  id: attr(),
};

export default TerminalModel;
