import React, { Fragment } from "react";
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';

export default function KzOrderLocationDate(props: PropsId) {
  const element = useKzCarriersOrderHookNae(props.id);
  
  if (!element) {
    return <Fragment />
  }

  return (
    <div
      className={
        element.locationUpdateDateDanger ? "text-red-500" : ""
      }
    >
      {element.locationUpdateDate}
    </div>
  );
}
