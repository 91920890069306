import React, { Fragment } from "react";

import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from '@newageerp/v3.bundles.utils-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { SFSOpenEditModalWindowProps, SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { Tooltip } from '@newageerp/v3.bundles.badges-bundle'
import { ICargoModelNae } from "../../_generated/_custom/hooks/useCargoHookNae";

interface IDataContact {
  id: number;
  fullName: string;
  skype: string;
  emails: any;
  carrier: {
    id: number;
    name: string;
    defaultLang: string;
  };
  description: string;
  phones: any;
}

interface Props {
  contact: IDataContact;
  cargo: ICargoModelNae;
  compact?: boolean;
  forceCanAdd?: boolean;
  calcType: string,
}

export default function CargoLogisticCalculatesContact(props: Props) {
  const { t } = useTranslation();

  const { contact, cargo } = props;

  const create = () => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: NaeSSchemaMap.CargoLoadingCalculation.schema,
      options: {
        createOptions: {
          convert: {
            schema: "cargo",
            element: { id: cargo.id },
            carrier: { id: contact.carrier.id },
            contact: { id: contact.id },
            calcType: props.calcType,
          },
        },
      },
      onSaveCallback: (_el, back) => {
        back();
      },
    });
  };

  const onSkype = () => {
    CopyToClipboard(
      "text-skype-" +
      (contact.carrier.defaultLang ? contact.carrier.defaultLang : "lt") +
      "-" +
      cargo.id
    );

    window.location.href = "skype:" + contact.skype + "?chat";
  };

  const onEmail = () => {
    CopyToClipboard(
      "text-skype-" +
      (contact.carrier.defaultLang ? contact.carrier.defaultLang : "lt") +
      "-" +
      cargo.id
    );

    const subject =
      "Užklausa nuo Caspian Logistics (" + cargo.serialNumber + ")";

    window.location.href =
      "mailto:" + contact.emails[0] + "?subject=" + encodeURIComponent(subject);
  };

  const loadingPriceCalculated = props.calcType === 'out' ? cargo.loadingPriceCalculatedOut :cargo.loadingPriceCalculated;

  const canAdd = !loadingPriceCalculated || props.forceCanAdd;

  return (
    <Fragment>
      <div
        className={
          "bg-slate-50 p-1 border border-slate-100 rounded-md"
        }
      >
        <div className={"flex gap-1 flex-wrap text-sm"}>

          <div
            className={"cursor-pointer"}
            onClick={() =>
              SFSOpenViewModalWindowProps({
                id: contact.carrier.id,
                schema: NaeSSchemaMap.Carrier.schema,
              })
            }
          >
            {contact.carrier.name}
          </div>
          <div
            className={"text-gray-400 cursor-pointer"}
            onClick={() =>
              SFSOpenViewModalWindowProps({
                id: contact.id,
                schema: NaeSSchemaMap.Contact.schema,
              })
            }
          >
            {contact.fullName}
          </div>




        </div>
        <div className={"flex items-center"}>
          {!!contact.phones && !!contact.phones[0] && (
            // <a href={"tel:" + contact.phones[0]}>
            //   <FontAwesomeIcon
            //     icon={faMobile}
            //     size={"2x"}
            //     className={"text-blue-500"}
            //   />
            // </a>
            <ToolbarButton
              onClick={onSkype}
              iconName="mobile"
              title="Skype"
              textColor="text-blue-400"
            />
          )}
          {!!contact.emails && !!contact.emails[0] && (
            <ToolbarButton
              onClick={onEmail}
              iconName="envelope"
              title="E-mail"
              textColor="text-blue-400"
            />
          )}
          {!!contact.skype && (
            <ToolbarButton
              onClick={onSkype}
              iconName="skype fa-brands"
              title="Skype"
              textColor="text-blue-400"
            />
          )}
          {!!contact.description && (
            <Fragment>
              <Tooltip textColor="text-purple-500" text={contact.description.split("\n").join("<br/>")} />
            </Fragment>
          )}

          {canAdd && (
            <ToolbarButton
              onClick={create}
              iconName="plus"
              textColor="text-sky-600"
            >
              {t("Create")}
            </ToolbarButton>
          )}
        </div>
      </div>
    </Fragment>
  );
}
