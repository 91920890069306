import React, { Fragment } from 'react'
import NaeNotePopover from '../../core/components/notes/NaeNotePopover';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorNoteNae } from '../../_generated/_custom/models/ormSelectors';
import { INoteModelNae } from '../../_generated/_custom/hooks/useNoteHookNae';

interface Props {
    parentId: number,
    parentSchema: string,
}

export default function NotesLine(props: Props) {
    const { parentId, parentSchema } = props;

    const {userState} = useTemplatesCore()

    const notes = useSelector((state) => SelectorNoteNae(state));

    const notesNotify = notes.filter((note: INoteModelNae) => {
        if (note.parentId === parentId && note.parentSchema === parentSchema) {
            return (
                (note.notify.indexOf(userState.id) >= 0 ||
                    note.notify.indexOf(userState.permissionGroup) >= 0) &&
                note.notifyAccept.indexOf(userState.id) === -1
            );
        }
        return false;
    })
        ;

    if (notesNotify.length === 0) {
        return <Fragment />
    }

    return (
        <div className={"flex"}>
            {notesNotify.map((note: any) => {
                return <NaeNotePopover key={"note-" + note.id} id={note.id} />;
            })}
        </div>
    )
}
