import React from "react";

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useListDataSource } from "@newageerp/v3.app.list.list-data-source";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";


export default function FvsExport() {
  const listDataSource = useListDataSource();

  const [doExport] = useURequest(NaePathsMap.post['pluginsFinvaldaExportExport']);

  const onFvsClick = () => {
    const filter = listDataSource.filter.prepare();
    doExport({
      filter,
      schema: NaeSSchemaMap.InvoiceIncoming.schema,
    }).then((res: any) => {
      window.open(res.data.fileName, '_blank');
    })
  };
  return (
    <ToolbarButton
        title={"Finvaldos eksportas"}
        onClick={onFvsClick}
        iconName={"display-arrow-down"}

      />
  );
}
