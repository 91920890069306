import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { FieldTextarea } from "@newageerp/v3.bundles.form-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function CalculationReturnLogistic(props: PropsId) {
  const { t } = useTranslation();

  const { id } = props;

  const [saveData, saveDataParams] = useUSave(
    "calculation"
  );

  const [returnText, setReturnText] = useState("");
  const [showForm, setShowForm] = useState(false);
  const toggleForm = () => setShowForm(!showForm);

  const onReturn = () => {
    saveData(
      {
        status: 0,
        addNote: {
          content: "Grąžintas paskaičiavimas: " + returnText,
          notify: ["owner"],
        },
      },
      id
    );
    toggleForm();
  };

  if (showForm) {
    return (
      <Fragment>
        <WhiteCard isCompact={true} title={t("Reason for return")}>

          <FieldTextarea
            value={returnText}
            onChange={(e) => setReturnText(e.target.value)}
            className={"w-full"}
            rows={2}
          />

          <div className={"grid grid-cols-2 gap-2"}>
            <MainButton color={"sky"} onClick={onReturn}>
              {t("Send")}
            </MainButton>
            <MainButton
              color="slate"
              onClick={() => {
                setReturnText("");
                toggleForm();
              }}
            >
              {t("Cancel")}
            </MainButton>
          </div>
        </WhiteCard>
      </Fragment>
    );
  }

  return (
    <MainButton
      onClick={toggleForm}
      color={"amber"}
      iconName={"undo-alt"}
      loading={saveDataParams.loading}
    >
      {t("Return")}
    </MainButton>
  );
}
