import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { caclculationTheadColumns } from './ManagerCalculationColumns';
import CalculationCalculatedSerialColumn from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCalculatedSerialColumn";
import CalculationCargosSerialNumberWithLinkAndNotes from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosSerialNumberWithLinkAndNotes";
import CalculationCargosLoadingAddressWithIndex from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosLoadingAddressWithIndex";
import CalculationUnloadAddressFullAddress from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationUnloadAddressFullAddress";
import { isHotCalculationDelayed } from "../../../Calculation/CalculationHelpers";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTable, { TableSize } from "../../../../core/old-ui/OldTable";
import OldThead from "../../../../core/old-ui/OldThead";
import OldTbody from "../../../../core/old-ui/OldTbody";
import { defaultStrippedRowClassName } from "../../../../core/old-ui/OldTrow";

interface Props {
  data: IData[];
}

interface IData {
  id: number,
  status25LastChangeDiffDays: number,
}

export default function ManagerCalculationHot(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={"relative"}>
      <WhiteCard isCompact={true} title={t('"Hot" quotations')}>

        <OldTable

          thead={<OldThead
            columns={[
              caclculationTheadColumns.serial,
              caclculationTheadColumns.cargosSerial,
              caclculationTheadColumns.loading,
              caclculationTheadColumns.unloading
            ]} />}
          tbody={
            <OldTbody
              data={props.data}
              callback={(el: IData, index: number) => {
                return (
                  {
                    columns: [
                      {
                        content: <Fragment>
                        <CalculationCalculatedSerialColumn id={el.id} />
                        <p><DfValueView path="calculation.purpose" id={el.id} /></p>
                        </Fragment>,
                        props: { className: "text-left w-48 space-y-1", size: TableSize.sm }
                      },
                      {
                        content: <CalculationCargosSerialNumberWithLinkAndNotes id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationCargosLoadingAddressWithIndex id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      { content: <CalculationUnloadAddressFullAddress id={el.id} />, props: { className: "text-left", size: TableSize.sm } },
                      { content: t(''), props: { className: "text-left", size: TableSize.sm } },
                    ],
                    className: isHotCalculationDelayed(el) ? (index % 2 === 0 ? 'bg-red-50' : 'bg-red-100') : defaultStrippedRowClassName(index)
                  }
                )
              }}
            />
          }

        />

      </WhiteCard>

      {/* <ManagerCalculationHotDelayedPopup ids={props.data.map((el: IData) => el.id)} /> */}
    </div>
  );
}
