import React, { Fragment } from 'react'
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import CarriersOrderSerialNumber from '../../../../_generated/_custom/models-cache-data/CarriersOrder/CarriersOrderSerialNumber';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { checkUserLogistic } from '../../../../_generated/_custom/config/NaeSPermissions';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';


export default function LogistiCargoCarriersLink(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const {userState} = useTemplatesCore()

    const isLogistic = checkUserLogistic(userState);

    if (!element || !element.activePoOrders) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.activePoOrders.map((co) => {
                return (
                    <div key={"carrier-order-" + co.id}>
                        <RsButton id={co.id} schema={NaeSSchemaMap.CarriersOrder.schema} defaultClick={"modal"} button={{ children: <CarriersOrderSerialNumber id={co.id} />, color: "white", skipPadding: true }} />

                        {isLogistic && element.typeIn === 'ftl' && <DfValueView path='cargo.expeditorCalculation.fullName' id={props.id} />}
                    </div>
                );
            })}
        </Fragment>
    )
}
