import React, { Fragment } from "react";

import CalculationSendToCalculate from "./Widget/CalculationSendToCalculate";
import CalculationCargoTnvedWidget from "./Widget/CalculationCargoTnvedWidget";
import CalculationStatusChange from "./Widget/CalculationStatusChange";
import CalculationCostHistory from "./Widget/CalculationCostHistory";
import CalculationAllCargosOneUnload from './Widget/CalculationAllCargosOneUnload';
import CalculationCargoFtlOverloadType from './Widget/CalculationCargoFtlOverloadType';
import CalculationSalesOrders from './Widget/CalculationSalesOrders';
import CalculationPriceWidget from './Widget/CalculationPriceWidget';
import { PropsId } from "../../_generated/_custom/models-cache-data/types";


export default function CalculationWorkflowWidget(props: PropsId) {

  return (
    <Fragment>
      <CalculationSalesOrders id={props.id} />

      <CalculationCargoTnvedWidget id={props.id} />

      <CalculationCargoFtlOverloadType id={props.id} />

      <CalculationAllCargosOneUnload id={props.id} />

      <CalculationSendToCalculate id={props.id} />

      <CalculationStatusChange id={props.id} />

      <CalculationPriceWidget id={props.id} />

      <CalculationCostHistory id={props.id} />

    </Fragment>

  );
}
