import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import CargoStatus7Badge from '../../_generated/_custom/models-cache-data/Cargo/CargoStatus7Badge';

export default function SalesOrderCargoBadges(props: PropsId) {
  const cargo = useCargoHookNae(props.id);
  if (!cargo) {
    return <Fragment />
  }
  return (
    <Fragment>
      <CargoStatus7Badge id={cargo.id} />
    </Fragment>
  )
}
