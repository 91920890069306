import React, { Fragment } from 'react'
import { PropsId } from '../types';
import moment from 'moment';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoLoadDate(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.loadDate ? moment(element.loadDate).format("YYYY-MM-DD") : ""}
        </Fragment>
    )
}
