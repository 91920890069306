import { Model, fk, many, attr } from "redux-orm";
import { NaeSSchemaMap } from "../config/NaeSSchema";
import { ICargoFieldsNae } from "../hooks/useCargoHookNae";
import { addToQueueModel } from "./QueueModel";

class CalculationModel extends Model {
  static reducer(action, CalculationModel, session) {
    switch (action.type) {
      case "UPSERT_calculation":
        action.payload.forEach((payload) => {
          const dbData = CalculationModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          } else {
            if (payload.cargos) {
              payload.cargos.forEach((c) => {
                addToQueueModel(
                  {
                    id: "cargo" + "-" + c.id,
                    elementId: c.id,
                    schema: "cargo",
                    fields: ICargoFieldsNae,
                  },
                  session.QueueModel
                );
              });
            }
          }

          CalculationModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.id}`;
  }
  // Declare any static or instance methods you need.
}
CalculationModel.modelName = "CalculationModel";

CalculationModel.fields = {
  id: attr(),
};

export default CalculationModel;
