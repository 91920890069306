const groupMap = (data: any[], keyFunc: (el: any) => string) => {
  let grouped: any = {};
  data.forEach((el: any) => {
    const groupKey = keyFunc(el);
    if (!(groupKey in grouped)) {
      grouped[groupKey] = [];
    }
    grouped[groupKey].push(el);
  });
  return grouped;
};


const nl2pWithIndex = (text: string, key: string) => {
  return text
    .split("\n")
    .map((t: string, index: number) => <p key={key + "-" + index}>{index + 1}) {t}</p>);
};

let uniqueArray = (a: any[]) =>
  a.filter((item, i, ar) => ar.indexOf(item) === i);


export { groupMap, nl2pWithIndex, uniqueArray };
