import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCommentProcessingNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICommentProcessingModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const ICommentProcessingFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useCommentProcessingHookNae(id: number) : ICommentProcessingModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCommentProcessingNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "comment-processing",
        fields: ICommentProcessingFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
