import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCarriersOrderHookNae } from '../../hooks/useCarriersOrderHookNae';

export default function CarriersOrderCarNumber(props: PropsId) {
    const element = useCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.carNumber}
        </Fragment>
    )
}
