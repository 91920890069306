import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorUserNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IUserModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      fullName: string,
      fullNameRu: string,
      email: string,
      permissionGroup: string,
      disabled: boolean,
      userTomSettings: {
              id: number,
            },
      userDpsSettings: {
              id: number,
            },
      userManagerSettings: {
              id: number,
            },
  }

export const IUserFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "fullName",
    "fullNameRu",
    "email",
    "permissionGroup",
    "disabled",
    "userTomSettings.id",
    "userDpsSettings.id",
    "userManagerSettings.id"
];

export function useUserHookNae(id: number) : IUserModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorUserNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "user",
        fields: IUserFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
