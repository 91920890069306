import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';

import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import {
    checkUserOperationalPro,
    checkUserOperational,
    checkUserForwardingManager,
    checkUserHeadOfLogisticsHelper,
    checkUserAccounting,
    checkUserFinances,
} from '../../../_generated/_custom/config/NaeSPermissions';

interface Props {
    elementId: number
}

interface IData {
    contact: {
        id: number,
    },
    expeditor: {
        id: number,
    },
    calculation: {
        id: number,
        purpose: number,
    },
    status: number,
}
const defObject: IData = {
    contact: {
        id: 0,
    },
    expeditor: {
        id: 0,
    },
    calculation: {
        id: 0,
        purpose: 0,
    },
    status: 0,
}

export default function CargoContactWidget(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);

    const { t } = useTranslation();
    const { userState } = useTemplatesCore()
    const isForwaringManager = checkUserForwardingManager(userState);
    const isOperationsDepartmentAdministrator = checkUserHeadOfLogisticsHelper(userState);

    const isHeadOfDocumentProcessingDepartment = checkUserOperationalPro(userState);
    const isDocumentProcessingManager = checkUserOperational(userState);
    const isOfficeManager = checkUserAccounting(userState);
    const isAccountatn = checkUserFinances(userState);

    const openPopup = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: "contact"
        })
    }

    if (!element) {
        return <Fragment />
    }
    if (!calculation) {
        return <Fragment />
    }

    if ((isForwaringManager || isOperationsDepartmentAdministrator || isHeadOfDocumentProcessingDepartment || isDocumentProcessingManager || isOfficeManager || isAccountatn)) {
        return <Fragment />
    }
    if (!(!!calculation && calculation.purpose !== 10)) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />;
    }

    return (
        <ToolbarButton
            iconName='edit'
            onClick={openPopup}
            title={t("Change contact person")}
            bgColor='bg-transparent'
            small={true}
        />
    )
}
