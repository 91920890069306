import React, { Fragment } from 'react'
import ClientNameWithLink from '../Client/ClientNameWithLink';
import { PropsId } from '../types';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationClientNameWithLink(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element || !element.client) {
        return <Fragment />
    }

    return (
        <ClientNameWithLink id={element.client.id} />
    )
}
