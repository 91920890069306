import React, { Fragment } from "react";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";

export default function CargoLogisticSellerInfoType50(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  if (!element) {
    return <Fragment />
  }
  if (element.typeIn !== 'none') {
    return <Fragment />
  }
  const isLogistic =
    !!element.expeditor && userState.id === element.expeditor.id;
  if (!(
    (element.status < 15 && element.typeIn === 'none' && !!element.salesOrder && isLogistic && element.contactedSeller) ||
    (isAdmin)
  )
  ) {
    return <Fragment />
  }

  return (
    <WhiteCard isCompact={true}>
      <MainEditContentInline
        schema={"cargo"}
        type={"cargo-seller-info-sender"}
        id={element.id.toString()}
        isCompact={true}
      />
    </WhiteCard>
  );
}
