import React, { Fragment } from 'react'
import { PropsId } from '../types';
import SalesOrderUnloadResponsiblePersonOrContactFullName from '../SalesOrder/SalesOrderUnloadResponsiblePersonOrContactFullName';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoSalesOrderUnloadResponsiblePersonOrContactFullName(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.salesOrder) {
        return <Fragment />
    }
    return (
        <SalesOrderUnloadResponsiblePersonOrContactFullName id={element.salesOrder.id} />
    )
}
