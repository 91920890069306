import React, { useState, Fragment, useEffect } from 'react'

import { useTranslation } from 'react-i18next';

import CargoSerialNumberWithLinkAndIcons from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndIcons';
import CargoOutgoingDocumentsChangeBadge from '../../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeBadge';

import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { checkUserForwardingManager, checkUserHeadOfLogisticsHelper } from '../../../../_generated/_custom/config/NaeSPermissions';
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { FieldInput } from '@newageerp/v3.bundles.form-bundle';
import { defTableSort, getPropertyTitleForSchema } from '../../../../_generated/v3/utils';
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../../_generated/_custom/config/NaePaths';

interface IDataCargo {
    id: number,
    extraTotalPenalty: number,
    extraTotalDowntime: number,
    extraTotalCustomsInspection: number,
    serialNumber: string,
    status: number,
    outgoingDocumentsChange: number,
    name: string,
    ldm: number,
    cbm: number,
    allCargoWeight: number,
    ldmCalculationType: number,
    cargoNotes: string,
    isExtraSvh: boolean,
    extraServiceDeliveryClient: number,
    needTemperatureMode: number,
}

const defObject: IDataCargo = {
    id: 0,
    extraTotalPenalty: 0,
    extraTotalDowntime: 0,
    extraTotalCustomsInspection: 0,
    serialNumber: '',
    status: 0,
    outgoingDocumentsChange: 0,
    name: '',
    ldm: 0,
    cbm: 0,
    allCargoWeight: 0,
    ldmCalculationType: 0,
    cargoNotes: '',
    isExtraSvh: false,
    extraServiceDeliveryClient: 0,
    needTemperatureMode: 0,
}

interface Props {
    elementId: number;
    unloadOnSave?: boolean,
    onSave?: () => void,
}

export default function TablePenalties(props: Props) {

    const elementId = props.elementId;

    let element = useKzCarriersOrderHookNae(elementId);

    const [getData, getDataParams] = useUList<IDataCargo>("cargo", getKeysFromObject(defObject));

    const loadData = () => {
        getData(
            [
                {
                    "and": [
                        ['i.kzCarriersOrder', '=', elementId, true]
                    ]
                }
            ],
            1,
            200,
            [
                defTableSort
            ]
        )
    };

    const data: IDataCargo[] = getDataParams.data.data;
    useEffect(loadData, [props.elementId]);

    const [saveData, saveDataParams] = useURequest(NaePathsMap.post['saveCargoPenalties']);
    const {userState} = useTemplatesCore()
    const isFm = checkUserForwardingManager(userState);
    const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);

    const fillPenalties = (_element: any) => {
        return [...data.map((cargo: IDataCargo) => (
            {
                id: cargo.id,
                extraTotalPenalty: cargo.extraTotalPenalty,
                extraTotalDowntime: cargo.extraTotalDowntime,
                extraTotalCustomsInspection: cargo.extraTotalCustomsInspection,
                kz: false,
            }
        )), {
            id: _element ? _element.id : -1,
            extraTotalPenalty: _element ? _element.extraTotalPenalty : 0,
            extraTotalDowntime: _element ? _element.extraTotalDowntime : 0,
            extraTotalCustomsInspection: _element ? _element.extraTotalCustomsInspection : 0,
            extraTotalSvh: _element ? _element.extraTotalSvh : 0,
            extraTotalDeliveryClient: _element ? _element.extraTotalDeliveryClient : 0,
            extraTotalTemperatureMode: _element ? _element.extraTotalTemperatureMode : 0,
            kz: true,
        }]
    }

    const [penalties, setPenalties] = useState<any[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (element && data.length > 0) {
            setPenalties(fillPenalties(element));
        }
    }, [element, data]);

    if (!element || data.length === 0 || !penalties || penalties.length === 0) {
        return <Fragment />;
    }

    const doSave = () => {
        saveData({ data: penalties, unloadOnSave: props.unloadOnSave, id: props.elementId });
        if (props.onSave) {
            props.onSave();
        }
    }

    let ldmTotal = 0;
    let weightTotal = 0;
    let cbmTotal = 0;
    let extraTotalPenaltyTotal = 0;
    let extraTotalDowntimeTotal = 0;
    let extraTotalCustomsInspectionTotal = 0;

    data.forEach((cargo: IDataCargo) => {
        ldmTotal += cargo.ldm;
        weightTotal += cargo.allCargoWeight;
        cbmTotal += cargo.cbm;
    });
    penalties.forEach((cargo: any) => {
        extraTotalPenaltyTotal += cargo.extraTotalPenalty;
        extraTotalDowntimeTotal += cargo.extraTotalDowntime;
        extraTotalCustomsInspectionTotal += cargo.extraTotalCustomsInspection;
    });

    const updateExtraTotal = (val: string, index: number, key: string) => {
        const _penalties = JSON.parse(JSON.stringify(penalties));
        _penalties[index][key] = isNaN(parseFloat(val)) ? 0 : parseFloat(val);
        setPenalties(_penalties);
    }


    return (
        <Fragment>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'serialNumber'
                                    )
                                )}
                            </Th>
                            <Th>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'name'
                                    )
                                )}
                            </Th>
                            <Th textAlignment='text-right'>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'ldm'
                                    )
                                )}
                            </Th>
                            <Th textAlignment='text-right'>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'cbm'
                                    )
                                )}
                            </Th>
                            <Th textAlignment='text-right'>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'weight'
                                    )
                                )}
                            </Th>
                            <Th textAlignment='text-right'>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'ldmCalculationType'
                                    )
                                )}
                            </Th>
                            <Th>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        'cargoNotes'
                                    )
                                )}
                            </Th>

                            <Th>{t('For additional unloads')}</Th>
                            <Th>{t('Delivery to the customer')}</Th>
                            <Th>{t('Temperature mode')}</Th>

                            <Th>{t('Penalty')}</Th>
                            <Th>{t('Downtime/additional costs')}</Th>
                            <Th>{t('Customs inspection')}</Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {data.map(
                            (cargo: IDataCargo, _cargoIndex: number) => {

                                return (
                                    <tr
                                        key={"cargo-rel-" + cargo.id}
                                    >
                                        <Td className={"whitespace-nowrap"}>
                                            <CargoSerialNumberWithLinkAndIcons id={cargo.id} />
                                            <CargoOutgoingDocumentsChangeBadge id={cargo.id} />
                                        </Td>
                                        <Td>
                                            <DfValueView path='cargo.name' id={cargo.id} />
                                        </Td>
                                        <Td textAlignment='text-right'>
                                            <DfValueView path="cargo.ldm" id={cargo.id} />
                                        </Td>
                                        <Td textAlignment='text-right'>
                                            <DfValueView path="cargo.cbm" id={cargo.id} />
                                        </Td>
                                        <Td textAlignment='text-right'>
                                            <DfValueView path="cargo.allCargoWeight" id={cargo.id} />
                                        </Td>
                                        <Td textAlignment='text-right'>
                                            <DfValueView path="cargo.ldmCalculationType" id={cargo.id} />
                                        </Td>
                                        <Td>
                                            <DfValueView path='cargo.cargoNotes' id={cargo.id} />
                                        </Td>
                                        <Td className={cargo.isExtraSvh ? "custom-bg bg-teal-200" : ""}></Td>
                                        <Td className={cargo.extraServiceDeliveryClient === 10 ? "custom-bg bg-teal-200" : ""}>

                                        </Td>
                                        <Td className={cargo.needTemperatureMode === 10 || cargo.needTemperatureMode === 30 ? "custom-bg bg-teal-200" : ""}>

                                        </Td>
                                        <Td>
                                            <FieldInput
                                                value={penalties[_cargoIndex].extraTotalPenalty}
                                                onChange={(e) => updateExtraTotal(e.target.value, _cargoIndex, 'extraTotalPenalty')}
                                                // onBlur={sentData}
                                                readOnly={element.status >= 40 || isTrackingSpecialist}
                                                className="w-[60px]"
                                            />
                                        </Td>
                                        <Td>
                                            <FieldInput
                                                value={penalties[_cargoIndex].extraTotalDowntime}
                                                onChange={(e) => updateExtraTotal(e.target.value, _cargoIndex, 'extraTotalDowntime')}
                                                // onBlur={sentData}
                                                readOnly={element.status >= 40 || isTrackingSpecialist}
                                                className="w-[60px]"
                                            />
                                        </Td>
                                        <Td>
                                            <FieldInput
                                                value={penalties[_cargoIndex].extraTotalCustomsInspection}
                                                onChange={(e) => updateExtraTotal(e.target.value, _cargoIndex, 'extraTotalCustomsInspection')}
                                                // onBlur={sentData}
                                                readOnly={element.status >= 40 || isTrackingSpecialist}
                                                className="w-[60px]"
                                            />
                                        </Td>
                                    </tr>
                                );
                            }
                        )}
                        <tr className={"total-row"}>
                            <Td className={"whitespace-nowrap"} colSpan={7}>
                                <strong>{t('Common')}</strong>
                            </Td>
                            <Td>
                                <FieldInput

                                    value={penalties[data.length].extraTotalSvh}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalSvh')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                            <Td>
                                <FieldInput
                                    value={penalties[data.length].extraTotalDeliveryClient}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalDeliveryClient')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                            <Td>
                                <FieldInput

                                    value={penalties[data.length].extraTotalTemperatureMode}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalTemperatureMode')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                            <Td>
                                <FieldInput

                                    value={penalties[data.length].extraTotalPenalty}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalPenalty')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                            <Td>
                                <FieldInput

                                    value={penalties[data.length].extraTotalDowntime}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalDowntime')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                            <Td>
                                <FieldInput
                                    value={penalties[data.length].extraTotalCustomsInspection}
                                    onChange={(e) => updateExtraTotal(e.target.value, data.length, 'extraTotalCustomsInspection')}
                                    // onBlur={sentData}
                                    readOnly={element.status >= 40 || isTrackingSpecialist}
                                    className="w-[60px]"
                                />
                            </Td>
                        </tr>
                        <tr className={"total-row"}>
                            <Td>{t('Total')}</Td>
                            <Td></Td>
                            <Td textAlignment='text-right'>
                                <DfValueView path="kz-carriers-order.allLdm" id={props.elementId} />
                            </Td>
                            <Td textAlignment='text-right'>
                                <DfValueView path="kz-carriers-order.allCbm" id={props.elementId} />
                            </Td>
                            <Td textAlignment='text-right'>
                                <DfValueView path="kz-carriers-order.allWeight" id={props.elementId} />
                            </Td>
                            <Td></Td>
                            <Td></Td>
                            <Td textAlignment='text-right'>
                                {penalties[data.length].extraTotalSvh.toFixed(2)}
                            </Td>
                            <Td textAlignment='text-right'>
                                {penalties[data.length].extraTotalDeliveryClient.toFixed(2)}
                            </Td>
                            <Td textAlignment='text-right'>
                                {penalties[data.length].extraTotalTemperatureMode.toFixed(2)}
                            </Td>

                            <Td textAlignment='text-right'>
                                {extraTotalPenaltyTotal.toFixed(2)}
                            </Td>
                            <Td textAlignment='text-right'>
                                {extraTotalDowntimeTotal.toFixed(2)}
                            </Td>
                            <Td textAlignment='text-right'>
                                {extraTotalCustomsInspectionTotal.toFixed(2)}
                            </Td>

                        </tr>
                    </tbody>
                }
            />

            {element.status < 40 &&
                <div className={"mt-2 flex justify-end"}>
                    <MainButton color={"teal"} onClick={doSave}>
                        {t(saveDataParams.loading ? 'Submiting' : (props.unloadOnSave ? 'Finish' : 'Save'))}
                    </MainButton>

                </div>
            }
        </Fragment>
    )
}
