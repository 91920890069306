import React, { Fragment } from "react";
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props {
  fieldKey: string,
}

export default function ClientStatus(props: Props) {
  const { data: tData } = useTemplatesLoader();
  const { element } = tData;
  const value = element[props.fieldKey];

  return (
    <Fragment>
      {element.clientStatus ? (
        <StatusWidget color={element.clientStatus.colorManager}>
          {element.clientStatus.titleManager}
        </StatusWidget>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
}
