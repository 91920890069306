import React, { Fragment } from "react";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCalculationHookNae } from "../../../_generated/_custom/hooks/useCalculationHookNae";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

interface Props extends PropsId {
  sm?: boolean;
}

export default function CargoWorkflowMultimodal(props: Props) {
  const element = useCalculationHookNae(props.id);

  if (!element || element.status < 5 || element.cargos.length === 0) {
    return <Fragment />;
  }
  if (!element.cargoRailway) {
    return <Fragment />
  }

  return (
    <WhiteCard isCompact={true}>
      <MainEditContentInline
        schema={NaeSSchemaMap.CargoRailway.schema}
        type={"main"}
        id={element.cargoRailway.id.toString()}
        isCompact={true}
      />
    </WhiteCard>
  )
}
