import React, { useEffect, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { useOutgoingDocumentsChangePurposeHookNae } from "../../_generated/_custom/hooks/useOutgoingDocumentsChangePurposeHookNae";
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle';
import { MainButtonWithSave } from "@newageerp/v3.bundles.buttons-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { useCargoOperationConfirmationHookNae } from "../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";

interface IData {
  outgoingDocumentsChange: number;
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean;
  };
}
const defObject: IData = {
  outgoingDocumentsChange: 0,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: false,
  },
};

interface IPurpose {
  name: string;
  id: number;
}

export default function OperativeDocsChange(props: PropsId) {
  const [showChangesPopup, setShowChangesPopup] = useState(false);

  const element = useCargoHookNae(props.id);
  const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)

  const outgoingDocumentsChangeEl = useOutgoingDocumentsChangePurposeHookNae(
    element && element.outgoingDocumentsChangePurpose
      ? element.outgoingDocumentsChangePurpose.id
      : -1
  );

  const { t } = useTranslation();

  const [getPurposes, getPurposesParams] = useUList<IPurpose>(
    NaeSSchemaMap.OutgoingDocumentsChangePurpose.schema,
    ["id", "name"]
  );

  useEffect(() => {
    if (showChangesPopup && getPurposesParams.data.data.length === 0) {
      getPurposes(
        [
          {
            and: [["i.needConfirmMail", "num_eq", 0]],
          },
        ],
        1,
        9999
      );
    }
  }, [showChangesPopup]);

  if (!element) {
    return <Fragment />
  }

  if (
    element.outgoingDocumentsChange === 10 &&
    !!outgoingDocumentsChangeEl &&
    !outgoingDocumentsChangeEl.needConfirmMail
  ) {
    return (
      <MenuItemWithSave
        elementId={props.id}
        saveData={{
          outgoingDocumentsChange: 0,
          outgoingDocumentsChangePurpose: null,
        }}
        schema={"cargo"}
        confirmation={true}
        iconName="ban"
      >
        {t("Cancel outgoing documents changes")}
      </MenuItemWithSave>
    );
  }

  if (element.outgoingDocumentsChange !== 0) {
    return <Fragment />;
  }

  const purposes = getPurposesParams.data.data;

  let disabledOutContent = undefined;
  if (confirmElement && confirmElement.confirmed && !confirmElement.headActiveChanges) {
    disabledOutContent = "Documents for customs are already prepared. Please contact responsible customs broker to make any changes.";
  }

  return (
    <Fragment>
      <MenuItem
        iconName="file"
        isDisabled={!!disabledOutContent}
        tooltipContent={disabledOutContent}
        onClick={() => setShowChangesPopup(true)}
      >
        {t('Outgoing documents are being changed')}
      </MenuItem>
      {showChangesPopup && <Popup onClick={() => setShowChangesPopup(false)} title={""} isPopup={true}>
        <div className={"space-y-2"}>
          {purposes.map((p) => {
            return (
              <MainButtonWithSave
                key={"p-" + p.id}
                elementId={props.id}
                schema={"cargo"}
                saveData={
                  {
                    outgoingDocumentsChange: 10,
                    outgoingDocumentsChangePurpose: { id: p.id },
                    addNote: {
                      content: `Outgoing documents are being changed: ${p.name}`,
                      notify: [element.creator.id]
                    }
                  }
                }
                iconName={"save"}
                confirmation={true}
                color="white"
                onSaveCallback={() => setShowChangesPopup(false)}
              >
                {p.name}
              </MainButtonWithSave>
            );
          })}
        </div>
      </Popup>}
    </Fragment>
  )

}
