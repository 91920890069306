import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CargoLogisticLink from "../../cargo/CargoLogisticLink";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import CargoSalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName";
import CargoTerminalOrSalesOrderAddress from "../../../_generated/_custom/models-cache-data/Cargo/CargoTerminalOrSalesOrderAddress";
import { ftlLogisticCargoFilter } from "./LogisticCalculationFtl";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import ClientStatusLogist from "../../../_generated/_custom/models-cache-data/Client/ClientStatusLogist";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

let reloadTimeout: any = -1;
interface IElement {
  id: number;
}

type Props = {
  userId: number,
}

export default function LogisticCargoReturned(props: Props) {
  const { t } = useTranslation();
  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isLogisticFtl = checkUserLogisticFtl(userState);

  const [getData, getDataParams] = useUList<IElement>(
    "cargo",
    ["id"]
  );
  const loadData = () => {
    let filter: any = [
      ["i.logisticCardIndex", "=", -2, true],
      ["i.status", "in", [5, 7, 10], true],
    ];
    if (isLogisticFtl) {
      filter.push(ftlLogisticCargoFilter);
      filter.push(["i.expeditorCalculation", "=", props.userId, true]);
    } else {
      filter.push(["i.expeditor", "=", props.userId, true]);
    }
    getData([{ and: filter }], 1, 200, [
      { key: "i.serialNumber", value: "ASC" },
    ]);
  };
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-logisticCardIndex--2",
      "cargo-logisticCardIndex--2",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-logisticCardIndex--2",
        "cargo-logisticCardIndex--2"
      );
    };
  }, []);

  if (getDataParams.data.data.length === 0) {
    return <Fragment />;
  }

  return (
    <WhiteCard isCompact={true} className={"border-2 border-yellow-500"} title={t("Returned orders")}>
      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Cargo")}</Th>
              <Th>{t("Seller")}</Th>
              <Th>{t("Client")}</Th>
              <Th></Th>
              <Th>{t("Unloading")}</Th>
              {(isLogisticSea || isLogisticTrain) && <Th>{t("Type of transportation")}</Th>}
              <Th>{t("Comments")}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {getDataParams.data.data.map((c: IElement, cIndex: number) => {
              return (
                <CargoLine id={c.id} />
              )
            })}
          </tbody>
        }
      />
    </WhiteCard>
  );
}


type CargoLineProps = {

} & PropsId;

const CargoLine = (props: CargoLineProps) => {
  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);
  
  const c = useCargoHookNae(props.id);
  if (!c) {
    return <Fragment/>
  }
  return (
    <tr
      key={"cargo-" + c.id}
    >
      <Td>
        <CargoLogisticLink id={c.id} />
        <p>
          <DfValueView path="cargo.creator.fullName" id={c.id} />
        </p>
      </Td>
      <Td>
        <DfValueView path="cargo.seller.name" id={c.id} />
      </Td>
      <Td>
        <CargoSalesOrderClientOrFilialName id={c.id} />
      </Td>
      <Td><ClientStatusLogist id={c.soClientId} /></Td>
      <Td>
        <CargoTerminalOrSalesOrderAddress id={c.id} />
      </Td>
      {(isLogisticSea || isLogisticTrain) && (
        <Td>
          <DfValueView path="cargo.typeIn" id={c.id} />
        </Td>
      )}
      <Td>
        <DfValueView path='cargo.loadingInfoReturnText' id={c.id} />
      </Td>
    </tr>
  );
}