import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderUnloadResponsibleComment(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    return (
        <Fragment>
            {element.unloadResponsibleComment}
        </Fragment>
    )
}
