import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import React from "react";
import {
  CheckUserPermissionComponent,
  ENaeSPermissions,
} from "../../../../_generated/_custom/config/NaeSPermissions";
import { PropsId } from "../../../../_generated/_custom/models-cache-data/types";
import PriceComponent from "./PriceComponent";

export interface IInvoice {
  id: number,
  serialNumber: string,
  date: string,
  total: number,
  currency: {
    currency: string,
  }
}

interface Props {
  element: any;
}

export default function SalesOrderBottomWidget(props: PropsId) {
  
  const permissions = [
    ENaeSPermissions.UserAdmin,
    ENaeSPermissions.UserManager,
    ENaeSPermissions.UserFinances,
    ENaeSPermissions.UserAccounting,
    ENaeSPermissions.UserHeadOfLogistics,
  ];
  return (
    <CheckUserPermissionComponent permissions={permissions}>

      <WhiteCard isCompact={true}>
        <PriceComponent id={props.id} />
      </WhiteCard>
    </CheckUserPermissionComponent>
  );
}
