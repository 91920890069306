import { NaeSSchemaMap } from "../config/NaeSSchema";

export const fieldDependencies: any = {
  "sales-order": {
    clientFilial: ["client.filialsCount"],
    contact: ["client.id"],
    unloadResponsiblePerson: ["client.id"],
    allCargosOneUnload: ['canSeparateCargos'],
    paymentTermDays: ['paymentTermType'],
  },
  [NaeSSchemaMap.Contact.schema]: {
    skype: ["carrier.id"],
    recommended: ["carrier.id"],
    transportServices: ["carrier.id"],
    loadingCountries: ["carrier.id", "unloadingCountries"],
  },

  [NaeSSchemaMap.Client.schema]: {
    paymentTermDays: ['paymentTermType'],
    priority: ['clientStatus.titleManager']
  },
  [NaeSSchemaMap.CarriersOrder.schema]: {
    loadDate: ['status'],
    unloadDate: ['status'],
  },
  [NaeSSchemaMap.KzCarriersOrder.schema]: {
    transportAutoType: ["transportType"],
    carNumber: ["transportType"],
    transportGuaranteeType: ["transportType"],
    vehicleStatus: ["transportType"],
    driver: ["transportType"],
    trip: ["transportType"],

    transportRailwayType: ["transportType"],
    railwayNumber: ["transportType"],

    customsClearance: ["isFtlDirect"],

    loadDate: ['status'],
    unloadDate: ['status'],
  },
  "calculation": {
    cargos: ["purpose"],
    hintPurpose: ["purpose"],
    allCargosOneUnload: ['canSeparateCargos'],
    clientFilial: ["client.filialsCount"],
  },
  "cargo": {
    terminalManual: [],
    needAlkoGlueWithStamps: ["specCargo", "type", "ftlType"],
    needAlkoGlueWithStampsBottles: ["specCargo", "needAlkoGlueWithStamps"],
    needAlkoGlueWithStampsType: ["specCargo", "needAlkoGlueWithStamps"],

    needAlkoGlueWithStampsType2: ["specCargo", "needAlkoGlueWithStamps"],
    needAlkoGlueWithStampsType3: ["specCargo", "needAlkoGlueWithStamps"],
    alcoSubType: ["specCargo"],
    temperatureSettings: ["needTemperatureMode"],
    senderMakeFito: ["specCargo"],
    notesOperations: ["type", "calculation.purpose"],
    terminal: ["type", "ftlType"],
    senderMakeEx: ["cargoStatus.code"],
    senderMakeExCost: ["cargoStatus.code", "calculation.purpose"],
    onlyFullWeight: ["places", "weight"],
    customsCode: [
      "type",
      "modalType",
      "senderTransportType",
      "calculation.purpose",
    ],
    commentLoading: ["type", "senderTransportType", "calculation.purpose"],

    extraServiceDeliveryClient: [
      "type",
      "modalType",
      "senderTransportType",
      "calculation.purpose",
    ],
    extraServiceCooSertificateRenew: [
      "outgoingDocumentsChange",
      "outgoingDocumentsChangePurpose.name",
      "extraServiceCooSertificateNew",
      "calculation.purpose",
    ],
    extraServiceCooSertificateNew: [
      "outgoingDocumentsChange",
      "outgoingDocumentsChangePurpose.name",
      "extraServiceCooSertificateRenew",
      "calculation.purpose",
    ],
    extraServiceCraneHandling: [
      "type",
      "ftlType",
      "ftlOverloadType",
      "modalType",
      "senderTransportType",
      "calculation.purpose",
    ],
    ftlType: ["type"],
    ftlAutoType: ["type", "modalType", "senderTransportType"],
    ftlOverloadType: ["type", "modalType", "senderTransportType", "ftlType"],
    modalType: ["type"],
    senderTransportType: ["type"],
    railwayType: ["type", "modalType", "senderTransportType"],
    preciousMetals: ["type", "modalType", "senderTransportType", "railwayType"],
    tnvedCodes: [
      "type",
      "modalType",
      "senderTransportType",
      "railwayType",
      "preciousMetals",
    ],
    tnvedCodesOperational: [
      "type",
      "modalType",
      "senderTransportType",
      "railwayType",
      "preciousMetals",
    ],
    senderLoadDate: ["type"],
    senderUnLoadDate: ["type"],
    seller: ["calculation.purpose"],
    loadAddress: ["calculation.purpose", "type", "seller.id"],
    loadAddressAdditional: ["calculation.purpose", "type", "seller.id"],
    loadAddressAdditional2: ["calculation.purpose", "type", "seller.id"],
    contact: ["calculation.purpose", "seller.id"],
    referenceNumber: ["calculation.purpose", "type"],
    loadDate: ["calculation.purpose"],
    commentProcessing: ["calculation.purpose"],
    cargoDescription: ["calculation.purpose"],
    loadingCountry: ["calculation.purpose", "type"],
    loadingCountryRegion: ["calculation.purpose", "type"],
    loadingExactAddress: ["calculation.purpose", "type"],
    places: ["calculation.purpose"],
    dimensions: ["calculation.purpose"],
    weight: ["calculation.purpose"],
    adrClass: ["calculation.purpose"],
    notesLogistic: ["calculation.purpose"],
    cargoStatus: ["calculation.purpose"],
    outgoingDocumentsChange: [
      "calculation.purpose",
      "outgoingDocumentsChangePurpose.name",
    ],
    cargoValue: ["calculation.purpose"],
    cargoValueCurrency: ["calculation.purpose"],
    salesOrder: ["soClientJson.id"],
  },
}

export const getDepenciesForField = (schema: string, key: string) => {
  if (!!fieldDependencies[schema] && !!fieldDependencies[schema][key]) {
    return fieldDependencies[schema][key];
  }
  return [];
};
