import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import CargoOutgoingDocumentsChangeBadge from '../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeBadge';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import CargoAlco from './CargoAlco';
import CargoIsAdrBadge from '../../../_generated/badges/cargo/CargoIsAdrBadge';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';
import OldBadge, { BadgeBgColor, BadgeSize } from '../../../core/old-ui/OldBadge';
import CargoCargoCheckForDualUseBadge from '../../../_generated/badges/cargo/CargoCargoCheckForDualUseBadge';
import CargoAviaGoodsLoadingTypeBadge from '../../../_generated/badges/cargo/CargoAviaGoodsLoadingTypeBadge';
import CargoCargoDocumentsCollectedBadge from '../../../_generated/badges/cargo/CargoCargoDocumentsCollectedBadge';

interface Props extends PropsId {
    skipUnloadInfo: boolean,
}

export default function CargoBadges(props: Props) {
    const { skipUnloadInfo } = props;

    const element = useCargoHookNae(props.id);

    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }

    return (
        <div className="flex gap-1 flex-wrap">
            {!skipUnloadInfo && (
                <CargoOutgoingDocumentsChangeBadge id={element.id} />
            )}
            <CargoIsAdrBadge id={props.id} isCompact={true} />

            <CargoCargoCheckForDualUseBadge id={props.id} isCompact={true} />

            <CargoCargoDocumentsCollectedBadge id={props.id} isCompact={true} />
            {/* {!!adrClass && adrClass.name !== "Not dangerous" && (
                <Badge bgColor={BadgeBgColor.yellow} size={BadgeSize.sm}>
                    {t('ADR')} {adrClass.name}
                </Badge>
            )} */}
            <CargoAlco id={element.id} />
            {element.specCargo === 30 && (
                <StatusWidget color='blue' smallPadding={true}>
                    {t('Phytosanitary certificate')}
                </StatusWidget>
            )}

            {element.extraServiceDeliveryClient === 10 && !skipUnloadInfo && (
                <StatusWidget color='slate' smallPadding={true}>
                    {t('Delivery to client')}
                </StatusWidget>
            )}
            {element.extraServiceCraneHandling === 10 && (
                <StatusWidget color='slate' smallPadding={true}>
                    {t('Perkrovimas su kranu')}
                </StatusWidget>
            )}
            {element.extraServiceCooSertificateRenew === 10 && !skipUnloadInfo && (
                <StatusWidget color='slate' smallPadding={true}>
                    {t('COO sertifikato perdarymas')}
                </StatusWidget>
            )}
            {element.extraServiceCooSertificateNew === 10 && !skipUnloadInfo && (
                <StatusWidget color='slate' smallPadding={true}>
                    {t('COO sertifikato išleidimas (naujas)')}
                </StatusWidget>
            )}
            {element.cargoInsurance && !skipUnloadInfo && (
                <StatusWidget color='slate' smallPadding={true}>
                    {t('Extra insurance')}
                </StatusWidget>
            )}

            {(element.typeIn === 'ftl' && element.typeOut === 'ftl') && element.ftlType === 0 && (
                <Fragment>
                    <StatusWidget smallPadding={true} color={"sky"}>
                        {element.ftlOverloadType === 0
                            ? "Palletized reloading"
                            : "Manual reloading"}
                    </StatusWidget>
                </Fragment>
            )}

            {element.typeOut === 'avia' &&
                <CargoAviaGoodsLoadingTypeBadge id={element.id} isCompact={true} />
            }
        </div>
    )
}
