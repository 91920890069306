import React, { Fragment } from "react";
import { PropsId } from "../types";
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { getPropertyDataForSchema, getPropertyEnumLabel } from "../../../v3/utils";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";

export default function CargoRailwayTypeWithHint(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const property = getPropertyDataForSchema(
    "cargo",
    "railwayType"
  );

  if (!element) {
    return <Fragment />;
  }
  if (element.typeOut !== "train") {
    return <Fragment />;
  }
  return (
    <div className="flex gap-2 items-center">
      {getPropertyEnumLabel(
        "cargo",
        "railwayType",
        element.railwayType
      )}
      {!!property && !!property.description && (
        <Tooltip text={property.description} />
      )}
    </div>
  );
}
