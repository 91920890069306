import React, { Fragment, useState } from "react";
import "@szhsin/react-menu/dist/index.css";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../_generated/_custom/hooks/useCalculationHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTerminalHookNae } from '../../_generated/_custom/hooks/useTerminalHookNae';
import { getLinkForFile } from '@newageerp/v3.bundles.data-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../_generated/_custom/config/NaeSPermissions";
import { useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import moment from "moment";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

type Props = {
  sm?: boolean;
  ids: number[];
}

const langs: any = {
  en: "English",
  lt: "Lietuviškai",
  ru: "По-русски",
  pl: "Po polsku",
};

export default function CargoSenderMailBtn(props: Props) {
  const [isPopup, setIsPopup] = useState(false);

  const element = useCargoHookNae(props.ids[0]);
  const terminal = useTerminalHookNae(element && element.terminal ? element.terminal.id : -1);

  const calculation = useCalculationHookNae(
    element ? element.calculation.id : -1
  );

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const [saveMultiple, saveMultipleParams] = useURequest(NaePathsMap.post['NAEUSaveMultiple']);

  const { t } = useTranslation();

  if (!element) {
    return <Fragment />;
  }
  if (!calculation) {
    return <Fragment />;
  }

  const isLogistic =
    !!element.expeditor && userState.id === element.expeditor.id;
  const isLogisticCalculation =
    !!element.expeditorCalculation &&
    userState.id === element.expeditorCalculation.id;
  const calcStatus = !!calculation ? calculation.status : 0;

  if (!(isLogistic || isAdmin || (isLogisticCalculation && calcStatus === 3))) {
    return <Fragment />;
  }

  const title = element.contactedSeller ? t("Resend first email to the Seller") : t("First email to the Seller");

  const openEmail = (lang: string) => {
    const files = [
      {
        name: 'NON DUAL USE SAMPLE.docx',
        link: 'https://files.caspianlogistics.lt/api/d/D6SMz-qRER6LadXPraYCbrIkBiqE/NON_DUAL_USE_DRAFT.docx',
      },
      // {
      //   name: 'LETTER DRAFT.docx',
      //   link: 'https://files.caspianlogistics.lt/api/d/D6SMz-qRER6LadXPraYCbrIkBiqE/LETTER_DRAFT.docx',
      // },
      // {
      //   name: 'Manufacturers declaration.docx',
      //   link: 'https://files.caspianlogistics.lt/api/d/D6SMz-qRER6LadXPraYCbrIkBiqE/manufacturers_declaration.docx',
      // }
    ];
    if (terminal && terminal.attorneyFile) {
      files.push(
        {
          name: terminal.attorneyFile.filename,
          link: getLinkForFile(
            terminal.attorneyFile
          ),
        }
      );
    }

    const options = {
      extraData: {
        id: element.id,
        schema: "cargo",
        template: "sender-loading-email",
        lang: lang,
        type: 'cargo-sender',
      },
      files: files,
      onSend: () => {
        onSaveAfterSendMail();
      }
    }
    const event = new CustomEvent(
      'SFSOpenEmailForm',
      {
        detail: options
      }
    );
    window.dispatchEvent(event);
    setIsPopup(false);
  }

  const onSaveAfterSendMail = async () => {
    await saveMultiple({
      schema: "cargo",
      fieldsToReturn: ['id'],
      data: props.ids.map((id) => {
        return (
          {
            id: id,
            data: { contactedSeller: true, contactedSellerTime: moment().format('YYYY-MM-DD HH:mm:00') }
          }
        )
      })
    })
  }

  return (
    <Fragment>
      {saveMultipleParams.loading ? (
        t("Sending...")
      ) : (
        <Fragment>
          <MenuItem onClick={() => setIsPopup(true)} iconName="paper-plane" className="whitespace-nowrap" textColor={element.contactedSeller ? undefined : "sky"}>
            {title}
          </MenuItem>

          {isPopup &&
            <Popup title={title} isPopup={true} onClick={() => setIsPopup(false)}>
              <div className="space-y-2">
                {Object.keys(langs).map((_lang: string) => (
                  <MainButton
                    key={"lang-" + _lang}
                    color="white"
                    className="w-[350px]"
                    onClick={() => {
                      openEmail(_lang);
                    }}
                  >
                    {langs[_lang]}
                  </MainButton>
                ))}
                <MainButton
                  color="white"
                  className="w-[350px]"
                  onClick={async () => {
                    await onSaveAfterSendMail();
                    setIsPopup(false);
                  }}
                >
                  {t("Continue without letter")}
                </MainButton>
              </div>
            </Popup>
          }

        </Fragment>
      )}
    </Fragment>
  );
}
