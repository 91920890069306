import React from 'react'
import { useTranslation } from 'react-i18next';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

export default function MainActionPresentation(props: PropsId) {
    const { t } = useTranslation();

    const openEmail = () => {
        const options = {
            extraData: {
                id: props.id,
                schema: "client",
                template: "presentation"
            }
        }
        const event = new CustomEvent(
            'SFSOpenEmailForm',
            {
                detail: options
            }
        );
        window.dispatchEvent(event);
    }


    return (
        <MainButton color="sky" iconName="paper-plane" onClick={openEmail} large={true}>
            {t("Presentation")}
        </MainButton>
    )
}
