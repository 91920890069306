import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle';
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../_generated/_custom/config/NaeSPermissions';

export default function CargoRevertToStatus5(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }

    const disabled = (element.status !== 10);


    return (
        <CheckUserPermissionComponent
            permissions={[
                ENaeSPermissions.UserAdmin,
                ENaeSPermissions.UserHeadOfLogistics,
                ENaeSPermissions.UserLogistic,
                ENaeSPermissions.UserLogisticSea,
                ENaeSPermissions.UserLogisticTrain,
                ENaeSPermissions.UserLogisticFtl,
            ]}
        >
            <MenuItemWithSave
                elementId={element.id}
                saveData={{
                    status: 5,
                    loadDateSellerFact: null,
                }}
                schema={"cargo"}
                confirmation={true}
                iconName={'truck-loading'}
                isDisabled={disabled}
            >
                {t('Bring back to awaiting loading from seller')}
            </MenuItemWithSave>
        </CheckUserPermissionComponent>
    )
}
