import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import CalculationReturnLogistic from './CalculationReturnLogistic';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

export default function CargoCalculationReturnLogistic(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.calculation) {
        return <Fragment />
    }
    return (
        <CalculationReturnLogistic id={element.calculation.id} />
    )
}
