import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import PriceBlock from "../../../../Plugins/SalesOrder/BottomWidget/PriceBlock";
import PayedBlock from "../../../../Plugins/SalesOrder/BottomWidget/PayedBlock";
import DebtBlock from "../../../../Plugins/SalesOrder/BottomWidget/DebtBlock";
import PaymentGraphComponent from "../../../../Plugins/SalesOrder/BottomWidget/PaymentGraphComponent";
import PaymentDatesBlock from "../../../../Plugins/SalesOrder/BottomWidget/PaymentDatesBlock";
import { PropsId } from "../../../../_generated/_custom/models-cache-data/types";

import { TabContainer } from "@newageerp/v3.bundles.layout-bundle";
import ElementDelayInDaysRed from "../../../../Plugins/SalesOrder/BottomWidget/ElementDelayInDaysRed";
import { useSalesOrderHookNae } from "../../../../_generated/_custom/hooks/useSalesOrderHookNae";
import {
  ENaeSPermissions,
  checkUserAdmin,
  checkUserFinances,
} from "../../../../_generated/_custom/config/NaeSPermissions";
import { TemplatesLoader, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAccounting } from '../../../../_generated/_custom/config/NaeSPermissions';

export default function PriceComponent(props: PropsId) {
  const { t } = useTranslation();
  const element = useSalesOrderHookNae(props.id);

  const commisionExpensePermissions = [
    ENaeSPermissions.UserAdmin,
    ENaeSPermissions.UserManager,
  ];

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isFinances = checkUserFinances(userState);
  const isUserAcounting = checkUserAccounting(userState);

  if (!element) {
    return <Fragment />;
  }

  const items = [];

  if (!isFinances) {
    items.push(
      {
        content: (
          <div className={"grid grid-cols-3 gap-2 items-start text-sm"}>
            <PriceBlock id={props.id} />
            <PayedBlock id={props.id} />
            <DebtBlock id={props.id} />
          </div>
        ),
        tab: {
          children: t("Sums"),
        },
      }
    );
  }
  if (!isFinances && !isUserAcounting) {
    items.push(
      {
        content: (
          <div className={"grid grid-cols-3 gap-2 items-start text-sm"}>
            <PaymentDatesBlock id={props.id} />

            <div className={"col-span-2"}>
              <PaymentGraphComponent id={props.id} />
            </div>
          </div>
        ),
        tab: {
          children: (
            <Fragment>
              {t("Payment terms")} <ElementDelayInDaysRed id={props.id} />
            </Fragment>
          ),
        },
      }
    );
  }

  if (!isFinances && !isUserAcounting) {
    items.push(
      {
        content: (
          <TemplatesLoader
            templateName="ListRelContent"
            data={{
              schema: 'sales-order-price-change',
              type: 'so-rel',
              rel: 'salesOrder',
              id: props.id,
              wrap: 0,
            }}
          />
        ),
        tab: {
          children: <Fragment>{t("Sales order price changes")} ({element.priceChanges.length})</Fragment>,
        },
      }
    );
  }

  // items.push(
  //   {
  //     content: (
  //       <div className={"grid grid-cols-3 gap-2 items-start text-sm"}>
  //         <div className={"col-span-3"}>
  //           {/* <InvoiceBlock id={props.id} /> */}
  //           <TemplateLoader
  //             templateName="ListRelContent"
  //             data={{
  //               schema: 'invoice-outgoing',
  //               type: 'so-rel',
  //               rel: 'salesOrder',
  //               id: props.id,
  //               wrap: 0,
  //             }}
  //           />
  //         </div>
  //       </div>
  //     ),
  //     tab: {
  //       children: <InvoiceTitleComponent id={props.id} />,
  //     },
  //   }
  // );
  // items.push(
  //   {
  //     content: (
  //       <TemplateLoader
  //         templateName="ListRelContent"
  //         data={{
  //           schema: 'invoice-outgoing-price-change',
  //           type: 'so-rel',
  //           rel: 'salesOrder',
  //           id: props.id,
  //           wrap: 0,
  //         }}
  //       />
  //     ),
  //     tab: {
  //       children: <Fragment>{t("Sąskaitų kainos pakeitimai")} ({element.invoicePriceChanges.length})</Fragment>,
  //     },
  //   }
  // );

  if (!isUserAcounting) {
    items.push(
      {
        content: (
          <TemplatesLoader
            templateName="ListRelContent"
            data={{
              schema: 'payment',
              type: 'so-rel',
              rel: 'salesOrder',
              id: props.id,
              wrap: 0,
            }}
          />

        ),
        tab: {
          children: <Fragment>{t("Payments")}</Fragment>,
        },
      }
    );
  }

  if (isAdmin || isUserAcounting) {
    items.push({
      content: (
        <TemplatesLoader
          templateName="ListRelContent"
          data={{
            schema: 'commission-expense',
            type: 'so-rel',
            rel: 'salesOrder',
            id: props.id,
            wrap: 0,
          }}
        />
      ),
      tab: {
        children: <Fragment>{t("Commision expenses")}</Fragment>,
      },
    });
  }

  return <TabContainer items={items} />;
}
