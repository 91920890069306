import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { useKzCarriersOrderHookNae } from '../../_generated/_custom/hooks/useKzCarriersOrderHookNae'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

export default function UserDpsToolbar(props: PropsId) {
    const { t } = useTranslation();

    const element = useKzCarriersOrderHookNae(props.id);

    return (
        <Fragment>
            {!!element && !!element.userDps && (
                <div className={classNames('text-sm whitespace-nowrap')}>
                    <p className={classNames('text-slate-700')}>
                        {t('Document Processing Specialist')}
                    </p>
                    <p className={classNames('text-slate-400')}>
                        <DfValueView path="kz-carriers-order.userDps.fullName" id={element.id} />
                    </p>
                </div>
            )}
        </Fragment>
    )
}
