import React, { Fragment, useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';

import CargoUnloadWarehouseDateOrPoUnloadDateStr from "../../../../_generated/_custom/models-cache-data/Cargo/CargoUnloadWarehouseDateOrPoUnloadDateStr";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { StatusWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { defTableSort, getPropertyTitleForSchema } from "../../../../_generated/v3/utils";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { BackBtn, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from '../../../../_generated/_custom/hooks/useSalesOrderHookNae';
import classNames from "classnames";
import { ToolbarButton, MainButton, ToolbarButtonWithMenu } from '@newageerp/v3.bundles.buttons-bundle';
import { FieldInput, FieldSelect } from "@newageerp/v3.bundles.form-bundle";
import { useClientHookNae } from '../../../../_generated/_custom/hooks/useClientHookNae';
import { TableCargosBorderColors } from "./TableCargos/TableCargos";
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorClientStatusNae } from "../../../../_generated/_custom/models/ormSelectors";
import { IClientStatusModelNae, useClientStatusHookNae } from "../../../../_generated/_custom/hooks/useClientStatusHookNae";
import { useCargoHoldHookNae } from "../../../../_generated/_custom/hooks/useCargoHoldHookNae";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import moment from "moment";
import SalesOrderClientOrFilialName from "../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { checkUserLogisticTrain } from "../../../../_generated/_custom/config/NaeSPermissions";
import CargoSellerDidEx from "../../../../Plugins/Cargo/CargoSellerDidEx";
import CargoCargoCheckForDualUseBadge from "../../../../_generated/badges/cargo/CargoCargoCheckForDualUseBadge";
import { useUList, useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../../_generated/_custom/config/NaePaths";


interface Props {
  ids: number[];
  onSave: () => void;
  contentBeforeTitle?: ReactNode,
  hideContent?: boolean,
}
interface IData {
  id: number;
  notplannedCargosCount: number,
  salesOrderUnloadGroup: string,
  possibleUnloadDate: string,
  serialNumber: string,
  client: {
    id: number,
    name: string,
    clientStatus: {
      id: number,
      colorLogist: '',
    }
  },
  allLdm: number,
  allCbm: number,
  allCargoValueEur: number,
  allWeight: number,
  cargos: {
    id: number,
    serialNumber: string,
    seller: {
      name: string,
    }
  }[]
}

const defObject: IData = {
  id: 0,
  notplannedCargosCount: 0,
  salesOrderUnloadGroup: '',
  possibleUnloadDate: '',
  serialNumber: '',
  client: {
    id: 0,
    name: '',
    clientStatus: {
      id: 0,
      colorLogist: '',
    }
  },
  allLdm: 0,
  allCbm: 0,
  allCargoValueEur: 0,
  allWeight: 0,
  cargos: [
    {
      id: 0,
      serialNumber: '',
      seller: {
        name: '',
      }
    }
  ]
};

const sortTypes = [
  {
    value: 'by_date_asc',
    label: 'By date ASC'
  },
  {
    value: 'by_date_desc',
    label: 'By date DESC'
  },
  {
    value: 'by_clm_asc',
    label: 'By clm ASC'
  },
  {
    value: 'by_clm_desc',
    label: 'By clm DESC'
  },
  {
    value: 'by_cbm_asc',
    label: 'By cbm ASC'
  },
  {
    value: 'by_cbm_desc',
    label: 'By cbm DESC'
  },
  {
    value: 'by_weight_asc',
    label: 'By weight ASC'
  },
  {
    value: 'by_weight_desc',
    label: 'By weight DESC'
  },
  {
    value: 'by_value_asc',
    label: 'By value ASC'
  },
  {
    value: 'by_value_desc',
    label: 'By value DESC'
  },
]

export default function PlannedCargos(props: Props) {
  const { t } = useTranslation();
  const clientStatuses: IClientStatusModelNae[] = useSelector((state) => SelectorClientStatusNae(state))

  const { userState } = useTemplatesCore()
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const [sortType, setSortType] = useState('by_date_asc');

  const [search, setSearch] = useState('');

  const [popupActiveKey, setPopupActiveKey] = useState('');
  const [popupOrders, setPopupOrders] = useState<IData[]>([]);

  const [showUnavailableDate, setShowUnavailableDate] = useState(true);
  const toggleShowUnavailableDate = () => setShowUnavailableDate(!showUnavailableDate);

  const [ordersToParse, setOrdersToParse] = useState<IData[]>([]);
  const [salesOrdersGrouped, setSalesOrdersGrouped] = useState<any>({});

  const [getSo, getSoParams] = useUList<IData>(
    "sales-order",
    getKeysFromObject(defObject)
  );

  // const [getDataOther, getDataOtherParams] = useUList<IData>(
  //   "cargo",
  //   getKeysFromObject(defObject)
  // );

  const loadData = () => {
    // const filterAnd: any = [
    //   ["i.kzCarriersOrder", "IS_NULL", true, true],
    //   ["i.contactedSeller", "=", true, true],
    //   ["i.status", "in", [5, 10, 15], true],
    // ];
    // const filter = [
    //   {
    //     and: filterAnd,
    //   },
    // ];
    // getDataOther(filter, 1, 2000, [defTableSort]);


    const filterSoAnd: any = [
      ["i.status", "=", 10, true],
    ];
    const filteSo = [
      {
        and: filterSoAnd,
      },
    ];
    getSo(filteSo, 1, 2000, [defTableSort]);
  };
  useEffect(loadData, []);


  useEffect(() => {
    let salesOrders = getSoParams.data.data.filter(el => {
      if (isLogisticTrain && el.salesOrderUnloadGroup !== 'Railway') {
        return false;
      }
      if (el.notplannedCargosCount === 0) {
        return false;
      }
      if (showUnavailableDate) {
        return true;
      }
      if (el.possibleUnloadDate === '2099-01-01') {
        return false;
      }
      return true;
    });
    salesOrders = salesOrders.sort((a, b) => {

      if (sortType === 'by_date_desc') {
        return (a.possibleUnloadDate > b.possibleUnloadDate) ? -1 : 1;
      }

      if (sortType === 'by_clm_asc') {
        return (a.allLdm > b.allLdm) ? 1 : -1;
      }
      if (sortType === 'by_clm_desc') {
        return (a.allLdm > b.allLdm) ? -1 : 1;
      }

      if (sortType === 'by_cbm_asc') {
        return (a.allCbm > b.allCbm) ? 1 : -1;
      }
      if (sortType === 'by_cbm_desc') {
        return (a.allCbm > b.allCbm) ? -1 : 1;
      }

      if (sortType === 'by_weight_asc') {
        return (a.allWeight > b.allWeight) ? 1 : -1;
      }
      if (sortType === 'by_weight_desc') {
        return (a.allWeight > b.allWeight) ? -1 : 1;
      }

      if (sortType === 'by_value_asc') {
        return (a.allCargoValueEur > b.allCargoValueEur) ? 1 : -1;
      }
      if (sortType === 'by_value_desc') {
        return (a.allCargoValueEur > b.allCargoValueEur) ? -1 : 1;
      }

      return (a.possibleUnloadDate > b.possibleUnloadDate) ? 1 : -1;
    }).filter(so => {
      if (!search) {
        return true;
      }
      return so.serialNumber.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0 ||
        so.client.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0 ||
        Math.max(...so.cargos.map(c => c.serialNumber.toLowerCase().indexOf(search.toLowerCase()))) >= 0 ||
        Math.max(...so.cargos.map(c => c.seller.name.toLowerCase().indexOf(search.toLowerCase()))) >= 0;
    });
    setOrdersToParse(salesOrders);
    setSalesOrdersGrouped(groupMap(salesOrders, (el: IData) => el.salesOrderUnloadGroup))
  }, [sortType, getSoParams.data.data, search, showUnavailableDate]);

  const soGroups = Object.keys(salesOrdersGrouped).sort();

  return (
    <WhiteCard isCompact={true} className="w-full">
      <div className={"flex gap-2 items-center"}>
        {!!popupActiveKey &&
          <BackBtn onClick={() => setPopupActiveKey('')} />
        }

        {props.contentBeforeTitle}
        <TextCardTitle>{t("Planning orders")} ({ordersToParse.length})</TextCardTitle>

        <ToolbarButton
          iconName="sync"
          onClick={loadData}
          loading={getSoParams.loading}
        />

        <span className="flex-grow"></span>

        <FieldInput iconName="search" placeholder="search..." value={search} onChange={e => setSearch(e.target.value)} />

        <FieldSelect
          value={sortType}
          onChange={setSortType}
          options={sortTypes}
          className="w-40"
          icon={"sort"}
        />

        {showUnavailableDate ? <MainButton color="bluelight" onClick={toggleShowUnavailableDate}>{t('Hide without unloading dates')}</MainButton> : <MainButton onClick={toggleShowUnavailableDate} color="blue">{t('Show without unloading dates')}</MainButton>}
      </div>

      {!props.hideContent && <Fragment>

        {!popupActiveKey &&
          <Fragment>
            {soGroups.map((group, groupIndex) => {
              const orders: IData[] = salesOrdersGrouped[group];

              const byStatusOrders = groupMap(orders, (o: IData) => o.client && (o.client.clientStatus ? o.client.clientStatus.id : -1).toString());
              const groupedClientStatuses = groupMap(clientStatuses, (o: IClientStatusModelNae) => o.titleLogist);
              const groupedClientStatusesKeys = Object.keys(groupedClientStatuses);

              return (
                <div
                  key={`group-${groupIndex}`}
                  className={
                    classNames(
                      'px-4 py-2',
                      "flex gap-2 items-center"
                    )
                  }>
                  <div
                    className={
                      classNames(
                        'cursor-pointer',
                        'underline text-blue-500 hover:no-underline',
                        'text-lg font-medium'
                      )
                    }
                    onClick={() => {
                      setPopupActiveKey(group);
                      setPopupOrders(orders);
                    }}
                  >
                    {group} ({orders.length})
                  </div>

                  <div className="flex gap-1 items-center">
                    {groupedClientStatusesKeys
                      .filter((_clientStatus: string) => {
                        const _okKeyClientStatuses = groupedClientStatuses[_clientStatus].map((o: IClientStatusModelNae) => o.id.toString());
                        let _orders: any = [];
                        _okKeyClientStatuses.forEach((_oKey: string) => {
                          if (!!byStatusOrders[_oKey] && byStatusOrders[_oKey].length > 0) {
                            _orders = [..._orders, ...byStatusOrders[_oKey]];
                          }
                        })
                        // const _oKey = s.id.toString();
                        // const _orders = byStatusOrders[_oKey];
                        return !!_orders && _orders.length > 0;
                      })
                      .map((_clientStatus: string) => {
                        const _okKeyClientStatuses = groupedClientStatuses[_clientStatus].map((o: IClientStatusModelNae) => o.id.toString());
                        let _orders: any = [];
                        _okKeyClientStatuses.forEach((_oKey: string) => {
                          if (!!byStatusOrders[_oKey] && byStatusOrders[_oKey].length > 0) {
                            _orders = [..._orders, ...byStatusOrders[_oKey]];
                          }
                        })
                        const s: IClientStatusModelNae = groupedClientStatuses[_clientStatus][0];

                        // const _oKey = s.id.toString();
                        // const _orders = byStatusOrders[_oKey];
                        return (
                          <button
                            onClick={() => {
                              setPopupActiveKey(group);
                              setPopupOrders(_orders);
                            }}
                            key={`group-${groupIndex}-${s.id}`}
                          >
                            <StatusWidget

                              // @ts-ignore
                              color={s.colorLogist}
                            >
                              {s.titleLogist} ({_orders.length})
                            </StatusWidget>
                          </button>

                        )
                      })}
                  </div>
                </div>
              )
            })}
          </Fragment>
        }
        {/* 
        {getDataOtherParams.data.data.map((c: IData) => {
          return <CargoEmptyFieldNae id={c.id} key={`cargo-empty-${c.id}`} />;
        })} */}


        {!!popupActiveKey &&
          <OrdersPopup
            title={popupActiveKey}
            orders={popupOrders}
            onClose={() => setPopupActiveKey('')}
            kzIds={props.ids}
            sortType={sortType}
            search={search}
            setSearch={setSearch}
            showUnavailableDate={showUnavailableDate}
          />
        }
      </Fragment>}

    </WhiteCard>
  );
}

type OrdersPopupProps = {
  title: string,
  orders: IData[],
  onClose: () => void,
  kzIds: number[],
  sortType: string,
  search: string,
  setSearch: (val: string) => void,
  showUnavailableDate: boolean,
}

const OrdersPopup = (props: OrdersPopupProps) => {
  const { sortType, search } = props;
  const { t } = useTranslation();

  return <div>

    <Table
      thead={
        <thead>
          <tr>
            <Th ySmall={true}>
              {t(
                getPropertyTitleForSchema(
                  "sales-order",
                  'serialNumber'
                )
              )}
            </Th>
            <Th ySmall={true}>
              <Fragment>
                {t(
                  getPropertyTitleForSchema(
                    "cargo",
                    'cargoNotes'
                  )
                )}
                <br />
                {t(
                  getPropertyTitleForSchema(
                    "cargo",
                    'commentLoading'
                  )
                )}
              </Fragment>
            </Th>
            <Th ySmall={true}>
              {t('Estimated arrival date')}
            </Th>
            <Th ySmall={true}>
              {t('Warehouse')}
            </Th>
            <Th ySmall={true}>
              {t('Who will prepare EX?')}
            </Th>
            <Th ySmall={true} textAlignment={"text-right"}>
              {t(
                getPropertyTitleForSchema(
                  "cargo",
                  'ldm'
                )
              )}
            </Th>
            <Th ySmall={true} textAlignment={"text-right"}>
              {t(
                getPropertyTitleForSchema(
                  "cargo",
                  'cbm'
                )
              )}
            </Th>
            <Th ySmall={true} textAlignment={"text-right"}>
              {t(
                getPropertyTitleForSchema(
                  "cargo",
                  'weight'
                )
              )}
            </Th>
            <Th ySmall={true} textAlignment={"text-right"}>
              {t(
                getPropertyTitleForSchema(
                  "cargo",
                  'cargoValue'
                )
              )}
            </Th>

            <Th ySmall={true}>
              {t('Actions')}
            </Th>
          </tr>
        </thead>
      }
      tbody={
        <tbody>
          {props.orders.filter(el => {

            if (props.showUnavailableDate) {
              return true;
            }
            if (el.possibleUnloadDate === '2099-01-01') {
              return false;
            }
            return true;
          }).sort((a, b) => {

            if (sortType === 'by_date_desc') {
              return (a.possibleUnloadDate > b.possibleUnloadDate) ? -1 : 1;
            }

            if (sortType === 'by_clm_asc') {
              return (a.allLdm > b.allLdm) ? 1 : -1;
            }
            if (sortType === 'by_clm_desc') {
              return (a.allLdm > b.allLdm) ? -1 : 1;
            }

            if (sortType === 'by_cbm_asc') {
              return (a.allCbm > b.allCbm) ? 1 : -1;
            }
            if (sortType === 'by_cbm_desc') {
              return (a.allCbm > b.allCbm) ? -1 : 1;
            }

            if (sortType === 'by_weight_asc') {
              return (a.allWeight > b.allWeight) ? 1 : -1;
            }
            if (sortType === 'by_weight_desc') {
              return (a.allWeight > b.allWeight) ? -1 : 1;
            }

            if (sortType === 'by_value_asc') {
              return (a.allCargoValueEur > b.allCargoValueEur) ? 1 : -1;
            }
            if (sortType === 'by_value_desc') {
              return (a.allCargoValueEur > b.allCargoValueEur) ? -1 : 1;
            }

            return (a.possibleUnloadDate > b.possibleUnloadDate) ? 1 : -1;
          }).filter(so => {
            if (!search) {
              return true;
            }
            return so.serialNumber.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0 ||
              so.client.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0 ||
              Math.max(...so.cargos.map(c => c.serialNumber.toLowerCase().indexOf(search.toLowerCase()))) >= 0 ||
              Math.max(...so.cargos.map(c => c.seller.name.toLowerCase().indexOf(search.toLowerCase()))) >= 0;
          })

            .map((order: IData) => {
              return <SoLine
                key={`order-${order.id}`}
                id={order.id}
                kzIds={props.kzIds}
                orders={props.orders}
                setSearch={props.setSearch}
              />
            })}
        </tbody>
      }
    />
  </div>
}

type SoLineProps = {
  kzIds: number[],
  orders: IData[],
  setSearch: (val: string) => void,
} & PropsId;

const SoLine = (props: SoLineProps) => {
  const element = useSalesOrderHookNae(props.id);
  const client = useClientHookNae(element ? element.client.id : -1);

  const clientId = client ? client.id : -1;
  const soCount = props.orders.filter((i) => i.client.id === clientId).length;

  const clientStatus = useClientStatusHookNae(client && client.clientStatus ? client.clientStatus.id : -1);

  const colorForLogist = clientStatus ? clientStatus.colorLogist : 'white';
  const titleForLogist = clientStatus ? clientStatus.titleLogist : '';

  const { t } = useTranslation();

  const [saveMultiple, saveMultipleParams] = useURequest(NaePathsMap.post['NAEUSaveMultiple']);
  const addMultipleCargos = (kzId: number) => {
    saveMultiple({
      schema: "cargo",
      fieldsToReturn: ['id'],
      data: element.cargos.map((cargo) => {
        return (
          {
            id: cargo.id,
            data: { kzCarriersOrder: { id: kzId } }
          }
        )
      })
    })
  }

  if (!element) {
    return <Fragment />
  }

  const style = { boxShadow: 'inset 0px 2px rgba(12, 74, 110, .3)' };

  return (
    <Fragment>
      <tr className="font-light">
        <Td
          ySmall={true}
          xSmall={true}
          style={style}
        // className={
        //   classNames(
        //     // "max-w-[150px]",
        //     'border-l-4',
        //     // @ts-ignore
        //     TableCargosBorderColors[colorForLogist]
        //   )
        // }
        >
          <DfValueView path="sales-order.serialNumber" id={element.id} link={true} />
        </Td>
        <Td style={style}
          ySmall={true}
          xSmall={true}
        >
          <p className={
            classNames(
              // "max-w-[150px]",
              'border-b-2',
              // @ts-ignore
              TableCargosBorderColors[colorForLogist],
              'font-medium'
            )
          }>
            <Fragment>
              <SalesOrderClientOrFilialName id={element.id} /> (<span>{titleForLogist}</span>) {soCount > 1 && <button onClick={() => props.setSearch(client.name)}>({soCount})</button>}
            </Fragment>
          </p>
          <DfValueView path="sales-order.address.shortAddress" id={element.id} />
        </Td>
        <Td style={style}
          ySmall={true}
          xSmall={true}
          colSpan={3}
        >
          {element.possibleUnloadDate === '2099-01-01' ?
            <span className="text-red-500">
              {'Not available'}
            </span> :
            <DfValueView path="sales-order.possibleUnloadDate" id={element.id} />
          }
        </Td>
        <Td textAlignment={"text-right"} style={style}
          ySmall={true}
          xSmall={true}
        >
          <DfValueView path="sales-order.allLdm" id={element.id} />
        </Td>
        <Td textAlignment={"text-right"} style={style}
          ySmall={true}
          xSmall={true}
        >
          <DfValueView path="sales-order.allCbm" id={element.id} />
        </Td>
        <Td textAlignment={"text-right"} style={style}
          ySmall={true}
          xSmall={true}
        >
          <DfValueView path="sales-order.allWeight" id={element.id} />
        </Td>
        <Td textAlignment={"text-right"} style={style}
          ySmall={true}
          xSmall={true}
        >
          <DfValueView path="sales-order.allCargoValueEur" id={element.id} />
        </Td>


        <Td style={style}
          ySmall={true}
          xSmall={true}
        >
          {props.kzIds.length > 0 &&
            <Fragment>
              {props.kzIds.length > 1 ?
                <ToolbarButtonWithMenu
                  button={{
                    iconName: "add",
                    bgColor: "purple-50",
                    small: true,
                  }}
                  menu={
                    {
                      items: props.kzIds.map((kzId) => {
                        return (
                          {
                            children: (<DfValueView id={kzId} path={"kz-carriers-order.serialNumber"} />),
                            onClick: () => addMultipleCargos(kzId)
                          }
                        )
                      })
                    }
                  }
                />
                :
                <MainButton
                  color={"purple"}
                  onClick={() => addMultipleCargos(props.kzIds[0])}
                  loading={saveMultipleParams.loading}
                  iconName={'paperclip'}
                >
                  {t("add")}
                </MainButton>
              }
            </Fragment>
          }
        </Td>
      </tr>
      {element.cargos.map((cargo) => {
        return <CargoLine
          id={cargo.id}
          key={`cargo-${cargo.id}`}
          kzIds={props.kzIds}
        />
      })}

    </Fragment>
  )
}

type CargoLineProps = {
  kzIds: number[]
} & PropsId;

const CargoLine = (props: CargoLineProps) => {
  const element = useCargoHookNae(props.id);
  const elementHold = useCargoHoldHookNae(element && element.cargoHold ? element.cargoHold.id : -1);
  const isPostpone = !!elementHold && elementHold.status === 0;

  const { t } = useTranslation();

  const [saveData, saveDataParams] = useUSave("cargo");
  const saveCargo = (kzId: number) => {
    if (saveDataParams.loading) {
      return;
    }
    saveData({ kzCarriersOrder: { id: kzId } }, props.id);
  };

  if (!element) {
    return <Fragment />
  }
  if (element.status === 900) {
    return <Fragment />
  }
  return (<tr>
    <Td ySmall={true}>
      <div className={"gap-1 items-center flex"}>
        <DfValueView path="cargo.serialNumber" id={element.id} link={true} />

        {element.status === 10 && (
          <i className={"fa-solid fa-truck-moving text-sky-500"} />
        )}
        {element.status === 15 && (
          <i className={"fa-solid fa-warehouse text-sky-500"} />
        )}

      </div>
    </Td>
    <Td className="max-w-[250px]" ySmall={true}>
      <DfValueView path="cargo.cargoNotes" id={element.id} />
      {!!element.commentLoading && <hr />}
      <DfValueView path="cargo.commentLoading" id={element.id} />
      <br/>
      <CargoCargoCheckForDualUseBadge id={element.id} isCompact={true} />
    </Td>
    <Td ySmall={true}>
      <CargoUnloadWarehouseDateOrPoUnloadDateStr
        id={element.id}
      /><br/>
      
      {elementHold && <Tooltip className="ml-2" text={`Postponed till ${moment(elementHold.finishDate).format('YYYY-MM-DD')}`} />}
    </Td>
    <Td>
      <DfValueView path="cargo.terminal.name" id={element.id}/>
    </Td>
    <Td ySmall={true}>
      <CargoSellerDidEx id={element.id} />
    </Td>
    <Td ySmall={true}>
      <div className={"justify-end flex"}>
        <div className="pr-1"><DfValueView path="cargo.ldm" id={element.id} /></div>
        (<DfValueView path="cargo.ldmCalculationType" id={element.id} />)
      </div>
    </Td>
    <Td className={"text-right"} ySmall={true}>
      <DfValueView path="cargo.cbm" id={element.id} />
    </Td>
    <Td className={"text-right"} ySmall={true}>
      <DfValueView path="cargo.allCargoWeight" id={element.id} />
    </Td>
    <Td className={"text-right"} ySmall={true}>
      <DfValueView path="cargo.cargoValueEur" id={element.id} />
    </Td>

    <Td ySmall={true}>
      {!isPostpone && !element.kzCarriersOrder && props.kzIds.length > 0 && (
        <Fragment>
          {props.kzIds.length > 1 ?
            <ToolbarButtonWithMenu
              button={{
                iconName: "add",
                bgColor: "sky-50",
                loading: saveDataParams.loading,
                small: true,
              }}
              menu={
                {
                  items: props.kzIds.map((kzId) => {
                    return (
                      {
                        children: (<DfValueView id={kzId} path={"kz-carriers-order.serialNumber"} />),
                        onClick: () => saveCargo(kzId)
                      }
                    )
                  })
                }
              }
            />
            :
            <MainButton
              color={"sky"}
              onClick={() => saveCargo(props.kzIds[0])}
              loading={saveDataParams.loading}
              iconName={'paperclip'}
            >
              {t("add")}
            </MainButton>
          }
        </Fragment>
      )}
      {element.kzCarriersOrder && <DfValueView path="cargo.kzCarriersOrder.serialNumber" id={element.id} />}
    </Td>
  </tr>);

}