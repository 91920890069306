import { CargoCalculationsBlueBtn, CargoCalculationsContactSuggestion, CargoCalculationsContactSuggestionHeader } from '@newageerp/apps.client.caspian';
import React, { Fragment, useState, useEffect } from 'react'

import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTerminalHookNae } from '../../../_generated/_custom/hooks/useTerminalHookNae';
import { useUnloadAddressHookNae } from '../../../_generated/_custom/hooks/useUnloadAddressHookNae';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useContactHookNae } from '../../../_generated/_custom/hooks/useContactHookNae';
import { useCarrierHookNae } from '../../../_generated/_custom/hooks/useCarrierHookNae';
import { SFSOpenEditModalWindowProps, SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';

type Props = {

} & PropsId;

export default function FtlCalculationSuggestions(props: Props) {
    const {userState} = useTemplatesCore()

    const element = useKzCarriersOrderHookNae(props.id);
    const cargo = useCargoHookNae(element && element.cargos.length > 0 ? element.cargos[0].id : 0);
    const terminal = useTerminalHookNae(cargo && cargo.terminal ? cargo.terminal.id : 0);
    const salesOrder = useSalesOrderHookNae(cargo && cargo.salesOrder ? cargo.salesOrder.id : 0);
    const unloadAddress = useUnloadAddressHookNae(salesOrder && salesOrder.address ? salesOrder.address.id : 0);

    const [getContacts, getContactsParams] = useUList<PropsId>(NaeSSchemaMap.Contact.schema, ['id']);

    const loadCountry = terminal ? terminal.country.id : 0;
    const unloadCountry = unloadAddress ? unloadAddress.country.id : 0;

    const [isShow, setIsShow] = useState(false);

    const doCreate = (contactId: number = 0) => {
        const createOptions = {
            convert: {
                schema: NaeSSchemaMap.KzCarriersOrder.schema,
                element: element,
                contactId
            }
        };

        SFSOpenEditModalWindowProps({
            id: "new",
            schema: NaeSSchemaMap.KzFtlCalculation.schema,
            options: { createOptions: createOptions }
        });
    }

    useEffect(() => {
        if (isShow && !getContactsParams.loading && getContactsParams.data.data) {
            getContacts(
                [
                    {
                        "and": [
                            ['i.carrier.ftlUser', '=', userState.id, true],
                            ['i.transportServices', 'JSON_CONTAINS', '4', true],
                            ['i.loadingCountries', 'JSON_CONTAINS', loadCountry.toString(), true],
                            ['i.unloadingCountries', 'JSON_CONTAINS', unloadCountry.toString(), true],
                        ]
                    }
                ],
                1,
                500,
                [{
                    key: 'i.id',
                    value: 'DESC'
                }]
            )
        }
    }, [isShow]);

    if (!element || !terminal || !unloadAddress) {
        return <Fragment />
    }

    return (
        <div className='w-72 space-y-2'>
            <CargoCalculationsContactSuggestionHeader isActive={isShow} text={"Mine"} onClick={setIsShow} />
            {isShow && <Fragment>{getContactsParams.data.data.map((c: PropsId) => <Line key={`line-${c.id}`} id={c.id} doCreate={doCreate} />)}</Fragment>}
            <CargoCalculationsBlueBtn onClick={doCreate} />
        </div>
    )
}

interface LineProps extends PropsId {
    doCreate: ( contactId: number) => void,
}

const Line = (props: LineProps) => {
    const contact = useContactHookNae(props.id);
    const carrier = useCarrierHookNae(contact && contact.carrier ? contact.carrier.id : 0);

    const showContact = () => {
        SFSOpenViewModalWindowProps({
            id: contact.id,
            schema: NaeSSchemaMap.Contact.schema
        })
    }
    const showCarrier = () => {
        SFSOpenViewModalWindowProps({
            id: carrier.id,
            schema: NaeSSchemaMap.Carrier.schema
        })
    }

    if (!contact || !carrier) {
        return <Fragment />
    }

    return (
        <CargoCalculationsContactSuggestion
            carrier={{
                text: carrier.name,
                onClick: showCarrier
            }}
            contact={{
                text: contact.fullName,
                onClick: showContact
            }}
            onCreate={() => props.doCreate(contact.id)}

            hint={contact.description}
        />
    )
}
