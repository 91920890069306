import React, { Fragment } from 'react'

interface IData {
    id: number,
    serialNumber: string,
    total: number,
    client: string,
}

interface Props {
    item: {
        cargoCalculations: IData[],
        total: number,
    }
}

export default function CalculationTd(props: Props) {
    const { item } = props;

    const { cargoCalculations } = item;
    if (!cargoCalculations) {
        return <Fragment />
    }

    const minPrice = Math.min(...cargoCalculations.map(p => p.total));
    const diff = minPrice - item.total;
    const className = diff > 1 ? "text-red-500" : (diff < -1 ? "text-green-500" : "")

    return (
        <div className={"flex gap-4 items-center"}>
            <table cellPadding={5}>
                <tbody>
                    {cargoCalculations.map((c) => {
                        return (
                            <tr key={"calc-" + c.id}>
                                <td width={200}>
                                    {c.client}
                                </td>
                                <td width={50} className={"text-right"}>
                                    {c.total.toFixed(2)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <span className={"text-right w-32 " + className}>
                {minPrice.toFixed(2)}
            </span>
            <span className={"text-right w-20 " + className}>
                {Math.abs(diff) > 1 && <Fragment>({(diff * -1).toFixed(2)})</Fragment>}
            </span>
        </div>
    )
}

