import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useRecoilState } from "recoil";
import moment from "moment";

import {
  checkUserAdmin,
} from "../../_generated/_custom/config/NaeSPermissions";
import {
  SelectAdvId,
} from "@newageerp/ui.form.base.form-pack";
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { SelectorUserNae } from '../../_generated/_custom/models/ormSelectors';
import { IUserModelNae } from "../../_generated/_custom/hooks/useUserHookNae";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useHistory } from "react-router";

import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { LogoLoader } from "@newageerp/v3.bundles.layout-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

interface IItem {
  id: number;
  client: number;
  createdAt: string;
  topIndex: number;
}

let clTimeout = -1;

export default function ClientTop20Page() {
  const { t } = useTranslation();

  const UserData = useSelector((state) => SelectorUserNae(state));
  const users = UserData.filter(
    (u: IUserModelNae) =>
      u.permissionGroup === "user-manager" && u.disabled === false
  ).sort((a: IUserModelNae, b: IUserModelNae) =>
    a.fullName.localeCompare(b.fullName)
  );

  const usersOptions = [
    {
      value: "",
      label: "",
    },
    ...users.map((u: IUserModelNae) => ({
      value: u.id,
      label: u.fullName,
    })),
  ];

  const {userState} = useTemplatesCore()
  const [filterUserId, setFilterUserId] = useState(userState.id);

  const isAdmin =
    checkUserAdmin(userState);

  const [popupVisible, setPopupVisible] = useState(-1);

  const loadData = () => {
    getData({
      userId: filterUserId,
    });
  };

  const onSelect = (el: any) => {
    saveData(
      {
        client: el,
        createdAt: moment().format("YYYY-MM-DD"),
      },
      popupVisible
    ).then(() => {
      loadData();
    });
    setPopupVisible(-1);
  };

  const remove = (id: number) => {
    saveData(
      {
        client: null,
      },
      id
    ).then(() => {
      loadData();
    });
  };

  const [saveData, saveDataParams] = useUSave(
    NaeSSchemaMap.ClientTop20.schema
  );

  const [moveData, moveDataParams] = useURequest<IItem>(
    NaePathsMap.post["pluginsClientTop20MoveTop20"]
  );

  const [getData, getDataParams] = useURequest<IItem>(
    NaePathsMap.post["pluginsClientTop20GetTop20"]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const items: string[] = getDataParams.data.data.map((e: IItem) =>
    e.id.toString()
  );

  const handleDragEnd = (event: any) => {
    const { active, over }: any = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const _items = arrayMove(items, oldIndex, newIndex);

      moveData({
        ids: _items,
      }).then(() => {
        loadData();
      });
    }
  };

  useEffect(() => {
    window.clearTimeout(clTimeout);
    clTimeout = window.setTimeout(loadData, 100);
  }, [filterUserId]);

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState);
  useEffect(() => {
    setToolbarTitle(t("Top 20"));
  }, []);

  if (!getDataParams.data.data) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className="space-y-2">
        {isAdmin && (
          <WhiteCard title={t("Filter")}>

            <Wide>
              <WideRow
                label={<FieldLabel>{t("User")}</FieldLabel>}
                control={
                  <SelectAdvId
                    selectedId={filterUserId}
                    onSelectId={setFilterUserId}
                    className={"w-60"}
                    options={usersOptions}
                  />
                }
              />
            </Wide>
          </WhiteCard>
        )}

        {getDataParams.loading ? (
          <LogoLoader />
        ) : (
          <Fragment>
            {getDataParams.data.data.length > 0 && (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  {getDataParams.data.data.map((item: IItem, index: number) => (
                    <SortableItem
                      key={"item-" + item.id}
                      item={item}
                      index={index}
                      setPopupVisible={setPopupVisible}
                      remove={remove}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            )}
          </Fragment>
        )}
      </div>

      {popupVisible > 0 && (
        <TemplatesLoader
          templateName="PageInlineList"
          data={{
            schema: NaeSSchemaMap.Client.schema,
            type: "onlyLead",
            isPopup: true,
            addSelectButton: true,
            addToolbar: true,
          }}
          templateData={{
            onBack: () => {
              setPopupVisible(-1);
            },
            onAddSelectButton: (el: any) => {
              onSelect(el);
            },
          }}
        />
      )}
    </Fragment>
  );
}

function SortableItem(props: any) {
  const history = useHistory();
  const item: IItem = props.item;

  const [showPopup, setShowPopup] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id.toString() });

  const style: any = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const hasClient = item.client > 0;

  const { t } = useTranslation();

  const calcsExtraFilter = { and: [["i.client", "=", item.client, true]] };
  const calcsLink = `/u/calculation/main/list?extraFilter=${encodeURIComponent(
    JSON.stringify(calcsExtraFilter)
  )}`;

  const openRouter = () => {
    history.push(calcsLink);
  };
  const openNewWindow = () => {
    window.open(calcsLink, "_blank");
  };
  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Fragment>
      <div
        ref={setNodeRef}
        style={style}
        className={`flex items-center gap-6 px-4 py-2 bg-white border border-gray-300 rounded-md ${hasClient ? "" : "opacity-50"
          }`}
      >
        <span
          {...listeners}
          {...attributes}
        >
          <i className="fa fa-fw fa-arrows-up-down-left-right fa-light" />
        </span>
        <span className="w-[30px]">{item.topIndex})</span>
        <div className="w-[300px]">
          {hasClient ? (
            <DfValueView
              path={"client-top20.client.name"}
              as={"object"}
              id={item.id}
            />
          ) : (
            <button onClick={() => props.setPopupVisible(item.id)}>
              {t("Add")}
            </button>
          )}
        </div>
        <span className="w-[150px]">
          {hasClient && (
            <DfValueView
              path="client-top20.client.country.nameLt"
              id={item.id}
            />
          )}
        </span>
        <span>
          {hasClient && (
            <Fragment>
              <div className="flex gap-2 items-center">
                <button onClick={openPopup} className="text-blue-500">
                  {t("quotations")}
                </button>
                <div className="space-x-2">
                  <button title={t("Pagrindinis langas")} onClick={openRouter}>
                    <i className="fad fa-window fa-fw text-nsecondary-500 hover:text-blue-500" />
                  </button>
                  <button title={t("Modalinis langas")} onClick={openPopup}>
                    <i className="fad fa-window-restore fa-fw text-nsecondary-500 hover:text-blue-500" />
                  </button>
                  <button title={t("Naujas langas")} onClick={openNewWindow}>
                    <i className="fad fa-external-link-alt fa-fw text-nsecondary-500 hover:text-blue-500" />
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </span>

        <span>
          {hasClient && (
            <DfValueView path="client-top20.updatedAt" id={item.id} />
          )}
        </span>
        {hasClient && (
          <MainButton
            color="white"
            skipPadding={true}
            iconName="trash"
            onClick={() => props.remove(item.id)}
          >
            {t("Remove")}
          </MainButton>
        )}
      </div>


      {showPopup && (
        <TemplatesLoader
          templateName="PageInlineList"
          data={{
            schema: "calculation",
            type: "main",
            isPopup: true,
            addToolbar: true,
            extraFilters: [calcsExtraFilter]
          }}
          templateData={{
            onBack: () => {
              closePopup()
            }
          }}
        />
      )}
    </Fragment>
  );
}
