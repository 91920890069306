import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CarrierName from '../Carrier/CarrierName';
import { useCarriersOrderHookNae } from '../../hooks/useCarriersOrderHookNae';

export default function CarriersOrderClientName(props: PropsId) {
    const element = useCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.client) {
        return <Fragment />
    }
    return (
        <CarrierName id={element.client.id} />
    )
}
