import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CargoSerialNumberWithLinkAndNotes from '../Cargo/CargoSerialNumberWithLinkAndNotes';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationCargosSerialNumberWithLinkAndNotes(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.cargos.map((cargo) => {
                return (
                    <p key={"cargo-" + cargo.id}>
                        <CargoSerialNumberWithLinkAndNotes id={cargo.id} />
                    </p>
                );
            })}
        </Fragment>
    )
}
