import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle'

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';

import { INaeStatus } from "../../../_generated/v3/utils";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserManager } from "../../../_generated/_custom/config/NaeSPermissions";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { useAppData } from "@newageerp/v3.app.app-data";
import { FieldTextarea } from "@newageerp/v3.bundles.form-bundle";

interface IData {
  id: number;
  purpose: number;
  status: number;
  total: number;
}
interface Props {
  id: number,
  compact?: boolean;
  element?: IData
}

// export const CalculationOrderStatuses = [20, 22, 24, 25, 30, 40, 50];
export const CalculationOrderStatuses = [25, 30, 40];
// export const CalculationQuotationStatuses = [20, 22, 24, 25, 30, 40, 50];
export const CalculationQuotationStatuses = [20, 25, 30, 40];
const statusesForComment = [20, 22, 25, 30, 40, 60];

export default function CalculationStatusChange(props: Props) {
  const appData = useAppData();
  const element = useCalculationHookNae(props.id);

  const { t } = useTranslation();
  const { compact } = props;

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);

  const [saveData] = useUSave(
    "calculation",
  );
  const statuses = appData.status.list.filter(
    (s: INaeStatus) => s.schema === "calculation"
  );

  const [showList, setShowList] = useState(false);
  const [statusForComment, setStatusForComment] = useState(0);
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    if (element && element.status === 10 && !showList && !compact) {
      setShowList(true);
    }
  }, [element]);

  const onSave = (status: number) => {
    if (statusesForComment.indexOf(status) >= 0) {
      setStatusForComment(status);
    } else {
      saveData({ status: status }, props.id);
    }
  };

  const onSaveComment = () => {
    saveData({ status: statusForComment, statusComment: commentText }, props.id);
    setStatusForComment(0);
    setCommentText("");
  };
  const onCancelComment = () => {
    setStatusForComment(0);
  };

  if (!element) {
    return <LogoLoader size={50} />;
  }

  if (!(isAdmin || isManager)) {
    return <Fragment />
  }
  if (!(element.status >= 10 && element.total > 0)) {
    return <Fragment />
  }
  if (element.status === 900) {
    return <Fragment />
  }

  if (!showList) {
    return (
      <MainButton
        color="blue"
        className={"w-full"}
        iconName={"list"}
        onClick={() => setShowList(true)}
      >
        {t("Update status")}
      </MainButton>
    );
  }

  const commonClass = "space-y-4";
  const containerClass = compact ? "" : "p-2 shadow-md bg-white rounded-md";

  return (
    <Fragment>
      <div className={containerClass + " " + commonClass}>
        {!compact && <TextCardTitle>{t("Calculation conclusion")}</TextCardTitle>}

        <div className={"grid gap-1"}>
          {statusForComment > 0 ? (
            <Fragment>
              <p>{t("enter a short comment")}</p>
              <FieldTextarea
                rows={5}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
            </Fragment>
          ) : (
            <Fragment>
              {statuses
                .filter((status: INaeStatus) => {
                  if (
                    element.purpose === 10 &&
                    CalculationQuotationStatuses.indexOf(status.status) === -1
                  ) {
                    return false;
                  }
                  if (
                    element.purpose === 20 &&
                    CalculationOrderStatuses.indexOf(status.status) === -1
                  ) {
                    return false;
                  }
                  return status.status !== element.status;
                })
                .map((status: INaeStatus, statusIndex: number) => {
                  return (
                    <MainButton
                      key={"status-" + statusIndex}
                      // @ts-ignore
                      color={status.variant}


                      className="w-full"
                      onClick={() => onSave(status.status)}
                    >
                      {t(status.text)}
                    </MainButton>
                  );
                })}
            </Fragment>
          )}
        </div>

        {statusForComment > 0 && !!commentText && (
          <div className={"flex gap-2"}>
            <MainButton
              color="sky"
              className="w-full"
              onClick={onSaveComment}
            >
              {t("Save")}
            </MainButton>
            <MainButton
              color="whiteandslate"
              className="w-full"
              onClick={onCancelComment}
            >
              {t("Cancel")}
            </MainButton>
          </div>
        )}
      </div>
    </Fragment>
  );
}
