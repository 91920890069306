import React, { Fragment } from "react";
import OperationalChangesSuccessBtn from "../../../components/cargo/Operational/CargoReturns/OperationalChangesSuccessBtn";
import OperationalNeedSpecificationSuccessBtn from "../../../components/cargo/Operational/CargoReturns/OperationalNeedSpecificationSuccessBtn";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

export default function OperationalCargoReturnSuccess(props: PropsId) {
  const element = useCargoHookNae(props.id);

  if (!element) {
    return <Fragment />
  }

  if (!(element.operativeCardIndex === 5 || element.operativeCardIndex === 6 || element.operativeCardIndex === 7)) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {(element.operativeCardIndex === 5 || element.operativeCardIndex === 7) && (
        <OperationalNeedSpecificationSuccessBtn id={element.id} />
      )}
      {element.operativeCardIndex === 6 && (
        <OperationalChangesSuccessBtn id={element.id} />
      )}
    </Fragment>
  );
}
