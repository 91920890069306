import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorViltSalesOrderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IViltSalesOrderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      invoiceDate: string,
      invoiceNumber: string,
      invoicePayToDate: string,
      total: number,
      totalDebt: number,
      client: {
              id: number,
            },
      trip: string,
      trailer: {
              id: number,
                      truckOwner: {
              id: number,
                },
            },
      currency: {
              id: number,
                    currency: string,
            },
  }

export const IViltSalesOrderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "invoiceDate",
    "invoiceNumber",
    "invoicePayToDate",
    "total",
    "totalDebt",
    "client.id",
    "trip",
    "trailer.id",
    "trailer.truckOwner.id",
    "currency.id",
    "currency.currency"
];

export function useViltSalesOrderHookNae(id: number) : IViltSalesOrderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorViltSalesOrderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "vilt-sales-order",
        fields: IViltSalesOrderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
