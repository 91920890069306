import React, { Fragment, useEffect } from 'react'
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CargoDocumentsBtn from '../../../components/dashboard/cargo/CargoDocumentsBtn';
import RelatedCargosLine from '../../Cargo/Components/RelatedCargosLine';
import SenderName from '../../../_generated/_custom/models-cache-data/Sender/SenderName';
import SalesOrderClientOrFilialName from '../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName';
import UserFullName from '../../../_generated/_custom/models-cache-data/User/UserFullName';
import CargoUnloadWarehouseDateOrPoUnloadDateStr from '../../../_generated/_custom/models-cache-data/Cargo/CargoUnloadWarehouseDateOrPoUnloadDateStr';
import CargoSerialNumberWithLink from '../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLink';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import CargoSellerDidEx from '../../../Plugins/Cargo/CargoSellerDidEx';
import { SocketService } from "@newageerp/v3.utils.socket-service";

let reloadTimeout: any = -1;
interface IData {
    id: number,
    serialNumber: string,
    seller: {
        id: number,
    },
    salesOrder: {
        id: number,
    },
    expeditor: {
        id: number,
    },
    creator: {
        id: number,
    },


    poUnloadDateStr: string,
    unloadWarehouseDate: string,


    operational?: {
        fullName: string,
    },
    filesSentToTerminal: boolean,
    stock: string,

    otherCargosSerialNumbers: string,
    typeIn: string,
    typeOut: string,
    operationConfirmation: {
        confirmed: boolean,
    },
    senderMakeEx: number,
}

const defObject: IData = {
    id: 0,
    serialNumber: '',
    seller: {
        id: 0,
    },
    salesOrder: {
        id: 0,
    },
    poUnloadDateStr: '',
    unloadWarehouseDate: '',
    expeditor: {
        id: 0,
    },
    creator: {
        id: 0,
    },

    operational: {
        fullName: '',
    },
    filesSentToTerminal: false,

    stock: '',

    otherCargosSerialNumbers: '',
    typeIn: '',
    typeOut: '',
    operationConfirmation: {
        confirmed: false,
    },
    senderMakeEx: 0,
}

export default function OperativePro() {
    const { t } = useTranslation();

    const [getDataIncoming, getDataIncomingProps] = useUList<IData>("cargo", getKeysFromObject(defObject));

    const [getDataStock, getDataStockProps] = useUList<IData>("cargo", getKeysFromObject(defObject));

    const loadDataIncoming = () => {
        getDataIncoming(
            [
                {
                    classicMode: true,
                    "and": [
                        ['i.operationConfirmation.confirmed', '=', true],
                        ['i.operationConfirmation.headConfirmed', '=', false],
                        ['i.terminal', 'IS_NOT_NULL', null],
                    ]
                }
            ],
            1,
            9999
        )
    }
    const loadDataStock = () => {
        getDataStock(
            [
                {
                    classicMode: true,
                    "and": [
                        ['i.operationConfirmation.headConfirmed', '=', true],
                        ['i.operationConfirmation.dpsProConfirmed', '=', false],
                        ['i.terminal', 'IS_NOT_NULL', null],
                    ]
                }
            ],
            1,
            9999
        )
    }
    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = () => {
        window.clearTimeout(reloadTimeout);
        reloadTimeout = setTimeout(() => {
            loadDataIncoming();
            loadDataStock();
        }, 500);

    }

    useEffect(() => {
        SocketService.subscribe("operation-pro-cargo-dashboard");
        return () => {
            SocketService.unsubscribe("operation-pro-cargo-dashboard");
        };
    }, []);

    useEffect(() => {
        SocketService.addCallback(
            "operation-pro-cargo-dashboard",
            "operation-pro-cargo-dashboard",
            reloadData
        );
        return () => {
            SocketService.removeCallback(
                "operation-pro-cargo-dashboard",
                "operation-pro-cargo-dashboard"
            );
        };
    }, []);

    return (
        <Fragment>
            <WhiteCard isCompact={true} title={t('Seller\'s documents')}>
                <Table
                    thead={
                        <thead>
                            <Th>{t('Cargo')}</Th>
                            <Th>{t('Seller')}</Th>
                            <Th>{t('Consignee')}</Th>
                            <Th>{t('Transport operations manager')}</Th>
                            <Th>{t('Sales manager')}</Th>
                            <Th>{t('Who will prepare EX?')}</Th>
                            <Th>{t('Date of arrival to the warehouse')}</Th>
                            <Th>{t('Warehouse reference')}</Th>
                            <Th>{t('Cargo documents')}</Th>
                        </thead>
                    }
                    tbody={
                        <tbody>
                            {getDataIncomingProps.data.data.sort((a, b) => {
                                var x = (a.unloadWarehouseDate ? moment(a.unloadWarehouseDate).format("YYYY-MM-DD") : a.poUnloadDateStr).toLowerCase();
                                var y = (b.unloadWarehouseDate ? moment(b.unloadWarehouseDate).format("YYYY-MM-DD") : b.poUnloadDateStr).toLowerCase();

                                return x < y ? -1 : x > y ? 1 : 0;
                            }).map((item: IData, index: number) => {
                                return (
                                    <tr key={`k-${item.id}`}>
                                        <Td>
                                            <Fragment>
                                                <CargoSerialNumberWithLink id={item.id} />
                                                <RelatedCargosLine id={item.id} />
                                            </Fragment>
                                        </Td>
                                        <Td><SenderName id={item.seller.id} /></Td>
                                        <Td><Fragment>{item.salesOrder && <SalesOrderClientOrFilialName id={item.salesOrder.id} />}</Fragment></Td>
                                        <Td><Fragment>{!!item.expeditor && <UserFullName id={item.expeditor.id} />}</Fragment></Td>
                                        <Td><Fragment>{!!item.creator && <UserFullName id={item.creator.id} />}</Fragment></Td>
                                        <Td><CargoSellerDidEx id={item.id} /></Td>
                                        <Td><CargoUnloadWarehouseDateOrPoUnloadDateStr id={item.id} /></Td>
                                        <Td><DfValueView path="cargo.stock" id={item.id} /></Td>
                                        <Td><CargoDocumentsBtn id={item.id} /></Td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    }
                />
            </WhiteCard>

            <WhiteCard isCompact={true} title={t('Warehouse documents')}>

                <Table
                    thead={
                        <thead>
                            <Th>{t('Cargo')}</Th>
                            <Th>{t('Seller')}</Th>
                            <Th>{t('Consignee')}</Th>
                            <Th>{t('Transport operations manager')}</Th>
                            <Th>{t('Sales manager')}</Th>
                            <Th>{t('Who will prepare EX?')}</Th>
                            <Th>{t('Date of arrival to the warehouse')}</Th>
                            <Th>{t('Warehouse reference')}</Th>
                            <Th>{t('Cargo documents')}</Th>
                        </thead>
                    }

                    tbody={
                        <tbody>
                            {getDataStockProps.data.data.sort((a, b) => {
                                var x = (a.unloadWarehouseDate ? moment(a.unloadWarehouseDate).format("YYYY-MM-DD") : a.poUnloadDateStr).toLowerCase();
                                var y = (b.unloadWarehouseDate ? moment(b.unloadWarehouseDate).format("YYYY-MM-DD") : b.poUnloadDateStr).toLowerCase();

                                return x < y ? -1 : x > y ? 1 : 0;
                            }).map((item: IData, index: number) => {
                                return (
                                    <tr key={`k-${item.id}`}>
                                        <Td>
                                            <Fragment>
                                                <CargoSerialNumberWithLink id={item.id} />
                                                <RelatedCargosLine id={item.id} />
                                            </Fragment>
                                        </Td>
                                        <Td><SenderName id={item.seller.id} /></Td>
                                        <Td><Fragment>{item.salesOrder && <SalesOrderClientOrFilialName id={item.salesOrder.id} />}</Fragment></Td>
                                        <Td><Fragment>{!!item.expeditor && <UserFullName id={item.expeditor.id} />}</Fragment></Td>
                                        <Td><Fragment>{!!item.creator && <UserFullName id={item.creator.id} />}</Fragment></Td>
                                        <Td><CargoSellerDidEx id={item.id} /></Td>
                                        <Td><CargoUnloadWarehouseDateOrPoUnloadDateStr id={item.id} /></Td>
                                        <Td><DfValueView path="cargo.stock" id={item.id} /></Td>
                                        <Td><CargoDocumentsBtn id={item.id} /></Td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    }
                />
            </WhiteCard>
        </Fragment>
    )
}
