import React, { Fragment, useState, useEffect } from "react";
import CargoLogisticCalculatesContact from "../../../../../components/cargo/CargoLogisticCalculatesContact";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../../../../_generated/_custom/config/NaeSSchema";
import { PropsId } from "../../../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../../../_generated/_custom/hooks/useCargoHookNae";
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoLoadingCalculationNae } from "../../../../../_generated/_custom/models//ormSelectors";
import { ICargoLoadingCalculationModelNae } from "../../../../../_generated/_custom/hooks/useCargoLoadingCalculationHookNae";
import { checkUserLogisticFtl } from "../../../../../_generated/_custom/config/NaeSPermissions";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface Props extends PropsId {
  compact?: boolean;
  forceCanAdd?: boolean;

  calcType: string,
}

interface IDataContact {
  id: number;
  fullName: string;
  skype: string;
  emails: any;
  carrier: {
    id: number;
    name: string;
    defaultLang: string;
  };
  description: string;
  phones: any;
  recommended: number;
  carrierFtlUserId: number;
}

const defObject: IDataContact = {
  id: 0,
  fullName: "",
  skype: "",
  emails: "",
  carrier: {
    id: 0,
    name: "",
    defaultLang: "",
  },
  description: "",
  phones: "",
  recommended: 0,
  carrierFtlUserId: 0,
};

export default function Recomendations(props: Props) {
  const cargo = useCargoHookNae(props.id);

  const { userState } = useTemplatesCore()
  const isFtlLogistic = checkUserLogisticFtl(userState);

  const activeCargoLoadingCalculations = props.calcType === 'out' ? cargo.activeCargoLoadingCalculationsOut : cargo.activeCargoLoadingCalculations;

  const ids =
    cargo &&
      activeCargoLoadingCalculations &&
      // @ts-ignore
      activeCargoLoadingCalculations.indexOf(null) === -1
      ? activeCargoLoadingCalculations.map((c) => c.id)
      : [-1];
  const calculations = useSelector((state) =>
    // @ts-ignore
    SelectorCargoLoadingCalculationNae(state, ids)
  );

  const { t } = useTranslation();
  const [showRecommeded, setShowRecommeded] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [getData, getDataParams] = useUList<IDataContact>(NaeSSchemaMap.Contact.schema, getKeysFromObject(defObject));

  const unloadCountryId = props.calcType === 'out' ? cargo.unloadCountryIdOut : cargo.unloadCountryId;
  const countryForSearch = props.calcType === 'out' ? cargo.countryForSearchOut : cargo.countryForSearch;

  const loadRecommendedData = () => {
    let transportService = "5";
    if (props.calcType === 'out') {
      if (cargo.typeOut === 'avia') {
        transportService = "1";
      } else if (cargo.typeOut === 'sea') {
        transportService = "2";
      } else if (cargo.typeOut === 'train') {
        transportService = "3";
      } else if (cargo.typeOut === 'ftl') {
        transportService = "4";
      }
    } else {
      if (cargo.typeIn === 'avia') {
        transportService = "1";
      } else if (cargo.typeIn === 'sea') {
        transportService = "2";
      } else if (cargo.typeIn === 'train') {
        transportService = "3";
      } else if (cargo.typeIn === 'ftl') {
        transportService = "4";
      }
    }

    getData(
      [
        {
          and: [
            ["i.transportServices", "JSON_CONTAINS", transportService, true],
            [
              "i.loadingCountries",
              "JSON_CONTAINS",
              countryForSearch.toString(),
              true,
            ],
            [
              "i.unloadingCountries",
              "JSON_CONTAINS",
              unloadCountryId.toString(),
              true,
            ],
          ],
        },
      ],
      1,
      500,
      [
        {
          key: "i.id",
          value: "DESC",
        },
      ]
    );
  };
  useEffect(() => {
    loadRecommendedData();
  }, []);

  if (!cargo) {
    return <Fragment />;
  }

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  const filteredData = isData
    ? getDataParams.data.data.filter((c: IDataContact) => {
      if (!isFtlLogistic) {
        return true;
      }
      return c.carrierFtlUserId === 0 || c.carrierFtlUserId === userState.id;
    })
    : [];

  let recommended = isData
    ? filteredData.filter((c: IDataContact) => c.recommended === 0)
    : [];
  let recommendedOther = isData
    ? filteredData.filter((c: IDataContact) => c.recommended === 10)
    : [];
  if (isFtlLogistic) {
    recommended = isData
      ? filteredData.filter(
        (c: IDataContact) => c.carrierFtlUserId === userState.id
      )
      : [];
    recommendedOther = isData
      ? filteredData.filter(
        (c: IDataContact) => c.carrierFtlUserId !== userState.id
      )
      : [];
  }

  const carriers = calculations
    .filter((c: ICargoLoadingCalculationModelNae) => c !== null)
    .map((calc: ICargoLoadingCalculationModelNae) =>
      calc.client ? calc.client.id : -1
    );

  return (
    <Fragment>
      {recommended.length > 0 && (
        <Fragment>
          <div className={"flex text-sm mb-1"}>
            <span className="flex-grow font-medium">
              {isFtlLogistic ? t("My") : t("Recommended")}
            </span>
            <Fragment>
              {showRecommeded ? (
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRecommeded(false);
                  }}
                  className={"ml-2 text-blue-500"}
                >
                  {t("Hide")}
                </a>
              ) : (
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRecommeded(true);
                  }}
                  className={"ml-2 text-blue-500"}
                >
                  {t("Show")}
                </a>
              )}
            </Fragment>
          </div>
          {showRecommeded && (
            <Fragment>
              {recommended
                .filter(
                  (contact: IDataContact) =>
                    carriers.indexOf(contact.carrier.id) === -1
                )
                .map((contact: IDataContact) => {
                  return (
                    <CargoLogisticCalculatesContact
                      key={"calc-" + contact.id}
                      contact={contact}
                      cargo={cargo}
                      compact={props.compact}
                      forceCanAdd={props.forceCanAdd}
                      calcType={props.calcType}
                    />
                  );
                })}
            </Fragment>
          )}
        </Fragment>
      )}

      {recommendedOther.length > 0 && !isFtlLogistic && (
        <Fragment>
          <div className={"flex text-sm mb-1"}>
            <span className={"flex-grow font-medium"}>{t("Others")}</span>

            {showOther ? (
              <a
                href={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOther(false);
                }}
                className={"ml-2 text-blue-500"}
              >
                {t("Hide")}
              </a>
            ) : (
              <a
                href={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOther(true);
                }}
                className={"ml-2 text-blue-500"}
              >
                {t("Show")}
              </a>
            )}

          </div>
          {showOther && (
            <Fragment>
              {recommendedOther
                .filter(
                  (contact: IDataContact) =>
                    carriers.indexOf(contact.carrier.id) === -1
                )
                .map((contact: IDataContact) => {
                  return (
                    <CargoLogisticCalculatesContact
                      key={"calc-" + contact.id}
                      contact={contact}
                      cargo={cargo}
                      compact={props.compact}
                      forceCanAdd={props.forceCanAdd}
                      calcType={props.calcType}
                    />
                  );
                })}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
