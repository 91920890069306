import React, { Fragment } from 'react'
import { PropsId } from '../../../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

export default function AviaTerminalName(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            {element.cargos.length > 0 && (
                <p>
                    <DfValueView path="cargo.terminal.name" id={element.cargos[0].id} />
                </p>
            )}
        </Fragment>
    )
}
