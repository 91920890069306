import React, { Fragment } from "react";
import CustomWorkDirectionField from "../../../../../components/form/CustomWorkDirection/CustomWorkDirectionField";
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props { }

export default function LoadingCountries(props: Props) {
  const { data: tData } = useTemplatesLoader();
  const { element, updateElement } = tData;

  if (!element.carrier) {
    return <Fragment />
  }

  return (
    <CustomWorkDirectionField element={element} updateElement={updateElement} />
  );
}
