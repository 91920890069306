import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

export default function CargoTerminalOrSalesOrderAddress(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    if (element.terminal) {
        return <DfValueView id={element.id} path={"cargo.terminal.fullAddress"} />
    }
    if (element.salesOrder) {
        return <DfValueView id={element.id} path={"cargo.salesOrder.address.fullAddress"} />
    }

    return <Fragment />
}
