import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { MainButton, MainButtonWithSave } from '@newageerp/v3.bundles.buttons-bundle';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useCargoOperationConfirmationHookNae } from '../../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae';

interface Props extends PropsId {
    onClose: () => void
}

export default function ConfirmIncomingDocs(props: Props) {
    const cargo = useCargoHookNae(props.id);
    const confirmElement = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)
    const headConfirmed = confirmElement ? confirmElement.headConfirmed : -1;

    const createDocChange = () => {
        SFSOpenEditModalWindowProps({
            id: "new",
            schema: NaeSSchemaMap.CargoOperationConfirmationChanges.schema,
            options: {
                createOptions: {
                    convert: {
                        schema: "cargo",
                        element: { id: cargo.id },
                    },
                },
            },
            onSaveCallback: (_el, back) => {
                back();
            },
        });
    }

    const { t } = useTranslation();

    if (!cargo) {
        return <Fragment />
    }

    return (
        <Fragment>

            <div className={"flex gap-2"}>
                {!headConfirmed &&
                    <MainButtonWithSave
                        className='flex-grow'
                        color='teal'
                        iconName='check'
                        confirmation={true}
                        elementId={confirmElement.id}
                        schema={NaeSSchemaMap.CargoOperationConfirmation.schema}
                        saveData={{
                            headConfirmed: true
                        }}
                    >
                        {t('Confirm')}
                    </MainButtonWithSave>
                }
                <MainButton
                    color='amber'
                    iconName='times-hexagon'
                    onClick={createDocChange}
                >
                    {t('Return')}
                </MainButton>


            </div>

        </Fragment>
    )
}
