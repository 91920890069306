import React, { Fragment, useState } from "react";
import CargoDocumentsPopup, { ICargo as ICargoDocumentsPopup } from "../../cargo/CargoDocumentsPopup/CargoDocumentsPopup";

import CargoOperationalFullName from '../../../_generated/_custom/models-cache-data/Cargo/CargoOperationalFullName';
import CargoNeedOperationIcon from '../../../_generated/_custom/models-cache-data/Cargo/CargoNeedOperationIcon';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { MainView } from "@newageerp/v3.bundles.app-bundle";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useCargoOperationConfirmationHookNae } from "../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";

interface IOperational {
  fullName: string
}

export interface ICargo extends ICargoDocumentsPopup {
  operational?: IOperational,

}
interface Props extends PropsId {
  iconSize?: string,
}

export default function CargoDocumentsBtn(props: Props) {
  const cargo = useCargoHookNae(props.id);
  const confirmElement = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)

  const [docsPopup, setDocsPopup] = useState(false);
  const toggleDocsPopup = () => setDocsPopup(!docsPopup);

  const { userState } = useTemplatesCore();
  const isTrain = checkUserLogisticTrain(userState);

  if (!confirmElement) {
    return <Fragment />
  }

  return (
    <Fragment>
      <div className={"whitespace-nowrap"}>
        {!isTrain &&
          <CargoNeedOperationIcon iconSize={props.iconSize} onClick={toggleDocsPopup} id={props.id} />
        }

        <CargoOperationalFullName id={props.id} />
      </div>

      {docsPopup &&
        <Popup title="Check" isPopup={docsPopup} onClick={toggleDocsPopup} className="w-[90vw] min-w-[90vw] max-w-[90vw]">
          <MainView

            schema={"cargo-operation-confirmation"}
            type={"main"}
            id={confirmElement.id.toString()}
          />
        </Popup>
        // <CargoDocumentsPopup
        //   visible={docsPopup}
        //   toggleVisible={toggleDocsPopup}
        //   id={props.id}
        // />

      }
    </Fragment>
  );
}
