import React, { Fragment } from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function InvoicesTable(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    return (
        <Fragment>
            {element.invoices.map((inv: any) => {
                return (
                    <div key={`inv-${inv.id}`}>
                        {inv.serialNumber} ({inv.total} {inv.currency?.currency})
                    </div>
                )
            })}
        </Fragment>
    )
}
