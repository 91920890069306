import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import LogisticCargoReturnForm from "./LogisticCargoReturnForm";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";

type Props = {
  sm?: boolean;
  transparent?: boolean;
  ids: number[];
}

export default function LogisticCargoReturn(props: Props) {
  const element = useCargoHookNae(props.ids[0]);

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  if (!element) {
    return <Fragment />;
  }
  if (element.status !== 5) {
    return <Fragment />;
  }
  const isLogistic =
    (!!element.expeditor && userState.id === element.expeditor.id) || isAdmin;
  if (!isLogistic) {
    return <Fragment />;
  }

  return (
    <Fragment>

      <MainButton iconName="undo-alt" color="amber" className={"w-full whitespace-nowrap"} onClick={() => setShowForm(true)}>
        {t("Return")}
      </MainButton>


      <Popup isPopup={showForm} title="Return" onClick={() => setShowForm(false)}>
        <LogisticCargoReturnForm
          ids={props.ids}
          onCloseForm={() => setShowForm(false)}
          transparent={props.transparent}
        />
      </Popup>
    </Fragment>
  );
}
