import React, { Fragment, useEffect } from "react";
import NEWidgetFiles from "../../core/components/widgets/NEWidgetFiles";
import NEWidgetFilesSend from "../../core/components/widgets/NEWidgetFilesSend";
import {
  checkIsAnyLogistic,
} from "../CaspianHelperFunctions";
import { useTranslation } from "react-i18next";
import { checkUserAdmin, checkUserForwardingManager, checkUserHeadOfLogisticsHelper, checkUserLogistic, checkUserLogisticSea,checkUserLogisticTrain, checkUserLogisticFtl } from "../../_generated/_custom/config/NaeSPermissions";
import { useCargoHookNae } from "../../_generated/_custom/hooks/useCargoHookNae";
import { useOutgoingDocumentsChangePurposeHookNae } from "../../_generated/_custom/hooks/useOutgoingDocumentsChangePurposeHookNae";
import { useSalesOrderHookNae } from "../../_generated/_custom/hooks/useSalesOrderHookNae";
import { useKzCarriersOrderHookNae } from "../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { useSenderHookNae } from "../../_generated/_custom/hooks/useSenderHookNae";
import {
  checkUserOperational,
  checkUserOperationalPro,
} from "../../_generated/_custom/config/NaeSPermissions";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { Template, parseChildrenNode, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { useCargoOperationConfirmationHookNae } from "../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";

type Props = {
  afterFiles: Template[],
} & PropsId;

export default function CargoFilesWidget(props: Props) {
  const { t } = useTranslation();

  const elementId = props.id;
  const cargo = useCargoHookNae(elementId);
  const confirmElement = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)
  const confirmed = confirmElement ? confirmElement.confirmed : false;
  const headActiveChanges = confirmElement ? confirmElement.headActiveChanges : false;

  const outgoingDocumentsChangePurpose =
    useOutgoingDocumentsChangePurposeHookNae(
      cargo && cargo.outgoingDocumentsChangePurpose
        ? cargo.outgoingDocumentsChangePurpose.id
        : -1
    );
  const salesOrder = useSalesOrderHookNae(
    cargo && cargo.salesOrder ? cargo.salesOrder.id : -1
  );
  const kzCarriersOrder = useKzCarriersOrderHookNae(
    cargo && cargo.kzCarriersOrder ? cargo.kzCarriersOrder.id : -1
  );
  const seller = useSenderHookNae(cargo && cargo.seller ? cargo.seller.id : -1);

  const {userState} = useTemplatesCore()
  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);
  const isForwardingManager = checkUserForwardingManager(userState);

  if (!cargo) {
    return <Fragment />;
  }
  // if (isLogisticFtl) {
  //   return <Fragment />;
  // }

  const needConfirmMail =
    !!outgoingDocumentsChangePurpose &&
    outgoingDocumentsChangePurpose.needConfirmMail === 10;

  const isSalesOrder = !!salesOrder;
  const isConfirmedSalesOrder = isSalesOrder && salesOrder.status > 5;

  const isKzCarriersOrder = !!kzCarriersOrder;
  const isKzCarriersOrderFormation =
    isKzCarriersOrder && kzCarriersOrder.status === 15;

  const isCreator = cargo.creator && cargo.creator.id === userState.id;

  const isCalcLogistic = cargo.expeditor && cargo.expeditor.id === userState.id;

  const isOperationalPro = checkUserOperationalPro(userState);
  const isAdmin = checkUserAdmin(userState) || isOperationalPro;

  const isOperational = checkUserOperational(userState);
  const isLogistic = checkUserLogistic(userState);

  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isAnyLogistic = checkIsAnyLogistic(userState);

  const isEuLogistic =
    isLogistic ||
    // (isLogisticFtl && !!terminal) ||
    (isLogisticSea && cargo.typeOut !== "avia");

  const isLogisticCargo =
    isAnyLogistic && !!cargo.expeditor && cargo.expeditor.id === userState.id;
  const isReadOnlyLogistic = isLogisticCargo && !!confirmElement;

  // console.log({ isLogisticCargo, isReadOnlyLogistic, isAnyLogistic });

  // const isOperationalActive =
  //   (!!confirmElement && !confirmElement.confirmed) ||
  //   (cargo.minimumSpecificationStatus >= 0 && cargo.minimumSpecificationStatus < 30) ||
  //   headActiveChanges;
  const isOperationalActive = true;
  const isReadOnlyOperational = isOperational && !isOperationalActive;

  const isCancel = cargo.status === 900;

  const canCancel = isAdmin || (isCreator && cargo.status < 10);

  const isReadOnly10 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isReadOnlyLogistic ||    
    (isAnyLogistic && !isCalcLogistic)
    || isTrackingSpecialist;
  const isReadOnly20 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isReadOnlyLogistic ||
    isTrackingSpecialist ||
    // isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);

  const isReadOnlyIE599 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isReadOnlyLogistic ||
    isTrackingSpecialist ||
    // isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);

  const isReadOnly30 =
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    (isOperational && needConfirmMail) ||
    (isOperational && !isOperationalActive) ||
    isAnyLogistic ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);
  const isReadOnly35 =
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isOperational ||
    isAnyLogistic ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);
  const isReadOnly40 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isReadOnlyLogistic ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);
  const isReadOnly50 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isReadOnlyLogistic ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);
  const isReadOnly55 =
    isCreator ||
    isCancel ||
    // (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    // isReadOnlyOperational ||
    isReadOnlyLogistic ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);
  const isReadOnly60 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isTrackingSpecialist ||
    isForwardingManager ||
    isLogisticFtl;
  const isReadOnly70 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isTrackingSpecialist ||
    isForwardingManager ||
    isLogisticFtl;
  const isReadOnly80 =
    isCreator ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isTrackingSpecialist ||
    (isAnyLogistic && !isCalcLogistic);

  const isReadOnlyStockDocs =
    isCreator ||
    isOperational ||
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isTrackingSpecialist ||
    isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);

  const isReadOnly90 =
    isCancel ||
    (isKzCarriersOrderFormation && isReadOnlyOperational) ||
    isReadOnlyOperational ||
    isTrackingSpecialist ||
    // isForwardingManager ||
    (isAnyLogistic && !isCalcLogistic);

  const isReadOnlySellerDocs = isLogisticFtl;

  const show20 =
    cargo.needEx1T1File ||
    (!!cargo.files["20"] && cargo.files["20"] > 0) ||
    isOperationalPro ||
    isAdmin ||
    isLogisticFtl;
  const showIE599 = cargo.needIE599File && !isLogisticFtl;

  const show30 = cargo.outgoingDocumentsChange === 10 && !isEuLogistic;
  const show35 =
    cargo.outgoingDocumentsChange === 10 &&
    needConfirmMail &&
    !isEuLogistic;

  const show40 =
    cargo.typeOut !== 'avia' &&
    (!isEuLogistic || (isEuLogistic && cargo.minimumSpecificationStatus >= 0))
    && !isLogisticFtl;

  const show50 = !isEuLogistic && !isLogisticFtl;
  const show55 = !isEuLogistic && !isLogisticFtl;

  const show60 = !isEuLogistic && !isLogisticFtl;
  const show70 = !isEuLogistic && !isLogisticFtl;
  const show80 = true;
  const showStockDocs = true;

  const showExtraStockDocs = isAdmin || isOperationalPro || isOperational;

  const show90 = true;

  const showSellerDocs = !!seller && isEuLogistic && cargo.senderMakeEx === 20;

  const showKzDocs = (isAdmin || isCreator) && !!kzCarriersOrder;

  return (
    <div className="grid grid-cols-3 gap-3">
      {isConfirmedSalesOrder && (
        <Fragment>
          <NEWidgetFiles
            schema={"cargo"}
            element={{ id: cargo.id }}
            options={{
              type: 10,
              title: "Seller's documents",
              readOnly: isReadOnly10,
              hideDeletedFiles: !isAdmin,
              hint: "invoice, packing, phyto/coo certificate",
            }}
          />
          {show20 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 20,
                title: "EX / T1",
                readOnly: isReadOnly20,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {showIE599 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: "IE599",
                title: "IE599",
                readOnly: isReadOnlyIE599,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show30 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 30,
                title: "Outgoing documents",
                className: "bg-amber-100",
                readOnly: isReadOnly30,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show35 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 35,
                title: "Confirmation letter",
                className: "bg-amber-100",
                readOnly: isReadOnly35,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show40 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 40,
                title: "Specification",
                readOnly: isReadOnly40,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show50 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 50,
                title: "Certificates",
                readOnly: isReadOnly50,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show55 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 55,
                title: "Letters of information",
                readOnly: isReadOnly55,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show60 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 60,
                title: "Waybill (for client)",
                readOnly: isReadOnly60,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
          {show70 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 70,
                title: "Waybill (after delivery)",
                readOnly: isReadOnly70,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}

          {showExtraStockDocs && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: "stock-extra-docs",
                title: "Additional documents for the warehouse",
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}

          {showStockDocs && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: "stock-docs",
                title: "Warehouse documents",
                readOnly: isReadOnlyStockDocs,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}

          {show80 && (
            <NEWidgetFiles
              schema={"cargo"}
              element={{ id: cargo.id }}
              options={{
                type: 80,
                title: "Warehouse photos",
                readOnly: isReadOnly80,
                hideDeletedFiles: !isAdmin,
              }}
            />
          )}
        </Fragment>
      )}
      {show90 && (
        <NEWidgetFiles
          schema={"cargo"}
          element={{ id: cargo.id }}
          options={{
            type: 90,
            title: "Files",
            readOnly: isReadOnly90,
            hideDeletedFiles: !isAdmin,
          }}
        />
      )}
      {/* {showSellerDocs && (
        <NEWidgetFiles
          schema={"sender"}
          element={{ id: seller.id }}
          options={{
            type: 10,
            title: "Įgaliojimas",
            hideDeletedFiles: !isAdmin,
            readOnly: isReadOnlySellerDocs
          }}
        />
      )} */}
      {showKzDocs && (
        <NEWidgetFiles
          schema={"kz-carriers-order"}
          element={{ id: kzCarriersOrder.id }}
          options={{
            type: 30,
            title: "Photos of loading",
            readOnly: true,
            hideDeletedFiles: !isAdmin,
            mailExtraData: {
              cargoId: cargo.id,
            },
          }}
        />
      )}

      {parseChildrenNode(props.afterFiles)}

      <div>
        {(isCreator || isAdmin) && (
          <NEWidgetFilesSend
            element={{ id: cargo.id }}
            schema={"cargo"}
            options={{}}
          />
        )}

      </div>
    </div>
  );
}
