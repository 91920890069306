import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoOperationConfirmationNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICargoOperationConfirmationModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      confirmed: boolean,
      priceConfirmed: boolean,
      headConfirmed: boolean,
      headActiveChanges: boolean,
      dpsProConfirmed: boolean,
      filesToStock: any,
  }

export const ICargoOperationConfirmationFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "confirmed",
    "priceConfirmed",
    "headConfirmed",
    "headActiveChanges",
    "dpsProConfirmed",
    "filesToStock"
];

export function useCargoOperationConfirmationHookNae(id: number) : ICargoOperationConfirmationModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCargoOperationConfirmationNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "cargo-operation-confirmation",
        fields: ICargoOperationConfirmationFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
