import React, { Fragment } from 'react'
import { PropsId } from '../types';

import { NaeSSchemaMap } from '../../config/NaeSSchema';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function CalculationSerialNumberButtonLink(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <RsButton
            id={props.id}
            schema={"calculation"}
            
            button={{
                color: "blue",
                children: element.serialNumber,
                className:"w-full text-left",
            }}
        />
    )
}
