import React, { Fragment } from "react";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import DashboardLogisticSea from "../../components/dashboard/DashboardLogisticSea";
import DashboardBusinessDev from "../../UserComponents/Dashboard/BusinessDev/DashboardBusinessDev";
import DashboardLogistic from "../../UserComponents/Dashboard/Logistic/DashboardLogistic";
import DashboardLogisticFtl from "../../components/dashboard/DashboardLogisticFtl";
import DashboardLogisticTrain from "../../components/dashboard/DashboardLogisticTrain";
import DashboardOperative from "../../components/dashboard/DashboardOperative";
import DashboardAccountingCarriers from "../../UserComponents/Dashboard/Accounting/DashboardAccountingCarriers";
import DashboardManager from "../../components/dashboard/DashboardManager";
import TrackingSpecialistDocsPage from "../../UserComponents/Dashboard/TrackingSpecialist/TrackingSpecialistDocsPage";
import { checkUserAccounting, checkUserBusinessDev, checkUserHeadOfLogisticsHelper, checkUserLogistic, checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain, checkUserManager, checkUserOperational, checkUserOperationalPro } from "../../_generated/_custom/config/NaeSPermissions";

export default function WelcomePage() {
  const { userState } = useTemplatesCore()

  if (!userState || !userState.id) {
    return <Fragment />;
  }

  const isLogistic = checkUserLogistic(userState);
  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isOperational =
    checkUserOperational(userState) || checkUserOperationalPro(userState);
  const isAccounting = checkUserAccounting(userState);
  const isManager = checkUserManager(userState);
  const isBusinessDev = checkUserBusinessDev(userState);

  const isHeadOfLogisticsHelper = checkUserHeadOfLogisticsHelper(userState);

  return (
    <Fragment>
      {isBusinessDev && <DashboardBusinessDev />}
      {isLogistic && <DashboardLogistic />}
      {isLogisticFtl && <DashboardLogisticFtl />}

      {isLogisticSea && <DashboardLogisticSea />}
      {isLogisticTrain && <DashboardLogisticTrain />}

      {isOperational && <DashboardOperative />}
      {isAccounting && <DashboardAccountingCarriers />}
      {isManager && <DashboardManager />}

      {isHeadOfLogisticsHelper && <TrackingSpecialistDocsPage />}
    </Fragment>
  );
}
