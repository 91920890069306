import React from "react";
import { useTranslation } from "react-i18next";
import KzLogisticOrdersWrapper from "./KZLogistic/KzLogisticOrdersWrapper";
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';

export default function DashboardKzLogistic() {
  const { t } = useTranslation();

  return (
    <div>
      <MainToolbarTitle title="Purchase orders (IK)" />
      <KzLogisticOrdersWrapper full={true} />
    </div>
  );
}
