import React from 'react'

interface IFile {
    path: string,
    filename: string,
}

interface Props {
    f: IFile,
    checked?: boolean,
    showCheck?: boolean,
    onClick?: (f: any) => void
}

export default function DocumentsPopupFileItem(props: Props) {
    const { f } = props;

    const onClick = props.onClick ? props.onClick : () => { };

    const isChecked = !!props.checked;

    let className = "";
    if (props.showCheck) {
        className = isChecked ? "text-blue-500" : "text-gray-500";
    } else {
        className = isChecked ? "text-blue-500" : "text-blue-300";
    }

    return (
        <div className={"flex gap-2"}>
            {!!props.showCheck &&
                <button onClick={() => onClick(f)}>
                    <i className={"fad fa-check " + (isChecked ? "text-green-500" : "text-gray-500")} />
                </button>}
            <button
                onClick={() => onClick(f)}
                className={
                    "flex-grow text-left " + className
                }
            >
                {f.filename}
            </button>
        </div>
    );
}
