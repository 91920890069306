import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";

import { useSalesOrderHookNae } from "../../../../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { PropsId } from "../../../../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { SFSOpenEditModalWindowProps } from "@newageerp/v3.bundles.popup-bundle";

export default function Invoice(props: PropsId) {
  const { t } = useTranslation();
  const element = useSalesOrderHookNae(props.id);

  const showCreateNewInvoice = (type: number) => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: NaeSSchemaMap.InvoiceOutgoing.schema,
      type: "main",
      options: {
        createOptions: {
          convert: {
            schema: "sales-order",
            element: element,
            type,
          },
        },
      },
    });
  };

  if (!element) {
    return <Fragment />;
  }

  let disabledInvoice = false;
  let disableInvoiceText = "";

  if (element.invoiceDebtTotal <= 0) {
    disabledInvoice = true;
    disableInvoiceText = t("jau išrašytos sąskaitos pilnai užsakymo sumai");
  }
  if (element.status !== 10) {
    disabledInvoice = true;
    disableInvoiceText = t(
      "sąskaitą galima išrašyti tik kai užsakymas \"Vykdomas\""
    );
  }
  if (!element.files["10"]) {
    disabledInvoice = true;
    disableInvoiceText = t(
      "įkelkite patvirtintą užsakymą nuo kliento, kad išrašyti sąskaitą"
    );
  }
  if (element.paymentTermType === 30) {
    disabledInvoice = true;
    disableInvoiceText = t(
      "sąskaitos išrašyti negalime, nes pasirinktas apmokėjimas grynais"
    );
  }
  return (
    <MenuItem
      isDisabled={disabledInvoice}
      tooltipContent={disableInvoiceText}
      onClick={() => showCreateNewInvoice(0)}
      iconName={"file-invoice"}
    >
      {t("Issue an invoice")}
    </MenuItem>
  );
}
