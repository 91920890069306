import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainButtonWithSave } from '@newageerp/v3.bundles.buttons-bundle';
import { useCargoOperationConfirmationHookNae } from '../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae';

export default function OperativeCargoValueConfirm(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)

    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }
    if (!confirmElement) {
        return <Fragment />
    }

    if (confirmElement.priceConfirmed) {
        return <Fragment />
    }

    const disabled = (!(element.cargoValue > 0 &&
        !!element.cargoValueCurrency));

    return <MainButtonWithSave
        elementId={confirmElement.id}
        saveData={
            {
                priceConfirmed: true,
                addNote: {
                    'content': t('Cargo value confirmed')
                }
            }
        }
        schema={NaeSSchemaMap.CargoOperationConfirmation.schema}

        disabled={disabled}
        disabledContent={
            {
                children: t('Enter the cargo value to continue')
            }
        }

        confirmation={true}
        iconName={'sack-dollar'}
        color={"blue"}
        className={"w-full"}
    >
        {t('Confirm cargo value')}
    </MainButtonWithSave>
}
