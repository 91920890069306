import React, { Fragment } from 'react'
import { checkUserLogisticFtl, checkUserLogistic } from '../../../_generated/_custom/config/NaeSPermissions';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { AlertWidget } from '@newageerp/v3.bundles.widgets-bundle';

interface Props extends PropsId {
    viewCargosId: number,
    setViewCargosId: (id: number) => void
}

export default function KzCarrierLinkWithEye(props: Props) {
    const { setViewCargosId, viewCargosId } = props;

    const element = useKzCarriersOrderHookNae(props.id);
    const needCIPhotos = !!element && !!element.kzInspection && element.kzInspection.needPhotos;

    const { userState } = useTemplatesCore()
    const isFtlLogist = checkUserLogisticFtl(userState);
    const isLogist = checkUserLogistic(userState);

    if (!element) {
        return <Fragment />
    }

    const skipEye = element.needFtlSupport > 0 && (isFtlLogist || isLogist);


    return (
        <div className='space-y-2'>
            <div className={"flex gap-1"}>
                {!skipEye &&
                    <button
                        onClick={
                            viewCargosId === element.id
                                ? () => setViewCargosId(0)
                                : () => setViewCargosId(element.id)
                        }
                    >
                        <i className={"fad fa-fw " + (viewCargosId === element.id ? "fa-eye-slash" : "fa-eye")} />

                    </button>
                }
                <DfValueView path="kz-carriers-order.serialNumber" id={element.id} link={true} />
            </div>
            {needCIPhotos && <AlertWidget isCompact={true} color='warning'>Customs inspection photo</AlertWidget>}
        </div>
    )
}
