import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { useCargoHookNae } from "../../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import KzOrderLocationHistory from "../../../../components/dashboard/cargo/kz-order/KzOrderLocationHistory";
import classNames from "classnames";
interface Props {
  data: IData[];
}

interface IData {
  salesOrder: {
    id: number,
    isContactDataOk: boolean,
    confirmCancelText: string,
    serialNumber: string,
    status: number,
    hasLoadedCargo: boolean,
    files: any,
    isNeedInvoiceFile: boolean,
    isNeedContractFile: boolean,
  },
  seller: {
    name: string,
  },
  id: number,
  serialNumber: string,
  kzCarriersOrder: {
    id: number,
    status: number,
  },
  status: number,
  files: any,
  outgoingDocumentsChange: number,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  },
  beforeChange: any,
  kzCarriersOrderJson: {
    creatorFullName: string,
    carNumber: string,
    driver: string,
    loadDate: string,
    location: string,
    locationUpdateDate: string,
    trip: string,
    locationComment: string,
    id: number,
    status20Date: string,
  },
  soClientJson: {
    id: number,
    name: string,
    filialName: string,
  },
}

export default function ManagerOrderOnKzRoad(props: Props) {
  const { t } = useTranslation();

  const data = props.data.sort((a, b) => {
    const aDateStr = a.kzCarriersOrderJson.status20Date;
    const bDateStr = b.kzCarriersOrderJson.status20Date;

    var x = aDateStr.toLowerCase();
    var y = bDateStr.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });

  const groupedData = groupMap(data, (el: IData) => el.kzCarriersOrderJson.id.toString());
  const kzOrders = Object.keys(groupedData).map(k => {
    const groupedBySo = groupMap(groupedData[k], (el: IData) => el.salesOrder.id.toString());
    return Object.keys(groupedBySo).map(k2 => groupedBySo[k2]);
  });

  // console.log('kzOrders', kzOrders);
  // return (<Fragment/>)
  let gridIndex = 0;

  return (
    <WhiteCard isCompact={true} title={t("On the way to the customer")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Order")}</Th>
              <Th>{t("Client")}</Th>
              <Th>{t("Cargo")}</Th>

              <Th>{t("Seller")}</Th>
              <Th>{t("Responsible")}</Th>
              <Th>{t("Departure date")}</Th>
              <Th>{t("Transport")}</Th>
              <Th className={"whitespace-nowrap"}>{t("Tracking")}</Th>
              <Th>{t("Route")}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>

            {kzOrders.sort((a, b) => {
              const aDateStr = a[0][0].kzCarriersOrderJson.status20Date;
              const bDateStr = b[0][0].kzCarriersOrderJson.status20Date;

              var x = aDateStr.toLowerCase();
              var y = bDateStr.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }).map((salesOrders: any, kzIndex: number,) => {
              let kzRowSpan = 0;
              salesOrders.forEach((cargos: IData[]) => kzRowSpan += cargos.length);

              const bgColor = kzIndex % 2 === 0 ? 'bg-gray-100' : '';

              return (
                <Fragment key={"l1-map-" + kzIndex}>
                  {salesOrders.map((cargos: IData[], soIndex: number) => {

                    const lastKzRow = soIndex === salesOrders.length - 1;

                    const textColor = gridIndex % 2 === 0 ? "text-blue-500" : "text-purple-500";

                    gridIndex++;

                    return (
                      <Fragment key={"l2-map-" + kzIndex + "-" + soIndex}>
                        {cargos.map((c: IData, cargoIndex: number) => {
                          return <Line c={c} cargoIndex={cargoIndex} cargos={cargos} soIndex={soIndex} bgColor={bgColor} textColor={textColor} kzRowSpan={kzRowSpan} key={"order-" + c.id} />
                        })}

                      </Fragment>
                    )
                  })}
                </Fragment>
              )

            })}


          </tbody>
        }
      />

    </WhiteCard >
  );
}

const Line = (props: { c: IData, cargoIndex: number, cargos: IData[], soIndex: number, bgColor: string, textColor: string, kzRowSpan: number, }) => {
  const { c, cargoIndex, cargos, bgColor, textColor, kzRowSpan, soIndex } = props;
  const soRowSpan = cargos.length;

  const kzO = c.kzCarriersOrderJson;
  const seller = c.seller;

  const element = useCargoHookNae(c.id);
  const kz = useKzCarriersOrderHookNae(element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1)
  if (!element) {
    return <Fragment />
  }
  if (!kz) {
    return <Fragment />
  }

  return (
    <tr>
      {cargoIndex === 0 &&
        <Td rowSpan={soRowSpan} className={classNames(
          bgColor,
          
        )}>
          <SalesOrderDashboardLink
            element={c.salesOrder}
            textColor={textColor}
          />
        </Td>
      }
      {cargoIndex === 0 &&
        <Td className={ bgColor} rowSpan={soRowSpan}>
          <SalesOrderClientWithLinkFilialName textColor={textColor} id={c.salesOrder.id} />
        </Td>
      }
      <Td className={bgColor}>
        <SalesOrderDashboardLink
          element={c.salesOrder}
          cargo={c}
          textColor={textColor}
        />
      </Td>

      <Td className={bgColor + " " + textColor}>
        {!!seller ? seller.name : " "}
      </Td>
      {(soIndex === 0 && cargoIndex === 0) && <Fragment>
        <Td rowSpan={kzRowSpan} className={bgColor}>
          {kz.doer ? <DfValueView id={kz.id} path="kz-carriers-order.doer.fullName" /> : <DfValueView id={kz.id} path="kz-carriers-order.creator.fullName" />}
        </Td>
        <Td className={"whitespace-nowrap " + bgColor} rowSpan={kzRowSpan}>
          <DfValueView path="kz-carriers-order.status20Date" id={kzO.id} />
        </Td>
        <Td rowSpan={kzRowSpan} className={bgColor}>
          <DfValueView path="kz-carriers-order.carNumber" id={kzO.id} />
          <hr />
          {kzO.driver}
        </Td>
        <Td className={"whitespace-pre-wrap w-[300px] " + bgColor} rowSpan={kzRowSpan}>
          {kzO.location}
          <hr />
          {kzO.locationUpdateDate}
          <br />
          <KzOrderLocationHistory id={kzO.id} />
        </Td>
        <Td className={"whitespace-nowrap " + bgColor} rowSpan={kzRowSpan}>
          <DfValueView path="kz-carriers-order.trip" id={kzO.id} />
        </Td>
      </Fragment>}
    </tr>
  );
}