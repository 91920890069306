import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorSalesOrderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ISalesOrderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      address: {
              id: number,
            },
      client: {
              id: number,
            },
      clientFilial: {
              id: number,
            },
      isNeedTerminal: boolean,
      hasPoKz: boolean,
      totalWithCurrency: string,
      serialNumber: string,
      creator: {
              id: number,
            },
      canSeparateCargos: number,
      allCargosOneUnload: number,
      unloadResponsiblePerson: {
              id: number,
            },
      contact: {
              id: number,
            },
      unloadResponsibleComment: string,
      cargos: ChildId[],
      confirmCancelText: string,
      hasNotCalculatedCargos: boolean,
      fullCost: number,
      paymentTermType: number,
      files: any,
      invoices: ChildId[],
      currency: {
              id: number,
                    currency: string,
            },
      total: number,
      payedTotal: number,
      debt: number,
      paymentTermDaysChanges: number,
      paymentTermDays: number,
      paymentTermDate: string,
      delayInDays: number,
      invoiceDebtTotal: number,
      invoicesCount: number,
      priceChanges: ChildId[],
      invoicePriceChanges: ChildId[],
      invoicePaidTotal: number,
      allCbm: number,
      allLdm: number,
      allWeight: number,
      allCargoValueEur: number,
      possibleUnloadDate: string,
      tracking: {
              id: number,
            },
  }

export const ISalesOrderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "address.id",
    "client.id",
    "clientFilial.id",
    "isNeedTerminal",
    "hasPoKz",
    "totalWithCurrency",
    "serialNumber",
    "creator.id",
    "canSeparateCargos",
    "allCargosOneUnload",
    "unloadResponsiblePerson.id",
    "contact.id",
    "unloadResponsibleComment",
    "cargos:id",
    "confirmCancelText",
    "hasNotCalculatedCargos",
    "fullCost",
    "paymentTermType",
    "files",
    "invoices:id",
    "currency.id",
    "currency.currency",
    "total",
    "payedTotal",
    "debt",
    "paymentTermDaysChanges",
    "paymentTermDays",
    "paymentTermDate",
    "delayInDays",
    "invoiceDebtTotal",
    "invoicesCount",
    "priceChanges:id",
    "invoicePriceChanges:id",
    "invoicePaidTotal",
    "allCbm",
    "allLdm",
    "allWeight",
    "allCargoValueEur",
    "possibleUnloadDate",
    "tracking.id"
];

export function useSalesOrderHookNae(id: number) : ISalesOrderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorSalesOrderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "sales-order",
        fields: ISalesOrderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
