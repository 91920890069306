import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorTruckLocationNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITruckLocationModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      uniqId: string,
      truck: {
              id: number,
                    number: string,
            },
      validTo: string,
  }

export const ITruckLocationFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "uniqId",
    "truck.id",
    "truck.number",
    "validTo"
];

export function useTruckLocationHookNae(id: number) : ITruckLocationModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTruckLocationNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "truck-location",
        fields: ITruckLocationFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
