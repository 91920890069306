import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CalculationSerialNumberWithLinkAndNotes from './CalculationSerialNumberWithLinkAndNotes';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

interface Props extends PropsId {
    hideLink?: boolean
}

export default function CalculationSerialNumberWithLinkAndNotesAndStatus(props: Props) {
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <div className={"flex items-center gap-1"}>
            <i className={"fad fa-fw " + (element.status === 3 ? "fa-info" : "fa-calculator") + " " + (element.hasCost ? " text-green-500" : "")} />
            <CalculationSerialNumberWithLinkAndNotes id={element.id} hideLink={props.hideLink} />
        </div>
    )
}
