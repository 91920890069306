import React, { Fragment } from 'react'
import { PropsId } from '../types';
import UserFullName from '../User/UserFullName';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderCreatorFullName(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    if (!element.creator) {
        return <Fragment/>
    }
    return (
        <Fragment>
            <UserFullName id={element.creator.id}/>
        </Fragment>
    )
}
