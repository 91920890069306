import React, { Fragment } from "react";

import KzCarriersOrderStatusWidget from "./Components/KzCarriersOrderStatusWidget";
import KzCarriersOrderCargoTnvedWidget from "../../UserComponents/KzCarriersOrder/Widget/KzCarriersOrderCargoTnvedWidget";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import StockFiles from "../../UserComponents/KzCarriersOrder/Widget/StockFiles";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { CheckUserPermissionComponent, ENaeSPermissions, checkUserAdmin, checkUserHeadOfLogisticsHelper, checkUserOperational } from "../../_generated/_custom/config/NaeSPermissions";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { RecordProvider, useNaeRecord } from '@newageerp/v3.app.mvc.record-provider'
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useKzCarriersOrderHookNae } from "../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

interface IDataCargo {
  terminalJson: {
    id: number;
  };
  id: number;
  typeOut: string;
  hasTnved: boolean;
  tnvedCodesOperational: string;
  serialNumber: string;
  filesSentToTerminal: boolean;
  cargoStockHtmlInfo: string;
  stock: string;
  soClientJson: {
    clientFilialName: string;
  };
}

export interface IKZWfData {
  id: number;
  status: number;
  cargos: IDataCargo[];
  trueUnloadDate: string;
  creator: {
    id: number;
  };
  transportType: number;
  responseDocsFinish: boolean;
  additionalGarantCost: number;
  transportGuaranteeType: number;
}

const defObject: IKZWfData = {
  id: 0,
  status: 0,
  cargos: [
    {
      terminalJson: {
        id: 0,
      },
      id: 0,
      typeOut: "",
      hasTnved: false,
      tnvedCodesOperational: "",
      serialNumber: "",
      filesSentToTerminal: false,
      cargoStockHtmlInfo: "",
      soClientJson: {
        clientFilialName: "",
      },
      stock: "",
    },
  ],
  trueUnloadDate: "",
  creator: {
    id: 0,
  },
  transportType: 0,
  responseDocsFinish: false,
  additionalGarantCost: 0,
  transportGuaranteeType: 0,
};

export default function KzCarriersOrderWorkflowWidget(
  props: PropsId
) {
  const element = useKzCarriersOrderHookNae(props.id);
  const { viewStackIndex } = useNaeRecord();
  const { userState } = useTemplatesCore()
  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);
  const isUserOperational = checkUserOperational(userState);
  const isUserAdmin = checkUserAdmin(userState);

  if (!element) {
    return <Fragment />
  }
  return (
    <Fragment>
      <RecordProvider
        defaultViewIndex={viewStackIndex}
        id={props.id}
        schema={NaeSSchemaMap.KzCarriersOrder.schema}
        fieldsToReturn={getKeysFromObject(defObject)}
        viewId={"KzCarriersOrderWorkflowWidget" + props.id}
      >
        <div className={"grid gap-1 mb-2"}>
          {/* OK */}
          {/* <LoadDatesChangeWidget id={element.id} /> */}

          <CheckUserPermissionComponent permissions={[
            ENaeSPermissions.UserAdmin,
            ENaeSPermissions.UserHeadOfLogistics,
            ENaeSPermissions.UserLogisticFtl,
            ENaeSPermissions.UserLogisticSea,
            ENaeSPermissions.UserLogisticTrain,
            ENaeSPermissions.UserOperationalPro,
            ENaeSPermissions.UserForwardingManager,
            ENaeSPermissions.UserOperational,
            // ENaeSPermissions.UserHeadOfLogisticsHelper,
          ]}>
            <KzCarriersOrderStatusWidget elementId={props.id} />
          </CheckUserPermissionComponent>


          <KzCarriersOrderCargoTnvedWidget elementId={props.id} />

          {element.status < 40 && !isTrackingSpecialist && !isUserOperational && (
            <WhiteCard isCompact={true}>
              <MainEditContentInline
                schema={NaeSSchemaMap.KzCarriersOrder.schema}
                type={"internal-comment"}
                id={props.id.toString()}
                isCompact={true}

              />

            </WhiteCard>
          )}

          {element.status < 40 && (isUserOperational || isUserAdmin) && (
            <WhiteCard isCompact={true}>
              <MainEditContentInline
                schema={NaeSSchemaMap.KzCarriersOrder.schema}
                type={"dps-notes"}
                id={props.id.toString()}
                isCompact={true}

              />

            </WhiteCard>
          )}

          <CheckUserPermissionComponent permissions={[
            ENaeSPermissions.UserAdmin,
            ENaeSPermissions.UserLogisticFtl,
            ENaeSPermissions.UserLogisticSea,
            ENaeSPermissions.UserLogisticTrain,
            ENaeSPermissions.UserOperationalPro,
            ENaeSPermissions.UserHeadOfLogistics,
            ENaeSPermissions.UserOperational,
          ]}>
            <StockFiles />
          </CheckUserPermissionComponent>
        </div>
      </RecordProvider>
    </Fragment>
  );
}
