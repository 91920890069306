import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { checkUserOperationalPro } from "../../../../_generated/_custom/config/NaeSPermissions";
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButtonWithSave } from "@newageerp/v3.bundles.buttons-bundle";


export default function OperationalNeedSpecificationSuccessBtn(props: PropsId) {
  const { t } = useTranslation();
  const element = useCargoHookNae(props.id);
  const {userState} = useTemplatesCore()
  const isOperationalPro = checkUserOperationalPro(userState);

  if (!element) {
    return <Fragment />
  }

  if (isOperationalPro && element.minimumSpecificationStatus === 10) {
    return (
      <div className="flex gap-2">
        <MainButtonWithSave
          elementId={props.id}
          schema={"cargo"}
          saveData={
            {
              needSpecification: 20,
            }
          }
          confirmation={true}
          color="red"
        >
          {t("Reject")}
        </MainButtonWithSave>
        <MainButtonWithSave
          elementId={props.id}
          schema={"cargo"}
          saveData={
            {
              needSpecification: 30,
              addNote: {
                content: "specifikacija paruošta",
                notify: [element.expeditor.id],
              },
            }
          }
          confirmation={true}
          color="teal"
        >
          {t("Confirm")}
        </MainButtonWithSave>
      </div>
    )
  }

  let disabledContent = undefined;
  let isDisabled = false;
  if (element.minimumSpecificationStatus === 10) {
    isDisabled = true;
    disabledContent = t('Laukiama patvirtinimo');
  }

  return (
    <div className="space-y-2">
      {element.minimumSpecificationStatus === 20 && <AlertWidget isCompact={true} color="danger">{t('Specifikacija atmesta')}</AlertWidget>}
      <MainButtonWithSave
        elementId={props.id}
        schema={"cargo"}
        saveData={
          {
            needSpecification: 30,
          }
        }
        confirmation={true}
        color="teal"
        disabled={isDisabled}
        disabledContent={{
          children: disabledContent
        }}
        className={"w-full"}
      >
        {t("Completed")}
      </MainButtonWithSave>
    </div>
  )
}
