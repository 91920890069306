import React from 'react'
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../_generated/_custom/config/NaeSPermissions';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import MainActionPresentation from './Components/MainActionPresentation';
import MainActionChats from './Components/MainActionChats';
import CardCallWidget from '../layout/view/custom-toolbar-items/components/client/CardCallWidget';
import ManagerClientDecline from './Components/ManagerClientDecline';
import BusinessDevWidget from './Components/BusinessDevWidget';
import AdminReturnToList from './Components/AdminReturnToList';

export default function ClientMainActions(props: PropsId) {
    return (
        <div className='flex items-center gap-4'>
            <CardCallWidget id={props.id} />
            <CheckUserPermissionComponent permissions={[
                ENaeSPermissions.UserAdmin,
                ENaeSPermissions.UserManager
            ]}>
                <MainActionPresentation id={props.id} />
                <MainActionChats id={props.id} />
                <ManagerClientDecline id={props.id} />
            </CheckUserPermissionComponent>
            <CheckUserPermissionComponent permissions={[
                ENaeSPermissions.UserAdmin,
                ENaeSPermissions.UserBusinessDev
            ]}>
                <BusinessDevWidget elementId={props.id} canAccept={true} />
            </CheckUserPermissionComponent>
            <CheckUserPermissionComponent permissions={[
                ENaeSPermissions.UserAdmin]}
            >
                <AdminReturnToList id={props.id} />
            </CheckUserPermissionComponent>
        </div>
    )
}
