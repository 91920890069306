import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useTranslation } from 'react-i18next';
import TerminalName from '../../../_generated/_custom/models-cache-data/Terminal/TerminalName';
import TerminalAddress from '../../../_generated/_custom/models-cache-data/Terminal/TerminalAddress';
import TerminalResponsiblePerson from '../../../_generated/_custom/models-cache-data/Terminal/TerminalResponsiblePerson';
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../../_generated/_custom/config/NaeSPermissions';
import CargoTerminalWidget from '../Widget/CargoTerminalWidget';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

export default function BottomWidgetTerminal(props: PropsId) {
    const { t } = useTranslation();
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.terminal) {
        return <Fragment />
    }
    return (
        <Fragment>
            <FieldLabel>{t('Warehouse')}</FieldLabel>
            <CheckUserPermissionComponent
                permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserHeadOfLogistics]}
            >
                <CargoTerminalWidget id={props.id} />
            </CheckUserPermissionComponent>

            <div>
                <TerminalName id={element.terminal.id} /><br/>
                <TerminalAddress id={element.terminal.id} /><br/>
                <TerminalResponsiblePerson id={element.terminal.id} />
            </div>

        </Fragment>
    )
}
