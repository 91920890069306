import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

interface PlayerProps {
    url: string
}

export default function VoximplantAudioPlayer(props: PlayerProps) {
    const { url } = props;
    const [authUrl, setAuthUrl] = useState('')

    const [token, setToken] = useState('');
    const [showPlayer, setShowPlayer] = useState(false);
    const togglePlayer = () => setShowPlayer(!showPlayer);
    const { t } = useTranslation();

    const [getData, getDataParams] = useURequest<string>(NaePathsMap.post['VOXgetToken']);

    useEffect(() => {
        if (showPlayer) {
            getData().then((res: any) => {
                setToken(res.data.data[0]);
            })
        }
    }, [showPlayer]);

    useEffect(() => {
        if (token) {
            const options = {
                headers: {
                    Authorization: token
                }
            }

            fetch(url, options)
                .then(response => response.blob())
                .then(blob => {
                    setAuthUrl(URL.createObjectURL(blob))
                });

        }
    }, [token]);

    if (!!authUrl) {
        return (
            <audio controls autoPlay>
                <source src={authUrl} type="audio/mpeg" />
            </audio>
        )
    }

    if (getDataParams.loading || !!token && !authUrl) {
        return <LogoLoader size={30} />
    }

    return (
        <MainButton color="sky" onClick={togglePlayer} className={"w-40"} iconName="play">{t('Play')}</MainButton>
    )
}
