import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from '../../../_generated/_custom/models/ormSelectors';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { ICargoModelNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { checkUserAdmin, checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';

export default function CalculationCargoFtlOverloadType(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    const elementCargos = element ? element.cargos.map(el => el.id) : [-1];
    // @ts-ignore
    const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);
    const isManager = checkUserManager(userState);

    if (!element) {
        return <Fragment />
    }
    if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
        return <Fragment />
    }
    if (cargos.length === 0) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />
    }
    if (element.cargos.length === 0) {
        return <Fragment />
    }
    if (!(isAdmin || isManager)) {
        return <Fragment />
    }

    const cargo = cargos[0];

    if (cargo.typeOut === "ltl" && element.allCargosOneUnload === 10) {
        return (
            <WhiteCard isCompact={true} className="border-4 border-yellow-500">
                <MainEditContentInline
                    schema={"cargo"}
                    type={"cargo-ftl-overload-type"}
                    id={cargo.id.toString()}
                    isCompact={true}
                />
            </WhiteCard>
        );
    }
    return <Fragment />
}
