import { Model, fk, many, attr } from "redux-orm";
import { NaeSSchemaMap } from "../config/NaeSSchema";
import { IContactFieldsNae } from "../hooks/useContactHookNae";
import { addToQueueModel } from "./QueueModel";

class ClientModel extends Model {
  static reducer(action, ClientModel, session) {
    switch (action.type) {
      case "UPSERT_" + NaeSSchemaMap.Client.schema:
        action.payload.forEach((payload) => {
          const dbData = ClientModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          } else {
            if (payload.contactsToUse) {
              payload.contactsToUse.forEach((c) => {
                addToQueueModel(
                  {
                    id: NaeSSchemaMap.Contact.schema + "-" + c.id,
                    elementId: c.id,
                    schema: NaeSSchemaMap.Contact.schema,
                    fields: IContactFieldsNae,
                  },
                  session.QueueModel,
                );
              });
            }
          }

          ClientModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.id}`;
  }
  // Declare any static or instance methods you need.
}
ClientModel.modelName = "ClientModel";

ClientModel.fields = {
  id: attr(),
};

export default ClientModel;
