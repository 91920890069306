import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import CargoLogisticCalculatesLine from "./Components/CargoLogisticCalculatesLine";

import TextForCopy from "../../UserComponents/Dashboard/Logistic/Components/CargoLogisticCalculates/TextForCopy";
import FinishBtn from "../../UserComponents/Dashboard/Logistic/Components/CargoLogisticCalculates/FinishBtn";
import Recomendations from "../../UserComponents/Dashboard/Logistic/Components/CargoLogisticCalculates/Recomendations";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from "../../_generated/_custom/hooks/useCargoHookNae";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { LogoLoader } from "@newageerp/v3.bundles.layout-bundle";

interface Props {
  cargoId: number;
  compact?: boolean;
  forceCanAdd?: boolean;

  calcType: string,
}

export default function CargoLogisticCalculates(props: Props) {
  const { cargoId } = props;

  const cargo = useCargoHookNae(cargoId);

  const { userState } = useTemplatesCore()

  const { t } = useTranslation();

  if (!cargo) {
    return <LogoLoader />;
  }

  const activeCargoLoadingCalculations = props.calcType === 'out' ? cargo.activeCargoLoadingCalculationsOut : cargo.activeCargoLoadingCalculations;

  const toMapNormal =
    Math.max(
      props.forceCanAdd ? 1 : 3,
      activeCargoLoadingCalculations.length
    ) - activeCargoLoadingCalculations.length;

  const toMap = Array.from(Array(toMapNormal).keys());

  const create = () => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: NaeSSchemaMap.CargoLoadingCalculation.schema,
      options: {
        createOptions: {
          convert: {
            schema: "cargo",
            element: { id: cargo.id, },
            calcType: props.calcType,
          },
        },
      },
      onSaveCallback: (_el, back) => {
        back();
      },
    });
  };

  const isFtlHelper = userState.ftlHelper === 10 && cargo.typeIn === 'ftl';
  const loadingPriceCalculated = props.calcType === 'out' ? cargo.loadingPriceCalculatedOut :cargo.loadingPriceCalculated;
  const isCanAdd = (!loadingPriceCalculated || !!props.forceCanAdd) && !isFtlHelper;

  return (
    <Fragment>
      <div className={"space-y-2"}>
        {activeCargoLoadingCalculations.map((calc) => {
          return (
            <CargoLogisticCalculatesLine
              id={calc.id}
              key={"calc-" + calc.id}
              canEdit={isCanAdd}
            />
          );
        })}

        <Fragment>
          {!isFtlHelper &&
            <Recomendations
              id={cargo.id}
              compact={props.compact}
              forceCanAdd={props.forceCanAdd}
              calcType={props.calcType}
            />
          }

          {isCanAdd && (
            <Fragment>
              <MainButton onClick={create} color={"sky"} iconName={"plus"} className="w-full">
                {t("Enter the quotation")}
              </MainButton>
            </Fragment>
          )}
        </Fragment>

        {activeCargoLoadingCalculations.length > 0 &&
          <FinishBtn cargoId={cargo.id} calcType={props.calcType} />
        }

        <TextForCopy id={cargo.id} />
      </div>
    </Fragment>
  );
}
