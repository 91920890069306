import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorLeadLostReasonNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ILeadLostReasonModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      color: string,
      title: string,
      sendToBusinessDev: number,
  }

export const ILeadLostReasonFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "color",
    "title",
    "sendToBusinessDev"
];

export function useLeadLostReasonHookNae(id: number) : ILeadLostReasonModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorLeadLostReasonNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "lead-lost-reason",
        fields: ILeadLostReasonFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
