import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import moment from "moment";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useRecoilState } from 'recoil';
import { checkUserAccounting, checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';
import DebtReportsClientTr from './DebtReportsClientTr';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { NotesContent } from "@newageerp/v3.bundles.notes-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { RsButton, ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { Table, Th, Td, UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { usePrint, useQuery, useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface IData {
  id: number;
  serialNumber: string;
  client: {
    id: number;
    name: string;
  };
  creator: {
    id: number;
    fullName: string;
  };
  totalEur: number;
  date: string;
  debt: number;
  paymentTermDate: string;
  delayInDays: number;
  debtForToday: number,
  debt0: number;
  debt8: number;
  debt20: number;
  debt21: number;
  debt91: number;
  debtDelayed: number;
  payedTotal: number;
  invoiceDebtTotal: number;
  cargoSenders: string;
  paymentTermTypeTextLt: string;
  maxUnloadDate: string;
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  client: {
    id: 0,
    name: "",
  },
  creator: {
    id: 0,
    fullName: "",
  },
  totalEur: 0,
  date: "",
  debt: 0,
  debtForToday: 0,
  paymentTermDate: "",
  delayInDays: 0,
  debt0: 0,
  debt8: 0,
  debt20: 0,
  debt21: 0,
  debt91: 0,
  debtDelayed: 0,
  payedTotal: 0,
  invoiceDebtTotal: 0,
  cargoSenders: "",
  paymentTermTypeTextLt: '',
  maxUnloadDate: '',
};

interface INote {
  id: number,
  parentId: number,
  createdAt: string,
}

const defNote: INote = {
  id: 0,
  parentId: 0,
  createdAt: '',
}

interface Props {
  missHeader?: boolean;
}

export const ClientDebtsSchema = 'client-debts';
export const SoDebtsSchema = 'so-debts';

export default function DebtsReports(props: Props) {
  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)

  const [printed, setPrinted] = useState(false);
  const isPrint = usePrint();
  const queryManager = useQuery().get('manager');

  const [selectedClient, setSelectedClient] = useState(0);

  const [selectedSo, setSelectedSo] = useState(0);

  const { t } = useTranslation();
  const [activeLine, setActiveLine] = useState("");
  const [getData, getDataParams] = useUList<IData>(
    "sales-order",
    getKeysFromObject(defObject)
  );

  const { userState } = useTemplatesCore()
  const isManager = checkUserManager(userState) || queryManager;
  const isAccounting = checkUserAccounting(userState);

  React.useEffect(() => {
    var paymentTermDate = moment().add(90, "days").format("YYYY-MM-DD");

    let _filter = [
      isManager
        ? {
          and: [
            ["i.debt", "gt", 0, true],
            // ["i.paymentTermDate", "dlte", paymentTermDate, true],
            ["i.status", "in", [10, 20], true],
            ["i.client.doer", "=", queryManager ? queryManager : "CURRENT_USER", true],
          ],
        }
        : {
          and: [
            ["i.debt", "gt", 0, true],
            // ["i.paymentTermDate", "dlte", paymentTermDate, true],
            ["i.status", "in", [10, 20], true],
          ],
        },
    ];
    getData(
      _filter,
      1,
      999999,
      [
        { key: 'i.paymentTermDate', value: 'ASC' }
      ]
      // [{ 'key': 'i.creator.firstName', 'value': 'ASC' }, { 'key': 'i.creator.lastName', 'value': 'ASC' }],
    ).then(() => {
      // loadNotes();
    });
  }, []);

  const defTdClassName = " " + (isPrint ? 'text-xs ' : '');

  const allData = getDataParams.data.data.map((el: any) => {
    const _debtDiff = el.debt - el.debtForToday;
    return {
      ...el,
      debt: el.debt,
      debt0: el.delayInDays <= 0 ? el.debtForToday : _debtDiff,
      debt8: el.delayInDays > 0 && el.delayInDays <= 7 ? el.debtForToday : 0,
      debt20: el.delayInDays > 7 && el.delayInDays <= 20 ? el.debtForToday : 0,
      debt21: el.delayInDays > 20 && el.delayInDays <= 90 ? el.debtForToday : 0,
      debt91: el.delayInDays > 90 ? el.debtForToday : 0,
      debtDelayed: el.delayInDays > 0 ? el.debtForToday : 0,
    };
  });

  const dataByCreator = groupMap(allData, (el: IData) => el.creator.fullName);

  const doPrint = (userId: number) => {
    const link = '/c/reports/debts?print=true&manager=' + userId;

    window.open(link, '_blank');
  }

  const CardComponent = isPrint ? OldTransparentCardCompact : WhiteCard;

  useEffect(() => {
    if (getDataParams.data.data.length > 0 && isPrint && !printed) {
      setTimeout(() => {
        window.print();
      }, 400);
      setPrinted(true);
    }
  }, [isPrint, getDataParams.data, printed]);

  const thead = <thead className="table-header-group">
    <Th className="text-left w-2/12">{t("Number")}</Th>
    <Th className="text-left w-2/12">{t("Seller")}</Th>
    <Th className="text-left w-2/12">{t("Payment terms")}</Th>
    <Th className="text-left w-1/12">{t("Due to")}</Th>

    <Th className="text-right w-1/12">{t("Overdue")}</Th>
    <Th className="text-right w-1/12">{t("Last unloading")}</Th>
    <Th className="text-right w-1/12">{t("Price, EUR")}</Th>

    <Th className="text-right w-1/12">{t("Not included invoices total")}</Th>
    <Th className="text-right w-1/12">{t("Paid, EUR")}</Th>
    <Th className="text-right w-1/12">{t("Debt, EUR")}</Th>
  </thead>

  useEffect(() => {
    setToolbarTitle(`Debt report ${isManager ? (Object.keys(dataByCreator)[0]) : ''}`);
  })

  return (
    <Fragment>
      <div className={"space-y-2"}>
        {!isManager && !isAccounting && (
          <CardComponent>
            <TextCardTitle>{t("All")}</TextCardTitle>

            <Table
              thead={
                <thead>
                  <tr>
                    <Th className={defTdClassName + "text-left"}></Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Not overdue, EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Overdue 1-7 d., EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Overdue 8-20 d., EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Overdue 21-90 d., EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Overdue 91+ d., EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Overdue debt (total), EUR")}
                    </Th>
                    <Th className={defTdClassName + "text-right"}>
                      {t("Debt, EUR")}
                    </Th>
                  </tr>
                </thead>
              }
              tbody={
                <tbody>
                  <tr className={"total-row"}>
                    <Td className={defTdClassName + "text-left"}></Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt0)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt8)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt20)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt21)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt91)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debtDelayed)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td className={defTdClassName + "text-right"}>
                      {allData
                        .map((o) => o.debt)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                  </tr>
                </tbody>
              }
            />

          </CardComponent>
        )}
        {Object.keys(dataByCreator).map((owner) => {
          const data: IData[] = dataByCreator[owner];
          const groupByClient: any = groupMap(data, (el) =>
            el.client.name
          );

          return (
            <CardComponent key={"owner-" + owner}>
              {!isPrint &&
                <div className="flex items-center gap-4">
                  <TextCardTitle>{owner}</TextCardTitle>

                  <ToolbarButton
                    onClick={() => {
                      doPrint(data[0].creator.id)
                    }}
                    iconName="print"
                  />

                </div>
              }
              <Table
                thead={!isPrint ?
                  <thead className="table-header-group">

                    <tr>
                      <Th className={defTdClassName + "text-left"}>
                        {t("Client")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Not overdue, EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Overdue 1-7 d., EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Overdue 8-20 d., EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Overdue 21-90 d., EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Overdue 91+ d., EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Overdue debt (total), EUR")}
                      </Th>
                      <Th className={defTdClassName + "text-right"}>
                        {t("Debt, EUR")}
                      </Th>
                    </tr>

                  </thead>
                  : thead
                }
                tbody={
                  <tbody>
                    {Object.keys(groupByClient).map(
                      (client: string, index: number) => {
                        const groupByClientData: IData[] = groupByClient[client];

                        const clientDebt0 = groupByClientData
                          .map((o) => o.debt0)
                          .reduce((a: number, b: number) => a + b, 0);

                        const clientDebt8 = groupByClientData
                          .map((o) => o.debt8)
                          .reduce((a: number, b: number) => a + b, 0);
                        const clientDebt20 = groupByClientData
                          .map((o) => o.debt20)
                          .reduce((a: number, b: number) => a + b, 0);
                        const clientDebt21 = groupByClientData
                          .map((o) => o.debt21)
                          .reduce((a: number, b: number) => a + b, 0);
                        const clientDebt91 = groupByClientData
                          .map((o) => o.debt91)
                          .reduce((a: number, b: number) => a + b, 0);
                        const clientDebtDelayed = groupByClientData
                          .map((o) => o.debtDelayed)
                          .reduce((a: number, b: number) => a + b, 0);

                        const clientDebt7plus = clientDebt8 + clientDebt20 + clientDebt21;

                        const isForAccounter = (clientDebt7plus > 1000 || clientDebt21 > 0) && clientDebt91 === 0;

                        if (!isForAccounter && isAccounting) {
                          return <Fragment key={"owner-" + owner + "-cl-" + client} />
                        }

                        const clientId = groupByClientData[0].client.id;

                        return (
                          <Fragment key={"owner-" + owner + "-cl-" + client}>
                            <DebtReportsClientTr
                              index={index}
                              id={clientId}
                            >
                              <Td className={defTdClassName + "text-left"} colSpan={isPrint ? 8 : 1}>
                                <div className={"flex gap-2 items-center"}>
                                  {!isPrint &&
                                    <ToolbarButton
                                      onClick={
                                        activeLine === client
                                          ? () => setActiveLine("")
                                          : () => setActiveLine(client)
                                      }
                                      bgColor={"transparent"}
                                      iconName={(activeLine === client
                                        ? "eye-slash"
                                        : "eye")}
                                    />
                                  }

                                  <RsButton
                                    id={groupByClientData[0].client.id}
                                    schema={NaeSSchemaMap.Client.schema}
                                    defaultClick={"modal"}
                                    button={{
                                      children: groupByClientData[0].client.name,
                                      color: 'white',
                                      skipPadding: true,
                                      className: (isPrint ? "text-xs" : "")
                                    }}

                                  />

                                </div>
                              </Td>
                              {!isPrint &&
                                <Fragment>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebt0.toFixed(2)}
                                  </Td>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebt8.toFixed(2)}
                                  </Td>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebt20.toFixed(2)}
                                  </Td>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebt21.toFixed(2)}
                                  </Td>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebt91.toFixed(2)}
                                  </Td>
                                  <Td className={defTdClassName + "text-right"}>
                                    {clientDebtDelayed.toFixed(2)}
                                  </Td>
                                </Fragment>
                              }
                              <Td className={defTdClassName + `text-right ${isPrint ? 'font-extrabold' : ''}`}>
                                {groupByClientData
                                  .map((o) => o.debt)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>

                            </DebtReportsClientTr>
                            {(activeLine === client || isPrint) && (
                              <tr>
                                <Td colSpan={isPrint ? 10 : 8} className={isPrint ? 'border-b-2 border-black' : ''}>
                                  <Table
                                    thead={isPrint ? undefined : thead
                                    }
                                    tbody={
                                      <tbody>
                                        {groupByClientData.map(
                                          (el: IData, soIndex: number) => {
                                            return (
                                              <tr
                                                key={"so-row-" + el.id}
                                                className={
                                                  isPrint ? "" : (
                                                    soIndex % 2 === 0
                                                      ? "bg-gray-50"
                                                      : ""
                                                  )
                                                }
                                              >
                                                <Td
                                                  className={"text-left w-2/12"}

                                                >
                                                  <div className="flex gap-1 items-center">
                                                    {!isPrint && <ToolbarButton onClick={() => setSelectedSo(el.id)} iconName="comment" />}

                                                    <RsButton
                                                      id={el.id}
                                                      schema={
                                                        "sales-order"
                                                      }
                                                      defaultClick={"modal"}
                                                      button={{
                                                        children: el.serialNumber,
                                                        color: "white",
                                                        skipPadding: true,
                                                      }}
                                                    />
                                                  </div>
                                                </Td>
                                                <Td
                                                  className={"text-left w-2/12"}

                                                >
                                                  {el.cargoSenders}
                                                </Td>
                                                <Td
                                                  className={"text-left w-2/12"}

                                                >
                                                  {el.paymentTermTypeTextLt}
                                                </Td>
                                                <Td
                                                  className={"text-left whitespace-nowrap w-1/12"}

                                                >
                                                  {moment(
                                                    el.paymentTermDate
                                                  ).format("YYYY-MM-DD")}
                                                </Td>
                                                <Td
                                                  className={"text-right whitespace-nowrap w-1/12"}

                                                >
                                                  {el.delayInDays} d.
                                                </Td>
                                                <Td
                                                  className={"text-right whitespace-nowrap w-1/12"}

                                                >
                                                  {el.maxUnloadDate}
                                                </Td>
                                                <Td
                                                  className={"text-right w-1/12"}

                                                >
                                                  {el.totalEur.toFixed(2)}
                                                </Td>
                                                <Td

                                                  className={`text-right w-1/12 ${el.invoiceDebtTotal > 0 ? 'text-red-500' : ''}`}
                                                >
                                                  {el.invoiceDebtTotal.toFixed(2)}
                                                </Td>
                                                <Td
                                                  className={"text-right w-1/12"}

                                                >
                                                  {el.payedTotal.toFixed(2)}
                                                </Td>
                                                <Td

                                                  className={"text-right w-1/12"}
                                                >
                                                  {el.debt.toFixed(2)}
                                                </Td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    }
                                  />
                                </Td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      }
                    )}
                    {!isPrint && !isAccounting &&
                      <tr className={"total-row"}>
                        <Td className={defTdClassName + "text-left"}></Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt0)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt8)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt20)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt21)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt91)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debtDelayed)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td className={defTdClassName + "text-right"}>
                          {data
                            .map((o) => o.debt)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                      </tr>
                    }
                  </tbody>
                }
              />


            </CardComponent>
          );
        })}
      </div>
      {selectedClient > 0 && <DebtReportsPopup id={selectedClient} onClose={() => setSelectedClient(0)} />}

      {selectedSo > 0 && <DebtReportsPopup id={selectedSo} onClose={() => setSelectedSo(0)} />}
    </Fragment>
  );
}

interface DebtReportsProps {
  id: number,
  onClose: () => void,
}

const DebtReportsPopup = (props: DebtReportsProps) => {
  const { t } = useTranslation();
  // const client = useClientHookNae(props.id);
  const so = useSalesOrderHookNae(props.id);
  // const user = useUserHookNae(client ? client.doer.id : 0);
  if (!so) {
    return <Fragment />
  }
  return (
    <Popup isPopup={true} onClick={props.onClose} title={t('Comment')}>
      <div className="mx-auto">
        <WhiteCard isCompact={true}>
          <TextCardTitle>{so.serialNumber}</TextCardTitle>

          <NotesContent schema={SoDebtsSchema} id={so.id} options={{}} showOnlyMy={false} />
        </WhiteCard>
      </div>

    </Popup>
  )
}

const OldTransparentCardCompact = (props: any) => {
  return <WhiteCard bgColor="transparent" {...props} />
}