import OrdersTraileref951 from "./components/ViltTrip/OrdersTrailer";
import OrdersCustom18c9c from "./components/ViltTrip/OrdersCustom";
import OrdersTrip1d57f from "./components/ViltTrip/OrdersTrip";
import OrdersTotals26a56 from "./components/ViltTrip/OrdersTotals";
import OrdersClient8cff8 from "./components/ViltTrip/OrdersClient";
import Phones79f34 from "./components/contact/Phones";
import UnloadAddressBadge00a73 from "./components/UnloadAddress/UnloadAddressBadge";
import CargoCalculationsc6ccf from "./components/CarriersOrder/CargoCalculations";
import SoClientNameTableWithId84d07 from "./components/Cargo/SoClientNameTableWithId";
import CalculationActiveCargoLoadingCalculationsf7ed3 from "./components/Cargo/CalculationActiveCargoLoadingCalculations";
import ClientStatus599f5 from "./components/Client/ClientStatus";
import InvoicesTable64108 from "./components/SalesOrder/InvoicesTable";
import CargoLinks332d7 from "./components/SalesOrder/CargoLinks";
import DebtRed90d0a from "./components/InvoiceOutgoing/DebtRed";
import LinkToClient7f58d from "./components/voximplant/LinkToClient";
import AudioPlayer78636 from "./components/voximplant/AudioPlayer";
import PriceChange0ca50 from "./components/InvoiceOutgoing/PriceChange";
import Pdf656e8 from "./components/InvoiceOutgoing/Pdf";
import Clientlinkf0229 from "./components/InvoiceOutgoing/Clientlink";
import InvoiceRels40750 from "./components/InvoiceOutgoing/InvoiceRels";
import MoveClientClienta4cb7 from "./components/User/MoveClientClient";
import UnAssignClientClient8bca5 from "./components/User/UnAssignClientClient";
import MoveClientLead74f46 from "./components/User/MoveClientLead";
import UnAssignClientLead47033 from "./components/User/UnAssignClientLead";
import UnAssignBusinessDevde794 from "./components/User/UnAssignBusinessDev";
import CustomClientName564f8 from "./components/InvoiceIncoming/CustomClientName";
import Actionsf6150 from "./components/InvoiceOutgointCreditRequest/Actions";
import Actions43704 from "./components/KzFtlCalculations/Actions";
import Confirmationb5319 from "./components/AccountingDiscrepancies/Confirmation";
export const CustomListComponentsMap: any = {
    'customlist.ViltTrip/OrdersTrailer': OrdersTraileref951,
    'customlist.ViltTrip/OrdersCustom': OrdersCustom18c9c,
    'customlist.ViltTrip/OrdersTrip': OrdersTrip1d57f,
    'customlist.ViltTrip/OrdersTotals': OrdersTotals26a56,
    'customlist.ViltTrip/OrdersClient': OrdersClient8cff8,
    'customlist.contact/Phones': Phones79f34,
    'customlist.UnloadAddress/UnloadAddressBadge': UnloadAddressBadge00a73,
    'customlist.CarriersOrder/CargoCalculations': CargoCalculationsc6ccf,
    'customlist.Cargo/SoClientNameTableWithId': SoClientNameTableWithId84d07,
    'customlist.Cargo/CalculationActiveCargoLoadingCalculations': CalculationActiveCargoLoadingCalculationsf7ed3,
    'customlist.Client/ClientStatus': ClientStatus599f5,
    'customlist.SalesOrder/InvoicesTable': InvoicesTable64108,
    'customlist.SalesOrder/CargoLinks': CargoLinks332d7,
    'customlist.InvoiceOutgoing/DebtRed': DebtRed90d0a,
    'customlist.voximplant/LinkToClient': LinkToClient7f58d,
    'customlist.voximplant/AudioPlayer': AudioPlayer78636,
    'customlist.InvoiceOutgoing/PriceChange': PriceChange0ca50,
    'customlist.InvoiceOutgoing/Pdf': Pdf656e8,
    'customlist.InvoiceOutgoing/Clientlink': Clientlinkf0229,
    'customlist.InvoiceOutgoing/InvoiceRels': InvoiceRels40750,
    'customlist.User/MoveClientClient': MoveClientClienta4cb7,
    'customlist.User/UnAssignClientClient': UnAssignClientClient8bca5,
    'customlist.User/MoveClientLead': MoveClientLead74f46,
    'customlist.User/UnAssignClientLead': UnAssignClientLead47033,
    'customlist.User/UnAssignBusinessDev': UnAssignBusinessDevde794,
    'customlist.InvoiceIncoming/CustomClientName': CustomClientName564f8,
    'customlist.InvoiceOutgointCreditRequest/Actions': Actionsf6150,
    'customlist.KzFtlCalculations/Actions': Actions43704,
    'customlist.AccountingDiscrepancies/Confirmation': Confirmationb5319,
}