import React, { Fragment } from 'react'

import { PropsId } from '../../../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../../../_generated/_custom/hooks/useCargoHookNae';
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function CargoAdminStock(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const { userState } = useTemplatesCore()

    if (!element) {
        return <Fragment />
    }
    if (!(element.status >= 10)) {
        return <Fragment />
    }

    return (
        <WhiteCard isCompact={true}>
            <MainEditContentInline
                schema={"cargo"}
                type={"stock"}
                id={element.id.toString()}
                isCompact={true}
            />
        </WhiteCard>
    )
}
