import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoLoadingCalculationHookNae } from '../../hooks/useCargoLoadingCalculationHookNae';

export default function CargoLoadingCalculationTotalWithCurrency(props: PropsId) {
    const element = useCargoLoadingCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.total.toFixed(2)} {element.currency ? element.currency.currency : ""}
        </Fragment>
    )
}
