import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useContactHookNae } from '../../hooks/useContactHookNae';

export default function ContactEmailsWithLink(props: PropsId) {
    const element = useContactHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.emails || element.emails.length === 0) {
        return <Fragment />
    }
    return (
        <div className={"space-y-1"}>
            {element.emails
                .filter((email: string) => !!email)
                .map((email: string, index: number) => {
                    return (
                        <p key={"contact-email-" + element.id + "-" + index}>
                            <i className={"mr-1 fa-fw fad fa-mailbox"} />

                            <a href={`mailto:${email}`} className={"hover:underline"}>
                                {email}{" "}
                            </a>
                        </p>
                    );
                })}
        </div>
    )
}
