import React, { Fragment, useState } from 'react'
import CargoSerialNumberWithLinkAndNotes from '../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndNotes';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useTranslation } from 'react-i18next';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import OutgoingDocumentsChangeWidget from '../../Cargo/Components/OutgoingDocumentsChangeWidget';


export default function KzOrderCargoLinks(props: PropsId) {
    const { t } = useTranslation();

    const element = useKzCarriersOrderHookNae(props.id);
    const [showCargos, setShowCargos] = useState(false);
    const toggleShowCargos = () => setShowCargos(!showCargos);

    if (!element) {
        return <Fragment />
    }

    const cargoVible = showCargos || element.cargos.length === 1;

    if (element.cargos.length === 0) {
        return <Fragment/>
    }

    return (
        <div className='p-2 bg-slate-100 rounded'>

            {cargoVible &&
                <div className={"space-y-2"}>
                    {element.cargos.map(c => (
                        <div className='flex items-center gap-2 max-w-[200px]'>
                            <CargoSerialNumberWithLinkAndNotes key={`c-${c.id}`} id={c.id} />
                            <OutgoingDocumentsChangeWidget
                                id={c.id}
                            />
                        </div>
                    ))}
                </div>
            }

            {element.cargos.length > 1 && <button className={"text-purple-500"} onClick={toggleShowCargos}>{cargoVible ? t('Hide') : t('Show')}</button>}
        </div>
    )
}
