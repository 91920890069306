import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CargoLoadingAddress from './CargoLoadingAddress';

interface Props extends PropsId {
    index: number,
}

export default function CargoLoadingAddressWithIndex(props: Props) {
    return <Fragment>
        {props.index}) <CargoLoadingAddress id={props.id}/>
    </Fragment>
}
