import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { LogoLoader } from "@newageerp/v3.bundles.layout-bundle";
import { TemplatesLoader, getTemplateLoaderData, TemplatesParser, useTemplatesCore } from "@newageerp/v3.templates.templates-core";

import { buildQsDictionary, ShowOnScreen } from '@newageerp/v3.bundles.utils-bundle';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { MainButton, ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { checkUserHeadOfLogisticsHelper } from "../../../_generated/_custom/config/NaeSPermissions";
import { FieldInput } from "@newageerp/v3.bundles.form-bundle";
interface IDataCargo {
  serialNumber: string;
  stock: string;
  id: number;
}

interface IData {
  id: number;
  serialNumber: string;
  carNumber: string;
  client: {
    name: string;
  };
  cargos: IDataCargo[];
  scopes: any;
  transportType: number;
  loadDate: string;
  unloadDate: string;
  creator: {
    fullName: string;
  };
  company: {
    name: string,
  },
  railwayTariffPayer: number,
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  carNumber: "",
  client: {
    name: "",
  },
  transportType: 0,

  cargos: [
    {
      serialNumber: "",
      stock: "",
      id: 0,
    },
  ],
  scopes: "",
  loadDate: "",
  unloadDate: "",
  creator: {
    fullName: "",
  },
  company: {
    name: "",
  },
  railwayTariffPayer: 0,
};

export default function DashboardAccountingCosts() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [templatesData, setTemplatesData] = useState<any>({});
  const [templates, setTemplates] = useState([]);

  const { userState } = useTemplatesCore();
  const isTrackingSpec = checkUserHeadOfLogisticsHelper(userState);

  const openEditPopup = (id: number, type: string) => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: 'invoice-incoming',
      type: 'invoices-for-expenses',
      options: {
        createOptions: {
          convert: {
            schema: NaeSSchemaMap.KzCarriersOrder.schema,
            element: { id },
            type
          },
        },
      },
      onSaveCallback: (_el, back) => {
        loadData();
        back();
      }
    })
  }

  const [getData, getDataParams] = useUList<IData>(
    NaeSSchemaMap.KzCarriersOrder.schema,
    getKeysFromObject(defObject)
  );

  useEffect(() => {
    getTemplateLoaderData('/app/nae-core/react-templates/get/DashboardAccountingCosts', {})
      .then((res) => {
        if (res.status === 200) {
          setIsLoaded(true);
          setTemplates(res.data.data);
          setTemplatesData(res.data.templatesData);
        }
      });
  }, []);

  const loadData = () => {
    if (!!search) {
      getData(
        [
          buildQsDictionary(templatesData.kzQs, search),
          {
            and: [["i.status", "!=", 900, true]],
          },
        ],
        1,
        5,
        [
          {
            key: "i.id",
            value: "DESC",
          },
        ]
      );
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      loadData();
    }
  };

  const isData =
    !getDataParams.loading &&
    !!getDataParams &&
    !!getDataParams.data &&
    getDataParams.data.data.length > 0;
  const data = isData ? getDataParams.data.data : [];


  if (!isLoaded) {
    return <Fragment />
  }

  return (
    <Fragment>
      <TemplatesParser templates={templates} />
      <div className={"space-y-4"}>
        <div>
          <FieldInput
            iconName="search"
            className={"w-full"}
            placeholder={t("Enter the order number and press ENTER")}
            onKeyDown={handleKeyDown}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </div>

        {getDataParams.loading && <LogoLoader />}
        {data.map((order: IData) => {
          const schema = NaeSSchemaMap.KzCarriersOrder.schema;

          return (
            <Fragment key={"order-" + order.id}>
              <div className={"flex gap-4 "}>
                <div className="w-[420px] min-w-[420px] max-w-[420px]">
                  <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                    {({ isVisible }: any) => isVisible ?
                      <TemplatesLoader
                        templateName="DashboardAccountingCostsViewForm"
                        data={{ id: order.id }}
                      /> :
                      <LogoLoader />
                    }
                  </ShowOnScreen>
                </div>

                <div className="flex-grow space-y-2">
                  <div className='flex items-center'>
                    <TextCardTitle className='flex-grow'>{t('Invoices')}</TextCardTitle>
                    {!isTrackingSpec &&
                      <div className="flex gap-2">
                        {order.scopes.indexOf("cant-terminal-costs") === -1 &&
                          <ToolbarButton onClick={() => openEditPopup(order.id, 'terminal')} iconName="plus">{t('terminal')}</ToolbarButton>
                        }
                        {order.scopes.indexOf("cant-garant-costs") === -1 &&
                          <ToolbarButton onClick={() => openEditPopup(order.id, 'garant')} iconName="plus">{t('garant')}</ToolbarButton>
                        }
                        {order.scopes.indexOf("cant-platformrent-costs") === -1 &&
                          <ToolbarButton onClick={() => openEditPopup(order.id, 'railway_platform_rent')} iconName="plus">{t('platform rent')}</ToolbarButton>
                        }
                        {order.transportType === 20 && order.railwayTariffPayer === 10 && (
                          <ToolbarButton

                            onClick={() =>
                              openEditPopup(
                                order.id,
                                "railway"
                              )
                            }
                            iconName="plus"
                          >
                            {t("Railway tariff")}
                          </ToolbarButton>
                        )}
                        <ToolbarButton onClick={() => openEditPopup(order.id, 'extra')} iconName="plus">{t('extra')}</ToolbarButton>
                      </div>
                    }
                  </div>
                  <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                    {({ isVisible }: any) => isVisible ?
                      <TemplatesLoader
                        templateName="ListRelContent"
                        data={{
                          schema: 'invoice-incoming',
                          type: 'rel',
                          rel: 'kzCarriersOrder',
                          id: order.id,
                          wrap: 0,
                        }}
                      />
                      :
                      <LogoLoader />
                    }
                  </ShowOnScreen>
                </div>

              </div>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
}

