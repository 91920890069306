import React, { Fragment } from 'react'
import { PropsId } from '../types';
import moment from 'moment';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoUnloadWarehouseDate(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {!!element.unloadWarehouseDate &&
                moment(element.unloadWarehouseDate).format("YYYY-MM-DD")}
        </Fragment>
    )
}
