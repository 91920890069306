import React from 'react'
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

interface IData {
    element: {
        seller: {
            name: string,

        },
        serialNumber: string,
        id: number
    }
}

export default function CargoTd(props: IData) {
    const { element } = props;

    return (
        <div>
            {element.seller.name}{" "}
            <RsButton
                defaultClick='modal'
                schema={"cargo"}
                id={element.id}
                button={{
                    children: element.serialNumber,
                    color: "white",
                    skipPadding: true,
                }}
            />
        </div>
    );
}
