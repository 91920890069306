import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorAccountingDiscrepanciesNae = createSelector(orm.AccountingDiscrepanciesModel);
export const SelectorAdrClassNae = createSelector(orm.AdrClassModel);
export const SelectorAttorneyNae = createSelector(orm.AttorneyModel);
export const SelectorCalculationNae = createSelector(orm.CalculationModel);
export const SelectorCallLogNae = createSelector(orm.CallLogModel);
export const SelectorCarServiceActNae = createSelector(orm.CarServiceActModel);
export const SelectorCarServiceActProductNae = createSelector(orm.CarServiceActProductModel);
export const SelectorCarServiceActServiceNae = createSelector(orm.CarServiceActServiceModel);
export const SelectorCarServiceClientNae = createSelector(orm.CarServiceClientModel);
export const SelectorCarServiceClientCarNae = createSelector(orm.CarServiceClientCarModel);
export const SelectorCarServiceClientContactNae = createSelector(orm.CarServiceClientContactModel);
export const SelectorCarServiceInvoiceIncomingNae = createSelector(orm.CarServiceInvoiceIncomingModel);
export const SelectorCarServiceInvoiceIncomingProductNae = createSelector(orm.CarServiceInvoiceIncomingProductModel);
export const SelectorCarServicePaymentNae = createSelector(orm.CarServicePaymentModel);
export const SelectorCarServiceProductNae = createSelector(orm.CarServiceProductModel);
export const SelectorCarServiceProductBarcodeNae = createSelector(orm.CarServiceProductBarcodeModel);
export const SelectorCarServiceProductCostListNae = createSelector(orm.CarServiceProductCostListModel);
export const SelectorCarServiceProductGroupNae = createSelector(orm.CarServiceProductGroupModel);
export const SelectorCarServiceServiceNae = createSelector(orm.CarServiceServiceModel);
export const SelectorCarServiceStockItemNae = createSelector(orm.CarServiceStockItemModel);
export const SelectorCarServiceSupplierNae = createSelector(orm.CarServiceSupplierModel);
export const SelectorCarServiceSupplierContactNae = createSelector(orm.CarServiceSupplierContactModel);
export const SelectorCargoNae = createSelector(orm.CargoModel);
export const SelectorCargoContactSellerLogNae = createSelector(orm.CargoContactSellerLogModel);
export const SelectorCargoDescriptionNae = createSelector(orm.CargoDescriptionModel);
export const SelectorCargoHoldNae = createSelector(orm.CargoHoldModel);
export const SelectorCargoInsuranceNae = createSelector(orm.CargoInsuranceModel);
export const SelectorCargoLoadingCalculationNae = createSelector(orm.CargoLoadingCalculationModel);
export const SelectorCargoLoadingCalculationNotesSuggestNae = createSelector(orm.CargoLoadingCalculationNotesSuggestModel);
export const SelectorCargoOperationConfirmationNae = createSelector(orm.CargoOperationConfirmationModel);
export const SelectorCargoOperationConfirmationChangesNae = createSelector(orm.CargoOperationConfirmationChangesModel);
export const SelectorCargoSpecificactionNae = createSelector(orm.CargoSpecificactionModel);
export const SelectorCargoStatusNae = createSelector(orm.CargoStatusModel);
export const SelectorCarrierNae = createSelector(orm.CarrierModel);
export const SelectorCarriersOrderNae = createSelector(orm.CarriersOrderModel);
export const SelectorCarriersOrderPaymentTermNae = createSelector(orm.CarriersOrderPaymentTermModel);
export const SelectorCarriersOrderPriceChangeNae = createSelector(orm.CarriersOrderPriceChangeModel);
export const SelectorClientNae = createSelector(orm.ClientModel);
export const SelectorClientContractNae = createSelector(orm.ClientContractModel);
export const SelectorClientFilialNae = createSelector(orm.ClientFilialModel);
export const SelectorClientStatusNae = createSelector(orm.ClientStatusModel);
export const SelectorClientStatusDataNae = createSelector(orm.ClientStatusDataModel);
export const SelectorClientTop20Nae = createSelector(orm.ClientTop20Model);
export const SelectorCommentIkNotesNae = createSelector(orm.CommentIkNotesModel);
export const SelectorCommentLoadingNae = createSelector(orm.CommentLoadingModel);
export const SelectorCommentOperationNae = createSelector(orm.CommentOperationModel);
export const SelectorCommentProcessingNae = createSelector(orm.CommentProcessingModel);
export const SelectorCommissionExpenseNae = createSelector(orm.CommissionExpenseModel);
export const SelectorCompanyNae = createSelector(orm.CompanyModel);
export const SelectorContactNae = createSelector(orm.ContactModel);
export const SelectorContactDuplicateNae = createSelector(orm.ContactDuplicateModel);
export const SelectorContractTermNae = createSelector(orm.ContractTermModel);
export const SelectorCountryNae = createSelector(orm.CountryModel);
export const SelectorCountryGeographyGroupNae = createSelector(orm.CountryGeographyGroupModel);
export const SelectorCountryLogisticGroupNae = createSelector(orm.CountryLogisticGroupModel);
export const SelectorCountryRegionNae = createSelector(orm.CountryRegionModel);
export const SelectorCurrencyNae = createSelector(orm.CurrencyModel);
export const SelectorDriverNae = createSelector(orm.DriverModel);
export const SelectorEtransportTruckNae = createSelector(orm.EtransportTruckModel);
export const SelectorExtraCostCargoNae = createSelector(orm.ExtraCostCargoModel);
export const SelectorExtraCostCommonNae = createSelector(orm.ExtraCostCommonModel);
export const SelectorExtraCostGarantNae = createSelector(orm.ExtraCostGarantModel);
export const SelectorExtraCostRailwayTarifNae = createSelector(orm.ExtraCostRailwayTarifModel);
export const SelectorExtraCostTerminalNae = createSelector(orm.ExtraCostTerminalModel);
export const SelectorFilesManagerFileNae = createSelector(orm.FilesManagerFileModel);
export const SelectorFilesManagerFolderNae = createSelector(orm.FilesManagerFolderModel);
export const SelectorFilterChangeNae = createSelector(orm.FilterChangeModel);
export const SelectorInsuranceRatesNae = createSelector(orm.InsuranceRatesModel);
export const SelectorInventoryNae = createSelector(orm.InventoryModel);
export const SelectorInvoiceIncomingNae = createSelector(orm.InvoiceIncomingModel);
export const SelectorInvoiceIncomingSepaExportNae = createSelector(orm.InvoiceIncomingSepaExportModel);
export const SelectorInvoiceOutgoingNae = createSelector(orm.InvoiceOutgoingModel);
export const SelectorInvoiceOutgoingCreditRequestNae = createSelector(orm.InvoiceOutgoingCreditRequestModel);
export const SelectorInvoiceOutgoingPriceChangeNae = createSelector(orm.InvoiceOutgoingPriceChangeModel);
export const SelectorKzCarriersOrderNae = createSelector(orm.KzCarriersOrderModel);
export const SelectorKzCarriersOrderLoadDateChangeNae = createSelector(orm.KzCarriersOrderLoadDateChangeModel);
export const SelectorKzCarriersOrderLocationNae = createSelector(orm.KzCarriersOrderLocationModel);
export const SelectorKzEx1FilesSendNae = createSelector(orm.KzEx1FilesSendModel);
export const SelectorKzFtlCalculationNae = createSelector(orm.KzFtlCalculationModel);
export const SelectorKzInspectionNae = createSelector(orm.KzInspectionModel);
export const SelectorKzLocationSuggestNae = createSelector(orm.KzLocationSuggestModel);
export const SelectorLeadLostReasonNae = createSelector(orm.LeadLostReasonModel);
export const SelectorLeadLostRecordNae = createSelector(orm.LeadLostRecordModel);
export const SelectorLeadSourceNae = createSelector(orm.LeadSourceModel);
export const SelectorLeadSourceCategoryNae = createSelector(orm.LeadSourceCategoryModel);
export const SelectorLoadAddressNae = createSelector(orm.LoadAddressModel);
export const SelectorNoteNae = createSelector(orm.NoteModel);
export const SelectorOilChangeNae = createSelector(orm.OilChangeModel);
export const SelectorOutgoingDocumentsChangePurposeNae = createSelector(orm.OutgoingDocumentsChangePurposeModel);
export const SelectorPaymentNae = createSelector(orm.PaymentModel);
export const SelectorPhotoAlbumNae = createSelector(orm.PhotoAlbumModel);
export const SelectorPoCancelReasonNae = createSelector(orm.PoCancelReasonModel);
export const SelectorPriceListFtlNae = createSelector(orm.PriceListFtlModel);
export const SelectorPriceListGarantNae = createSelector(orm.PriceListGarantModel);
export const SelectorPriceListLtlNae = createSelector(orm.PriceListLtlModel);
export const SelectorPriceListLtlLineNae = createSelector(orm.PriceListLtlLineModel);
export const SelectorPriceListRailwayCarriageNae = createSelector(orm.PriceListRailwayCarriageModel);
export const SelectorPriceListRailwayContainerNae = createSelector(orm.PriceListRailwayContainerModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorRepairTensionBearingsNae = createSelector(orm.RepairTensionBearingsModel);
export const SelectorRepairWaterPompNae = createSelector(orm.RepairWaterPompModel);
export const SelectorSalaryPointCostNae = createSelector(orm.SalaryPointCostModel);
export const SelectorSalesOrderNae = createSelector(orm.SalesOrderModel);
export const SelectorSalesOrderPaymentDatesNae = createSelector(orm.SalesOrderPaymentDatesModel);
export const SelectorSalesOrderPriceChangeNae = createSelector(orm.SalesOrderPriceChangeModel);
export const SelectorSenderNae = createSelector(orm.SenderModel);
export const SelectorSupplierCustomsNae = createSelector(orm.SupplierCustomsModel);
export const SelectorSupplierExtraCostsNae = createSelector(orm.SupplierExtraCostsModel);
export const SelectorSupplierRailwayNae = createSelector(orm.SupplierRailwayModel);
export const SelectorTaskNae = createSelector(orm.TaskModel);
export const SelectorTaskCategoryNae = createSelector(orm.TaskCategoryModel);
export const SelectorTerminalNae = createSelector(orm.TerminalModel);
export const SelectorTerminalContactNae = createSelector(orm.TerminalContactModel);
export const SelectorTrailerNae = createSelector(orm.TrailerModel);
export const SelectorTruckNae = createSelector(orm.TruckModel);
export const SelectorTruckLocationNae = createSelector(orm.TruckLocationModel);
export const SelectorTruckOwnerNae = createSelector(orm.TruckOwnerModel);
export const SelectorTyreCheckNae = createSelector(orm.TyreCheckModel);
export const SelectorUnloadAddressNae = createSelector(orm.UnloadAddressModel);
export const SelectorUserNae = createSelector(orm.UserModel);
export const SelectorUserCountryLogisticGroupRelNae = createSelector(orm.UserCountryLogisticGroupRelModel);
export const SelectorUserLeadCategoryRelNae = createSelector(orm.UserLeadCategoryRelModel);
export const SelectorUserPointsLogNae = createSelector(orm.UserPointsLogModel);
export const SelectorUserPointsSettingsNae = createSelector(orm.UserPointsSettingsModel);
export const SelectorViltClientNae = createSelector(orm.ViltClientModel);
export const SelectorViltContactNae = createSelector(orm.ViltContactModel);
export const SelectorViltPaymentNae = createSelector(orm.ViltPaymentModel);
export const SelectorViltPurchaseOrderNae = createSelector(orm.ViltPurchaseOrderModel);
export const SelectorViltSalesOrderNae = createSelector(orm.ViltSalesOrderModel);
export const SelectorViltTripNae = createSelector(orm.ViltTripModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'AccountingDiscrepancies') {
        return SelectorAccountingDiscrepanciesNae;
    }
        if (schema === 'AdrClass') {
        return SelectorAdrClassNae;
    }
        if (schema === 'Attorney') {
        return SelectorAttorneyNae;
    }
        if (schema === 'Calculation') {
        return SelectorCalculationNae;
    }
        if (schema === 'CallLog') {
        return SelectorCallLogNae;
    }
        if (schema === 'CarServiceAct') {
        return SelectorCarServiceActNae;
    }
        if (schema === 'CarServiceActProduct') {
        return SelectorCarServiceActProductNae;
    }
        if (schema === 'CarServiceActService') {
        return SelectorCarServiceActServiceNae;
    }
        if (schema === 'CarServiceClient') {
        return SelectorCarServiceClientNae;
    }
        if (schema === 'CarServiceClientCar') {
        return SelectorCarServiceClientCarNae;
    }
        if (schema === 'CarServiceClientContact') {
        return SelectorCarServiceClientContactNae;
    }
        if (schema === 'CarServiceInvoiceIncoming') {
        return SelectorCarServiceInvoiceIncomingNae;
    }
        if (schema === 'CarServiceInvoiceIncomingProduct') {
        return SelectorCarServiceInvoiceIncomingProductNae;
    }
        if (schema === 'CarServicePayment') {
        return SelectorCarServicePaymentNae;
    }
        if (schema === 'CarServiceProduct') {
        return SelectorCarServiceProductNae;
    }
        if (schema === 'CarServiceProductBarcode') {
        return SelectorCarServiceProductBarcodeNae;
    }
        if (schema === 'CarServiceProductCostList') {
        return SelectorCarServiceProductCostListNae;
    }
        if (schema === 'CarServiceProductGroup') {
        return SelectorCarServiceProductGroupNae;
    }
        if (schema === 'CarServiceService') {
        return SelectorCarServiceServiceNae;
    }
        if (schema === 'CarServiceStockItem') {
        return SelectorCarServiceStockItemNae;
    }
        if (schema === 'CarServiceSupplier') {
        return SelectorCarServiceSupplierNae;
    }
        if (schema === 'CarServiceSupplierContact') {
        return SelectorCarServiceSupplierContactNae;
    }
        if (schema === 'Cargo') {
        return SelectorCargoNae;
    }
        if (schema === 'CargoContactSellerLog') {
        return SelectorCargoContactSellerLogNae;
    }
        if (schema === 'CargoDescription') {
        return SelectorCargoDescriptionNae;
    }
        if (schema === 'CargoHold') {
        return SelectorCargoHoldNae;
    }
        if (schema === 'CargoInsurance') {
        return SelectorCargoInsuranceNae;
    }
        if (schema === 'CargoLoadingCalculation') {
        return SelectorCargoLoadingCalculationNae;
    }
        if (schema === 'CargoLoadingCalculationNotesSuggest') {
        return SelectorCargoLoadingCalculationNotesSuggestNae;
    }
        if (schema === 'CargoOperationConfirmation') {
        return SelectorCargoOperationConfirmationNae;
    }
        if (schema === 'CargoOperationConfirmationChanges') {
        return SelectorCargoOperationConfirmationChangesNae;
    }
        if (schema === 'CargoSpecificaction') {
        return SelectorCargoSpecificactionNae;
    }
        if (schema === 'CargoStatus') {
        return SelectorCargoStatusNae;
    }
        if (schema === 'Carrier') {
        return SelectorCarrierNae;
    }
        if (schema === 'CarriersOrder') {
        return SelectorCarriersOrderNae;
    }
        if (schema === 'CarriersOrderPaymentTerm') {
        return SelectorCarriersOrderPaymentTermNae;
    }
        if (schema === 'CarriersOrderPriceChange') {
        return SelectorCarriersOrderPriceChangeNae;
    }
        if (schema === 'Client') {
        return SelectorClientNae;
    }
        if (schema === 'ClientContract') {
        return SelectorClientContractNae;
    }
        if (schema === 'ClientFilial') {
        return SelectorClientFilialNae;
    }
        if (schema === 'ClientStatus') {
        return SelectorClientStatusNae;
    }
        if (schema === 'ClientStatusData') {
        return SelectorClientStatusDataNae;
    }
        if (schema === 'ClientTop20') {
        return SelectorClientTop20Nae;
    }
        if (schema === 'CommentIkNotes') {
        return SelectorCommentIkNotesNae;
    }
        if (schema === 'CommentLoading') {
        return SelectorCommentLoadingNae;
    }
        if (schema === 'CommentOperation') {
        return SelectorCommentOperationNae;
    }
        if (schema === 'CommentProcessing') {
        return SelectorCommentProcessingNae;
    }
        if (schema === 'CommissionExpense') {
        return SelectorCommissionExpenseNae;
    }
        if (schema === 'Company') {
        return SelectorCompanyNae;
    }
        if (schema === 'Contact') {
        return SelectorContactNae;
    }
        if (schema === 'ContactDuplicate') {
        return SelectorContactDuplicateNae;
    }
        if (schema === 'ContractTerm') {
        return SelectorContractTermNae;
    }
        if (schema === 'Country') {
        return SelectorCountryNae;
    }
        if (schema === 'CountryGeographyGroup') {
        return SelectorCountryGeographyGroupNae;
    }
        if (schema === 'CountryLogisticGroup') {
        return SelectorCountryLogisticGroupNae;
    }
        if (schema === 'CountryRegion') {
        return SelectorCountryRegionNae;
    }
        if (schema === 'Currency') {
        return SelectorCurrencyNae;
    }
        if (schema === 'Driver') {
        return SelectorDriverNae;
    }
        if (schema === 'EtransportTruck') {
        return SelectorEtransportTruckNae;
    }
        if (schema === 'ExtraCostCargo') {
        return SelectorExtraCostCargoNae;
    }
        if (schema === 'ExtraCostCommon') {
        return SelectorExtraCostCommonNae;
    }
        if (schema === 'ExtraCostGarant') {
        return SelectorExtraCostGarantNae;
    }
        if (schema === 'ExtraCostRailwayTarif') {
        return SelectorExtraCostRailwayTarifNae;
    }
        if (schema === 'ExtraCostTerminal') {
        return SelectorExtraCostTerminalNae;
    }
        if (schema === 'FilesManagerFile') {
        return SelectorFilesManagerFileNae;
    }
        if (schema === 'FilesManagerFolder') {
        return SelectorFilesManagerFolderNae;
    }
        if (schema === 'FilterChange') {
        return SelectorFilterChangeNae;
    }
        if (schema === 'InsuranceRates') {
        return SelectorInsuranceRatesNae;
    }
        if (schema === 'Inventory') {
        return SelectorInventoryNae;
    }
        if (schema === 'InvoiceIncoming') {
        return SelectorInvoiceIncomingNae;
    }
        if (schema === 'InvoiceIncomingSepaExport') {
        return SelectorInvoiceIncomingSepaExportNae;
    }
        if (schema === 'InvoiceOutgoing') {
        return SelectorInvoiceOutgoingNae;
    }
        if (schema === 'InvoiceOutgoingCreditRequest') {
        return SelectorInvoiceOutgoingCreditRequestNae;
    }
        if (schema === 'InvoiceOutgoingPriceChange') {
        return SelectorInvoiceOutgoingPriceChangeNae;
    }
        if (schema === 'KzCarriersOrder') {
        return SelectorKzCarriersOrderNae;
    }
        if (schema === 'KzCarriersOrderLoadDateChange') {
        return SelectorKzCarriersOrderLoadDateChangeNae;
    }
        if (schema === 'KzCarriersOrderLocation') {
        return SelectorKzCarriersOrderLocationNae;
    }
        if (schema === 'KzEx1FilesSend') {
        return SelectorKzEx1FilesSendNae;
    }
        if (schema === 'KzFtlCalculation') {
        return SelectorKzFtlCalculationNae;
    }
        if (schema === 'KzInspection') {
        return SelectorKzInspectionNae;
    }
        if (schema === 'KzLocationSuggest') {
        return SelectorKzLocationSuggestNae;
    }
        if (schema === 'LeadLostReason') {
        return SelectorLeadLostReasonNae;
    }
        if (schema === 'LeadLostRecord') {
        return SelectorLeadLostRecordNae;
    }
        if (schema === 'LeadSource') {
        return SelectorLeadSourceNae;
    }
        if (schema === 'LeadSourceCategory') {
        return SelectorLeadSourceCategoryNae;
    }
        if (schema === 'LoadAddress') {
        return SelectorLoadAddressNae;
    }
        if (schema === 'Note') {
        return SelectorNoteNae;
    }
        if (schema === 'OilChange') {
        return SelectorOilChangeNae;
    }
        if (schema === 'OutgoingDocumentsChangePurpose') {
        return SelectorOutgoingDocumentsChangePurposeNae;
    }
        if (schema === 'Payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'PhotoAlbum') {
        return SelectorPhotoAlbumNae;
    }
        if (schema === 'PoCancelReason') {
        return SelectorPoCancelReasonNae;
    }
        if (schema === 'PriceListFtl') {
        return SelectorPriceListFtlNae;
    }
        if (schema === 'PriceListGarant') {
        return SelectorPriceListGarantNae;
    }
        if (schema === 'PriceListLtl') {
        return SelectorPriceListLtlNae;
    }
        if (schema === 'PriceListLtlLine') {
        return SelectorPriceListLtlLineNae;
    }
        if (schema === 'PriceListRailwayCarriage') {
        return SelectorPriceListRailwayCarriageNae;
    }
        if (schema === 'PriceListRailwayContainer') {
        return SelectorPriceListRailwayContainerNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'RepairTensionBearings') {
        return SelectorRepairTensionBearingsNae;
    }
        if (schema === 'RepairWaterPomp') {
        return SelectorRepairWaterPompNae;
    }
        if (schema === 'SalaryPointCost') {
        return SelectorSalaryPointCostNae;
    }
        if (schema === 'SalesOrder') {
        return SelectorSalesOrderNae;
    }
        if (schema === 'SalesOrderPaymentDates') {
        return SelectorSalesOrderPaymentDatesNae;
    }
        if (schema === 'SalesOrderPriceChange') {
        return SelectorSalesOrderPriceChangeNae;
    }
        if (schema === 'Sender') {
        return SelectorSenderNae;
    }
        if (schema === 'SupplierCustoms') {
        return SelectorSupplierCustomsNae;
    }
        if (schema === 'SupplierExtraCosts') {
        return SelectorSupplierExtraCostsNae;
    }
        if (schema === 'SupplierRailway') {
        return SelectorSupplierRailwayNae;
    }
        if (schema === 'Task') {
        return SelectorTaskNae;
    }
        if (schema === 'TaskCategory') {
        return SelectorTaskCategoryNae;
    }
        if (schema === 'Terminal') {
        return SelectorTerminalNae;
    }
        if (schema === 'TerminalContact') {
        return SelectorTerminalContactNae;
    }
        if (schema === 'Trailer') {
        return SelectorTrailerNae;
    }
        if (schema === 'Truck') {
        return SelectorTruckNae;
    }
        if (schema === 'TruckLocation') {
        return SelectorTruckLocationNae;
    }
        if (schema === 'TruckOwner') {
        return SelectorTruckOwnerNae;
    }
        if (schema === 'TyreCheck') {
        return SelectorTyreCheckNae;
    }
        if (schema === 'UnloadAddress') {
        return SelectorUnloadAddressNae;
    }
        if (schema === 'User') {
        return SelectorUserNae;
    }
        if (schema === 'UserCountryLogisticGroupRel') {
        return SelectorUserCountryLogisticGroupRelNae;
    }
        if (schema === 'UserLeadCategoryRel') {
        return SelectorUserLeadCategoryRelNae;
    }
        if (schema === 'UserPointsLog') {
        return SelectorUserPointsLogNae;
    }
        if (schema === 'UserPointsSettings') {
        return SelectorUserPointsSettingsNae;
    }
        if (schema === 'ViltClient') {
        return SelectorViltClientNae;
    }
        if (schema === 'ViltContact') {
        return SelectorViltContactNae;
    }
        if (schema === 'ViltPayment') {
        return SelectorViltPaymentNae;
    }
        if (schema === 'ViltPurchaseOrder') {
        return SelectorViltPurchaseOrderNae;
    }
        if (schema === 'ViltSalesOrder') {
        return SelectorViltSalesOrderNae;
    }
        if (schema === 'ViltTrip') {
        return SelectorViltTripNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'accounting-discrepancies') {
        return SelectorAccountingDiscrepanciesNae;
    }
        if (schema === 'adr-class') {
        return SelectorAdrClassNae;
    }
        if (schema === 'attorney') {
        return SelectorAttorneyNae;
    }
        if (schema === 'calculation') {
        return SelectorCalculationNae;
    }
        if (schema === 'call-log') {
        return SelectorCallLogNae;
    }
        if (schema === 'car-service-act') {
        return SelectorCarServiceActNae;
    }
        if (schema === 'car-service-act-product') {
        return SelectorCarServiceActProductNae;
    }
        if (schema === 'car-service-act-service') {
        return SelectorCarServiceActServiceNae;
    }
        if (schema === 'car-service-client') {
        return SelectorCarServiceClientNae;
    }
        if (schema === 'car-service-client-car') {
        return SelectorCarServiceClientCarNae;
    }
        if (schema === 'car-service-client-contact') {
        return SelectorCarServiceClientContactNae;
    }
        if (schema === 'car-service-invoice-incoming') {
        return SelectorCarServiceInvoiceIncomingNae;
    }
        if (schema === 'car-service-invoice-incoming-product') {
        return SelectorCarServiceInvoiceIncomingProductNae;
    }
        if (schema === 'car-service-payment') {
        return SelectorCarServicePaymentNae;
    }
        if (schema === 'car-service-product') {
        return SelectorCarServiceProductNae;
    }
        if (schema === 'car-service-product-barcode') {
        return SelectorCarServiceProductBarcodeNae;
    }
        if (schema === 'car-service-product-cost-list') {
        return SelectorCarServiceProductCostListNae;
    }
        if (schema === 'car-service-product-group') {
        return SelectorCarServiceProductGroupNae;
    }
        if (schema === 'car-service-service') {
        return SelectorCarServiceServiceNae;
    }
        if (schema === 'car-service-stock-item') {
        return SelectorCarServiceStockItemNae;
    }
        if (schema === 'car-service-supplier') {
        return SelectorCarServiceSupplierNae;
    }
        if (schema === 'car-service-supplier-contact') {
        return SelectorCarServiceSupplierContactNae;
    }
        if (schema === 'cargo') {
        return SelectorCargoNae;
    }
        if (schema === 'cargo-contact-seller-log') {
        return SelectorCargoContactSellerLogNae;
    }
        if (schema === 'cargo-description') {
        return SelectorCargoDescriptionNae;
    }
        if (schema === 'cargo-hold') {
        return SelectorCargoHoldNae;
    }
        if (schema === 'cargo-insurance') {
        return SelectorCargoInsuranceNae;
    }
        if (schema === 'cargo-loading-calculation') {
        return SelectorCargoLoadingCalculationNae;
    }
        if (schema === 'cargo-loading-calculation-notes-suggest') {
        return SelectorCargoLoadingCalculationNotesSuggestNae;
    }
        if (schema === 'cargo-operation-confirmation') {
        return SelectorCargoOperationConfirmationNae;
    }
        if (schema === 'cargo-operation-confirmation-changes') {
        return SelectorCargoOperationConfirmationChangesNae;
    }
        if (schema === 'cargo-specificaction') {
        return SelectorCargoSpecificactionNae;
    }
        if (schema === 'cargo-status') {
        return SelectorCargoStatusNae;
    }
        if (schema === 'carrier') {
        return SelectorCarrierNae;
    }
        if (schema === 'carriers-order') {
        return SelectorCarriersOrderNae;
    }
        if (schema === 'carriers-order-payment-term') {
        return SelectorCarriersOrderPaymentTermNae;
    }
        if (schema === 'carriers-order-price-change') {
        return SelectorCarriersOrderPriceChangeNae;
    }
        if (schema === 'client') {
        return SelectorClientNae;
    }
        if (schema === 'client-contract') {
        return SelectorClientContractNae;
    }
        if (schema === 'client-filial') {
        return SelectorClientFilialNae;
    }
        if (schema === 'client-status') {
        return SelectorClientStatusNae;
    }
        if (schema === 'client-status-data') {
        return SelectorClientStatusDataNae;
    }
        if (schema === 'client-top20') {
        return SelectorClientTop20Nae;
    }
        if (schema === 'comment-ik-notes') {
        return SelectorCommentIkNotesNae;
    }
        if (schema === 'comment-loading') {
        return SelectorCommentLoadingNae;
    }
        if (schema === 'comment-operation') {
        return SelectorCommentOperationNae;
    }
        if (schema === 'comment-processing') {
        return SelectorCommentProcessingNae;
    }
        if (schema === 'commission-expense') {
        return SelectorCommissionExpenseNae;
    }
        if (schema === 'company') {
        return SelectorCompanyNae;
    }
        if (schema === 'contact') {
        return SelectorContactNae;
    }
        if (schema === 'contact-duplicate') {
        return SelectorContactDuplicateNae;
    }
        if (schema === 'contract-term') {
        return SelectorContractTermNae;
    }
        if (schema === 'country') {
        return SelectorCountryNae;
    }
        if (schema === 'country-geography-group') {
        return SelectorCountryGeographyGroupNae;
    }
        if (schema === 'country-logistic-group') {
        return SelectorCountryLogisticGroupNae;
    }
        if (schema === 'country-region') {
        return SelectorCountryRegionNae;
    }
        if (schema === 'currency') {
        return SelectorCurrencyNae;
    }
        if (schema === 'driver') {
        return SelectorDriverNae;
    }
        if (schema === 'etransport-truck') {
        return SelectorEtransportTruckNae;
    }
        if (schema === 'extra-cost-cargo') {
        return SelectorExtraCostCargoNae;
    }
        if (schema === 'extra-cost-common') {
        return SelectorExtraCostCommonNae;
    }
        if (schema === 'extra-cost-garant') {
        return SelectorExtraCostGarantNae;
    }
        if (schema === 'extra-cost-railway-tarif') {
        return SelectorExtraCostRailwayTarifNae;
    }
        if (schema === 'extra-cost-terminal') {
        return SelectorExtraCostTerminalNae;
    }
        if (schema === 'files-manager-file') {
        return SelectorFilesManagerFileNae;
    }
        if (schema === 'files-manager-folder') {
        return SelectorFilesManagerFolderNae;
    }
        if (schema === 'filter-change') {
        return SelectorFilterChangeNae;
    }
        if (schema === 'insurance-rates') {
        return SelectorInsuranceRatesNae;
    }
        if (schema === 'inventory') {
        return SelectorInventoryNae;
    }
        if (schema === 'invoice-incoming') {
        return SelectorInvoiceIncomingNae;
    }
        if (schema === 'invoice-incoming-sepa-export') {
        return SelectorInvoiceIncomingSepaExportNae;
    }
        if (schema === 'invoice-outgoing') {
        return SelectorInvoiceOutgoingNae;
    }
        if (schema === 'invoice-outgoing-credit-request') {
        return SelectorInvoiceOutgoingCreditRequestNae;
    }
        if (schema === 'invoice-outgoing-price-change') {
        return SelectorInvoiceOutgoingPriceChangeNae;
    }
        if (schema === 'kz-carriers-order') {
        return SelectorKzCarriersOrderNae;
    }
        if (schema === 'kz-carriers-order-load-date-change') {
        return SelectorKzCarriersOrderLoadDateChangeNae;
    }
        if (schema === 'kz-carriers-order-location') {
        return SelectorKzCarriersOrderLocationNae;
    }
        if (schema === 'kz-ex-1-files-send') {
        return SelectorKzEx1FilesSendNae;
    }
        if (schema === 'kz-ftl-calculation') {
        return SelectorKzFtlCalculationNae;
    }
        if (schema === 'kz-inspection') {
        return SelectorKzInspectionNae;
    }
        if (schema === 'kz-location-suggest') {
        return SelectorKzLocationSuggestNae;
    }
        if (schema === 'lead-lost-reason') {
        return SelectorLeadLostReasonNae;
    }
        if (schema === 'lead-lost-record') {
        return SelectorLeadLostRecordNae;
    }
        if (schema === 'lead-source') {
        return SelectorLeadSourceNae;
    }
        if (schema === 'lead-source-category') {
        return SelectorLeadSourceCategoryNae;
    }
        if (schema === 'load-address') {
        return SelectorLoadAddressNae;
    }
        if (schema === 'note') {
        return SelectorNoteNae;
    }
        if (schema === 'oil-change') {
        return SelectorOilChangeNae;
    }
        if (schema === 'outgoing-documents-change-purpose') {
        return SelectorOutgoingDocumentsChangePurposeNae;
    }
        if (schema === 'payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'photo-album') {
        return SelectorPhotoAlbumNae;
    }
        if (schema === 'po-cancel-reason') {
        return SelectorPoCancelReasonNae;
    }
        if (schema === 'price-list-ftl') {
        return SelectorPriceListFtlNae;
    }
        if (schema === 'price-list-garant') {
        return SelectorPriceListGarantNae;
    }
        if (schema === 'price-list-ltl') {
        return SelectorPriceListLtlNae;
    }
        if (schema === 'price-list-ltl-line') {
        return SelectorPriceListLtlLineNae;
    }
        if (schema === 'price-list-railway-carriage') {
        return SelectorPriceListRailwayCarriageNae;
    }
        if (schema === 'price-list-railway-container') {
        return SelectorPriceListRailwayContainerNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'repair-tension-bearings') {
        return SelectorRepairTensionBearingsNae;
    }
        if (schema === 'repair-water-pomp') {
        return SelectorRepairWaterPompNae;
    }
        if (schema === 'salary-point-cost') {
        return SelectorSalaryPointCostNae;
    }
        if (schema === 'sales-order') {
        return SelectorSalesOrderNae;
    }
        if (schema === 'sales-order-payment-dates') {
        return SelectorSalesOrderPaymentDatesNae;
    }
        if (schema === 'sales-order-price-change') {
        return SelectorSalesOrderPriceChangeNae;
    }
        if (schema === 'sender') {
        return SelectorSenderNae;
    }
        if (schema === 'supplier-customs') {
        return SelectorSupplierCustomsNae;
    }
        if (schema === 'supplier-extra-costs') {
        return SelectorSupplierExtraCostsNae;
    }
        if (schema === 'supplier-railway') {
        return SelectorSupplierRailwayNae;
    }
        if (schema === 'task') {
        return SelectorTaskNae;
    }
        if (schema === 'task-category') {
        return SelectorTaskCategoryNae;
    }
        if (schema === 'terminal') {
        return SelectorTerminalNae;
    }
        if (schema === 'terminal-contact') {
        return SelectorTerminalContactNae;
    }
        if (schema === 'trailer') {
        return SelectorTrailerNae;
    }
        if (schema === 'truck') {
        return SelectorTruckNae;
    }
        if (schema === 'truck-location') {
        return SelectorTruckLocationNae;
    }
        if (schema === 'truck-owner') {
        return SelectorTruckOwnerNae;
    }
        if (schema === 'tyre-check') {
        return SelectorTyreCheckNae;
    }
        if (schema === 'unload-address') {
        return SelectorUnloadAddressNae;
    }
        if (schema === 'user') {
        return SelectorUserNae;
    }
        if (schema === 'user-country-logistic-group-rel') {
        return SelectorUserCountryLogisticGroupRelNae;
    }
        if (schema === 'user-lead-category-rel') {
        return SelectorUserLeadCategoryRelNae;
    }
        if (schema === 'user-points-log') {
        return SelectorUserPointsLogNae;
    }
        if (schema === 'user-points-settings') {
        return SelectorUserPointsSettingsNae;
    }
        if (schema === 'vilt-client') {
        return SelectorViltClientNae;
    }
        if (schema === 'vilt-contact') {
        return SelectorViltContactNae;
    }
        if (schema === 'vilt-payment') {
        return SelectorViltPaymentNae;
    }
        if (schema === 'vilt-purchase-order') {
        return SelectorViltPurchaseOrderNae;
    }
        if (schema === 'vilt-sales-order') {
        return SelectorViltSalesOrderNae;
    }
        if (schema === 'vilt-trip') {
        return SelectorViltTripNae;
    }
    }