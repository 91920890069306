import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle'
import { NaeSSchemaMap } from '../../../../../_generated/_custom/config/NaeSSchema';

interface Props {
    fieldKey: string,
}

export default function Clientlink(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element: item } = tData;
    const value = item[props.fieldKey];

    return (
        <RsButton
            button={{
                children: value,
                skipPadding: true,
                color: 'white'
            }}
            id={item.clientId}
            schema={NaeSSchemaMap.Client.schema}
            defaultClick={"modal"}
        />
    )
}
