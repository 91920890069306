import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTranslation } from 'react-i18next';
import CargoValueWithCurrency from '../../../_generated/_custom/models-cache-data/Cargo/CargoValueWithCurrency';
import CargoCustomsCode from '../../../_generated/_custom/models-cache-data/Cargo/CargoCustomsCode';
import CargoCommentProcessing from '../../../_generated/_custom/models-cache-data/Cargo/CargoCommentProcessing';
import CargoCommentLoading from '../../../_generated/_custom/models-cache-data/Cargo/CargoCommentLoading';
import { checkUserLogistic, checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain } from '../../../_generated/_custom/config/NaeSPermissions';
import { getPropertyTitleForSchema } from '../../../_generated/v3/utils';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

export default function CargoLoadingFormComment(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const { t } = useTranslation();

    const { userState } = useTemplatesCore()
    const isLogistic = checkUserLogistic(userState);
    const isLogisticFtl = checkUserLogisticFtl(userState);
    const isLogisticSea = checkUserLogisticSea(userState);
    const isLogisticTrain = checkUserLogisticTrain(userState);

    if (!element) {
        return <Fragment />
    }
    const hasLoadingComment =
        (element.cargoValue > 0 && !!element.cargoValueCurrency) ||
        element.customsCode > 0 ||
        !!element.commentProcessing ||
        !!element.commentLoading;

    if (!hasLoadingComment) {
        return <Fragment />
    }
    return (
        <div className={"mt-2 space-y-1"}>
            <hr/>
            {element.cargoValue > 0 && !!element.cargoValueCurrency && (
                <div>
                    <FieldLabel>{t("Cargo value")}:</FieldLabel>
                    <CargoValueWithCurrency id={element.id} />
                </div>
            )}
            {!(isLogistic || isLogisticFtl) && (
                <Fragment>
                    {element.customsCode > 0 && !(isLogisticSea || isLogisticTrain) && (
                        <div>
                            <FieldLabel>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        "customsCode"
                                    )
                                )}:
                            </FieldLabel>
                            <CargoCustomsCode id={element.id} />
                        </div>
                    )}
                    {!!element.commentProcessing && !(isLogisticSea || isLogisticTrain) && (
                        <div>
                            <FieldLabel>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        "commentProcessing"
                                    )
                                )}
                                :
                            </FieldLabel>
                            <CargoCommentProcessing id={element.id} />
                        </div>
                    )}
                    {!!element.commentLoading && (
                        <div>
                            <FieldLabel>
                                {t(
                                    getPropertyTitleForSchema(
                                        "cargo",
                                        "commentLoading"
                                    )
                                )}
                                :
                            </FieldLabel>
                            <CargoCommentLoading id={element.id} />
                        </div>
                    )}
                </Fragment>
            )}
            <hr/>
        </div>
    )
}
