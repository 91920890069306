import React, {Fragment} from 'react'
import { PropsId } from '../types';
import { useTerminalHookNae } from '../../hooks/useTerminalHookNae';

export default function TerminalName(props: PropsId) {
    const element = useTerminalHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    return (
        <Fragment>
            {element.name}
        </Fragment>
    )
}
