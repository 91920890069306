import React, { Fragment, useEffect } from "react";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";


export default function CargoLogisticSellerInfo(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  if (!element) {
    return <Fragment />
  }
  const isLogistic =
    !!element.expeditor && userState.id === element.expeditor.id;
    
    // (element.status === 5 && !!element.salesOrder && isLogistic && element.contactedSeller && !element.operationConfirmation) ||

  if (!(
    (element.status === 5 && !!element.salesOrder && isLogistic && element.contactedSeller) ||
    (isAdmin)
  )
  ) {
    return <Fragment />
  }

  return (
    <Fragment>
      <WhiteCard isCompact={true}>
        <MainEditContentInline
          schema={"cargo"}
          type={"cargo-seller-info"}
          id={element.id.toString()}
          isCompact={true}
          // onBack={(isNew, el) => { }}
        />
      </WhiteCard>
    </Fragment>
  );
}
