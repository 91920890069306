import React, { Fragment } from 'react'
import { useRecoilState } from 'recoil';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { naePhoneState } from '@newageerp/v3.bundles.voximplant-bundle';
import { ToolbarButtonWithMenu } from '@newageerp/v3.bundles.buttons-bundle';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

interface Props {
    fieldKey: string,
}

export default function Phones(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    const [, setPhoneDial] = useRecoilState(naePhoneState);
    const contacts = element.phones;

    if (!contacts || contacts.length === 0) {
        return <Fragment />
    }

    return (
        <ToolbarButtonWithMenu
            button={{
                iconName: "phone"
            }}
            menu={{
                children: <Fragment>
                    {contacts.map((contact: string, idxContact: number) => {
                        return (<MenuItem onClick={() => setPhoneDial(contact)} key={`contact-${contact}-${idxContact}`}>{contact}</MenuItem>)
                    })}
                </Fragment>
            }}
        />
    );
}
