import React, { Fragment } from 'react'
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

export default function ElementDelayInDaysRed(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            {element.delayInDays > 0 && (
                <span className={"text-red-500"}>({element.delayInDays})</span>
            )}
        </Fragment>

    )
}
