import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import CalculationSerialNumber from './CalculationSerialNumber';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

interface Props extends PropsId {
    hideLink?: boolean
}

export default function CalculationSerialNumberWithLink(props: Props) {
    const { id, hideLink } = props;

    return (
        <Fragment>
            {!hideLink ? (
                <RsButton
                    id={id}
                    schema={"calculation"}
                    defaultClick="modal"
                    button={{
                        children: <CalculationSerialNumber id={id} />,
                        color: "white",
                        skipPadding: true,
                    }}
                />
            ) : (
                <CalculationSerialNumber id={id} />
            )}

        </Fragment>
    );
}
