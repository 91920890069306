import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { IKZWfData } from "../KzCarriersOrderWorkflowWidget";
import KzCarriersOrderUnloadWidget from './KzCarriersOrderUnloadWidget';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { AlertWidget, transformErrorAlert } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserHeadOfLogistics, checkUserOperational } from "../../../_generated/_custom/config/NaeSPermissions";
import { useNaeRecord } from "@newageerp/v3.app.mvc.record-provider";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { INaeStatus } from "@newageerp/v3.app.main-bundle";
import { useAppData } from "@newageerp/v3.app.app-data";

interface ElementProps {
  id: number;
  status: number;
  cargos: IDataCargo[];
  trueUnloadDate: string;
  creator: {
    id: number;
  };
}

interface IDataCargo {
  terminalJson: {
    id: number;
  };
}

interface Props {
  elementId: number;
}

const defObject: ElementProps = {
  id: 0,
  status: 0,
  cargos: [
    {
      terminalJson: {
        id: 0,
      },
    },
  ],
  trueUnloadDate: "",
  creator: {
    id: 0,
  },
};

const cargoValueLimit = 400 * 1000;

export default function KzCarriersOrderStatusWidget(props: Props) {
  const appData = useAppData();

  const elementId = props.elementId;
  const element: IKZWfData = useNaeRecord().element;
  const kzElement = useKzCarriersOrderHookNae(elementId);

  const [reloadingStatus, setReloadingStatus] = React.useState(0);

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isCoo = checkUserHeadOfLogistics(userState);
  const isDps = checkUserOperational(userState);

  const statuses = appData.status.list.filter(s => s.schema === NaeSSchemaMap.KzCarriersOrder.schema);

  const { t } = useTranslation();

  const [saveData, saveDataParams] = useUSave(
    NaeSSchemaMap.KzCarriersOrder.schema,
  );
  const doSave = (data: any) => {
    setReloadingStatus(data.status);
    if (saveDataParams.loading) return;
    saveData(data, elementId);
  };

  if (!element || !kzElement) {
    return <Fragment />;
  }

  if (kzElement.allCargoValueWithoutInsured > cargoValueLimit && (kzElement.transportType === 10 || kzElement.transportType === 50)) {
    return (
      <AlertWidget color='danger' className='w-full' title={t('Negalima tęsti')}>
        <p>
          {`Bendra krovinių vertė (${kzElement.allCargoValue.toFixed(2)}) viršiją draudimo normą ${cargoValueLimit} EUR`}
        </p>
      </AlertWidget>
    )
  }

  //   if (element.status >= 10 && element.total > 0 && isCreator) {
  const terminal =
    element.cargos.length > 0 ? element.cargos[0].terminalJson : null;

  const canStatus = (status: number) => {
    // return true;

    if (isDps) {
      if (status === 20 && element.status === 15) {
        return true;
      }
      if (status === 15 && element.status === 10 && kzElement.arrivedAtLoad) {
        return true;
      }
      return false;
    }

    if (status > 40 && status !== 900) {
      return false;
    }
    if (status === 40) {
      return false;
    }
    if (isCoo) {
      if (status < element.status) {
        if (status === 15 && !terminal) {
          return false;
        }
        return true;
      }
    }
    if (
      (isAdmin) &&
      element.creator.id !== userState.id
    ) {
      if (status === element.status) {
        return false;
      }
      if (status === 15 && !terminal) {
        return false;
      }
      return true;
    }
    // if (status === 0) {
    //   return true;
    // }
    if (element.status === 0) {
      if (status === 10 || status === 900) {
        return true;
      }
      return false;
    }
    if (element.status === 10) {
      if (status === 900) {
        return true;
      }
      if (!!terminal) {
        if (status === 15) {
          return true;
        } else {
          return false;
        }
      } else if (status === 20) {
        return true;
      }
      return false;
    }
    if (element.status === 15) {
      if (status === 20 || status === 900) {
        return true;
      }
      return false;
    }
    if (element.status === 20) {
      if (status === 30) {
        return true;
      }
      return false;
    }
    if (element.status === 30) {
      if (status === 40) {
        return true;
      }
      return false;
    }
    if (status <= element.status) {
      return false;
    }
    return true;
  };
  return (
    <Fragment>
      {!!saveDataParams.error && <AlertWidget color='danger'>
        {transformErrorAlert(saveDataParams.error)}
      </AlertWidget>}
      {statuses
        .filter((status: INaeStatus) => canStatus(status.status))
        .map((status: INaeStatus) => {
          const isLoading =
            saveDataParams.loading && status.status === reloadingStatus;
          return (
            <Fragment key={"status-" + status.status}>
              {/* @ts-ignore */}
              <MainButton iconName="arrow-alt-right" loading={isLoading} color={status.variant} confirmation={true} onClick={() => doSave({ status: status.status })}>
                {`${t(status.text)} ${isLoading ? "..." : ""}`}
              </MainButton>
            </Fragment>
          );
        })}

      <KzCarriersOrderUnloadWidget id={elementId} />
    </Fragment>
  );
}
