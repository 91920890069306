import React, { Fragment } from "react";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useCargoOperationConfirmationHookNae } from "../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";

export default function CargoOperationalData(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)
  const confirmed = confirmElement ? confirmElement.confirmed : false;
  const headActiveChanges = confirmElement ? confirmElement.headActiveChanges : false;

  if (!element) {
    return <Fragment />;
  }
  const isOperationalActive =
    (!!confirmElement && !confirmed) ||
    (element.minimumSpecificationStatus >= 0 && element.minimumSpecificationStatus < 30) ||
    headActiveChanges;
  if (!isOperationalActive) {
    return <Fragment />;
  }

  return (
    <WhiteCard isCompact={true}>
      <MainEditContentInline
        schema={"cargo"}
        type={"operations"}
        id={element.id.toString()}
        isCompact={true}
      />
    </WhiteCard>
  );
}
