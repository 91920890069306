import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useKzCarriersOrderHookNae } from '../../hooks/useKzCarriersOrderHookNae';
import { useTranslation } from 'react-i18next';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function BadgeKzCarriersOrderArrivedAtLoad(props: PropsId) {
    const {t} = useTranslation();

    const element = useKzCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.contactedCarrier && !element.arrivedAtLoad && <StatusWidget color='orange' smallPadding={true}>{t('Waiting for loading')}</StatusWidget>}
        </Fragment>
    )
}
