import React, { Fragment, useEffect } from 'react'
import moment from 'moment';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';

interface Props extends PropsId {
    status: number,
    loadDate: string,
    status15Date: string,

    extraContent?: any
}

interface IDataChange {
    id: number,
    oldLoadDate: string,
    loadDate: string,
    comment: string,
}
const defObject: IDataChange = {
    id: 0,
    oldLoadDate: '',
    loadDate: '',
    comment: '',
}

export default function KzLoadDateWithChanges(props: Props) {

    const { status, loadDate, status15Date } = props;
    const [getData, getDataParams] = useUList<IDataChange>(
        NaeSSchemaMap.KzCarriersOrderLoadDateChange.schema,
        getKeysFromObject(defObject)
    );
    const dateChanges = getDataParams.data.data;

    const loadData = () => {
        getData(
            [{
                "and": [
                    ['i.kzCarriersOrder', '=', props.id, true]
                ]
            }],
            1,
            100,
            [
                {
                    key: 'i.id',
                    value: 'ASC'
                }
            ]
        )
    }
    useEffect(loadData, []);

    const tooltip = dateChanges.length > 0 ? <Tooltip className={"ml-2"} text={
        dateChanges.map((dateChange: IDataChange, index: number) => {
            return (
                `<p>
                ${dateChange.oldLoadDate} -> ${dateChange.loadDate} (${dateChange.comment})
            </p>`
            )
        }).join("\n")
    } /> : <Fragment />

    return (
        <Fragment>
            {!!loadDate && status > 0 && (
                <p>
                    {props.extraContent}
                    <i className={"text-lg fa-fw fad fa-truck-loading mr-1"} />
                    {moment(loadDate).format("YYYY-MM-DD")}
                    {tooltip}
                </p>
            )}
            {!!status15Date && (
                <p className={"text-info"}>
                    {moment(status15Date).format("YYYY-MM-DD")}
                </p>
            )}
        </Fragment>
    )
}
