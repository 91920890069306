import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { useCargoOperationConfirmationHookNae } from '../../hooks/useCargoOperationConfirmationHookNae';

interface Props extends PropsId {
    onClick?: () => void;
    iconSize?: string,
}

export default function CargoNeedOperationIcon(props: Props) {
    const element = useCargoHookNae(props.id);
    const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)

    const confirmed = confirmElement ? confirmElement.confirmed : false;
    const headConfirmed = confirmElement ? confirmElement.headConfirmed : false;
    const dpsProConfirmed = confirmElement ? confirmElement.dpsProConfirmed : false;
    const headChanges = confirmElement ? confirmElement.headActiveChanges : false;

    if (!element) {
        return <Fragment />
    }

    let docColor = "";
    // if (element.filesSentToTerminal) {
    //     docColor = 'fas text-purple-500';
    // } else 
    if (dpsProConfirmed) {
        docColor = 'fad text-green-900';
    } else if (headConfirmed) {
        docColor = 'fal text-black';
    } else if (headChanges) {
        docColor = 'fas text-orange-500';
    } else if (confirmed) {
        docColor = 'fad text-blue-500';
    } else {
        docColor = 'fad text-gray-500';
    }

    if (confirmed) {
        return (
            <button onClick={props.onClick}>
                <i className={`fa-file mr-1 ${docColor} ${props.iconSize ? props.iconSize : ''}`} />
            </button>
        )
    }

    return <i className={`fa-file mr-1 ${docColor} ${props.iconSize ? props.iconSize : ''}`} />;
}
