import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCarServiceActNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICarServiceActModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      total: number,
      totalDebt: number,
      totalWVat: number,
      profit: number,
      totalProducts: number,
      totalServices: number,
      invoiceDate: string,
      invoiceNumber: string,
      invoicePayToDate: string,
      totalVat: number,
      totalCost: number,
  }

export const ICarServiceActFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "total",
    "totalDebt",
    "totalWVat",
    "profit",
    "totalProducts",
    "totalServices",
    "invoiceDate",
    "invoiceNumber",
    "invoicePayToDate",
    "totalVat",
    "totalCost"
];

export function useCarServiceActHookNae(id: number) : ICarServiceActModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCarServiceActNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "car-service-act",
        fields: ICarServiceActFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
