import React, { Fragment, useEffect, useState } from 'react'
import { ILeadLostReasonFieldsNae, ILeadLostReasonModelNae } from '../../_generated/_custom/hooks/useLeadLostReasonHookNae';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useHistory } from "@newageerp/v3.templates.templates-core";
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Tag } from '@newageerp/v3.bundles.badges-bundle'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useClientHookNae } from '../../_generated/_custom/hooks/useClientHookNae';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useTranslation } from 'react-i18next';
import { useUList, useUSave } from '@newageerp/v3.bundles.hooks-bundle';

export default function ClientManagerDecline(props: PropsId) {
    const element = useClientHookNae(props.id);
    const history = useHistory();
    const [isChangeForm, setIsChangeForm] = useState(false);
    
    const [getReasons, getReasonsData] = useUList<ILeadLostReasonModelNae>(NaeSSchemaMap.LeadLostReason.schema, ILeadLostReasonFieldsNae,);
    const [doSave] = useUSave(NaeSSchemaMap.Client.schema);

    const { t } = useTranslation();

    const cancelOwner = (reasonId: number, sendToBusinessDev: number, sendToBusinessDev2: number) => {
        doSave(
            {
                addLostReason: {
                    id: reasonId
                },
                mergeMode: true,
                skipRequiredCheck: true,
                needBusinessDev: sendToBusinessDev === 10 && sendToBusinessDev2 === 10,
            },
            props.id
        ).then(() => {
            history.goBack();
        });
    }


    const loadData = () => {
        if (isChangeForm) {
            getReasons(
                undefined,
                1,
                1000,
                [
                    {
                        key: "i.sort",
                        value: "ASC",
                    }
                ]
            );
        }
    }
    useEffect(loadData, [isChangeForm]);

    if (!element) return <Fragment />

    return (
        <Fragment>
            {isChangeForm &&
                <Popup isPopup={true} onClick={() => setIsChangeForm(false)} title={t("Atsisakyti")}>

                    <div>
                        {getReasonsData.data.data.map((reason: ILeadLostReasonModelNae) => {

                            return (
                                <div className='flex gap-2 items-center' key={`reason-${reason.id}`}>
                                    <Tag
                                        // @ts-ignore
                                        color={reason.color}
                                    />

                                    <MainButton
                                        key={"reason-" + reason.id}
                                        onClick={() => cancelOwner(
                                            reason.id,
                                            reason.sendToBusinessDev,
                                            element.sendToBusinessDev
                                        )
                                        }
                                        color={"white"}
                                        // skipPadding={true}
                                        confirmation={true}
                                    >


                                        {t(reason.title)}
                                        
                                    </MainButton>
                                </div>
                            )
                        })}
                    </div>

                </Popup>
            }
            <ToolbarButton iconName='face-pensive' title={t("Atsisakyti")} onClick={() => setIsChangeForm(true)} />

        </Fragment>
    )
}
