import React, { Fragment } from "react";

import parse from "html-react-parser";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useNoteHookNae } from '../../../_generated/_custom/hooks/useNoteHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import OldPopoverConfirm from "../../old-ui/OldPopoverConfirm";
import classNames from 'classnames';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";


export default function NaeNotePopover(props: PropsId) {
  const element = useNoteHookNae(props.id);

  const [saveData, saveDataParams] = useUSave("note");
  const {userState} = useTemplatesCore()

  const hideNote = () => {
    if (!element) return;
    if (saveDataParams.loading) return;
    saveData(
      {
        notifyAccept: [...element.notifyAccept, userState.id],
      },
      element.id
    );
  };

  if (!element) {
    return <Fragment />
  }

  const isCreator = element.creator && element.creator.id === userState.id;
  const fromSystem = !element.creator;
  const color = fromSystem
    ? "bg-purple-300"
    : isCreator
      ? "bg-amber-300"
      : "bg-blue-300";
  return (
    <OldPopoverConfirm
      confirmText={"Hide"}
      cancelText={"Leave"}
      onClick={hideNote}
      customText={
        <div>
          <p className="flex gap-2">
            <span className={"text-xs font-medium text-gray-500"}>
              {element.creator ? <DfValueView path="note.creator.fullName" id={props.id} /> : "System"}
            </span>
            <span className={"text-xs text-gray-500"}><DfValueView path="note.createdAt" id={props.id} /></span>
          </p>

          <div>{parse(element.content)}</div>
        </div>
      }
    >
      <button className={classNames(color, 'mr-1 rounded-sm p-1')}>
        <i className={classNames("fa fa-solid fa-fw fa-info text-white")} />
      </button>
    </OldPopoverConfirm>
  );
}
