import React, { Fragment } from "react";
import { PropsId } from "../types";
import { useKzCarriersOrderHookNae } from "../../hooks/useKzCarriersOrderHookNae";
import { useTranslation } from "react-i18next";
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../config/NaeSPermissions';
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../config/NaePaths";

export default function ActionKzCarriersOrderArrivedAtLoad(props: PropsId) {
  const { t } = useTranslation();

  const [saveData, saveDataParams] = useURequest(
    NaePathsMap.post["pluginsKzArrivedAtLoadArrived"]
  );

  const element = useKzCarriersOrderHookNae(props.id);
  if (!element) {
    return <Fragment />;
  }

  const doSave = () => {
    saveData({
      id: element.id,
    });
  };

  return (
    <CheckUserPermissionComponent

      permissions={[
        ENaeSPermissions.UserAdmin,
        ENaeSPermissions.UserLogisticFtl,
        ENaeSPermissions.UserForwardingManager,
        ENaeSPermissions.UserHeadOfLogistics,
      ]}
    >
      {!element.arrivedAtLoad && !element.isFtlDirect && (
        <MainButton
          color={"blue"}
          onClick={doSave}
          confirmation={true}
          className="whitespace-nowrap"
        >
          {t("Arrived for loading")}
        </MainButton>
      )}
    </CheckUserPermissionComponent>
  );
}
