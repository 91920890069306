import React, { Fragment } from 'react'
import { PropsId } from '../types';
import SalesOrderClientOrFilialName from '../SalesOrder/SalesOrderClientOrFilialName';
import CalculationClientName from '../Calculation/CalculationClientName';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

type Props = {
    link?: boolean,
} & PropsId;

export default function CargoSalesOrderClientOrFilialName(props: Props) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    if (element.salesOrder) {
        return <SalesOrderClientOrFilialName id={element.salesOrder.id} link={props.link} />
    }
    if (element.calculation) {
        return <CalculationClientName id={element.calculation.id} />
    }

    return <Fragment />
}
