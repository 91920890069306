import React, { Fragment } from "react";
import SalesOrderCostContent from './SalesOrderCostContent';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";

export default function SalesOrderCost(props: PropsId) {
  const element = useSalesOrderHookNae(props.id);

  if (!element) {
    return <Fragment />;
  }
  if (!(element.status === 10 || element.status === 20)) {
    return <Fragment />;
  }

  return <SalesOrderCostContent id={element.id} />;
}
