import React, { Fragment } from 'react'
import CargoTd from '../../../../../UserComponents/SalesOrder/Components/Table/CargoTd';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function CargoLinks(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    return (
        <Fragment>{element.cargos.map((c: any) => {
            return <CargoTd element={c} key={"cargo-td-" + c.id} />;
        })}</Fragment>
    )
}
