import moment from "moment";
import { NaeSSchemaMap } from "../config/NaeSSchema";

export const onEditElementUpdate = (schema: string, key: string, val: any, element: any) => {
  if (schema === NaeSSchemaMap.InvoiceIncoming.schema) {
    if (key === 'type') {
      element.carrier = undefined;
      element.costSupplierRailway = undefined;
      element.costSupplier = undefined;
      element.terminal = undefined;
      element.costSupplierExtra = undefined;
    }
    const checkForIncludeToAutomaticPayments = (element: any, k: string) => {
      if (
        !!element[k] &&
        !!element[k].includeToAutomaticPayments
      ) {
        element.includeToAutomaticPayments = 10;
      } else {
        element.includeToAutomaticPayments = 20;
      }
    }
    if (key === 'carrier' || key === 'costSupplierRailway' || key === 'costSupplier' || key === 'terminal' || key === 'costSupplierExtra') {
      checkForIncludeToAutomaticPayments(element, key);
    }
    if (key === "invoiceIssueDate") {
      let delayDays = 28;
      if (
        !!element.carrier &&
        !!element.carrier.paymentDelay &&
        element.carrier.paymentDelay > 0
      ) {
        delayDays = element.carrier.paymentDelay;
      }
      if (
        !!element.costSupplierRailway &&
        !!element.costSupplierRailway.paymentDelay &&
        element.costSupplierRailway.paymentDelay > 0
      ) {
        delayDays = element.costSupplierRailway.paymentDelay;
      }
      if (
        !!element.costSupplier &&
        !!element.costSupplier.paymentDelay &&
        element.costSupplier.paymentDelay > 0
      ) {
        delayDays = element.costSupplier.paymentDelay;
      }
      if (
        !!element.terminal &&
        !!element.terminal.paymentDelay &&
        element.terminal.paymentDelay > 0
      ) {
        delayDays = element.terminal.paymentDelay;
      }
      if (
        !!element.costSupplierExtra &&
        !!element.costSupplierExtra.paymentDelay &&
        element.costSupplierExtra.paymentDelay > 0
      ) {
        delayDays = element.costSupplierExtra.paymentDelay;
      }
      element.invoiceDate = moment(val).add(delayDays, 'days').format("YYYY-MM-DD")
    }
  }

  if (schema === NaeSSchemaMap.InvoiceIncoming.schema && key === 'serialNumber') {
    element[key] = val.replace(/\s/g, '');
  }

  if (
    (schema === NaeSSchemaMap.KzCarriersOrder.schema &&
      key === "invoiceNumber") ||
    (schema === NaeSSchemaMap.CarriersOrder.schema &&
      key === "invoiceNumber") ||
    (schema === NaeSSchemaMap.ExtraCostCargo.schema &&
      key === "serialNumber") ||
    (schema === NaeSSchemaMap.ExtraCostCommon.schema &&
      key === "serialNumber") ||
    (schema === NaeSSchemaMap.ExtraCostGarant.schema &&
      key === "serialNumber") ||
    (schema === NaeSSchemaMap.ExtraCostRailwayTarif.schema &&
      key === "serialNumber") ||
    (schema === NaeSSchemaMap.ExtraCostTerminal.schema &&
      key === "serialNumber")
  ) {
    const replaces = ["NR", "Nr", "No", "NO", "НР", "Нр", "НО", "Но"];
    let _val = val.replace(/[^a-zA-Z0-9]/g, "");
    replaces.forEach((r) => {
      _val = _val.replace(r, "");
    });
    element[key] = _val;
  }

  if (
    (schema === NaeSSchemaMap.Client.schema && key === "name") ||
    (schema === NaeSSchemaMap.ClientFilial.schema && key === "name") ||
    (schema === NaeSSchemaMap.Carrier.schema && key === "name") ||
    (schema === NaeSSchemaMap.Sender.schema && key === "name")
  ) {
    element[key] = val.replace(/[^a-zA-Z0-9, ]/g, "");
  }

  if (schema === "kz-carriers-order") {
    if (key === "client") {
      element.contact = null;
    }
  }
  if (schema === "carriers-order") {
    if (key === "client") {
      element.contact = null;
    }
  }
  if (schema === "cargo-loading-calculation") {
    if (key === "client") {
      element.contact = null;
    }
  }

  if (schema === "cargo") {
    if (key === "seller" && element.seller === null) {
      element.contact = null;
      element.loadAddress = null;
      element.loadAddressAdditional = null;
      element.loadAddressAdditional2 = null;
    }
  }

  if (
    schema === NaeSSchemaMap.Client.schema ||
    schema === NaeSSchemaMap.SupplierRailway.schema ||
    schema === NaeSSchemaMap.Sender.schema ||
    schema === NaeSSchemaMap.Contact.schema ||
    schema === NaeSSchemaMap.Carrier.schema
  ) {
    if (key === "phones" && !!element.phones) {
      element.phones = element.phones.map((p: string) =>
        p.replace(/[^0-9]/g, ""),
      );
    }
  }

  if (
    schema === "sales-order" ||
    schema === NaeSSchemaMap.Client.schema
  ) {
    if (
      key === 'paymentTermType' &&
      element.paymentTermType === 30
    ) {
      element.paymentTermDays = 1;
    }
  }

  if (schema === NaeSSchemaMap.ViltTrip.schema) {
    if (key === 'truck') {
      element["trailer"] = element.truck.trailer;
      element["driver"] = element.truck.driver;
    }
  }

  return element;
}