import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoInsuranceNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICargoInsuranceModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      invoices: any,
      cargo: {
              id: number,
            },
  }

export const ICargoInsuranceFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "invoices",
    "cargo.id"
];

export function useCargoInsuranceHookNae(id: number) : ICargoInsuranceModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCargoInsuranceNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "cargo-insurance",
        fields: ICargoInsuranceFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
