import React, { Fragment } from 'react'
import { PropsId } from '../types';
import ClientName from '../Client/ClientName';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationClientName(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element || !element.client) {
        return <Fragment />
    }

    return (
        <ClientName id={element.client.id} />
    )
}
