import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainButtonWithSave } from "@newageerp/v3.bundles.buttons-bundle";
import { useCargoOperationConfirmationHookNae } from "../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";

export default function OperativeCargoSendFormation(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)

  const priceConfirmed = confirmElement && confirmElement.priceConfirmed;
  const confirmed = confirmElement ? confirmElement.confirmed : false;

  const { t } = useTranslation();

  if (!element) {
    return <Fragment />;
  }

  let disabledContent = '';
  let disabledColor: any = undefined;

  if (confirmed) {
    disabledContent = t('Documents are already sent')
    disabledColor = 'success';
  }

  if (!priceConfirmed) {
    disabledContent = t('Confirm the cargo value to continue')
  } else {

    let hasError = false;
    let errors = [];
    if (element.alcoSubType === 0 && element.specCargo === 10) {
      errors.push(t("Atsakykite kokio stiprumo alkoholis?"));
      hasError = true;
    }
    if (!element.files["10"]) {
      errors.push(t("Įkelkite atvykimo dokumentus, kad tęsti darbą"));
      hasError = true;
    }
    if (!element.files["30"] && element.outgoingDocumentsChange === 10) {
      errors.push(t("Įkelkite išvykimo dokumentus, kad tęsti darbą"));
      hasError = true;
    }
    if (!element.files["20"] && element.needEx1T1File) {
      errors.push(t("Įkelkite EX/T1 deklaraciją, kad tęsti darbą"));
      hasError = true;
    }
    if (!element.files["40"] && element.typeOut !== 'avia') {
      errors.push(t("Įkelkite vertimą, kad tęsti darbą"));
      hasError = true;
    }

    if (hasError) {
      disabledContent = errors.join(", ");
    }
  }
  const disabled = !!disabledContent;

  return <MainButtonWithSave
    elementId={!!confirmElement ? confirmElement.id : -1}
    saveData={
      {
        confirmed: true,
        addNote: {
          content: t("Cargo was sent for processing"),
        },
        _events: [
          'evt.userpoints.onOperativeFinish',
        ],
      }
    }
    schema={NaeSSchemaMap.CargoOperationConfirmation.schema}

    disabled={disabled}
    disabledContent={
      {
        children: disabledContent,
        color: disabledColor,
      }
    }

    // confirmation={true}
    iconName={'file'}
    color={"teal"}
    className={"w-full"}
  >
    {t('Send documents for processing')}
  </MainButtonWithSave>

}
