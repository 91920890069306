import React, { Fragment } from "react";
import CargoCarriersOrder from "./Widget/CargoCarriersOrder";
import CargoLoadBtn from "./Widget/CargoLoadBtn";
import CargoStockStatus from "./Widget/CargoStockStatus";
import LogisticCargoReturn from "./Widget/LogisticCargoReturn";
import OperationalCargoReturnBlock from "./Widget/OperationalCargoReturnBlock";

import CartoParentLinks from "./Widget/CartoParentLinks";
import OperativeAdminClm from "./Widget/OperativeAdminClm";
import CargoNeedLoadDateConfirm from "./Widget/CargoNeedLoadDateConfirm";
import ManagerCargoReturnWidget from "./Widget/ManagerCargoReturnWidget";
import CargoLogisticUrgentData from "./Widget/CargoLogisticUrgentData";
import CargoLogisticCalculatesCard from "./Widget/CargoLogisticCalculatesCard";
import OperativeCargoValueConfirm from "./Widget/OperativeCargoValueConfirm";
import OperativeCargoSendFormation from "./Widget/OperativeCargoSendFormation";
import CargoAdminStock from "../../Plugins/layout/view/custom-toolbar-items/components/cargo/CargoAdminStock";
import CargoOperationalData from "./Widget/CargoOperationalData";
import CargoManagerData from "./Widget/CargoManagerData";
import CargoLogisticStock from "./Widget/CargoLogisticStock";
import CargoLogisticSellerInfo from "./Widget/CargoLogisticSellerInfo";
import CargoLogisticSendToOperation from "./Widget/CargoLogisticSendToOperation";
import CargoLogisticSpecification from "./Widget/CargoLogisticSpecification";
import CargoLogisticStatus3 from "./Widget/CargoLogisticStatus3";
import CargoManagerUrgentChanges from "./Widget/CargoManagerUrgentChanges";
import CargoLogisticSellerInfoType50 from "./Widget/CargoLogisticSellerInfoType50";
import {
  checkUserLogisticFtl,
  CheckUserPermissionComponent,
  ENaeSPermissions,
} from "../../_generated/_custom/config/NaeSPermissions";
import CargoDocumentsBtn from "../../components/dashboard/cargo/CargoDocumentsBtn";
import { useTranslation } from "react-i18next";
import { useCargoHookNae } from "../../_generated/_custom/hooks/useCargoHookNae";
import FinishBtn from "../Dashboard/Logistic/Components/CargoLogisticCalculates/FinishBtn";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";

export default function CargoWorkflowWidget(props: PropsId) {
  const { t } = useTranslation();

  const cargo = useCargoHookNae(props.id);

  const { userState } = useTemplatesCore()
  const isLogisticFtl = checkUserLogisticFtl(userState);

  if (!cargo) {
    return <Fragment />;
  }
  const isLogistic = !!cargo.expeditor && userState.id === cargo.expeditor.id;

  if (isLogistic && cargo.status === 7) {
    return <Fragment />;
  }

  const isForFtl =
    (userState.ftlHelper === 10 || isLogisticFtl) && cargo.typeIn === "ftl";
  const isExchange = isForFtl && !cargo.expeditorCalculation;

  return (
    <Fragment>
      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserOperationalPro,
        ]}
      >
        <WhiteCard isCompact={true} title={t("Documents verification")}>
          <CargoDocumentsBtn id={props.id} iconSize={"text-2xl"} />
        </WhiteCard>
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserLogistic,
          ENaeSPermissions.UserLogisticSea,
          ENaeSPermissions.UserLogisticFtl,
          ENaeSPermissions.UserLogisticTrain,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserForwardingManager,
        ]}
      >
        <CargoNeedLoadDateConfirm id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserOperational,
          ENaeSPermissions.UserOperationalPro,
          ENaeSPermissions.UserHeadOfLogistics,
        ]}
      >
        <OperationalCargoReturnBlock id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserHeadOfLogistics,]}
      >
        <CargoManagerUrgentChanges id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserLogistic,
          ENaeSPermissions.UserLogisticSea,
          ENaeSPermissions.UserLogisticFtl,
          ENaeSPermissions.UserLogisticTrain,
          ENaeSPermissions.UserForwardingManager,
        ]}
      >
        <CargoLogisticStatus3 id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
        ]}
      >
        <OperativeAdminClm id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserLogistic,
          ENaeSPermissions.UserLogisticSea,
          ENaeSPermissions.UserLogisticFtl,
          ENaeSPermissions.UserLogisticTrain,
          ENaeSPermissions.UserForwardingManager,
        ]}
      >
        <CargoLogisticSpecification id={props.id} />

        <CargoLogisticSendToOperation id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserLogistic,
          ENaeSPermissions.UserLogisticSea,
          ENaeSPermissions.UserLogisticFtl,
          ENaeSPermissions.UserLogisticTrain,
          ENaeSPermissions.UserForwardingManager,
        ]}
      >
        <CargoLoadBtn id={props.id} />

        <CargoCarriersOrder id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserHeadOfLogistics,
          ENaeSPermissions.UserLogistic,
          ENaeSPermissions.UserLogisticSea,
          ENaeSPermissions.UserLogisticFtl,
          ENaeSPermissions.UserLogisticTrain,
          ENaeSPermissions.UserForwardingManager,
        ]}
      >
        <CargoLogisticSellerInfo id={props.id} />

        <CargoLogisticSellerInfoType50 id={props.id} />

        <CargoLogisticUrgentData id={props.id} />

        <CargoLogisticStock id={props.id} />

        <CargoStockStatus id={props.id} />

        <CargoLogisticCalculatesCard id={props.id} />

        <LogisticCargoReturn ids={[props.id]} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[ENaeSPermissions.UserManager]}
      >
        <Fragment>
          {(cargo.typeIn === "ftl" || cargo.typeIn === "ltl") &&
            <FinishBtn cargoId={cargo.id} calcType={"in"} />}
        </Fragment>
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserManager,
          ENaeSPermissions.UserHeadOfLogistics,
        ]}
      >
        <ManagerCargoReturnWidget id={props.id} />

        <CargoManagerData id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[
          ENaeSPermissions.UserAdmin,
          ENaeSPermissions.UserOperational,
          ENaeSPermissions.UserOperationalPro,
          ENaeSPermissions.UserHeadOfLogistics,
        ]}
      >
        <OperativeCargoSendFormation id={props.id} />

        <OperativeCargoValueConfirm id={props.id} />

        <CargoOperationalData id={props.id} />

      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserHeadOfLogistics,]}
      >

        <CartoParentLinks id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserHeadOfLogistics,]}>
        <CargoAdminStock id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent
        permissions={[ENaeSPermissions.UserOperationalPro, ENaeSPermissions.UserHeadOfLogistics,]}
      >

        <WhiteCard isCompact={true}>
          <MainEditContentInline
            schema={"cargo"}
            type={"operations-pro"}
            id={props.id.toString()}
            isCompact={true}
          />
        </WhiteCard>
      </CheckUserPermissionComponent>

    </Fragment>
  );
}
