import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { useSalesOrderHookNae } from '../../../../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../../../../_generated/_custom/models-cache-data/types';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle';

export default function CancelSo(props: PropsId) {
    const {userState} = useTemplatesCore()

    const salesOrder = useSalesOrderHookNae(props.id);

    const { t } = useTranslation();

    const isAdmin = userState.scopes.indexOf("user-admin") >= 0;

    const isCreator = salesOrder.creator && salesOrder.creator.id === userState.id;

    const canCancel =
        isAdmin || (isCreator && salesOrder.scopes.indexOf("can-cancel") > -1);

    if (!salesOrder) {
        return <Fragment />
    }

    if (!canCancel) {
        return <Fragment />
    }


    const disabled = salesOrder.invoicePaidTotal !== 0.0;
    let tooltipContent = undefined;
    if (salesOrder.invoicePaidTotal !== 0.0) {
        tooltipContent = t('It is not possible to cancel the order until the invoice has been issued. Cancel the invoices to cancel the order.');
    }


    return (
        <MenuItemWithSave
            isDisabled={disabled}
            iconName={"ban"}
            tooltipContent={tooltipContent}
            textColor={"red"}
            confirmation={true}
            saveData={
                { status: 900 }
            }
            elementId={props.id}
            schema={"sales-order"}
        >
            {t("Cancel order")}
        </MenuItemWithSave>
    )
}
