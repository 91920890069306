import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCalculationNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICalculationModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      status: number,
      hasCost: boolean,
      costHistoryRecords: number,
      cargos: ChildId[],
      client: {
              id: number,
            },
      address: {
              id: number,
            },
      total: number,
      purpose: number,
      status25LastChangeDiffDays: number,
      status10LastChangeDiffDays: number,
      allCargosOneUnload: number,
      status5LastChange: string,
      canSeparateCargos: number,
      contact: {
              id: number,
            },
      currency: {
              id: number,
                    currency: string,
            },
      fillErrors: any,
      cargoRailway: {
              id: number,
            },
      creator: {
              id: number,
            },
      salesOrder: {
              id: number,
            },
      copyFrom: {
              id: number,
            },
  }

export const ICalculationFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "status",
    "hasCost",
    "costHistoryRecords",
    "cargos:id",
    "client.id",
    "address.id",
    "total",
    "purpose",
    "status25LastChangeDiffDays",
    "status10LastChangeDiffDays",
    "allCargosOneUnload",
    "status5LastChange",
    "canSeparateCargos",
    "contact.id",
    "currency.id",
    "currency.currency",
    "fillErrors",
    "cargoRailway.id",
    "creator.id",
    "salesOrder.id",
    "copyFrom.id"
];

export function useCalculationHookNae(id: number) : ICalculationModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCalculationNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "calculation",
        fields: ICalculationFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
