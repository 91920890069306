import React, { Fragment, useEffect, useState } from 'react'
import { PropsId } from '../../../../../_generated/_custom/models-cache-data/types'
import { useCargoHookNae } from '../../../../../_generated/_custom/hooks/useCargoHookNae';
import { FieldTextarea } from '@newageerp/v3.bundles.form-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { NaeSSchemaMap } from '../../../../../_generated/_custom/config/NaeSSchema';

export default function CommentLoadingField(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const [comment, setComment] = useState(element.commentLoading);

  const [doReq, reqParams] = useUSave("cargo");

  const onSave = () => {
    doReq({
      commentLoading: comment,
    }, props.id)
  }

  useEffect(() => {
    setComment(element.commentLoading);
  }, [element?.commentLoading]);

  if (!element) {
    return <Fragment />
  }

  return (
    <div className='space-y-1'>
      <FieldTextarea rows={2} autoRows={true} value={comment} onChange={e => setComment(e.target.value)} />

      {comment !== element.commentLoading &&
        <MainButton className='w-full' iconName='save' color='sky' loading={reqParams.loading} onClick={onSave}>Save</MainButton>
      }
    </div>
  )
}
