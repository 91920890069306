import React, { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import moment from "moment";
import { checkUserManager } from "../../../../../../_generated/_custom/config/NaeSPermissions";
import { PropsId } from "../../../../../../_generated/_custom/models-cache-data/types";
import { useClientHookNae } from "../../../../../../_generated/_custom/hooks/useClientHookNae";
import { IContactModelNae } from "../../../../../../_generated/_custom/hooks/useContactHookNae";
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { SelectorContactNae } from "../../../../../../_generated/_custom/models/ormSelectors";
import OldBadge, {
  BadgeBgColor,
  BadgeSize,
} from "../../../../../../core/old-ui/OldBadge";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import classNames from 'classnames';
import { naePhoneState } from "@newageerp/v3.bundles.voximplant-bundle";
import { toast } from '@newageerp/v3.templates.templates-core';
import { useUList, useURemove, useUSave } from "@newageerp/v3.bundles.hooks-bundle";


const hintText = `
<div>
<p>ERP constantly updates and saves data regarding connected or NOT connected calls. When you move your mouse pointer on the phone number, ERP shows call in attempts of the selected number:</p>
<p>&nbsp;</p>
<p><span class="w-4 h-4 rounded-full bg-yellow-500 inline-block"></span> – indicates, that attempts to call in on the selected number "X" times were unsuccessful. <strong>However, it is very likely that this number exists.</strong></p>
<p>&nbsp;</p>
<p><span class="w-4 h-4 rounded-full bg-red-500 inline-block"></span> – indicates, that it is likely the <strong>number is unreachable or non-existant</strong>. ERP indicates quantity of unsuccessful attempts to reach the number.</p>
<p>&nbsp;</p>
<p><span class="w-4 h-4 rounded-full bg-green-500 inline-block"></span> – indicates, that the <strong>number is correct and reachable</strong>. ERP indicates quantity of successful attempts to reach the number.</p>
<p>&nbsp;</p>
<p class="bg-red-200 rounded-sm"><strong>Red field</strong> – indicates that this <strong>telephone number is certainly unreachable or non-existant</strong>. It is <strong>STRICTLY PROHIBITED</strong> to attempt to call this number for two reasons: <strong>every such call costs money for the company</strong> 
and <strong>you may be marked as spammer</strong> and all your future calls will be limited.</p>
<p>&nbsp;</p>
<p class="bg-green-200 rounded-sm"><strong>Green field</strong> - indicates that this <strong>telephone numbers EXISTS</strong> and there were at least 4 successful call ins. If for some reason your call hasn't been answered, try again later.</p>
</div>
`;

const successCallsCounter = 4;
const errorCallsCounter = 10;

interface IData {
  id: number;
  phones: any;
  name: string;
  contacts: IContact[];
}

interface IContact {
  id: number;
  phones: any;
  fullName: string;
}

const defObject: IData = {
  id: 0,
  phones: "",
  name: "",
  contacts: [
    {
      id: 0,
      phones: "",
      fullName: "",
    },
  ],
};

export default function CardCallWidget(props: PropsId) {
  const client = useClientHookNae(props.id);
  const elementCargos = client ? client.contactsToUse.map((el) => el.id) : [-1];
  // @ts-ignore
  const contacts: IContactModelNae[] = useSelector((state) => SelectorContactNae(state, elementCargos));

  const {userState} = useTemplatesCore()
  const isManager = checkUserManager(userState);

  const [showPopup, setShowPopup] = useState(false);
  const toggleShowPopup = () => setShowPopup(!showPopup);

  const [phones, setPhones] = useState<string[]>([]);

  const { t } = useTranslation();
  const [, setPhoneDial] = useRecoilState(naePhoneState);

  const [saveHistoryData, saveHistoryDataParams] = useUSave(
    NaeSSchemaMap.CallLog.schema
  );
  const [removeHistoryData, removeHistoryDataParams] = useURemove(
    NaeSSchemaMap.CallLog.schema
  );

  const removeOtherCalls = async (number: string) => {
    const calls: any[] = getHistoryDataParams.data.data;
    for (let i = 0; i < calls.length; i++) {
      if (calls[i].toNumber === number) {
        await removeHistoryData(calls[i].id);
      }
    }
  };

  const add4SuccessCalls = async (number: string) => {
    removeOtherCalls(number);
    for (let i = 0; i < successCallsCounter; i++) {
      await saveHistoryData(
        {
          duration: 1,
          fromNumber: "auto",
          toNumber: number,
          sourceId: "xxx",
          date: moment().format("YYYY-MM-DD"),
        },
        "new"
      );
    }
    toast.success("Išsaugota");
    loadHistoryData();
  };
  const add4ErrorCalls = async (number: string) => {
    removeOtherCalls(number);
    for (let i = 0; i < errorCallsCounter; i++) {
      await saveHistoryData(
        {
          duration: 0,
          fromNumber: "auto",
          toNumber: number,
          sourceId: "xxx",
          date: moment().format("YYYY-MM-DD"),
          stopReason: "manual",
        },
        "new"
      );
    }
    toast.success("Išsaugota");
    loadHistoryData();
  };

  const [getHistoryData, getHistoryDataParams] = useUList(
    NaeSSchemaMap.CallLog.schema,
    ["id", "toNumber", "duration", "stopReason"]
  );
  const loadHistoryData = () => {
    getHistoryData(
      [
        {
          classicMode: true,
          and: [["i.toNumber", "in", phones.map((p: any) => p.phone)]],
        },
      ],
      1,
      100,
      [{ key: "i.date", value: "DESC" }]
    );
  };

  useEffect(loadHistoryData, [phones]);

  const groupedCalls = groupMap(
    getHistoryDataParams.data.data,
    (el) => el.toNumber
  );

  // console.log("groupedCalls", groupedCalls);
  useEffect(() => {
    if (
      !!client &&
      contacts.filter((e: IContactModelNae) => e === null).length === 0
    ) {
      const data: any[] = [];
      client.phones.map((p: string) => {
        if (p) {
          data.push({
            phone: p,
            title: client.name,
          });
        }
      });
      contacts.forEach((contact) => {
        contact.phones.map((p: string) => {
          if (p) {
            data.push({
              phone: p,
              title: contact.fullName,
            });
          }
        });
      });
      if (JSON.stringify(phones) !== JSON.stringify(data)) {
        setPhones(data);
      }
    }
  }, [client, contacts]);

  if (phones.length === 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className="flex gap-2 items-center">
        <MainButton color="purple" iconName="phone" onClick={toggleShowPopup} large={true}>
          {t('Call')}
        </MainButton>
        <Tooltip text={hintText} />
      </div>
      <Popup title={t('Call')} onClick={toggleShowPopup} isPopup={showPopup}>
        <div className="space-y-2">
          {phones.map((p: any, pIndex: number) => {
            let success = 0;
            let warning = 0;
            let error = 0;
            if (p.phone in groupedCalls) {
              success = groupedCalls[p.phone].filter(
                (s: any) => s.duration > 0 && !s.stopReason
              ).length;
              error = groupedCalls[p.phone].filter(
                (s: any) =>
                  s.stopReason === "404" ||
                  s.stopReason === "480" ||
                  s.stopReason === "manual"
              ).length;
              warning = groupedCalls[p.phone].length - success - error;
            }

            const showBadges =
              error < errorCallsCounter && success < successCallsCounter;

            return (
              <div
                key={"phone-" + pIndex + "-" + props.id}
                onClick={() => { }}
                className={
                  classNames(
                    'flex items-center gap-4 px-4 py-2 rounded-sm',
                    { 'bg-green-200': (success >= successCallsCounter) },
                    { 'bg-red-200': !(success >= successCallsCounter) && (error >= errorCallsCounter) },
                  )
                }
                title={`${error >= errorCallsCounter
                  ? "NESKAMBINTI. nurodytas numeris neegzistuoja"
                  : ""
                  } ${success >= successCallsCounter ? "numeris teisingas" : ""}`}
              >
                <button className="flex-grow text-left" onClick={() => { setPhoneDial(p.phone); toggleShowPopup() }}>
                  {p.phone}<br />
                  <span>{p.title}</span>
                </button>

                {showBadges && (
                  <div className="flex gap-2">
                    {success > 0 && (
                      <OldBadge
                        size={BadgeSize.xs}
                        bgColor={BadgeBgColor.green}
                        title={`numeris teisingas. prisiskambinta ${success} kartų.`}
                      >
                        {success}
                      </OldBadge>
                    )}
                    {warning > 0 && (
                      <OldBadge
                        size={BadgeSize.xs}
                        bgColor={BadgeBgColor.yellow}
                        title={`nurodytu numerių NEprisiskambinta ${warning} kartų`}
                      >
                        {warning}
                      </OldBadge>
                    )}
                    {error > 0 && error < errorCallsCounter && (
                      <OldBadge
                        size={BadgeSize.xs}
                        bgColor={BadgeBgColor.red}
                        title={`nurodytas numeris neegzistuoja, NEprisiskambinta ${error} kartų.`}
                      >
                        {error}
                      </OldBadge>
                    )}
                  </div>
                )}

                <div className="flex gap-2">
                  {!isManager && (
                    <button
                      title={t("Numeris teisingas")}
                      onClick={() => add4SuccessCalls(p.phone)}
                    >
                      <i className={"fad fa-fw fa-check-double text-green-500"} />
                    </button>
                  )}

                  {!isManager && (
                    <button
                      title={t("The number is unreachable")}
                      onClick={() => add4ErrorCalls(p.phone)}
                    >
                      <i className={"fad fa-fw fa-ban text-red-500"} />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Popup>

    </Fragment >
  );
}
