import React, { Fragment, useState } from 'react'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useClientHookNae } from '../../../_generated/_custom/hooks/useClientHookNae';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorContactNae } from '../../../_generated/_custom/models/ormSelectors';
import { IContactModelNae } from '../../../_generated/_custom/hooks/useContactHookNae';
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { CopyToClipboard } from '@newageerp/v3.bundles.utils-bundle';
import { useUserHookNae } from '../../../_generated/_custom/hooks/useUserHookNae';

interface ICaspianPhone {
    phone: string,
    title: string,
}

export default function MainActionChats(props: PropsId) {
    const { t } = useTranslation();

    const {userState} = useTemplatesCore()

    const [showPopup, setShowPopup] = useState(false);
    const toggleShowPopup = () => setShowPopup(!showPopup);

    const element = useClientHookNae(props.id);
    const contacts = element ? element.contactsToUse.map(c => c.id) : [];
    // @ts-ignore
    const elementContacts = useSelector(state => SelectorContactNae(state, contacts));

    const userElement = useUserHookNae(userState.id);

    if (!element || !userElement) {
        return <Fragment />
    }
    if (elementContacts.filter((e: IContactModelNae) => e === null).length > 0) {
        return <Fragment />
    }

    const phonesToDial: ICaspianPhone[] = [];
    if (element.phones) {
        element.phones.forEach((p: string) => {
            if (p) {
                phonesToDial.push({
                    phone: p,
                    title: element.name,
                });
            }
        })
    }
    if (elementContacts) {
        elementContacts.forEach((c: IContactModelNae) => {
            if (c.phones) {
                c.phones.forEach((p: string) => {
                    if (p) {
                        phonesToDial.push({
                            phone: p,
                            title: c.fullName,
                        });
                    }
                })
            }
        })
    }

    const textToCopy = `Добрый день! 
Это ${userElement.fullNameRu} из Caspian Logistics (Литва). 
Благодарю Вас за беседу! 
Если у Вас появятся какие либо вопросы относительно логистики, пожалуйста обращайтесь.
Наша компания будет рада Вам помочь. 
На всякий случай - 
Мой email: ${userElement.email}. 
Всего доброго!`;
    const containerId = "presentation-" + props.id;
    const openWhatsAppLink = (phone: string) => {
        CopyToClipboard(containerId);
        window.open("https://wa.me/" + phone + "?text=" + textToCopy);
        toggleShowPopup();
    }

    if (!phonesToDial) {
        return <Fragment />
    }

    return (
        <Fragment>
            <MainButton iconName='sms' color='lime' onClick={toggleShowPopup} large={true}>
                {t("WhatsApp")}
            </MainButton>

            <div style={{display:'none'}} id={containerId}>{textToCopy}</div>

            <Popup isPopup={showPopup} onClick={toggleShowPopup} title={t("WhatsApp")}>
                <div className='space-y-2'>
                    {phonesToDial.map((p: ICaspianPhone) => {
                        return (
                            <MainButton
                                key={"phone-wa-" + p}
                                onClick={() => openWhatsAppLink(p.phone)}
                                color='white'
                            >
                                {p.phone} ({p.title})
                            </MainButton>
                        )
                    })}
                </div>
            </Popup>
        </Fragment>
    )
}
