import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import React from 'react'
import { toast } from '@newageerp/v3.templates.templates-core';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

export default function CalculationsRecalculate() {
    const [reloadData] = useURequest(NaePathsMap.post['AppPluginsCalculationRecalculateRecalculate']);

    const doReload = () => {
        reloadData();
        toast.success('Recalculation start');
    }

    return (
        <ToolbarButton iconName='retweet' title='Recalculate quotations' onClick={doReload} />
    )
}
