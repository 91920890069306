import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

export default function CartoFtlToLtl(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const { t } = useTranslation();

    const [saveData, saveDataParams] = useUSave("cargo")
    const doSave = () => {
        if (saveDataParams.loading) return;
        saveData(
            {
                type: 40,
                modalType: 60,
                needLdm: true,
            },
            element.id
        );
    }

    if (!element) {
        return <Fragment />
    }

    const isAllow = (((element.typeIn === 'ftl' && element.typeOut === 'ftl') && element.ftlType === 0) || (element.typeIn === 'none' && element.typeOut === 'ftl'));

    if (!isAllow) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />
    }

    return (
        <MenuItem
            onClick={doSave}
            confirmation={{
                onYes: () => { },
                children: <Fragment>
                    <p>{t('If you choose this option,')}</p>
                    <p>{t('the cargo will depart the warehouse as LTL.')}</p>
                    <p>{t('Are you sure?')}</p>
                </Fragment>
            }} iconName={"truck"} iconLoading={saveDataParams.loading}>
            {t('Depart the cargo from our warehouse as LTL')}
        </MenuItem>
    )
}
