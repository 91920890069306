import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import React from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props {
    fieldKey: string,
}

export default function CustomClientName(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    return (
        <RsButton
            button={{
                children: element.clientName,
                skipPadding: true,
                color: "white"
            }}
            defaultClick={"modal"}
            id={element.clientIdAndSchema.id}
            schema={element.clientIdAndSchema.schema}
        />
    )
}
