import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import React from 'react'
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { toast } from '@newageerp/v3.templates.templates-core';

export default function SelfCostRecalculate() {
    const listDs = useListDataSource();

    const [doReq, doReqParams] = useURequest(NaePathsMap.post.pluginsSalesOrderFullCostRecaclulateOrderListFullCost)

    const onClick = async () => {
        await doReq({
            listToken: listDs.data.cache.token
        })
        toast.success("Success");
        listDs.data.reload.do();
    }
    return (
        <ToolbarButton
            title={"Self cost recalculate"}
            onClick={onClick}
            iconName={"retweet"}
            loading={doReqParams.loading}
        />
    );
}
