import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import KzLogisticOrderRoad from "./KzLogisticOrderRoad";
import { useTranslation } from "react-i18next";
import KzToolbarComponent from "../../../UserComponents/KzCarriersOrder/Components/KzToolbarComponent";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import KzLogisticOrderUnload from "./KzLogisticOrderUnload";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useQuery } from "../../../core/old-ui/OldTable";
import KzLogisticOrderFormation from "./KzLogisticOrderFormation";
import KzLogisticOrderPlanned from "./KzLogisticOrderPlanned";
import FtlLogisticsToCalculate from "../../../UserComponents/Dashboard/LogisticFtl/Components/FtlLogisticsToCalculate";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import LogisticCalculationFtl from "../Logistic/LogisticCalculationFtl";
import LogisticCargoWrapper from "../Logistic/LogisticCargoWrapper";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface Props {
  query?: any;
}

export interface IKzDashboardDataOrderCargo {
  id: number;
  badKzCarriersDate: boolean;
}

export interface IKzDashboardDataOrder {
  id: number;
  status: number;
  orderTerminals: number;
  loadDate: string;
  status20Date: string;
  cargos: IKzDashboardDataOrderCargo[];
  creator: {
    id: number,
  },
  doer: {
    id: number,
  },
}

const defObject: IKzDashboardDataOrder = {
  id: 0,
  status: 0,
  orderTerminals: 0,
  loadDate: "",
  status20Date: "",
  creator: {
    id: 0,
  },
  doer: {
    id: 0,
  },
  cargos: [
    {
      id: 0,
      badKzCarriersDate: false,
    },
  ],
};

let reloadTimeout: any = -1;

export default function KzLogisticForwardingManager(props: Props) {
  const { t } = useTranslation();
  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState);

  let query = useQuery();

  const { userState } = useTemplatesCore()
  const [getData, getDataParams] = useUList<IKzDashboardDataOrder>(
    NaeSSchemaMap.KzCarriersOrder.schema,
    getKeysFromObject(defObject)
  );

  const loadData = () => {
    const filters = props.query
      ? props.query
      : [
        {
          and: [
            ["i.transportType", "in", [10, 50], true],
            {
              or: [
                {
                  "and": [
                    ['i.doer', '=', 'CURRENT_USER', true],
                    ['i.status', 'in', [10, 15, 20, 30], true],
                  ]
                },
                {
                  "and": [
                    ['i.creator', '=', 'CURRENT_USER', true],
                  ]
                },
                {
                  "and": [
                    ['i.needFtlSupport', '=', 10, true],
                    ['i.doer', 'IS_NULL', '', true],
                    ['i.status', 'in', [10], true],
                  ]
                },
              ]
            }
          ],
        },
      ];
    getData(filters, 1, 500, [
      {
        key: "i.serialNumber",
        value: "ASC",
      },
    ]);
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  useEffect(() => {
    SocketService.subscribe("kz-carriers-dashboard");
    return () => {
      SocketService.unsubscribe("kz-carriers-dashboard");
    };
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "kz-carriers-dashboard",
      "kz-carriers-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "kz-carriers-dashboard",
        "kz-carriers-dashboard"
      );
    };
  }, []);

  useEffect(() => {
    setToolbarTitle(t("Sekimas"));
  }, []);

  let data: IKzDashboardDataOrder[] = getDataParams.data.data.filter(c => {

    return (
      // FtlLogisticsToCalculate
      (c.status === 10 || c.status === 15) && !(c.creator.id === userState.id || (!!c.doer && c.doer.id === userState.id))
      ||
      // KzLogisticOrderUnload
      (c.status === 30)
      ||
      // KzLogisticOrderRoad
      (c.status === 20)
      ||
      // KzLogisticOrderFormation
      (c.status === 15)
      ||
      // KzLogisticOrderPlanned
      (c.status === 10)

    )

  });

  return (
    <Fragment>
      <div className={"space-y-4"}>
        <div className="space-y-2">
          <TextCardTitle>{t("Quotations")}</TextCardTitle>
          <LogisticCalculationFtl ftlType={10} />
        </div>

        <div className="space-y-2">
          <TextCardTitle>{t("Cargo")}</TextCardTitle>
          <LogisticCargoWrapper userId={userState.id} />
        </div>

        <KzToolbarComponent data={data} />

        <FtlLogisticsToCalculate
          data={data.filter(
            (c: IKzDashboardDataOrder) => (c.status === 10 || c.status === 15) && !(c.creator.id === userState.id || (!!c.doer && c.doer.id === userState.id))
          )}
        />

        <KzLogisticOrderUnload
          data={data.filter((c: IKzDashboardDataOrder) => c.status === 30)}
        />

        <KzLogisticOrderRoad
          data={data.filter((c: IKzDashboardDataOrder) => c.status === 20)}
        />

        <KzLogisticOrderFormation
          data={data.filter(
            (c: IKzDashboardDataOrder) => c.status === 15
          )}
        />


        <KzLogisticOrderPlanned
          data={data.filter(
            (c: IKzDashboardDataOrder) => c.status === 10
          )}
        />
      </div>
    </Fragment>
  );
}
