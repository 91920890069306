import React, { Fragment } from "react";
import NEWidgetFiles from "../../../core/components/widgets/NEWidgetFiles";
import CargoOutgoingDocumentsChangeBadge from "../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeBadge";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useOutgoingDocumentsChangePurposeHookNae } from "../../../_generated/_custom/hooks/useOutgoingDocumentsChangePurposeHookNae";

interface IDataCargo {
  id: number;
  outgoingDocumentsChange: number;
  outgoingDocumentsChangePurpose: {
    id: number;
    name: string;
    needConfirmMail: number;
  };
}

interface Props {
  cargo: IDataCargo;
}

export default function OutgoingDocumentsChangeWidget(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const outgoingDocumentsChangeEl = useOutgoingDocumentsChangePurposeHookNae(
    element && element.outgoingDocumentsChangePurpose
      ? element.outgoingDocumentsChangePurpose.id
      : -1
  );

  if (!element) {
    return <Fragment />;
  }
  const isDocumentsChange = element.outgoingDocumentsChange === 10;

  if (!isDocumentsChange) {
    return <Fragment />;
  }

  const needConfirmMail =
    !!element.outgoingDocumentsChange &&
    !!outgoingDocumentsChangeEl &&
    outgoingDocumentsChangeEl.needConfirmMail === 10;

  return (
    <div className={"space-y-2 whitespace-normal"} style={{ maxWidth: 300, minWidth: 300 }}>
      <div className={"flex"}>
        <CargoOutgoingDocumentsChangeBadge id={element.id} />
      </div>
      {needConfirmMail && (
        <div>
          <NEWidgetFiles
            schema={"cargo"}
            element={{ id: element.id }}
            options={{
              type: 35,
              skipHeader: true,
              readOnly: true,
              actions: ["check", "preview", "download"],
            }}
          />
        </div>
      )}
    </div>
  );
}
