import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useTranslation } from 'react-i18next';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import classNames from 'classnames';

export default function BottomWidgetDimensions(props: PropsId) {
    const { t } = useTranslation();
    const cargo = useCargoHookNae(props.id);

    if (!cargo) {
        return <Fragment/>
    }

    const isAvia = cargo && cargo.typeOut === 'avia';
    const colsClassName = isAvia ? "grid-cols-4" : "grid-cols-3";

    return (
        <div className={classNames(
            "grid gap-1 text-sm",
            colsClassName
        )}>
            <div className='border-b border-gray-300'>
                <div className="flex items-center gap-2 font-medium">
                    <i className="fad fa-fw fa-pallet text-lg" />
                    {t('Colli quantity')}
                </div>
                <DfValueView path='cargo.places' id={props.id} />
            </div>
            <div className='border-b border-gray-300'>
                <div className="flex items-center gap-2 font-medium">
                    <i className="fad fa-fw fa-ruler-vertical text-lg" />
                    {t('Dimensions')}
                </div>
                <DfValueView path='cargo.dimensions' id={props.id} />
            </div>
            <div className='border-b border-gray-300'>
                <div className="flex items-center gap-2 font-medium">
                    <i className="fad fa-fw fa-weight text-lg" />
                    {t('Weight (kg)')}
                </div>
                <DfValueView path='cargo.weight' id={props.id} />
            </div>

            {isAvia && <div className='border-b border-gray-300'>
                <div className="flex items-center gap-2 font-medium">
                    <i className="fad fa-fw fa-weight text-lg" />
                    {t('Volume weight (kg)')}
                </div>
                <p></p>
            </div>}

            <div className={"font-medium"}>
                <div className='flex gap-2'>
                    <DfValueView path="cargo.allCargoPlaces" id={props.id} /> {t('Cll')}
                </div>
            </div>
            <div className={"font-medium"}>
                <div className='flex gap-2'>
                    <DfValueView path="cargo.cbm" id={props.id} /> {t('CBM')}
                </div>
            </div>
            <div className={"font-medium"}>
                <div className='flex gap-2'>
                    <DfValueView path="cargo.allCargoWeight" id={props.id} /> {t('kg')}
                </div>
            </div>
            {isAvia && <div className={"font-medium"}>
                <div className='flex gap-2'>
                    {(cargo.cbm * 167).toFixed(2)} {t('kg')}
                </div>
            </div>}
        </div>
    )
}
