import React, {Fragment} from 'react'
import { PropsId } from '../types';
import UnloadAddressFullAddress from '../UnloadAddress/UnloadAddressFullAddress';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderUnloadAddressFullAddress(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    if (!element.address) {
        return <Fragment/>
    }
    return (
        <UnloadAddressFullAddress id={element.address.id}/>
    )
}
