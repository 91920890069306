import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

export default function CargoDpsMailEx(props: PropsId) {
    const { t } = useTranslation();

    const openEmail = () => {
        const options = {
            extraData: {
              id: props.id,
              schema: 'cargo',
              template: 'ex-dps',
            },
          }
          const event = new CustomEvent(
            'SFSOpenEmailForm',
            {
              detail: options
            }
          );
          window.dispatchEvent(event);
    }

    return (
        <Fragment>
            <MenuItem iconName={"paper-plane"} onClick={openEmail}>
                {t("EX order")}
            </MenuItem>
        </Fragment>
    )
}
