import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorSenderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ISenderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
      country: {
              id: number,
            },
  }

export const ISenderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name",
    "country.id"
];

export function useSenderHookNae(id: number) : ISenderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorSenderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "sender",
        fields: ISenderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
