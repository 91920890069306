import React, { Fragment } from 'react'
import { useInvoiceOutgoingHookNae } from '../../../hooks/useInvoiceOutgoingHookNae';
import { useTranslation } from 'react-i18next';
import { NaeSSchemaMap } from '../../../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../models-cache-data/types';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import InvoicePdfWithGenerate from './Components/InvoicePdfWithGenerate';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useConvert } from '@newageerp/v3.bundles.hooks-bundle';

interface Props {
    fieldKey: string,
}

export default function InvoiceRels(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element: item } = tData;
    const value = item[props.fieldKey];

    const [openPopup] = useConvert({
        from: NaeSSchemaMap.InvoiceOutgoing.schema,
        to: NaeSSchemaMap.InvoiceOutgoing.schema,
        id: value,
        type: 'resubmission',
        inPopup: true
    });

    const { t } = useTranslation();
    const element = useInvoiceOutgoingHookNae(value);
    if (!element) {
        return <Fragment />
    }

    if (element.invoices.length === 0) {
        return (
            <MainButton color={'sky'} onClick={openPopup}>
                {t('Issue invoice')}
            </MainButton>
        )
    }

    return (
        <div>
            {element.invoices.map((i: PropsId) => {
                return (
                    <InvoicePdfWithGenerate key={`inv-${i.id}`} id={i.id} />
                )
            })}
        </div>
    )
}
