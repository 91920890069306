import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";
import { PdfWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { useInvoiceOutgoingHookNae } from "../../../../hooks/useInvoiceOutgoingHookNae";
import { PropsId } from "../../../../models-cache-data/types";
import { NaeSSchemaMap } from "../../../../config/NaeSSchema";
import { toast } from '@newageerp/v3.templates.templates-core';

export default function InvoicePdfWithGenerate(props: PropsId) {
  const element = useInvoiceOutgoingHookNae(props.id);

  const { t } = useTranslation();

  const [showPdf, setShowPdf] = useState(false);

  const generateNumber = () => {
    axiosInstance
      .post("/app/plugins/InvoiceOutgoingCompany/generateInvoiceNumber", {
        id: element.id,
      })
      .then(() => {
        toast.success("Success");
      })
      .catch(() => {
        toast.error("Error");
      });
  };

  if (!element) {
    return <Fragment />;
  }

  if (element.serialNumber.indexOf("TMP") === 0) {
    return (
      <Fragment>
        <div className="flex items-center gap-2">
          <PdfWidget
            items={[
              {
                action: '',
                comp: '_.WidgetsBundle.PdfWidgetItem',
                props: {
                  id: element.id,
                  title: 'PDF',
                  schema: NaeSSchemaMap.InvoiceOutgoing.schema,
                  template: 'pdf',
                }
              }
            ]}
          />

          <div>
            <MainButton color="sky" confirmation={true} onClick={generateNumber}>
              {t("Generate invoice number")}
            </MainButton>
          </div>
        </div>

      </Fragment>
    );
  }

  return (
    <PdfWidget
      items={[
        {
          action: '',
          comp: '_.WidgetsBundle.PdfWidgetItem',
          props: {
            id: element.id,
            title: 'PDF',
            schema: NaeSSchemaMap.InvoiceOutgoing.schema,
            template: 'pdf',
          }
        }
      ]}
    />
  );
}
