import React, { useState, Fragment, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { FieldSelect, FieldTextarea } from "@newageerp/v3.bundles.form-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { useSelector, useTemplatesCore, useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { SelectorKzLocationSuggestNae } from "../../../../_generated/_custom/models/ormSelectors";
import { IKzLocationSuggestModelNae } from "../../../../_generated/_custom/hooks/useKzLocationSuggestHookNae";
import { useKzInspectionHookNae } from "../../../../_generated/_custom/hooks/useKzInspectionHookNae";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { checkUserOperational } from "../../../../_generated/_custom/config/NaeSPermissions";

type Props = {
  allowDpsUpdate?: boolean
  forceReadOnly?: boolean
} & PropsId

export default function KzOrderLocation(props: Props) {
  const { userState } = useTemplatesCore();
  const isDps = checkUserOperational(userState);

  const locationSuggests: IKzLocationSuggestModelNae[] = useSelector(state => SelectorKzLocationSuggestNae(state));
  const [locationSuggestValue] = useState("");

  const element = useKzCarriersOrderHookNae(props.id);
  const { t } = useTranslation();

  const [saveData, saveDataParams] = useUSave(
    NaeSSchemaMap.KzCarriersOrder.schema
  );
  const [location, setLocation] = useState(element ? element.location : '');
  useEffect(() => {
    if (element) {
      setLocation(element.location);
    }
  }, [element?.location]);

  const sentData = () => {
    saveData({
      location,
      locationComment: "",
      locationUpdateDate: moment().format("YYYY-MM-DD HH:mm"),
    }, element.id);
  };

  // if (saveDataParams.loading) {
  //   return <Fragment>{t("Siunčiama")}</Fragment>;
  // }

  if (!element) {
    return <Fragment />
  }

  const isReadOnly = (isDps && !props.allowDpsUpdate) || props.forceReadOnly;

  return (
    <div className={"space-y-1 mb-2"}>
      {element.kzInspection && (
        <CustomsInpectionBadge id={element.kzInspection.id} />
      )}
      {isReadOnly ?
        <p>{element.location}</p>
        :
        <Fragment>
          <FieldTextarea
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            autoRows={true}
            rows={2}
          />

          <FieldSelect
            options={[
              {
                value: '',
                label: '',
              },
              ...locationSuggests.map(s => ({
                value: s.text,
                label: s.text,
              }))
            ]}
            onChange={e => setLocation(e)}
            value={locationSuggestValue}
          />

          {
            location !== element.location && (
              <MainButton
                onClick={sentData}
                color={"sky"}
                iconName={"save"}
                loading={saveDataParams.loading}
              >
                {t("Save")}
              </MainButton>
            )
          }
        </Fragment>
      }
    </div>
  );
}

export const KzCustomsInpectionBadge = (props: PropsId) => {
  const kzOrder = useKzCarriersOrderHookNae(props.id);
  if (!kzOrder) {
    return <Fragment />
  }
  if (!kzOrder.kzInspection) {
    return <Fragment />
  }
  return <CustomsInpectionBadge id={kzOrder.kzInspection.id} />
}

export const CustomsInpectionBadge = (props: PropsId) => {
  const element = useKzInspectionHookNae(props.id);
  const { t } = useTranslation();

  if (!element) {
    return <Fragment />
  }
  if (!!element.finishDate) {
    return <Fragment />
  }
  return (
    <AlertWidget
      color="danger"
      isCompact={true}
    >
      {t("Customs inspection")}
      <br />
      {moment(element.startDate).format('YYYY-MM-DD HH:mm')}
    </AlertWidget>
  )
}