import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'
import { useCargoOperationConfirmationChangesHookNae } from '../../hooks/useCargoOperationConfirmationChangesHookNae';

export default function CargoNeedHeadLogisticOperationChangesText(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.dpsConfirmationChanges.map(d => {
                return (<TextLine id={d.id} key={`d-${d.id}`} />)
            })}
        </Fragment>
    )
}


const TextLine = (props: PropsId) => {
    const el = useCargoOperationConfirmationChangesHookNae(props.id);
    if (!el) {
        return <Fragment />
    }
    if (!el.active) {
        return <Fragment />
    }
    return (<Fragment>
        {nl2p(el.comment, 't-')}
    </Fragment>)
}