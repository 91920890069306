import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { checkUserOperational, checkUserOperationalPro } from '../../../_generated/_custom/config/NaeSPermissions';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function RelatedCargosLine(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const { t } = useTranslation();
    const {userState} = useTemplatesCore()
    const isOperational = checkUserOperational(userState) || checkUserOperationalPro(userState);

    if (!element) {
        return <Fragment />
    }

    if (!(!!element.otherCargosSerialNumbers && ((element.typeIn === 'ftl' && element.typeOut === 'ltl') || isOperational))) {
        return <Fragment />
    }
    return (
        <div className={"mt-2 bg-gray-200 p-1 rounded-md inline-block"}>
            <p className={"font-medium"}>{t('Related cargo')}</p>
            <p>{element.otherCargosSerialNumbers}</p>
        </div>
    )
}
