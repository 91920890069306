import React, { Fragment } from "react";

// import CalculationSendToCalculate from '../../../Calculation/Widget/CalculationSendToCalculate';
import { useTranslation } from 'react-i18next';
import CalculationSerialNumberWithLinkAndNotes from '../../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberWithLinkAndNotes';
import { caclculationTheadColumns } from './ManagerCalculationColumns';
import CalculationCargosSerialNumberWithLinkAndNotes from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosSerialNumberWithLinkAndNotes";
import CalculationCargosLoadingAddressWithIndex from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosLoadingAddressWithIndex";
import CalculationClientNameWithLink from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationClientNameWithLink";
import CalculationUnloadAddressFullAddress from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationUnloadAddressFullAddress";
import CalculationSendToCalculate from "../../../Calculation/Widget/CalculationSendToCalculate";
import { NaeSSchemaMap } from "../../../../_generated/_custom/config/NaeSSchema";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTable, { TableSize } from "../../../../core/old-ui/OldTable";
import OldThead from "../../../../core/old-ui/OldThead";
import OldTbody from "../../../../core/old-ui/OldTbody";
import { defaultStrippedRowClassName } from "../../../../core/old-ui/OldTrow";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";

interface Props {
  data: IData[];
}

interface IData {
  id: number,
}

export default function ManagerCalculationDraft(props: Props) {
  const { t } = useTranslation();

  const createNew = () => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: "calculation"
    })
  }

  const isData = !!props.data;

  return (
    <div className={"relative"}>
      <WhiteCard isCompact={true}>

        <div className={"flex items-center"}>
          <TextCardTitle className={"flex-grow"}>{t('Quotations being prepared')}</TextCardTitle>
          <ToolbarButton
            onClick={createNew}
            iconName="plus"
            small={true}
          />
        </div>

        {isData && <OldTable

          thead={<OldThead
            columns={[
              caclculationTheadColumns.serial,
              caclculationTheadColumns.cargosSerial,
              caclculationTheadColumns.client,
              caclculationTheadColumns.loading,
              caclculationTheadColumns.unloading,
              caclculationTheadColumns.actions
            ]} />}
          tbody={
            <OldTbody
              data={props.data}
              callback={(el: IData, index: number) => {
                return (
                  {
                    columns: [
                      {
                        content: <Fragment>
                          <CalculationSerialNumberWithLinkAndNotes id={el.id} />
                          <p><DfValueView path="calculation.purpose" id={el.id} /></p>
                        </Fragment>, props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationCargosSerialNumberWithLinkAndNotes id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      { content: <CalculationClientNameWithLink id={el.id} />, props: { className: "text-left", size: TableSize.sm } },
                      {
                        content: <CalculationCargosLoadingAddressWithIndex id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      { content: <CalculationUnloadAddressFullAddress id={el.id} />, props: { className: "text-left", size: TableSize.sm } },
                      {
                        content: <CalculationSendToCalculate
                          id={el.id}
                          sm={true}
                        />, props: { className: "text-left", size: TableSize.sm }
                      },
                    ],
                    className: defaultStrippedRowClassName(index)
                  }
                )
              }}
            />
          }

        />}


      </WhiteCard>
    </div>
  );
}
