import { NaeSSchemaMap } from "../config/NaeSSchema";

export const cacheData = (userState: any) => ([
    {
        schema: NaeSSchemaMap.Company.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.AdrClass.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.CargoStatus.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.Terminal.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.UnloadAddress.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.OutgoingDocumentsChangePurpose.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.User.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.ClientStatus.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.KzLocationSuggest.schema,
        page: 1,
        pageSize: 200,
    },
    {
        schema: NaeSSchemaMap.Note.schema,
        page: 1,
        pageSize: 200,
        filters: [
            userState && ('id' in userState) ?
                {
                    "and": [
                        {
                            "or": [
                                ['i.notify', 'JSON_CONTAINS', userState.id.toString(), true],
                                ['i.notify', 'JSON_CONTAINS', `"${userState.permissionGroup}"`, true],
                            ]
                        },
                        ['i.notifyAccept', 'JSON_NOT_CONTAINS', userState.id.toString(), true],
                    ]
                }
                : {}
        ]
    },
]);