import React from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function BottomWidgetResponsiblePersonsWorkersChange(props: PropsId) {

    const { t } = useTranslation();

    const showPopup = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: "admin-users-change"
        })
    }
    return (
        <MainButton
            onClick={showPopup}
            iconName='edit'
            color='bluelight'
        >
            {t("Change responsible person")}
        </MainButton>
    );
}
