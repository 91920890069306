import { FieldVisibilityParams } from "../fieldVisibility";
import { ICargoCalculation } from "../../../../intefaces/cargoCalculation";

const isLogistic = (user: any) =>
  user.scopes.indexOf("user-logistic-avia") >= 0 ||
  user.scopes.indexOf("user-logistic-ftl") >= 0 ||
  user.scopes.indexOf("user-logistic") >= 0;

const isAlco = (element: any) => element.specCargo === 10; // 'specCargo'
const isBeer = (element: any) => element.specCargo === 6; // 'specCargo'
const isTemp = (element: any) => element.needTemperatureMode > 0; // 'needTemperatureMode'
const isFito = (element: any) => element.specCargo === 30; // 'specCargo'

const isNeedTerminal = (element: any) =>
  !(element.type === 30 || (element.type === 20 && element.ftlType === 10));
// 'type', 'ftlType'

const hasEx = (element: any) => {
  return (
    element.cargoStatus &&
    element.cargoStatus.code &&
    element.cargoStatus.code.indexOf("EX") > -1
  );
};

const isFtl = (element: any) => element.type === 20; // 'type'

const isFtlOut = (element: any) =>
  element.type === 20 ||
  (element.type === 40 && element.modalType === 50) ||
  (element.type === 50 && element.senderTransportType === 10); // 'type', 'modalType', 'senderTransportType'

const isLtlOut = (element: any) =>
  element.type === 10 ||
  (element.type === 40 &&
    (element.modalType === 0 ||
      element.modalType === 40 ||
      element.modalType === 60)) ||
  (element.type === 50 && element.senderTransportType === 0); // 'type', 'modalType', 'senderTransportType'

const isLtlOrFtlOut = (element: any) => isFtlOut(element) || isLtlOut(element);

const isAviaOut = (element: any) =>
  element.type === 30 ||
  (element.type === 50 && element.senderTransportType === 30) ||
  (element.type === 40 && element.modalType === 70); // 'type', 'senderTransportType'

  const isSeaIn = (element: any) =>
  (element.type === 40 && element.modalType === 30) ||
  (element.type === 40 && element.modalType === 40) ||
  (element.type === 40 && element.modalType === 50)

const isCanCraneHandling = (element: any) => {
  let _isCanCraneHandling =
    element.type === 10 ||
    (element.type === 20 &&
      element.ftlType === 0 &&
      element.ftlOverloadType !== 10) ||
    (element.type === 40 && element.modalType === 40) ||
    (element.type === 40 && element.modalType === 50) ||
    (element.type === 50 && element.senderTransportType === 0) ||
    (element.type === 50 && element.senderTransportType === 10);
  if (
    element.needTemperatureMode > 0 ||
    element.ftlAutoType === 10 ||
    element.specCargo === 10
  ) {
    _isCanCraneHandling = false;
  }
  return _isCanCraneHandling;
}; // 'type', 'ftlType', 'ftlOverloadType', 'modalType', 'senderTransportType'

const needRailwayType = (element: any) =>
  (element.type === 40 &&
    (element.modalType === 10 ||
      element.modalType === 20 ||
      element.modalType === 30)) ||
  (element.type === 50 && element.senderTransportType === 20);
// 'type', 'modalType', 'senderTransportType'

const needPreciousMetals = (element: any) =>
  needRailwayType(element) &&
  (element.railwayType === 20 || element.railwayType === 30);
// 'type', 'modalType', 'senderTransportType', 'railwayType'

const needTnved = (element: any) =>
  needRailwayType(element) &&
  (element.railwayType === 0 ||
    element.railwayType === 10 ||
    element.railwayType === 40 ||
    element.railwayType === 50 ||
    (needPreciousMetals(element) && element.preciousMetals === 0));
// 'type', 'modalType', 'senderTransportType', 'railwayType', 'preciousMetals'

const calculationPurpose = (element: any, parentElement: any) => {
  const calculation: ICargoCalculation = parentElement
    ? parentElement
    : element.calculation;
  return calculation ? calculation.purpose : 0;
};

export const cargoVisibility = {
  terminalManual: (props: FieldVisibilityParams) => {
    const { element, hasChanges } = props;
    return !hasChanges && !element.terminalManual;
  },
  needAlkoGlueWithStamps: (props: FieldVisibilityParams) => {
    const { element } = props;
    let isHide = false;
    if (!(isAlco(element) || isBeer(element))) {
      isHide = true;
    }
    if (!isNeedTerminal(element)) {
      isHide = true;
    }
    return isHide;
  },
  needAlkoGlueWithStampsBottles: (props: FieldVisibilityParams) => {
    const { element } = props;
    let isHide = false;

    if (!(isAlco(element) || isBeer(element))) {
      isHide = true;
    }
    if (element.needAlkoGlueWithStamps !== 10) {
      isHide = true;
    }

    return isHide;
  },
  needAlkoGlueWithStampsType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isAlco(element) || element.needAlkoGlueWithStamps !== 10;
  },
  needAlkoGlueWithStampsType2: (props: FieldVisibilityParams) => {
    const { element } = props;
    let isHide = false;

    if (!(isAlco(element) || isBeer(element))) {
      isHide = true;
    }
    if (element.needAlkoGlueWithStamps !== 10) {
      isHide = true;
    }
    return isHide;
  },
  needAlkoGlueWithStampsType3: (props: FieldVisibilityParams) => {
    const { element } = props;
    let isHide = false;

    if (!(isAlco(element) || isBeer(element))) {
      isHide = true;
    }
    if (element.needAlkoGlueWithStamps !== 10) {
      isHide = true;
    }
    return isHide;
  },
  alcoSubType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isAlco(element);
  },
  temperatureSettings: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isTemp(element);
  },
  senderMakeFito: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isFito(element);
  },
  notesOperations: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      element.type === 30 ||
      element.type === 20 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  terminal: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isNeedTerminal(element);
  },
  senderMakeEx: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !hasEx(element);
  },
  senderMakeExCost: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      !hasEx(element) ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  onlyFullWeight: (props: FieldVisibilityParams) => {
    const { element } = props;
    return (
      !element.places ||
      !element.weight ||
      element.places.split("\n").length === element.weight.split("\n").length
    );
  },
  customsCode: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      isAviaOut(element) ||
      needRailwayType(element) ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  commentLoading: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      isAviaOut(element) ||
      calculationPurpose(element, parentElement) === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  'tagCloud:commentLoading:AppPluginsTagCloudCommentLoading': (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      isAviaOut(element) ||
      calculationPurpose(element, parentElement) === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  extraServiceDeliveryClient: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      !isLtlOrFtlOut(element) ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  extraServiceDeliveryClientAddress: (props: FieldVisibilityParams) => {
    const { element } = props;
    return element.extraServiceDeliveryClient !== 10;
  },
  extraServiceCooSertificateRenew: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      element.outgoingDocumentsChange !== 10 ||
      element.extraServiceCooSertificateNew === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  extraServiceCooSertificateNew: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      element.outgoingDocumentsChange !== 10 ||
      element.extraServiceCooSertificateRenew === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  extraServiceCraneHandling: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      !isCanCraneHandling(element) ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  ftlType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isFtl(element);
  },
  ftlAutoType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return (
      (!isFtl(element) && !isFtlOut(element)) || element.needTemperatureMode > 0
    );
  },
  ftlOverloadType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return (!isFtl(element) && !isFtlOut(element)) || element.ftlType === 10;
  },
  modalType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return element.type !== 40;
  },
  senderTransportType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return element.type !== 50;
  },
  aviaGoodsLoadingType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isAviaOut(element);
  },
  railwayType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !needRailwayType(element);
  },
  preciousMetals: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !needPreciousMetals(element);
  },
  tnvedCodes: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !needTnved(element);
  },
  tnvedCodesOperational: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !needTnved(element);
  },
  senderLoadDate: (props: FieldVisibilityParams) => {
    const { element } = props;
    return element.type !== 50;
  },
  senderUnLoadDate: (props: FieldVisibilityParams) => {
    const { element } = props;
    return element.type !== 50;
  },
  seller: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return calculationPurpose(element, parentElement) === 10;
  },
  loadAddress: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) === 10 || element.type === 50
    );
  },
  loadAddressAdditional: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) === 10 || element.type === 50
    );
  },
  loadAddressAdditional2: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) === 10 || element.type === 50
    );
  },
  contact: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return calculationPurpose(element, parentElement) === 10;
  },
  referenceNumber: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) === 10 ||
      (calculationPurpose(element, parentElement) === 10 && element.type === 50)
    );
  },
  loadDate: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return calculationPurpose(element, parentElement) === 10;
  },
  commentProcessing: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  'tagCloud:commentProcessing:AppPluginsTagCloudCommentProcessing': (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 10 ||
      (calculationPurpose(element, parentElement) === 30 && !isLogistic(user))
    );
  },
  // cargoDescription: (props: FieldVisibilityParams) => {
  //   const { element, parentElement } = props;
  //   return calculationPurpose(element, parentElement) === 10;
  // },
  loadingCountry: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) !== 10 || element.type === 50
    );
  },
  loadingCountryRegion: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) !== 10 || element.type === 50
    );
  },
  loadingExactAddress: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return (
      calculationPurpose(element, parentElement) !== 10 || element.type === 50
    );
  },
  places: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  dimensions: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  weight: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  adrClass: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  notesLogistic: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  cargoStatus: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  outgoingDocumentsChange: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  outgoingDocumentsChangeType: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return element.outgoingDocumentsChange === 0;
  },
  outgoingDocumentsChangePurpose: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return element.outgoingDocumentsChange === 0;
  },
  cargoValue: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  cargoValueCurrency: (props: FieldVisibilityParams) => {
    const { element, parentElement, user } = props;
    return (
      calculationPurpose(element, parentElement) === 30 && !isLogistic(user)
    );
  },
  extraServicesLabel: (props: FieldVisibilityParams) => {
    const { element, parentElement } = props;
    return calculationPurpose(element, parentElement) === 30;
  },
  seaContainerType: (props: FieldVisibilityParams) => {
    const { element } = props;
    return !isSeaIn(element);
  },
};
