import React, { Fragment } from "react";

import CargoLoadingCalculationTotalWithCurrency from "../../../_generated/_custom/models-cache-data/CargoLoadingCalculation/CargoLoadingCalculationTotalWithCurrency";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { checkUserAdmin, checkUserLogisticFtl } from '../../../_generated/_custom/config/NaeSPermissions';
import { useCargoLoadingCalculationHookNae } from '../../../_generated/_custom/hooks/useCargoLoadingCalculationHookNae';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

interface Props extends PropsId {
  canEdit: boolean;
}

export default function CargoLogisticCalculatesLine(props: Props) {
  let canEdit = props.canEdit;
  const element = useCargoLoadingCalculationHookNae(props.id);
  const cargo = useCargoHookNae(element && element.cargo ? element.cargo.id : -1);

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isFtl = checkUserLogisticFtl(userState);

  if (!element || !cargo) {
    return <Fragment />
  }

  const isFtlHelper = userState.ftlHelper === 10 && cargo.typeIn === 'ftl';

  const isCreator = userState.id === element.creator.id || isAdmin;
  if (!isCreator) {
    canEdit = false;
  }

  return (
    <Fragment>
      <div className={"text-sm bg-slate-50 p-1 border border-slate-100 rounded-md"}>
        <div className={"flex gap-2"}>
          <div className={"flex-grow"}>

            <DfValueView path="cargo-loading-calculation.serialNumber" id={element.id} link={isCreator} />
          </div>
          {isCreator &&
            <div className="whitespace-nowrap">
              <CargoLoadingCalculationTotalWithCurrency id={element.id} />
            </div>
          }
          {canEdit && (
            <div>
              <button onClick={() => SFSOpenEditModalWindowProps({
                id: element.id, schema: NaeSSchemaMap.CargoLoadingCalculation.schema, onSaveCallback: (_el, back) => {
                  back();
                }
              })}>
                <i className="fad fa-edit" />
              </button>
            </div>
          )}
        </div>
        {isCreator &&
          <div className="flex gap-1 flex-wrap">
            <DfValueView path="cargo-loading-calculation.client.name" id={element.id} />

            <span className="text-gray-400">
              <DfValueView path="cargo-loading-calculation.contact.fullName" id={element.id} />
            </span>
          </div>
        }
        {isFtlHelper &&
          <p><DfValueView path="cargo-loading-calculation.creator.fullName" id={element.id} /></p>
        }
        {(isAdmin) && (
          <p>
            <DfValueView path="cargo-loading-calculation.createdAt" id={element.id} />
          </p>
        )}
      </div>

    </Fragment >
  );
}
