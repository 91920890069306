import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useLoadAddressHookNae } from '../../hooks/useLoadAddressHookNae';

export default function LoadAddressStockWorkTime(props: PropsId) {
    const element = useLoadAddressHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            {element.stockWorkTime}
        </Fragment>
    )
}
