import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { Fragment, useState } from "react";
import { useCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useCarriersOrderHookNae";
import classNames from "classnames";

type PoLoadingDatesChangeHistoryBtnProps = {
  ids: number[],
  asIcon?: boolean
}

export const PoLoadingDatesChangeHistoryBtn = (props: PoLoadingDatesChangeHistoryBtnProps) => {
  const [datesPopup, setDatesPopup] = useState(false);
  const carriersOrder = useCarriersOrderHookNae(props.ids.length > 0 ? props.ids[0] : -1)

  const onLoadLoadingDatesHistory = (e: any) => {
    e.preventDefault();
    setDatesPopup(true);
  }

  const isDates = carriersOrder && carriersOrder.dateChangesCount > 0;

  return (
    <Fragment>

      <a href="#" title="Loading dates change history" className="text-purple-500 underline hover:no-underline" onClick={onLoadLoadingDatesHistory}>
        {props.asIcon ? <i className={classNames(
          "fa fa-rectangle-history",
          { 'fa-light': !isDates },
          { 'fa-solid': isDates },
        )} /> : `Loading dates change history${isDates ? ` (${carriersOrder.dateChangesCount})` : ''}`}
      </a>

      {props.ids.length > 0 &&
        <PoLoadingDatesChangeHistoryPopup
          ids={props.ids}
          visible={datesPopup}
          onClose={() => setDatesPopup(false)}
        />
      }
    </Fragment>
  )
}

type PoLoadingDatesChangeHistoryPopupProps = {
  visible: boolean,
  onClose: () => void,
  ids: number[]
}

export const PoLoadingDatesChangeHistoryPopup = (props: PoLoadingDatesChangeHistoryPopupProps) => {
  return <Popup title="Loading dates change history" onClick={props.onClose} isPopup={props.visible}>
    <TemplatesLoader
      templateName="PageInlineList"
      data={{
        schema: "carriers-order-load-date-change",
        type: "main-popup",
        extraFilters: [{
          "and": [
            ['i.carriersOrder.id', 'in', props.ids, true]
          ]
        }]
      }}
    />
  </Popup>
}