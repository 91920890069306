import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CalculationSerialNumberWithLinkAndNotes from '../Calculation/CalculationSerialNumberWithLinkAndNotes';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

interface Props extends PropsId {
    hideLink?: boolean
}

export default function CargoCalculationSerialNumberWithLinkAndNotes(props: Props) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.calculation) {
        return <Fragment />
    }
    return (
        <CalculationSerialNumberWithLinkAndNotes id={element.calculation.id} hideLink={props.hideLink} />
    )
}
