import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useClientHookNae } from '../../hooks/useClientHookNae';
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { useClientStatusHookNae } from '../../hooks/useClientStatusHookNae';

type Props = {
    short?: boolean,
} & PropsId

export default function ClientStatusLogist(props: Props) {
    const element = useClientHookNae(props.id);
    const clientStatus = useClientStatusHookNae(element && element.clientStatus?element.clientStatus.id:-1);
    if (!element) {
        return <Fragment />
    }
    if (!clientStatus) {
        return <Fragment />
    }
    return (
        <StatusWidget
            // @ts-ignore
            color={clientStatus.colorLogist}
            title={clientStatus.titleLogist}
        >
            {props.short ? clientStatus.titleLogist[0] : clientStatus.titleLogist}
        </StatusWidget>
    )
}
