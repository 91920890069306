import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorInvoiceIncomingSepaExportNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IInvoiceIncomingSepaExportModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      attachments: any,
      updatedAt: string,
  }

export const IInvoiceIncomingSepaExportFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "attachments",
    "updatedAt"
];

export function useInvoiceIncomingSepaExportHookNae(id: number) : IInvoiceIncomingSepaExportModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorInvoiceIncomingSepaExportNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "invoice-incoming-sepa-export",
        fields: IInvoiceIncomingSepaExportFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
