import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae'
import { useDfValue } from '@newageerp/v3.app.data-cache-provider';

export default function CargoSellerDidEx(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const cargoStatusName = useDfValue({
        path: 'cargo.cargoStatus.code',
        id: props.id
    })

    if (!element) {
        return <Fragment />
    }

    const isT1 = !!cargoStatusName && cargoStatusName.indexOf('T1') >= 0;


    return (
        <p>
            {isT1 ? <span>T1</span> : <Fragment>
                {element.senderMakeEx === 20 && <span className="text-red-500">Warehouse</span>}
                {element.senderMakeEx === 40 && <span className="text-red-500">Remotely</span>}

                {element.senderMakeEx === 10 && <span className="text-green-500">Consignor</span>}
                {element.senderMakeEx === 30 && <span className="text-green-500">Partners</span>}

            </Fragment>}

        </p>
    )
}
