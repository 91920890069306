import React, { Fragment } from 'react'
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import KzFtlCalculations from './KzFtlCalculations';

export default function KzFtlCalculationsOrClient(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.client ? <Fragment>
                <p>
                    <DfValueView path="kz-carriers-order.client.name" id={element.id} />
                </p>
                <p>
                    <DfValueView path="kz-carriers-order.contact.fullName"
                        id={element.id}
                    />
                </p>
            </Fragment> : <KzFtlCalculations id={element.id} />}
        </Fragment>

    )
}
