import React from 'react'

import CargoSerialNumberWithLinkAndIcons from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndIcons';
import CargoOutgoingDocumentsChangeBadge from '../../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeBadge';
import { useTranslation } from 'react-i18next';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import OldFilesWidget from '../../../../core/old-ui/OldFilesWidget';
import { Table, Td, Th } from '@newageerp/v3.table.table';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserHeadOfLogisticsHelper } from '../../../../_generated/_custom/config/NaeSPermissions';

interface IData {
    sellerJson: {
        name: string,
    },
    id: number,
    serialNumber: string,
    status: number,
    outgoingDocumentsChange: number,
    name: string,
    needIE599File: boolean,
}

interface Props {
    data: IData[];
}

export default function TableFiles(props: Props) {
    const { data } = props;
    const { t } = useTranslation();

    const {userState} = useTemplatesCore()
    const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);

    return (
        <Table
            thead={
                <thead>
                    <tr>
                        <Th>{t('Cargo')}</Th>
                        {/* <th>{t('Cargo description')}</th>
                    <th>{t('Seller')}</th> */}
                        <Th className={"w-1/5"}>{t('Waybill (for client)')}</Th>
                        <Th className={"w-1/5"}>{t('EX / T1')}</Th>
                        <Th className={"w-1/5"}>{t('IE599')}</Th>
                        <Th className={"w-1/5"}>{t('Waybill (after delivery)')}</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {data.map((cargo: IData) => {
                        return (
                            <tr key={"cargo-rel-files-" + cargo.id}>
                                <Td className={"whitespace-nowrap max-w-[400px]"}>
                                    <CargoSerialNumberWithLinkAndIcons id={cargo.id} />
                                    <CargoOutgoingDocumentsChangeBadge id={cargo.id} size={"sm"} className={""} /><br />

                                    <p><DfValueView path="cargo.name" id={cargo.id} /></p>
                                    <p><DfValueView path="cargo.seller.name" id={cargo.id} /></p>
                                </Td>
                                <Td>
                                    <OldFilesWidget
                                        schema={"cargo"}
                                        element={cargo}
                                        options={{
                                            type: 60,
                                            skipHeader: true,
                                            readOnly: isTrackingSpecialist,
                                        }}
                                    />
                                </Td>
                                <Td>
                                    <OldFilesWidget
                                        schema={"cargo"}
                                        element={cargo}
                                        options={{
                                            type: 20,
                                            skipHeader: true,
                                            readOnly: isTrackingSpecialist,
                                        }}
                                    />
                                </Td>
                                <Td>
                                    {cargo.needIE599File &&
                                        <OldFilesWidget
                                            schema={"cargo"}
                                            element={cargo}
                                            options={{
                                                type: "IE599",
                                                skipHeader: true,
                                                readOnly: isTrackingSpecialist,
                                            }}
                                        />
                                    }
                                </Td>
                                <Td>
                                    <OldFilesWidget
                                        schema={"cargo"}
                                        element={cargo}
                                        options={{
                                            type: 70,
                                            skipHeader: true,
                                            readOnly: isTrackingSpecialist,
                                        }}
                                    />
                                </Td>
                            </tr>
                        );
                    })}
                </tbody>
            }
        />

    )
}
