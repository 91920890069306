import React, {Fragment} from 'react'
import { useTranslation } from 'react-i18next';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

export default function DebtBlock(props: PropsId) {
    const { t } = useTranslation();

    const element = useSalesOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    return (
        <div className='space-y-2'>
            <div>{t("Debt, EUR")}</div>
            <p className='text-3xl'>{element.debt.toFixed(2)}</p>
        </div>
    )
}
