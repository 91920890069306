import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import KzDatesBlock from './KzDatesBlock';
import CargoLoadingFormComment from './CargoLoadingFormComment';
import PoDatesBlock from './PoDatesBlock';
import CargoStatusComponent from './CargoStatusComponent';
import CargoFtlAutoTypeBadge from '../../../_generated/badges/cargo/CargoFtlAutoTypeBadge';
import { getPropertyEnumLabel } from '../../../_generated/v3/utils';
import CargoSeaContainerTypeBadge from '../../../_generated/badges/cargo/CargoSeaContainerTypeBadge';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoFromSellerBlock(props: PropsId) {

    const { t } = useTranslation();
    const element = useCargoHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    let refFromSeller = "";
    if (element.needTemperatureMode === 10) {
        refFromSeller = "REF: " + element.temperatureSettings;
    } else if (element.needTemperatureMode === 20) {
        refFromSeller = "REF: " + element.temperatureSettings;
    } else if (element.needTemperatureMode === 30) {
    }

    let fromSellerIcon = '';
    if (element.typeIn === "ltl") {
        fromSellerIcon = 'truck';
    } else if (element.typeIn === "ftl") {
        fromSellerIcon = 'truck-moving';
    } else if (element.typeIn === "avia") {
        fromSellerIcon = 'plane';
    } else if (element.typeIn === "sea") {
        fromSellerIcon = 'ship';
    } else if (element.typeIn === "train") {
        fromSellerIcon = 'train';
    } else if (element.typeIn === "none") {
        fromSellerIcon = 'ban';
    }

    let ftlAutoTypeIn = "";
    if (element.typeIn === "ftl") {
        ftlAutoTypeIn = getPropertyEnumLabel(
            "cargo",
            "ftlAutoType",
            element.ftlAutoType
        );
    }

    return (
        <Fragment>
            {!!fromSellerIcon && (
                <div className={"mb-1 text-center"}>
                    <i className={`fad fa-${fromSellerIcon} fa-fw text-4xl text-nsecondary`} />
                </div>
            )}
            {element.typeIn === "ftl" && (
                <CargoFtlAutoTypeBadge id={element.id} isCompact={true} />
            )}
            {element.typeIn === 'sea' && <CargoSeaContainerTypeBadge id={element.id} isCompact={true} />}
            {(!element.needTerminal) && <CargoStatusComponent id={element.id} />}
            {!!refFromSeller && (
                <StatusWidget smallPadding={true} color={"orange"}>{refFromSeller}</StatusWidget>
            )}
            {element.needTerminal ? (
                <Fragment>
                    {element.carriersOrders.length > 0 && <PoDatesBlock id={element.id} />}
                </Fragment>
            ) : (
                <Fragment>
                    {!!element.kzCarriersOrder && <KzDatesBlock id={element.id} />}
                </Fragment>
            )}
            {!element.needTerminal && <CargoLoadingFormComment id={element.id} />}
        </Fragment>
    )
}
