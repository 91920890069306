import React, { Fragment } from 'react'
import { PropsId } from '../types';
import SalesOrderUnloadAddressShortAddress from '../SalesOrder/SalesOrderUnloadAddressShortAddress';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoSalesOrderUnloadAddressShortAddress(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    if (!element.salesOrder) {
        return <Fragment/>
    }
    return (
        <SalesOrderUnloadAddressShortAddress id={element.salesOrder.id}/>
    )
}
