import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CargoLogisticCalculates from "../../cargo/CargoLogisticCalculates";
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'

import NotesLine from "../../../UserComponents/Notes/NotesLine";
import CargoIsAdrBadge from "../../../_generated/badges/cargo/CargoIsAdrBadge";
import CargoIsAlcoBadge from "../../../_generated/badges/cargo/CargoIsAlcoBadge";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import CargoCalculationReturnLogistic from "../../../UserComponents/Calculation/Components/CargoCalculationReturnLogistic";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getPropertyEnumLabel } from "../../../_generated/v3/utils";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { checkUserLogisticSea, checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import ClientStatusLogist from "../../../_generated/_custom/models-cache-data/Client/ClientStatusLogist";
import CargoSalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName";


let timeoutCalcCargoReloadDashboard: any = -1;

interface IData {
  id: number;

  expeditorCalculationOut: {
    id: number,
  }

  calculationJson: {
    id: number;
    purpose: number;
    calcedPurpose: number;
    creatorFullName: string;
    status5LastChange: string;
  };
  soUnloadAddressJson: {
    fullAddress: string;
  };
  terminalJson: {
    fullAddress: string;
  };
  seller: {
    name: string;
  };
  beforeChange: any;

  serialNumber: string;

  loadingAddress: string;
  loadingAddressFullWoSeller: string;

  ftlAutoType: number;
  typeIn: number;
  typeOut: number;
  notesLogistic: string;
  notesOperations: string;

  soClientId: number;
  soCalcClientId: number;
}

const defObject: IData = {
  id: 0,
  expeditorCalculationOut: {
    id: 0,
  },
  calculationJson: {
    id: 0,
    purpose: 0,
    calcedPurpose: 0,
    creatorFullName: "",
    status5LastChange: "",
  },
  soUnloadAddressJson: {
    fullAddress: "",
  },
  terminalJson: {
    fullAddress: "",
  },
  seller: {
    name: "",
  },
  beforeChange: "",

  serialNumber: "",

  loadingAddress: "",
  loadingAddressFullWoSeller: "",

  ftlAutoType: 0,
  typeIn: 0,
  typeOut: 0,
  notesLogistic: "",
  notesOperations: "",

  soClientId: 0,
  soCalcClientId: 0,
};

type Props = {
  userId: number,
}

export default function LogisticCalculation(props: Props) {
  const { t } = useTranslation();

  const { userState } = useTemplatesCore()
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isLogisticSea = checkUserLogisticSea(userState);

  const [getData, getDataParams] = useUList<IData>(
    "cargo",
    getKeysFromObject(defObject)
  );

  const loadData = () => {
    getData(
      [
        {
          and: [
            {
              "or": [
                {
                  "and": [
                    ["i.expeditorCalculation", "=", props.userId, true],
                    ["i.loadingPriceCalculated", "=", false, true],
                  ]
                },
                {
                  "and": [
                    ["i.expeditorCalculationOut", "=", props.userId, true],
                    ["i.loadingPriceCalculatedOut", "=", false, true],
                  ]
                }
              ]
            },
            ["i.calculation.status", ">=", 5, true],
            ["i.status", "!=", 900, true],
            {
              'or': [
                ["i.type", "!=", 50, true],
                {
                  "and": [
                    ["i.type", "=", 50, true],
                    ["i.typeOut", "=", 'avia', true]
                  ]
                }
              ]
            },

          ],
        },
      ],
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(timeoutCalcCargoReloadDashboard);
    timeoutCalcCargoReloadDashboard = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("calc-cargo-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("calc-cargo-dashboard-" + userState.id);
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "calc-cargo-reload-dashboard",
      "calc-cargo-reload-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calc-cargo-reload-dashboard",
        "calc-cargo-reload-dashboard"
      );
    };
  }, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  return (
    <WhiteCard isCompact={true} title="Need quotation">
      {isData && (
        <Table
          thead={
            <thead>
              <tr>
                <Th>Cargo</Th>
                {isLogisticSea && <Fragment><Th>Client</Th><Th></Th></Fragment>}
                <Th className={"w-[20%]"}>Loading</Th>
                <Th className={"w-[15%]"}>Unloading</Th>
                {(isLogisticTrain || isLogisticSea) && <Th>Type of transportation</Th>}
                <Th className={"w-[20%]"}>Notes</Th>
                <Th></Th>
                <Th className={"w-[280px]"}>Carrier, Price</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {getDataParams.data.data
                .sort((a: any, b: any) => {
                  const p1 = a.calculationJson.purpose;
                  const p2 = b.calculationJson.purpose;

                  const x = !!a.beforeChange ? 0 : 1;
                  const y = !!b.beforeChange ? 0 : 1;

                  return p1 > p2
                    ? -1
                    : p1 < p2
                      ? 1
                      : x < y
                        ? -1
                        : x > y
                          ? 1
                          : 0;
                })
                .map((cargo: IData, index: number) => {
                  if (!!cargo.expeditorCalculationOut && userState.id === cargo.expeditorCalculationOut.id) {
                    return <CalculationOutLine cargo={cargo} key={"cargo-el-" + cargo.id} />
                  }
                  return <DefaultLine cargo={cargo} key={"cargo-el-" + cargo.id} />
                })}
            </tbody>
          }
        />
      )}
    </WhiteCard>
  );
}

type DefaultLineProps = {
  cargo: IData
}


export const DefaultLine = (props: DefaultLineProps) => {
  const { userState } = useTemplatesCore()
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isLogisticSea = checkUserLogisticSea(userState);

  const { cargo } = props;

  const calculation = cargo.calculationJson;
  const unloadAddress = cargo.soUnloadAddressJson;
  const terminal = cargo.terminalJson;

  const className = [];
  if (!!cargo.beforeChange) {
    className.push("bg-yellow-100");
  }
  if (!!calculation && calculation.purpose === 30) {
    className.push("border-bottom-2-warning");
  }
  if (!!calculation && calculation.calcedPurpose > 0) {
    className.push("border-bottom-2-warning");
  }
  return (
    <tr

      className={className.join(" ")}
    >
      <Td>

        <DfValueView id={cargo.id} path={"cargo.serialNumber"} link={true} />

        {!!calculation && (
          <p>
            <DfValueView path="calculation.purpose" id={calculation.id} />
          </p>
        )}
        <p>{calculation.creatorFullName}</p>
        <p>{calculation.status5LastChange}</p>
        <NotesLine
          parentId={cargo.id}
          parentSchema={"cargo"}
        />
      </Td>

      {isLogisticSea && <Fragment>
        <Td>
          <CargoSalesOrderClientOrFilialName id={cargo.id} />
        </Td>
        <Td><ClientStatusLogist id={cargo.soCalcClientId} /></Td>

      </Fragment>}

      <Td>
        {calculation.purpose === 10 ? (
          cargo.loadingAddress
        ) : (
          <Fragment>
            <strong>
              {!!cargo.seller && cargo.seller.name}
            </strong>
            <br />
            {cargo.loadingAddressFullWoSeller}
          </Fragment>
        )}
      </Td>
      <Td>
        {!!terminal
          ? terminal.fullAddress
          : unloadAddress
            ? unloadAddress.fullAddress
            : ""}
      </Td>

      {(isLogisticTrain || isLogisticSea) && (
        <Td>
          {getPropertyEnumLabel(
            "cargo",
            "typeIn",
            cargo.typeIn
          )}
        </Td>
      )}

      <Td>
        {nl2p(cargo.notesLogistic, "notes-" + cargo.id)}
        <div className="flex gap-2 flex-wrap">
          <CargoIsAdrBadge id={cargo.id} isCompact={true} />
          <CargoIsAlcoBadge id={cargo.id} isCompact={true} />
        </div>
      </Td>
      <Td className="whitespace-nowrap">
        <CargoCalculationReturnLogistic id={cargo.id} />
      </Td>
      <Td className={"w-[250px]"}>
        <CargoLogisticCalculates cargoId={cargo.id} calcType="in" />
      </Td>
    </tr>
  );
}


export const CalculationOutLine = (props: DefaultLineProps) => {
  const { userState } = useTemplatesCore()
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isLogisticSea = checkUserLogisticSea(userState);

  const { cargo } = props;

  const calculation = cargo.calculationJson;
  const unloadAddress = cargo.soUnloadAddressJson;
  const terminal = cargo.terminalJson;

  const className = [];
  if (!!cargo.beforeChange) {
    className.push("bg-yellow-100");
  }
  if (!!calculation && calculation.purpose === 30) {
    className.push("border-bottom-2-warning");
  }
  if (!!calculation && calculation.calcedPurpose > 0) {
    className.push("border-bottom-2-warning");
  }
  return (
    <tr

      className={className.join(" ")}
    >
      <Td>

        <DfValueView id={cargo.id} path={"cargo.serialNumber"} link={true} />

        {!!calculation && (
          <p>
            <DfValueView path="calculation.purpose" id={calculation.id} />
          </p>
        )}
        <p>{calculation.creatorFullName}</p>
        <p>{calculation.status5LastChange}</p>
        <NotesLine
          parentId={cargo.id}
          parentSchema={"cargo"}
        />
      </Td>

      {isLogisticSea && <Fragment>
        <Td>
          <CargoSalesOrderClientOrFilialName id={cargo.id} />
        </Td>
        <Td><ClientStatusLogist id={cargo.soCalcClientId} /></Td>

      </Fragment>}

      {/* <Td>
        {calculation.purpose === 10 ? (
          cargo.loadingAddress
        ) : (
          <Fragment>
            <strong>
              {!!cargo.seller && cargo.seller.name}
            </strong>
            <br />
            {cargo.loadingAddressFullWoSeller}
          </Fragment>
        )}
      </Td> */}
      <Td>
        {!!terminal
          ? terminal.fullAddress
          : ""}
      </Td>
      <Td>
        {unloadAddress
          ? unloadAddress.fullAddress
          : ""}
      </Td>

      {(isLogisticTrain || isLogisticSea) && (
        <Td>
          {getPropertyEnumLabel(
            "cargo",
            "typeOut",
            cargo.typeOut
          )}
        </Td>
      )}

      <Td>
        {nl2p(cargo.notesOperations, "notes-" + cargo.id)}
        <div className="flex gap-2 flex-wrap">
          <CargoIsAdrBadge id={cargo.id} isCompact={true} />
          <CargoIsAlcoBadge id={cargo.id} isCompact={true} />
        </div>
      </Td>
      <Td className="whitespace-nowrap">
        <CargoCalculationReturnLogistic id={cargo.id} />
      </Td>
      <Td className={"w-[250px]"}>
        <CargoLogisticCalculates cargoId={cargo.id} calcType="out" />
      </Td>
    </tr>
  );
}