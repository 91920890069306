import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import React, { Fragment, useState } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle'


export default function CargoDocumentsCollected(props: PropsId) {
    const [showPopup, setShowPopup] = useState(false);
    const [saveReq, doSaveParams] = useUSave("cargo");
    const doSave = async (s: number) => {
        await saveReq({
            cargoDocumentsCollected: s
        }, props.id);
        setShowPopup(false);
    }

    return (
        <Fragment>
            <MenuItem iconName='badge-check' onClick={() => setShowPopup(true)}>
                Cargo Documents
            </MenuItem>

            <Popup isPopup={showPopup} onClick={() => setShowPopup(false)} title="Cargo Documents">
                <div className='space-y-4'>
                    <MainButton className='w-full' color='red' onClick={() => doSave(0)}>Documents were not collected</MainButton>
                    <MainButton color='teal' className='w-full' onClick={() => doSave(10)}>Documents were collected</MainButton>
                </div>
            </Popup>

        </Fragment>
    )
}
