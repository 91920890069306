import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import KzLoadDateWithChanges from '../../../UserComponents/KzCarriersOrder/Components/KzLoadDateWithChanges';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";

export default function KzDatesBlock(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const kzCarriersOrder = useKzCarriersOrderHookNae(element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1);

    const { t } = useTranslation();

    if (!element || !kzCarriersOrder) {
        return <Fragment />;
    }

    return (
        <div className={"space-y-1 mt-2"}>
            {kzCarriersOrder.loadDate &&

                <div>
                    <KzLoadDateWithChanges
                        status={kzCarriersOrder.status}
                        loadDate={kzCarriersOrder.loadDate}
                        status15Date={kzCarriersOrder.status15Date}
                        id={kzCarriersOrder.id}
                        extraContent={<Tooltip text={element.needTerminal ? t("Pakrovimo data (iš sandėlio)") : t("Pakrovimo data (pas pardavėją)")} className={"mr-1"} />}
                    />
                </div>
            }
            {kzCarriersOrder.unloadDate && kzCarriersOrder.status > 0 &&
                <div>
                    <Tooltip text={t("Iškrovimo data (pas klientą)")} className={"mr-1"} />

                    <i className={"text-lg fa-fw fad fa-ramp-loading mr-1"} />

                    {moment(kzCarriersOrder.unloadDate).format(
                        "YYYY-MM-DD"
                    )}

                </div>
            }
            <div className="mt-2">
                <p className="font-semibold">{t("Vehicle number / Partners name")}</p>
                <p>{kzCarriersOrder.carNumber ? kzCarriersOrder.carNumber : '-'}</p>
            </div>
        </div>
    )
}
