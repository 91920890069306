import { FieldSelect } from "@newageerp/v3.bundles.form-bundle";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import FollowUpLeads from "../../../../../UserComponents/Followup/FollowUpLeads";
import {MainToolbarTitle} from '@newageerp/v3.bundles.layout-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

interface Props {
  hideToolbar?: boolean;
  customTitle?: string;
}

export default function FollowUpContent(props: Props) {
  const { t } = useTranslation();
  const { userState } = useTemplatesCore();

  const variants = [
    t('Today'),
    t('Tomorrow'),
    t('Upcoming 7 days'),
    t('Upcoming 30 days'),
    t('Past'),
    t('All'),
  ];
  const [activeState, setActiveState] = useState(0);

  const title = t("Reminders");

  return (
    <Fragment>
      <MainToolbarTitle title={title} />
      <div className="space-y-2">
        {!props.hideToolbar && (

          <FieldSelect
            value={activeState}
            onChange={setActiveState}
            className={"w-56"}
            options={
              variants.map((variant: string, index: number) => {
                return {
                  value: index,
                  label: variant
                }

              })
            }
          />
        )}

        <FollowUpLeads type={activeState} userId={userState.id} />
      </div>
    </Fragment>
  );
}
