import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoCustomsCode(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.customsCode}
        </Fragment>
    )
}
