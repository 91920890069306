import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { defaultStrippedRowClassName } from "../../../../core/old-ui/OldTrow";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { useCargoHookNae } from "../../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";

interface Props {
  data: IData[];
}

interface IData {
  salesOrder: {
    id: number,
    isContactDataOk: boolean,
    confirmCancelText: string,
    serialNumber: string,
    status: number,
    hasLoadedCargo: boolean,
    files: any,
    isNeedInvoiceFile: boolean,
    isNeedContractFile: boolean,
  },
  id: number,
  serialNumber: string,
  kzCarriersOrder: {
    id: number,
    status: number,
  },
  status: number,
  files: any,
  outgoingDocumentsChange: number,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  },
  beforeChange: any,
  soClientJson: {
    id: number,
    name: string,
    filialName: string,
  },
  kzCarriersOrderJson: {
    id: number,
    creatorFullName: string,
    carNumber: string,
    driver: string,
    loadDate: string,
    status20Date: string,
  },
}


export default function ManagerOrderOnKz(props: Props) {
  const { t } = useTranslation();

  const groupedData = groupMap(
    props.data.sort((a, b) => {
      const aDateStr = a.kzCarriersOrderJson.status20Date;
      const bDateStr = b.kzCarriersOrderJson.status20Date;

      var x = aDateStr.toLowerCase();
      var y = bDateStr.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    }),
    (el: IData) => el.kzCarriersOrderJson.id + "-" + el.salesOrder.id
  )
  const soIds = Object.keys(groupedData)

  return (
    <WhiteCard isCompact={true} title={t("Arrived for unloading")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Order')}</Th>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Responsible')}</Th>
              <Th>{t('Transport')}</Th>
            </tr>
          </thead>}
        tbody={
          <tbody>
            {soIds.map((soId: string, soIndex: number) => {
              const cargos = groupedData[soId];
              // const rowClassName = defaultStrippedRowClassName(soIndex);

              return (
                <Fragment key={"so-row-" + soId}>
                  {cargos.map((c: IData, cargoIndex: number) => {
                    return <Line c={c} cargoIndex={cargoIndex} cargos={cargos} key={"order-" + c.id} />
                  })}
                </Fragment>
              )
            })}



          </tbody>
        }
      />

    </WhiteCard>
  );
}

const Line = (props: { c: IData, cargoIndex: number, rowClassName?: string, cargos: IData[] }) => {
  const { c, cargoIndex, rowClassName, cargos } = props;

  const element = useCargoHookNae(c.id);
  const kz = useKzCarriersOrderHookNae(element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1)
  if (!element) {
    return <Fragment />
  }
  if (!kz) {
    return <Fragment />
  }

  const kzO = c.kzCarriersOrderJson;
  return (
    <tr className={"shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)]"}>
      {cargoIndex === 0 &&
        <Td rowSpan={cargos.length}>
          <SalesOrderDashboardLink
            element={c.salesOrder}

          />
        </Td>
      }
      <Td>
        <SalesOrderDashboardLink
          element={c.salesOrder}
          cargo={c}

        />
      </Td>
      {cargoIndex === 0 &&
        <Td rowSpan={cargos.length}>
          <SalesOrderClientWithLinkFilialName id={c.salesOrder.id} />
        </Td>
      }
      {cargoIndex === 0 &&
        <Td rowSpan={cargos.length}>
          {kz.doer ? <DfValueView id={kz.id} path="kz-carriers-order.doer.fullName" /> : <DfValueView id={kz.id} path="kz-carriers-order.creator.fullName" />}
        </Td>
      }
      {cargoIndex === 0 &&
        <Td rowSpan={cargos.length}>
          <DfValueView path="kz-carriers-order.carNumber" id={kzO.id} />
          <hr />
          {kzO.driver}
        </Td>
      }
    </tr>
  );
}