import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae'
import { useDfValue } from '@newageerp/v3.app.data-cache-provider';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoWarehouseExOrder(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const cargoStatusName = useDfValue({
        path: 'cargo.cargoStatus.code',
        id: props.id
    })

    if (!element) {
        return <Fragment />
    }

    const isT1 = !!cargoStatusName && cargoStatusName.indexOf('T1') >= 0;

    const isShow = element.senderMakeEx === 20 || element.senderMakeEx === 40;

    if (isT1 || !isShow) {
        return <Fragment/>
    }
    if (!!element.cargoExOrder) {
        return <Fragment/>
    }


    return (
        <StatusWidget color='red' smallPadding={true}>EX not sent</StatusWidget>
    )
}
