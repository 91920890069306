import React, { Fragment } from 'react'
import ClientName from '../Client/ClientName';
import { PropsId } from '../types';
import ClientFilialName from '../ClientFilial/ClientFilialName';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderClientFilialName(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.client && <ClientName id={element.client.id} />}
            {element.clientFilial && <Fragment> (<ClientFilialName id={element.clientFilial.id} />)</Fragment>}
        </Fragment>
    )
}
