import React from "react";

import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { ToolbarButtonWithMenu } from "@newageerp/v3.bundles.buttons-bundle";
import { useTranslation } from "react-i18next";
import { SelectorCompanyNae } from "../../_generated/_custom/models/ormSelectors";
import { useSelector } from '@newageerp/v3.templates.templates-core';
import { ICompanyModelNae } from "../../_generated/_custom/hooks/useCompanyHookNae";
import { SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";

export default function SepaExport() {
  const { t } = useTranslation();
  const { data: tData } = useTemplatesLoader();

  const companies = useSelector(state => SelectorCompanyNae(state));

  const onClick = (companyId: number) => {
    axiosInstance.post('/app/plugins/SepaExport/generate', { companyId }).then(res => {
      if (res.data.id) {
        SFSOpenViewModalWindowProps({
          id: res.data.id,
          type: 'main',
          schema: NaeSSchemaMap.InvoiceIncomingSepaExport.schema
        })
      }
    })

  };
  return (
    <ToolbarButtonWithMenu
      button={{
        title: t("Bank export"),
        // onClick: onFvsClick,
        iconName: "money-bill-transfer",
      }}
      menu={{
        items: companies.map((company: ICompanyModelNae) => ({
          children: company._viewTitle,
          onClick: () => onClick(company.id)
        }))
      }}
    />
  );
}
