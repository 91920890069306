import React, { Fragment } from 'react'
import { useCargoHookNae } from '../../_custom/hooks/useCargoHookNae';
import { PropsId } from '../../_custom/models-cache-data/types';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

interface Props extends PropsId {
    isCompact?: boolean
}

export default function CargoAviaGoodsLoadingTypeBadge(props: Props) {
    const {modules} = useTemplatesCore();
    const element = useCargoHookNae(props.id);
    if (!element) {
        return (<Fragment/>);
    }

    const isShowBadge = element.badges['aviaGoodsLoadingType'];

    if (!isShowBadge) {
        return (<Fragment/>);
    }

    const badgeVariant = modules.enums.color("cargo", "aviaGoodsLoadingType", element["aviaGoodsLoadingType"]);
    const badgeContent = modules.enums.title("cargo", "aviaGoodsLoadingType", element["aviaGoodsLoadingType"]);

    return (
        <StatusWidget color={badgeVariant} smallPadding={props.isCompact}>
            {badgeContent}
        </StatusWidget>
    )
}
