import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorTyreCheckNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITyreCheckModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const ITyreCheckFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useTyreCheckHookNae(id: number) : ITyreCheckModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTyreCheckNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "tyre-check",
        fields: ITyreCheckFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
