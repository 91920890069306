import React, { Fragment, useEffect } from "react";
import { WidgetFilesProps } from "../../../core/components/widgets/NEWidgetFiles";
import DocumentsPopupFileItem from "./DocumentsPopupFileItem";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

interface Props extends WidgetFilesProps {
  activePath: string;
  onSelect: (f: any) => void;
  title: string;

  showCheck?: boolean;
  checkedFiles?: any[];

  data?: any[]
}

export default function CargoDocumentsFilesList(props: Props) {
  const [getData, getDataParams] = useURequest(NaePathsMap.post["NAEfilesList"]);
  const type = props.options.type ? props.options.type : 10;
  const folder = props.schema + "/" + props.element.id + "/" + type;
  const hideOnEmpty = props.options.hideOnEmpty
    ? props.options.hideOnEmpty
    : false;

  const loadData = () => {
    if (!props.data) {
      getData({ folder });
    }
  };
  useEffect(loadData, [props.schema, props.element.id, props.data]);

  const title = (t: string) => <p className={"font-medium text-sm"}>{t}</p>

  const _data = (props.data !== undefined ? props.data : getDataParams.data.data).filter((f: any) => !f.deleted);

  const isData = !!_data && _data.length > 0;

  if (!isData) {
    if (hideOnEmpty) {
      return <Fragment />;
    } else {
      return (
        <div className={"text-base space-y-1"}>
          {title(props.title)}
        </div>
      );
    }
  }

  const checkedFiles = props.checkedFiles ? props.checkedFiles : [];

  const onClick = (f: any) => {
    props.onSelect(f);
  }

  return (
    <Fragment>
      <div className={"text-base space-y-1"}>
        {title(props.title)}
        {_data
          .map((f: any) => {
            const isChecked = props.showCheck ? checkedFiles.filter(_f => _f.id === f.id).length > 0 : props.activePath === f.path;

            return (
              <DocumentsPopupFileItem checked={isChecked} f={f} onClick={onClick} showCheck={props.showCheck} key={"f-" + f.id} />
            );
          })}
      </div>
    </Fragment>
  );
}
