import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import React, { Fragment } from 'react'
import { NaeSSchemaMap } from '../../../config/NaeSSchema';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';
import { useNaeRecord } from '@newageerp/v3.app.mvc.record-provider';

interface Props {

}

export default function CheckClient(props: Props) {
    const { addToQueue } = useDataCache();

    const { data: tData } = useTemplatesLoader();
    const info = tData.element.clientTrackInfo;
    const naeRecord = useNaeRecord();
    const [doSave] = useUSave("client-tracking")

    const onCreate = () => {
        SFSOpenEditModalWindowProps({
            id: 'new',
            schema: "client-tracking",
            type: 'client',
            options: {
                createOptions: {
                    convert: {
                        element: tData.element,
                        schema: "sales-order-tracking"
                    }
                }
            },
            onSaveCallback: (_el, back) => {
                back();
                naeRecord.reloadData();
            }
        })
    }
    const onUpdate = async () => {
        await doSave({
            emails: tData.element.emails
        }, info.id)

        naeRecord.reloadData();
    }

    if (!info.hasTracking) {
        return <MainButton color='teal' onClick={onCreate}>Do you want to enable tracking of all orders for this customer?</MainButton>
    }

    if (info.hasTracking && JSON.stringify(info.emails) !== JSON.stringify(tData.element.emails)) {
        return <MainButton color="sky" onClick={onUpdate}>Do you want to update tracking of all orders for this customer?</MainButton>
    }

    return (
        <Fragment />
    )
}
