import React, { Fragment, useEffect, useState } from "react";
import ManagerOrderConfirmed from "./Components/ManagerOrderConfirmed";
import ManagerOrderDraft from "./Components/ManagerOrderDraft";
import ManagerOrderOnEuRoad from "./Components/ManagerOrderOnEuRoad";
import ManagerOrderOnKz from "./Components/ManagerOrderOnKz";
import ManagerOrderOnKzRoad from "./Components/ManagerOrderOnKzRoad";
import ManagerOrderStock from "./Components/ManagerOrderStock";
import ManagerOrderWaitingLoad from "./Components/ManagerOrderWaitingLoad";
import ManagerOrderWaitingLoadKz from "./Components/ManagerOrderWaitingLoadKz";
import { uniqueArray } from "../../../core/components/utils/mapUtils";
import SalesOrderUnpayedOrders from "./SalesOrderUnpayedOrders";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { NumberCardWidget } from '@newageerp/v3.bundles.widgets-bundle'
import { useTranslation } from 'react-i18next';
import ManagerOrdersPopup from "./Components/ManagerOrdersPopup";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";


export interface ManagerOrderIData {
  managerCardIndex: number;
  id: number;
  salesOrder: {
    id: number;
    isContactDataOk: boolean;
    confirmCancelText: string;
    serialNumber: string;
    status: number;
    totalWithCurrency: string;
    hasLoadedCargo: boolean;
    files: any;
    isNeedInvoiceFile: boolean;
    isNeedContractFile: boolean;

    tracking: {
      id: number;
    }
  };
  serialNumber: string;
  kzCarriersOrder: {
    id: number;
    status: number;
  };
  status: number;
  files: any;
  outgoingDocumentsChange: number;
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean;
  };
  beforeChange: any;
  seller: {
    name: string;
  };
  soClientJson: {
    id: number;
    name: string;
    filialName: string;
  };

  poLoadDateDiff: number;
  loadDateDiffManagerConfirmed: boolean;
  loadDateDiff: number;

  expeditor: {
    fullName: string;
  };
  loadDateSeller: string;
  loadDate: string;
  poLoadDateStr: string;
  poUnloadDateStr: string;
  contactedSeller: boolean;
  contactedSellerTime: string;

  loadDateSellerFact: string;
  unloadWarehouseDate: string;

  kzCarriersOrderJson: {
    creatorFullName: string;
    carNumber: string;
    driver: string;
    loadDate: string;
    location: string;
    locationUpdateDate: string;
    trip: string;
    locationComment: string;
    id: number;
    status15Date: string;
    status: number;
    status20Date: string;
    dateChanges: any;
  };
  activePoOrders: PropsId[],
}

const defObject: ManagerOrderIData = {
  managerCardIndex: 0,
  id: 0,
  salesOrder: {
    id: 0,
    isContactDataOk: false,
    confirmCancelText: "",
    serialNumber: "",
    status: 0,
    totalWithCurrency: "",
    hasLoadedCargo: false,
    files: "",
    isNeedInvoiceFile: false,
    isNeedContractFile: false,

    tracking: {
      id: 0
    }
  },
  serialNumber: "",
  kzCarriersOrder: {
    id: 0,
    status: 0
  },
  status: 0,
  files: "",
  outgoingDocumentsChange: 0,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: false,
  },
  beforeChange: "",
  seller: {
    name: "",
  },
  soClientJson: {
    id: 0,
    name: "",
    filialName: "",
  },
  poLoadDateDiff: 0,
  loadDateDiffManagerConfirmed: false,
  loadDateDiff: 0,

  expeditor: {
    fullName: "",
  },
  loadDateSeller: "",
  loadDate: "",
  poLoadDateStr: "",
  poUnloadDateStr: "",
  contactedSeller: false,
  contactedSellerTime: "",

  loadDateSellerFact: "",
  unloadWarehouseDate: "",

  kzCarriersOrderJson: {
    creatorFullName: "",
    carNumber: "",
    driver: "",
    loadDate: "",
    location: "",
    locationUpdateDate: "",
    trip: "",
    locationComment: "",
    id: 0,
    status15Date: "",
    status: 0,
    status20Date: "",
    dateChanges: "",
  },
  activePoOrders: [
    {
      id: 0,
    }
  ]
};

let reloadTimeout: any = -1;

type Props = {
  userId: number,
}

export default function ManagerOrderWrapper(props: Props) {
  const { t } = useTranslation();

  const [ordersPopup, setOrdersPopup] = useState<any[]>([]);

  const { userState } = useTemplatesCore()
  const [getData, getDataParams] = useUList<ManagerOrderIData>("cargo", getKeysFromObject(defObject));

  useEffect(() => {
    SocketService.subscribe("order-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("order-dashboard-" + userState.id);
    };
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "order-reload-dashboard",
      "order-reload-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "order-reload-dashboard",
        "order-reload-dashboard"
      );
    };
  }, []);

  const loadData = () => {
    getData(
      [
        {
          "and": [
            ['i.salesOrder.status', '!=', 20, true],
            ['i.salesOrder.status', '!=', 900, true],
            ['i.salesOrder.client.doer', '=', props.userId, true]
          ]
        }
      ],
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        }
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  if (!isData) {
    return <Fragment />;
  }

  const data: ManagerOrderIData[] = getDataParams.data.data;

  let notConfirmedOrders: number[] = [];
  let noInvoice: number[] = [];
  let noContract: number[] = [];
  let noTracking: number[] = [];

  data.forEach((cargo: ManagerOrderIData) => {
    if (cargo.salesOrder.hasLoadedCargo && !cargo.salesOrder.files["10"]) {
      notConfirmedOrders.push(cargo.salesOrder.id);
    }
    if (cargo.salesOrder.isNeedInvoiceFile) {
      noInvoice.push(cargo.salesOrder.id);
    }
    if (!cargo.salesOrder.tracking) {
      noTracking.push(cargo.salesOrder.id);
    }
    if (
      cargo.salesOrder.hasLoadedCargo &&
      cargo.salesOrder.isNeedContractFile
    ) {
      noContract.push(cargo.salesOrder.id);
    }
  });

  notConfirmedOrders = uniqueArray(notConfirmedOrders);
  noInvoice = uniqueArray(noInvoice);
  noContract = uniqueArray(noContract);
  noTracking = uniqueArray(noTracking);

  return (
    <Fragment>
      {!!ordersPopup && ordersPopup.length > 0 &&
        <ManagerOrdersPopup
          orders={ordersPopup}
          onClose={() => setOrdersPopup([])}
        />}
      <div className={"grid grid-cols-4 gap-6 mb-4"}>
        {noInvoice.length > 0 &&
          <NumberCardWidget onClick={() => setOrdersPopup(noInvoice)} children={noInvoice.length} title={t('Not billed')} color={"red"} />
        }
        {notConfirmedOrders.length > 0 &&
          <NumberCardWidget onClick={() => setOrdersPopup(notConfirmedOrders)} children={notConfirmedOrders.length} title={t('Unconfirmed order')} color={"orange"} />
        }
        {noContract.length > 0 &&
          <NumberCardWidget onClick={() => setOrdersPopup(noContract)} children={noContract.length} title={t('No contract')} color={"pink"} />
        }
        {noTracking.length > 0 &&
          <NumberCardWidget onClick={() => setOrdersPopup(noTracking)} children={noTracking.length} title={t('Tracking is not being sent')} color={"blue"} />
        }
      </div>

      <ManagerOrderDraft
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 1)}
      />
      <ManagerOrderConfirmed
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 2)}
      />
      <ManagerOrderWaitingLoad
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 3)}
      />
      <ManagerOrderOnEuRoad
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 4)}
      />
      <ManagerOrderStock
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 5)}
      />
      <ManagerOrderWaitingLoadKz
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 6)}
      />
      <ManagerOrderOnKzRoad
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 7)}
      />
      <ManagerOrderOnKz
        data={data.filter((el: ManagerOrderIData) => el.managerCardIndex === 8)}
      />
      {/* <TabContainer
        items={[
          {
            content: <ManagerOrderDraft
              data={data.filter((el: IData) => el.managerCardIndex === 1)}
            />,
            tab: {
              children: t("Orders being prepared")
            }
          },
          {
            content: <ManagerOrderConfirmed
              data={data.filter((el: IData) => el.managerCardIndex === 2)}
            />,
            tab: {
              children: t("Confirmed orders")
            }
          },
          {
            content: <ManagerOrderWaitingLoad
              data={data.filter((el: IData) => el.managerCardIndex === 3)}
            />,
            tab: {
              children: t("Planned for loading")
            }
          },

          {
            content: <ManagerOrderOnEuRoad
              data={data.filter((el: IData) => el.managerCardIndex === 4)}
            />,
            tab: {
              children: t("Loaded cargo")
            }
          },
          {
            content: <ManagerOrderStock
              data={data.filter((el: IData) => el.managerCardIndex === 5)}
            />,
            tab: {
              children: t("Storred cargo")
            }
          },

          {
            content: <ManagerOrderWaitingLoadKz
              data={data.filter((el: IData) => el.managerCardIndex === 6)}
            />,
            tab: {
              children: t("Planned for transit")
            }
          },

          {
            content: <ManagerOrderOnKzRoad
              data={data.filter((el: IData) => el.managerCardIndex === 7)}
            />,
            tab: {
              children: ''
            }
          },


          {
            content: <ManagerOrderOnKz
              data={data.filter((el: IData) => el.managerCardIndex === 8)}
            />,
            tab: {
              children: t("On the way to the customer")
            }
          },
        ]}
      /> */}

      <SalesOrderUnpayedOrders />
    </Fragment>
  );
}
