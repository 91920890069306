import React from 'react'
import CalculationTd from '../../../../../UserComponents/CarriersOrder/Table/CalculationTd';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function CargoCalculations(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    return (
        <CalculationTd item={element} />
    )
}
