import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainToolbarTitle, Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { ITableLocationStateDates } from '../../_generated/v3/utils';
import { FieldDateRange, FieldLabel, WideRow } from '@newageerp/v3.bundles.form-bundle'
import { FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import moment from 'moment';
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

interface IData {
    id: number,
    serialNumber: string,

    creator: {
        id: number,
        fullName: string
    },

}

const defObject: IData = {
    id: 0,
    serialNumber: '',

    creator: {
        id: 0,
        fullName: ''
    },

}

export default function IKExtraCostsReports() {
    const { t } = useTranslation();

    const dateNow = moment().format("YYYY-MM-DD");
    const defaultDates = {
        dateFrom: dateNow,
        dateTo: dateNow,
    };
    const [dates, setDates] = React.useState<ITableLocationStateDates>(defaultDates);
    const [mode, setMode] = React.useState(3);

    const [getData, getDataParams] = useUList<IData>(NaeSSchemaMap.KzCarriersOrder.schema, getKeysFromObject(defObject))
    const [getCostsData, getCostsDataParams] = useURequest(NaePathsMap.post['getKzCarriersOrdersCosts']);

    React.useEffect(() => {
        if (!dates) {
            return;
        }
        let _filter = [
            mode === 3 ?
                {
                    'and': [
                        ['i.cargos.salesOrder.finishDate', 'dgte', dates.dateFrom],
                        ['i.cargos.salesOrder.finishDate', 'dlte', dates.dateTo],
                        ['i.status', 'in', [40, 50], true]
                    ]
                } :
                (
                    mode === 1 ?
                        {
                            'and': [
                                ['i.trueUnloadDate', 'dgte', dates.dateFrom, true],
                                ['i.trueUnloadDate', 'dlte', dates.dateTo, true],
                                ['i.status', 'in', [40, 50], true]
                            ]
                        }
                        :
                        {
                            'and': [
                                ['i.unloadDate', 'dgte', dates.dateFrom, true],
                                ['i.unloadDate', 'dlte', dates.dateTo, true],
                                ['i.status', 'in', [15, 20, 30, 40, 50], true]
                            ]
                        }
                )
        ];
        getData(
            _filter,
            1,
            999999,
            // [{ 'key': 'i.creator.firstName', 'value': 'ASC' }, { 'key': 'i.creator.lastName', 'value': 'ASC' }],
        )
    }, [dates, mode]);


    React.useEffect(() => {
        if (getDataParams.data.data.length > 0) {
            const ids = getDataParams.data.data.map(el => el.id);
            getCostsData({ ids, showNegative: true });
        }
    }, [getDataParams.data.data]);

    const dataByCreator = groupMap(getDataParams.data.data, (el: IData) => el.creator.fullName)

    const costsData: any = !!getCostsDataParams && !!getCostsDataParams.data && !!getCostsDataParams.data.data ? getCostsDataParams.data.data : {};

    return (
        <div className={"space-y-2"}>
            <MainToolbarTitle title={t('Papildomų išlaidų ataskaita')} />

            <WhiteCard title={t('Dates')}>
                <FieldDateRange dates={dates} setDates={setDates} />
            </WhiteCard>
            <WhiteCard title={t('Settings')}>
                <WideRow control={<FieldSelect options={[
                    {
                        value: 1,
                        label: 'Iškrauti pagal iškrovimo datą'
                    },
                    {
                        value: 2,
                        label: 'Patvirtinti pagal numatomą iškrovimo datą'
                    },
                    {
                        value: 3,
                        label: 'Užbaigti pagal užbaigimo datą'
                    }
                ]}
                    value={mode} onChange={e => setMode(e)}
                />}
                    label={<FieldLabel>{t('Type')}</FieldLabel>}
                />
            </WhiteCard>

            {Object.keys(dataByCreator).map(owner => {
                const data: IData[] = dataByCreator[owner];
                let totalTerminal = 0;
                let totalGarant = 0;
                let totalTerminalReturn = 0;
                let totalGarantReturn = 0;
                return (
                    <WhiteCard key={"owner-" + owner} title={owner}>

                        <Table
                            className={"w-full"}
                            thead={
                                <thead>
                                    <tr>
                                        <Th>{t('Number')}</Th>
                                        <Th textAlignment={"text-right"}>{t('Terminalo papildomos išlaidos')}</Th>
                                        <Th textAlignment={"text-right"}>{t('Papildomai už garantiją')}</Th>
                                        <Th textAlignment={"text-right"}>{t('Terminalo išlaidų grąžinimas')}</Th>
                                        <Th textAlignment={"text-right"}>{t('Garantijos išlaidų grąžinimas')}</Th>
                                    </tr>
                                </thead>
                            }
                            tbody={
                                <tbody>
                                    {data.map((o: IData, index: number) => {
                                        const costData = o.id in costsData ? costsData[o.id] : [];
                                        const extraTerminalCosts = costData.filter((el: any) => el.group === 'terminalo papildomos išlaidos').map((o: any) => o.cost).reduce((a: number, b: number) => a + b, 0);
                                        const extraGarantCosts = costData.filter((el: any) => el.group === 'papildomai už garantiją').map((o: any) => o.cost).reduce((a: number, b: number) => a + b, 0);

                                        const extraTerminalCostsReturn = costData.filter((el: any) => el.group === 'terminalo išlaidų skirtumo grąžinimas').map((o: any) => o.cost).reduce((a: number, b: number) => a + b, 0);
                                        const extraGarantCostsReturn = costData.filter((el: any) => el.group === 'garantijos išlaidų skirtumo grąžinimas').map((o: any) => o.cost).reduce((a: number, b: number) => a + b, 0);

                                        const isRed = extraTerminalCosts > 55 || extraGarantCosts > 55;
                                        const isYellow = extraTerminalCosts < -55 || extraGarantCosts < -55

                                        totalTerminal += extraTerminalCosts;
                                        totalGarant += extraGarantCosts;

                                        totalTerminalReturn += extraTerminalCostsReturn;
                                        totalGarantReturn += extraGarantCostsReturn;

                                        return (
                                            <tr className={isRed ? 'bg-red-100' : (isYellow ? 'bg-yellow-100' : (index % 2 === 0 ? 'bg-gray-100' : ''))}>
                                                <Td>
                                                    <RsButton
                                                        id={o.id}
                                                        schema={NaeSSchemaMap.KzCarriersOrder.schema}
                                                        button={{
                                                            children: o.serialNumber,
                                                            color: 'white',
                                                            skipPadding: true,
                                                        }}
                                                    />

                                                </Td>
                                                <Td textAlignment={"text-right"}>{extraTerminalCosts.toFixed(2)}</Td>
                                                <Td textAlignment={"text-right"}>{extraGarantCosts.toFixed(2)}</Td>
                                                <Td textAlignment={"text-right"}>{extraTerminalCostsReturn.toFixed(2)}</Td>
                                                <Td textAlignment={"text-right"}>{extraGarantCostsReturn.toFixed(2)}</Td>
                                            </tr>
                                        )
                                    })}
                                    <tr className={'total-row'}>
                                        <Td className={"font-medium"}>
                                            {t('Total')}
                                        </Td>
                                        <Td textAlignment={"text-right"} className={"font-medium"}>{totalTerminal.toFixed(2)}</Td>
                                        <Td textAlignment={"text-right"} className={"font-medium"}>{totalGarant.toFixed(2)}</Td>
                                        <Td textAlignment={"text-right"} className={"font-medium"}>{totalTerminalReturn.toFixed(2)}</Td>
                                        <Td textAlignment={"text-right"} className={"font-medium"}>{totalGarantReturn.toFixed(2)}</Td>
                                    </tr>
                                </tbody>
                            }
                        />
                    </WhiteCard>
                )
            })}


        </div>
    )
}
