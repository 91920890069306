import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorTerminalNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITerminalModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
      fullAddress: string,
      address: string,
      responsiblePerson: string,
      attorneyFile: any,
      country: {
              id: number,
            },
  }

export const ITerminalFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name",
    "fullAddress",
    "address",
    "responsiblePerson",
    "attorneyFile",
    "country.id"
];

export function useTerminalHookNae(id: number) : ITerminalModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTerminalNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "terminal",
        fields: ITerminalFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
