import React, { Fragment } from 'react'
import CalculationCancelWidget from './components/calculation/CalculationCancelWidget';
import CalculationEditAfter from './components/calculation/CalculationEditAfter';
import { ToolbarButtonWithMenu } from '@newageerp/v3.bundles.buttons-bundle';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';


export default function CalculationToolbarItemWidget(props: PropsId) {
    return (
        <ToolbarButtonWithMenu
            button={{
                iconName: "circle-ellipsis-vertical"
            }}
            menu={{
                children: <Fragment>
                    <CalculationEditAfter id={props.id} />

                    <CalculationCancelWidget id={props.id} />
                </Fragment>
            }}
        />

    )
}
