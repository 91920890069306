import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useTranslation } from 'react-i18next';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { useOutgoingDocumentsChangePurposeHookNae } from '../../hooks/useOutgoingDocumentsChangePurposeHookNae';
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

interface Props extends PropsId {
    size?: string,
    showOnlyOnDirect?: boolean,
    className?: string,
}

export default function CargoOutgoingDocumentsChangeBadge(props: Props) {
    const { t } = useTranslation();
    const text = t('KD');
    const element = useCargoHookNae(props.id);

    const docChangePurposeId = !!element && !!element.outgoingDocumentsChangePurpose ? element.outgoingDocumentsChangePurpose.id : -1;
    const docChangePurposeElement = useOutgoingDocumentsChangePurposeHookNae(docChangePurposeId);

    if (!element || element.outgoingDocumentsChange === 0) {
        return <Fragment />
    }
    if (props.showOnlyOnDirect && element.needTerminal) {
        return <Fragment />
    }

    const needConfirmMail = docChangePurposeElement && docChangePurposeElement.needConfirmMail > 0;
    const color = needConfirmMail ? "red" : "orange";

    return (
        <StatusWidget className={props.className} smallPadding={true} color={color} title={text}>
            <i className={"fa fa-fw fa-file-edit mr-1"} />
            {text}
            {!!docChangePurposeElement &&
                <Tooltip text={docChangePurposeElement.name} className={"ml-2"} />
            }
            {needConfirmMail &&
                <i title={t("reikalingas patvirtinimo laiškas")} className={"fas fa-envelope"} />
            }
        </StatusWidget>
    )
}
