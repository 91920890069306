import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useKzCarriersOrderHookNae } from '../../hooks/useKzCarriersOrderHookNae';
import { useTranslation } from 'react-i18next';
import OldBadge, { BadgeBgColor, BadgeSize } from '../../../../core/old-ui/OldBadge';
import { AlertWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function BadgeKzCarriersOrderContactedSeller(props: PropsId) {
    const {t} = useTranslation();

    const element = useKzCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {!element.contactedCarrier && <AlertWidget isCompact={true} color='danger'>{t('order not sended')}</AlertWidget>}
        </Fragment>
    )
}
