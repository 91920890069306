import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import KzOrderLogisticCargoLines from "../../../../components/dashboard/cargo/kz-order/KzOrderLogisticCargoLines";
import KzOrderLocation from "../../../../components/dashboard/cargo/kz-order/KzOrderLocation";
import KzOrderCargoLinks from "../../../KzCarriersOrder/Components/KzOrderCargoLinks";
import RailwayOrderTransportInfo from "./Components/RailwayOrderTransportInfo";
import KzCarrierLinkWithEye from '../../../KzCarriersOrder/Components/KzCarrierLinkWithEye';
import KzOrderLocationDate from "../../../../components/dashboard/cargo/kz-order/KzOrderLocationDate";
import AviaTerminalName from "./Components/AviaTerminalName";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import KzOrderLocationHistory from "../../../../components/dashboard/cargo/kz-order/KzOrderLocationHistory";

interface Props {
  data: IData[];
}

interface IData {
  id: number;
  status20Date: string;
}

export default function AviaLogisticsOnRoad(props: Props) {
  const { t } = useTranslation();
  const [viewCargosId, setViewCargosId] = useState<number>(0);

  return (
    <Fragment>
      <WhiteCard
        title={t("En route")}
        isCompact={true}
      >
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                {/* <Th>{t("Cargo")}</Th> */}
                <Th>{t('Carrier')}</Th>
                <Th>{t('Set off date')}</Th>
                <Th>{t('Vehicle')}</Th>
                <Th>{t('Terminal')}</Th>
                <Th>{t('Unloading address')}</Th>
                <Th>{t('Vehicle location')}</Th>
                <Th>{t('My notes')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data
                .sort((a, b) => {
                  const aDateStr = a.status20Date;
                  const bDateStr = b.status20Date;

                  var x = aDateStr.toLowerCase();
                  var y = bDateStr.toLowerCase();

                  return x < y ? -1 : x > y ? 1 : 0;
                })
                .map((o: IData) => {
                  return (
                    <Fragment key={"kz-o-" + o.id}>
                      <tr
                        className={viewCargosId === o.id ? "bg-blue-100" : ""}
                      >
                        <Td className={"whitespace-nowrap space-y-2"}>
                          <KzCarrierLinkWithEye id={o.id} viewCargosId={viewCargosId} setViewCargosId={setViewCargosId} />

                          <KzOrderCargoLinks id={o.id} />
                        </Td>
                        {/* <Td>
                          <KzOrderCargoLinks id={o.id} />
                        </Td> */}
                        <Td>
                          <p>
                            <DfValueView path="kz-carriers-order.client.name" id={o.id} />
                          </p>
                          <p>
                            <DfValueView path="kz-carriers-order.contact.fullName"
                              id={o.id}
                            />
                          </p>
                        </Td>
                        <Td className="whitespace-nowrap">
                          <DfValueView path="kz-carriers-order.status20Date" id={o.id} />
                        </Td>
                        <Td>
                          <RailwayOrderTransportInfo id={o.id} />
                        </Td>
                        <Td>
                          <AviaTerminalName id={o.id} />
                        </Td>
                        <Td><DfValueView path="kz-carriers-order.cargoUnloadAddress" id={o.id} /></Td>
                        <Td>
                          <KzOrderLocation id={o.id} />
                          <KzOrderLocationDate id={o.id} />

                          <KzOrderLocationHistory id={o.id} />
                        </Td>

                        <Td className={"w-[200px]"}>
                          <DfValueView path="kz-carriers-order.internalComment" id={o.id} />
                        </Td>
                      </tr>
                      {viewCargosId === o.id && (
                        <tr>
                          <td colSpan={99}>
                            <KzOrderLogisticCargoLines id={o.id} />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}
