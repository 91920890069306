import CargoCancel from "./Cargo/CargoCancel";
import CargoManagerHold from "./Cargo/CargoManagerHold";
import CargoManagerTemperatureSettings from "./Cargo/CargoManagerTemperatureSettings";
import CargoSenderMailBtn from "./Cargo/CargoSenderMailBtn";
import CargoSendTerminalMailBtn from "./Cargo/CargoSendTerminalMailBtn";
import OperativeDocsChange from "./Cargo/OperativeDocsChange";
import AccountingUnlockOrder from "./CarriersOrder/AccountingUnlockOrder";
import PoCancelWidget from "./CarriersOrder/PoCancelWidget";
import SenderAttorneyWidget from "./Sender/SenderAttorneyWidget";
import FvsExport from "./InvoiceOutgoing/FvsExport";
import FvsExportSO from "./ViltSalesOrder/FvsExport";
import FvsExportInvInc from "./InvoiceIncoming/FvsExport";
import FvsExportPayment from "./Payment/FvsExport";
import FvsExportViltPayment from "./ViltPayment/FvsExport";

import CargoLogisticDocsChange from "./Cargo/CargoLogisticDocsChange";
import KzAccountingUnlockOrder from './KzCarriersOrders/AccountingUnlockOrder';
import CargoSenderNonDualUse from "./Cargo/CargoSenderNonDualUse";
import ClientManagerDecline from "./Client/ClientManagerDecline";
import CalculationsRecalculate from "./Calculation/CalculationsRecalculate";
import IKCalculationsBottomWidget from "./KzCarriersOrders/IKCalculations/IKCalculationsBottomWidget";
import SepaExport from "./InvoiceOutgoing/SepaExport";
import ClientMainActions from "./Client/ClientMainActions";
import VoxCallsList from "./Client/VoxCallsList";
import KzLoadDatesChangeWidget from "./KzCarriersOrders/KzLoadDatesChangeWidget";
import KzCarriersOrderCargosWidget from "../components/kz-carriers-order/KzCarriersOrderCargosWidget";
import KzCarriersOrderEpi from "./KzCarriersOrders/KzCarriersOrderEpi";
import CargoAttorneyWidget from "./Cargo/CargoAttorneyWidget";
import BottomWidgetDimensions from "./Cargo/BottomWidget/BottomWidgetDimensions";
import CargoBottomWidget from "../components/cargo/CargoBottomWidget";
import SalesOrderBottomWidget from "../UserComponents/SalesOrder/Widget/Bottom/SalesOrderBottomWidget";
import SoHistoryWidget from "./SalesOrder/Element/SoHistoryWidget";
import SepaExportInvoices from "./SepaExport/SepaExportInvoices";
import CargoRevertToStatus5 from "./Cargo/CargoRevertToStatus5";
import CargoMoveSalesOrder from "./Cargo/CargoMoveSalesOrder";
import NotesNameResolver from "../_generated/_custom/models-cache-data/NotesNameResolver";
import * as NotesBundle from '@newageerp/v3.bundles.notes-bundle'
import * as BookmarksBundle from '@newageerp/v3.bundles.bookmarks-bundle'
import * as TasksBundle from '@newageerp/v3.bundles.tasks-bundle'
import * as FollowupBundle from '@newageerp/v3.bundles.followup-bundle'
import * as VoximplantBundle from '@newageerp/v3.bundles.voximplant-bundle'
import * as MailsBundle from '@newageerp/v3.bundles.mails-bundle'

import FollowUpContent from "../core/components/apps/components/FollowUp/FollowUpContent";
import SoToolbarPriceChange from "./layout/view/custom-toolbar-items/SoToolbarPriceChange";
import SalesOrderWorkflowWidget from "../UserComponents/SalesOrder/SalesOrderWorkflowWidget";
import CalculationWorkflowWidget from "../UserComponents/Calculation/CalculationWorkflowWidget";
import MoveWidget from "../UserComponents/LeadSource/MoveWidget";
import CargoWorkflowWidget from "../UserComponents/Cargo/CargoWorkflowWidget";
import KzCarriersOrdersFilesWidget from "../components/kz-carriers-order/KzCarriersOrdersFilesWidget";
import KzCarriersOrderWorkflowWidget from "../components/kz-carriers-order/KzCarriersOrderWorkflowWidget";
import CarriersOrderRequiredWidget from "../UserComponents/CarriersOrder/Widget/CarriersOrderRequiredWidget";
import MergeWidget from "../components/client/MergeWidget";
import CalculationToolbarItemWidget from "./layout/view/custom-toolbar-items/CalculationToolbarItemWidget";
import SoToolbarItemWidget from "./layout/view/custom-toolbar-items/SoToolbarItemWidget";
import UserCompaniesRel from "./Users/Widgets/UserCompaniesRel";
import CargoDpsUzsInfo from "./Cargo/CargoDpsUzsInfo";
import CargoDpsCheckFiles from "./Cargo/CargoDpsCheckFiles";
import CargoCheckForDualUse from "./Cargo/CargoCheckForDualUse";
import SoReloadFileToolbar from "./SalesOrder/Element/SoReloadFileToolbar";
import LoadDatesChangeWidget from "./CarriersOrder/LoadDatesChangeWidget";
import KzPlanningV3 from "./KzCarriersOrders/Planning/KzPlanningV3";
import UnFinishedOrdersReports from "../UserComponents/Reports/Accounter/UnFinishedOrdersReports";
import DashboardAccountingDocs from "../UserComponents/Dashboard/Accounting/DashboardAccountingDocs";
import SalesReports from "../UserComponents/Reports/SalesReports";
import DashboardAccountingPayments from "../UserComponents/Dashboard/Accounting/DashboardAccountingPayments";
import DashboardAccountingCargoCosts from "../UserComponents/Dashboard/Accounting/DashboardAccountingCargoCosts";
import IKExtraCostsReports from "../UserComponents/Reports/IKExtraCostsReports";
import DashboardManager from "../components/dashboard/DashboardManager";
import DashboardOperativeAdmin from "../UserComponents/Dashboard/OperationalAdmin/DashboardOperativeAdmin";
import DashboardOperative from "../components/dashboard/DashboardOperative";
import DashboardLogistic from "../UserComponents/Dashboard/Logistic/DashboardLogistic";
import DashboardKzLogistic from "../components/dashboard/DashboardKzLogistic";
import KzCalendar from "../UserComponents/Dashboard/DashboardKz/KzCalendar";
import KzLogisticHelperOrdersWrapper from "../components/dashboard/KZLogistic/KzLogisticHelperOrdersWrapper";
import KzLogisticForwardingManager from "../components/dashboard/KZLogistic/KzLogisticForwardingManager";
import DashboardLogisticFtl from "../components/dashboard/DashboardLogisticFtl";
import DashboardLogisticSea from "../components/dashboard/DashboardLogisticSea";
import DashboardLogisticTrain from "../components/dashboard/DashboardLogisticTrain";
import DashboardAdmin from "../UserComponents/Dashboard/Admin/DashboardAdmin";
import DashboardBusinessDev from "../UserComponents/Dashboard/BusinessDev/DashboardBusinessDev";
import DashboardAccountingCarriers from "../UserComponents/Dashboard/Accounting/DashboardAccountingCarriers";
import DashboardAccountingCosts from "../UserComponents/Dashboard/Accounting/DashboardAccountingCosts";
import ClientImportPage from "../UserComponents/Client/ClientImportPage";
import ClientCallsImportPage from "../UserComponents/Client/ClientCallsImportPage";
import DebtsReports from "../UserComponents/Reports/DebtReports/DebtsReports";
import CallReports from "../UserComponents/Reports/CallReports";
import WeeklyReportsLinks from "../UserComponents/Reports/WeeklyReportsLinks";
import CalculationReportsStatusComment from "../UserComponents/Reports/CalculationReportsStatusComment";
import ClientTop20Page from "../UserComponents/ClientTop20/ClientTop20Page";
import AccountingNeedDocsPage from "../UserComponents/Dashboard/Accounting/AccountingNeedDocsPage";
import ClientsStatusReports from "./Reports/Clients/ClientsStatusReports";
import PlannedTurnoverReport from "../UserComponents/Reports/PlannedTurnoverReport";
import TrackingSpecialistDocsPage from "../UserComponents/Dashboard/TrackingSpecialist/TrackingSpecialistDocsPage";
import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import SelfCostRecalculate from "./SalesOrder/List/SelfCostRecalculate";
import CartoLtlToFtl from "../UserComponents/Cargo/Widget/CartoLtlToFtl";
import CartoFtlToLtl from "../UserComponents/Cargo/Widget/CartoFtlToLtl";
import SoFinishBtn from "./SalesOrder/Element/SoFinishBtn";
import UserDpsToolbar from "./KzCarriersOrders/UserDpsToolbar";
import CargoDpsMailEx from "./Cargo/CargoDpsMailEx";
import WelcomePage from "./Routing/WelcomePage";
import { PostponeDelivery } from "./Cargo/PostponeDelivery/PostponeDelivery";
import CargoDocumentsCollected from "./Cargo/CargoDocumentsCollected";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomViewComponentsMap,
    ...CustomListComponentsMap,

    "CustomNotesApp": (comp: string) => {
        if (comp === 'NotesNameResolver') {
            return NotesNameResolver;
        }
        // @ts-ignore
        return NotesBundle[comp];
    },
    "CustomMailsApp": (comp: string) => {
        // @ts-ignore
        return MailsBundle[comp];
    },
    "CustomBookmarksApp": (comp: string) => {
        // @ts-ignore
        return BookmarksBundle[comp];
    },
    "CustomTasksApp": (comp: string) => {
        // @ts-ignore
        return TasksBundle[comp];
    },
    "CustomFollowupApp": (comp: string) => {
        if (comp === 'ManagerCustom') {
            return FollowUpContent;
        }
        // @ts-ignore
        return FollowupBundle[comp];
    },
    "CustomVoximplantApp": (comp: string) => {
        // @ts-ignore
        return VoximplantBundle[comp];
    },



    'custom.carriers-order.AccountingUnlockOrder': AccountingUnlockOrder,
    'custom.carriers-order.PoCancelWidget': PoCancelWidget,

    'custom.cargo.OperativeDocsChange': OperativeDocsChange,

    'custom.cargo.CargoSenderMailBtn': CargoSenderMailBtn,
    'custom.cargo.CargoSendTerminalMailBtn': CargoSendTerminalMailBtn,
    'custom.cargo.CargoSenderNonDualUse': CargoSenderNonDualUse,

    'custom.cargo.CargoManagerHold': CargoManagerHold,
    'custom.cargo.PostponeDelivery': PostponeDelivery,

    'custom.cargo.CargoManagerTemperatureSettings': CargoManagerTemperatureSettings,
    'custom.cargo.CargoCancel': CargoCancel,

    'custom.cargo.CargoLogisticDocsChange': CargoLogisticDocsChange,

    'custom.seller.SenderAttorneyWidget': SenderAttorneyWidget,

    'custom.cargo.CargoAttorneyWidget': CargoAttorneyWidget,

    'custom.kz.AccountingUnlockOrder': KzAccountingUnlockOrder,
    'custom.kz.KzLoadDatesChangeWidget': KzLoadDatesChangeWidget,
    'custom.kz.KzCarriersOrderEpi': KzCarriersOrderEpi,


    'custom.invoice-outgoing.FvsExport': FvsExport,
    'custom.vilt-sales-order.FvsExport': FvsExportSO,
    'custom.invoice-incoming.FvsExport': FvsExportInvInc,
    'custom.invoice-incoming.SepaExport': SepaExport,

    'custom.payment.FvsExport': FvsExportPayment,
    'custom.vilt-payment.FvsExport': FvsExportViltPayment,

    'custom.client.ClientManagerDecline': ClientManagerDecline,

    'custom.calculation.Recalculate': CalculationsRecalculate,

    'custom.so.SelfCostRecalculate': SelfCostRecalculate,

    'custom.so.SoFinishBtn': SoFinishBtn,

    'custom.kz-carriers-order.IKCalculationsBottomWidget': IKCalculationsBottomWidget,
    'custom.kz.KzCarriersOrderCargosWidget': KzCarriersOrderCargosWidget,

    'custom.client.ClientMainActions': ClientMainActions,

    'custom.clients.VoxCallList': VoxCallsList,

    'custom.cargo.BottomWidgetDimensions': BottomWidgetDimensions,
    'custom.cargo.CargoBottomWidget': CargoBottomWidget,

    'custom.so.SalesOrderBottomWidget': SalesOrderBottomWidget,
    'custom.so.SoHistoryWidget': SoHistoryWidget,
    'custom.so.SoReloadFileToolbar': SoReloadFileToolbar,

    'custom.SepaExportInvoices': SepaExportInvoices,

    'custom.plugins.CargoRevertToStatus5': CargoRevertToStatus5,

    'custom.plugins.CargoMoveSalesOrder': CargoMoveSalesOrder,

    'custom.so.SoToolbarPriceChange': SoToolbarPriceChange,
    'custom.so.SalesOrderWorkflowWidget': SalesOrderWorkflowWidget,

    'custom.pc.CalculationWorkflowWidget': CalculationWorkflowWidget,

    'custom.cargo.CargoWorkflowWidget': CargoWorkflowWidget,

    'custom.leadSource.MoveWidget': MoveWidget,

    'custom.kz.KzCarriersOrdersFilesWidget': KzCarriersOrdersFilesWidget,
    'custom.kz.KzCarriersOrderWorkflowWidget': KzCarriersOrderWorkflowWidget,


    'custom.kz.CarriersOrderRequiredWidget': CarriersOrderRequiredWidget,

    'custom.po.CarriersOrderRequiredWidget': CarriersOrderRequiredWidget,

    'custom.client.MergeWidget': MergeWidget,

    'custom.pc.CalculationToolbarItemWidget': CalculationToolbarItemWidget,
    'custom.so.SoToolbarItemWidget': SoToolbarItemWidget,

    'custom.user.UserCompaniesRel': UserCompaniesRel,

    'custom.cargo.CargoDpsUzsInfo': CargoDpsUzsInfo,

    'custom.cargo.CargoDpsCheckFiles': CargoDpsCheckFiles,

    'custom.cargo.CargoCheckForDualUse': CargoCheckForDualUse,
    'custom.cargo.CargoDocumentsCollected': CargoDocumentsCollected,


    'custom.po.LoadDatesChangeWidget': LoadDatesChangeWidget,

    'custom.cargo.CartoFtlToLtl': CartoFtlToLtl,
    'custom.cargo.CartoLtlToFtl': CartoLtlToFtl,
    
    'kz.UserDpsToolbar': UserDpsToolbar,

    'cargo.CargoDpsMailEx': CargoDpsMailEx,

    'custom.routes': {
        '/c/kz-v3': KzPlanningV3,
        '/c/reports/accounter/un-finished-orders': UnFinishedOrdersReports,
        '/c/dashboard-accounting-docs': DashboardAccountingDocs,
        '/c/reports/salaries': SalesReports,
        '/c/dashboard-accounting-payments': DashboardAccountingPayments,
        '/c/reports/accounter/cargo-costs': DashboardAccountingCargoCosts,
        '/c/reports/ik-extra-costs': IKExtraCostsReports,
        '/c/dashboard-manager': DashboardManager,
        '/c/dashboard-operative-admin': DashboardOperativeAdmin,
        '/c/dashboard-operative': DashboardOperative,
        '/c/dashboard-logistic': DashboardLogistic,
        '/c/dashboard-kz-logistic': DashboardKzLogistic,
        '/c/dashboard-kz-calendar': KzCalendar,
        '/c/dashboard-kz-logistic-helper': KzLogisticHelperOrdersWrapper,
        '/c/dashboard-forwarding-manager': KzLogisticForwardingManager,
        '/c/dashboard-logistic-ftl': DashboardLogisticFtl,
        '/c/dashboard-logistic-sea': DashboardLogisticSea,
        '/c/dashboard-logistic-train': DashboardLogisticTrain,
        '/c/dashboard-admin': DashboardAdmin,
        '/c/dashboard-business-dev': DashboardBusinessDev,
        '/c/dashboard-accounting-carriers': DashboardAccountingCarriers,
        '/c/dashboard-accounting-costs': DashboardAccountingCosts,
        '/c/clients-import': ClientImportPage,
        '/c/clients-calls-import': ClientCallsImportPage,
        '/c/reports/debts': DebtsReports,
        '/c/reports/calls': CallReports,
        '/c/reports/weekly-reports': WeeklyReportsLinks,
        '/c/reports/calculation-status-comment': CalculationReportsStatusComment,
        '/': WelcomePage,
        '/c/top20': ClientTop20Page,
        '/c/accounting/AccountingNeedDocsPage': AccountingNeedDocsPage,
        '/c/reports/client-status-changes': ClientsStatusReports,
        '/c/reports/planned-turnover': PlannedTurnoverReport,
        '/c/tracking-specialist-docs': TrackingSpecialistDocsPage,


        
    }
}

// max-w-[100px]