import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorClientTop20Nae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IClientTop20ModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      createdAt: string,
      client: {
              id: number,
                      country: {
              id: number,
                },
            },
      updatedAt: string,
  }

export const IClientTop20FieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "createdAt",
    "client.id",
    "client.country.id",
    "updatedAt"
];

export function useClientTop20HookNae(id: number) : IClientTop20ModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorClientTop20Nae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "client-top20",
        fields: IClientTop20FieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
