import React, { Fragment } from "react";

import SalesOrderConfirmOrder from "./Widget/SalesOrderConfirmOrder";
import SalesOrderManagerStatus from "./Widget/SalesOrderManagerStatus";
import SalesOrderCalculationWidget from "./Widget/SalesOrderCalculationWidget";
import SalesOrderCost from './Widget/SalesOrderCost';
import SalesOrderCargoFtlOverloadType from "./Widget/SalesOrderCargoFtlOverloadType";
import SalesOrderAllCargosOneUnload from './Widget/SalesOrderAllCargosOneUnload';
import { CheckUserPermissionComponent, ENaeSPermissions } from "../../_generated/_custom/config/NaeSPermissions";

import { PropsId } from "../../_generated/_custom/models-cache-data/types";

export default function SalesOrderWorkflowWidget(props: PropsId) {
  return (
    <Fragment>

      <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
        <SalesOrderManagerStatus id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserChiefSalesOfficer]} >
        <SalesOrderConfirmOrder id={props.id} />
      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
        <SalesOrderCargoFtlOverloadType id={props.id} />

        <SalesOrderAllCargosOneUnload id={props.id} />

      </CheckUserPermissionComponent>

      <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserChiefSalesOfficer]} >
        <SalesOrderCalculationWidget id={props.id} />

        <SalesOrderCost id={props.id} />
      </CheckUserPermissionComponent>
    </Fragment>
  );
}
