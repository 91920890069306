import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CargoLoadBtn from "../../../UserComponents/Cargo/Widget/CargoLoadBtn";
import CargoLogisticLink from "../../cargo/CargoLogisticLink";

import LogistiCargoCarriersLink from "./Components/LogistiCargoCarriersLink";
import LogistiCargoCarriersContact from "./Components/LogistiCargoCarriersContact";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import CargoSalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName";
import CargoNeedOperationBadgeForLogist from "../../../UserComponents/Cargo/Components/Badges/CargoNeedOperationBadgeForLogist";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserLogisticSea, checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import ClientStatusLogist from "../../../_generated/_custom/models-cache-data/Client/ClientStatusLogist";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { getKeysFromObject, groupMap } from "@newageerp/v3.bundles.utils-bundle";

let reloadTimeout: any = -1;

type IElement = {
  id: number;
  poLoadDateStr: string;
  logisticCardIndex: number;
  salesOrder: {
    id: number
  },
  seller: {
    id: number,
  },
  serialNumber: string,
}

const defObject: IElement = {
  id: 0,
  poLoadDateStr: '',
  logisticCardIndex: 0,
  salesOrder: {
    id: 0,
  },
  seller: {
    id: 0,
  },
  serialNumber: '',
}

type Props = {
  userId: number,
}

export default function LogistiсCargoWaitingLoadHot(props: Props) {
  const { t } = useTranslation();

  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const [getData, getDataParams] = useUList<IElement>(
    "cargo",
    getKeysFromObject(defObject)
  );
  const loadData = () => {
    let filter: any = [
      ["i.logisticCardIndex", "in", [3, 4], true],
      ["i.status", "in", [5, 7, 10], true],
    ];
    filter.push(["i.expeditor", "=", props.userId, true]);

    getData([{ and: filter }], 1, 200, [
      { key: "i.serialNumber", value: "ASC" },
    ]);
  };
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-logisticCardIndex-3",
      "cargo-logisticCardIndex-3",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-logisticCardIndex-3",
        "cargo-logisticCardIndex-3"
      );
    };
  }, []);

  const data = getDataParams.data.data;
  const sortedData = data
    .sort((a: IElement, b: IElement) => {
      const aDateStr = a.poLoadDateStr;
      const bDateStr = b.poLoadDateStr;

      var x = aDateStr.toLowerCase();
      var y = bDateStr.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

  const sortedData3 = sortedData.filter(c => c.logisticCardIndex === 3);
  const sortedData4 = sortedData.filter(c => c.logisticCardIndex === 4);

  const groupedBySo3 = groupMap(sortedData3, (el: IElement) => `${el.salesOrder.id}-${el.seller.id}`);
  const keys3 = Object.keys(groupedBySo3);

  const groupedBySo4 = groupMap(sortedData4, (el: IElement) => `${el.salesOrder.id}-${el.seller.id}`);
  const keys4 = Object.keys(groupedBySo4);

  return (
    <WhiteCard isCompact={true} title={t("Planned for loading")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Cargo")}</Th>
              <Th>{t("Seller")}</Th>
              <Th>{t("Client")}</Th>
              <Th></Th>
              <Th>{t("Carrier order")}</Th>
              <Th>{t("Carrier")}</Th>
              {(isLogisticSea || isLogisticTrain) && <Th>{t("Type of transportation")}</Th>}
              <Th>{t("Date of loading")}</Th>
              <Th>{t("")}</Th>
            </tr>
          </thead>

        }
        tbody={
          <tbody>
            {sortedData4.length > 0 &&
              <Fragment>
                <tr className="total-row">
                  <Td colSpan={9}>{t('Should have loaded')}</Td>
                </tr>
                {keys4.map((cargo: string, cIndex: number) => {
                  return (
                    <CargoLine
                      key={`cargo-${cargo}`}
                      ids={groupedBySo4[cargo]}
                    />
                  );
                })}
              </Fragment>}
            {sortedData3.length > 0 &&
              <Fragment>
                <tr className="total-row">
                  <Td colSpan={9}>{t('Waiting')}</Td>
                </tr>
                {keys3.map((cargo: string, cIndex: number) => {
                  return (
                    <CargoLine
                      key={`cargo-${cargo}`}
                      ids={groupedBySo3[cargo]}
                    />
                  );
                })}
              </Fragment>}
          </tbody>
        }
      />
    </WhiteCard>
  );
}

type CargoLineProps = {
  ids: IElement[],
}

const CargoLine = (props: CargoLineProps) => {
  const element = useCargoHookNae(props.ids[0].id);

  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  if (!element) {
    return <Fragment />
  }

  return (
    <tr>
      <Td className="space-y-2">
        <div className="flex flex-col gap-1">
          {props.ids.map(el => <CargoLogisticLink id={el.id} key={`c-${el.id}`} />)}
        </div>

        <p>
          <DfValueView path="cargo.creator.fullName" id={element.id} />
        </p>

        <CargoNeedOperationBadgeForLogist id={element.id} />
      </Td>
      <Td>
        <DfValueView path="cargo.seller.name" id={element.id} />
      </Td>
      <Td>
        <CargoSalesOrderClientOrFilialName id={element.id} />
      </Td>
      <Td><ClientStatusLogist id={element.soClientId} /></Td>
      <Td className="space-y-2">
        {props.ids.map(el => <div title={el.serialNumber} key={`cc-${el.id}`}>
          <LogistiCargoCarriersLink id={element.id} />
        </div>
        )}
      </Td>
      <Td className="whitespace-nowrap space-y-2">
        {props.ids.map(el => <p title={el.serialNumber} key={`cc-${el.id}`}>
          <LogistiCargoCarriersContact id={element.id} />
        </p>
        )}
      </Td>
      {(isLogisticSea || isLogisticTrain) && (
        <Td>
          <DfValueView path="cargo.typeIn" id={element.id} />
        </Td>
      )}
      <Td className="whitespace-nowrap space-y-2">
        {props.ids.map(el =>
          <p title={el.serialNumber} key={`po-load-${el.id}`}><DfValueView path="cargo.poLoadDateStr" id={el.id} /></p>
        )}
      </Td>
      <Td className="space-y-2">
        {(element.logisticCardIndex === 3 || element.logisticCardIndex === 4) &&
          <Fragment>
            {props.ids.map(el => <CargoLoadBtn id={el.id} sm={true} extraTitle={element.serialNumber} />)}
          </Fragment>

        }
      </Td>
    </tr>
  );
}