
import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'

export default function CargoNotesOperations(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element || !element.notesOperations) {
        return <Fragment />
    }
    return (
        <Fragment>
            {nl2p(element.notesOperations, "nlt")}
        </Fragment>
    )
}
