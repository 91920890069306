import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorLoadAddressNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ILoadAddressModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      stockWorkTime: string,
      addressIndex: string,
      senderRequirements: string,
      fullAddress: string,
  }

export const ILoadAddressFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "stockWorkTime",
    "addressIndex",
    "senderRequirements",
    "fullAddress"
];

export function useLoadAddressHookNae(id: number) : ILoadAddressModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorLoadAddressNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "load-address",
        fields: ILoadAddressFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
