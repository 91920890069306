import React, { Fragment, useEffect } from 'react'
import { ICargoSoContact } from '../../../../intefaces/cargoSoContact';
import { groupMap, uniqueArray } from '../../../../core/components/utils/mapUtils';
import { CopyToClipboard } from '@newageerp/v3.bundles.utils-bundle';
import { useTranslation } from 'react-i18next';
import CargoSalesOrderUnloadAddressShortAddress from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadAddressShortAddress';
import CargoSalesOrderClientOrFilialName from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName';
import CargoSerialNumberWithLink from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLink';
import CargoSalesOrderUnloadResponsiblePersonOrContactFullName from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadResponsiblePersonOrContactFullName';

import CargoSalesOrderUnloadResponsiblePersonOrContactPhones from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadResponsiblePersonOrContactPhones';
import CargoSalesOrderUnloadResponsibleComment from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadResponsibleComment';
import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';

import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

interface IData {
    id: number,
    serialNumber: string,
    soUnloadAddressJson: {
        shortAddress: string,
        fullAddress: string,
    },
    salesOrderJson: any,
    extraServiceDeliveryClient: number,
    extraServiceDeliveryClientAddress: string,
    soClientJson: {
        clientFilialName: string,
    },
    soContactJson: {
        id: number,
        fullName: string,
        phones: string[],
        emails: string[],
    }
}

interface Props {
    data: IData[];
    elementId: number;
}

export default function TableContacts(props: Props) {
    const { data, elementId } = props;
    const element = useKzCarriersOrderHookNae(elementId);

    const { t } = useTranslation();

    const trips = !!element && !!element.trip ? element.trip.split("\n") : [];

    const contactsForCopy = data.map((cargo: IData) => {
        const soUnloadAddress = cargo.soUnloadAddressJson;
        const salesOrder = cargo.salesOrderJson;

        const contact: ICargoSoContact = salesOrder.unloadResponsiblePerson ? salesOrder.unloadResponsiblePerson : cargo.soContactJson;

        return {
            'name': contact && contact.fullName,
            'phone': contact && contact.phones && contact.phones.join(', '),
            'address': !!soUnloadAddress &&
                soUnloadAddress.shortAddress,
            'extraServiceDeliveryClient': cargo.extraServiceDeliveryClient,
            'extraServiceDeliveryClientAddress': cargo.extraServiceDeliveryClientAddress,
            'unloadResponsibleComment': salesOrder.unloadResponsibleComment,
        }
    });
    const contactsForCopyGrouped = groupMap(contactsForCopy, (el: any) => el.address);

    const cargoIds = data.map(cargo => ({ id: cargo.id }));

    return (
        <Fragment>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th>{t('Contact person')}</Th>
                            <Th>{t('Telephone number')}</Th>
                            <Th>{t('Comments')}</Th>
                            <Th>{t('Cargo')}</Th>
                            <Th>{t('Client')}</Th>
                            <Th>{t('Unloading')}</Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {cargoIds.map((cargo, index) => {
                            return (
                                <tr key={`cargo-${cargo.id}`}>
                                    <Td><CargoSalesOrderUnloadResponsiblePersonOrContactFullName id={cargo.id} /></Td>
                                    <Td><CargoSalesOrderUnloadResponsiblePersonOrContactPhones id={cargo.id} /></Td>
                                    <Td><CargoSalesOrderUnloadResponsibleComment id={cargo.id} /></Td>
                                    <Td><CargoSerialNumberWithLink id={cargo.id} /></Td>
                                    <Td><CargoSalesOrderClientOrFilialName id={cargo.id} /></Td>
                                    <Td><CargoSalesOrderUnloadAddressShortAddress id={cargo.id} /></Td>
                                </tr>
                            )
                        })}
                    </tbody>
                }
            />


            <div className={"mt-2 w-96"}>
                <div className={"flex items-center"}>
                    <p className="font-semibold flex-grow">{t('Information for forwarding')}</p>

                    <MainButton color="teal" onClick={() => CopyToClipboard("text-to-copy")}>{t('Copy')}</MainButton>
                </div>
                <hr />
                <div id={"text-to-copy"}>
                    {trips.map((key: string) => {
                        const contactsData = uniqueArray(contactsForCopyGrouped[key].map((el: any) => JSON.stringify(el))).map((el: any) => JSON.parse(el));
                        return (
                            <Fragment>
                                <p><strong>{key}</strong></p>
                                {contactsData.map((el: any) => <p>{el.name}   {el.phone}   {el.extraServiceDeliveryClient === 10 ? <Fragment>({(!!el.extraServiceDeliveryClientAddress ? el.extraServiceDeliveryClientAddress : "довоз до склада")})</Fragment> : ""} {!!el.unloadResponsibleComment ? <strong>({el.unloadResponsibleComment})</strong> : ""}</p>)}
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </Fragment>
    )
}
