import React, { Fragment } from "react";
import CargoNeedLoadDateConfirm from "../../../../UserComponents/Cargo/Widget/CargoNeedLoadDateConfirm";
import CargoPoSendBtn from "../../../cargo/CargoPoSendBtn";
import CargoCarriersOrder from '../../../../UserComponents/Cargo/Widget/CargoCarriersOrder';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

export default function LogisticCarriersOrderCell(props: PropsId) {
  const cargo = useCargoHookNae(props.id);

  const {userState} = useTemplatesCore()

  if (!cargo) {
    return <Fragment />
  }

  return (
    <Fragment>
      {cargo.needLoadDateConfirm ? (
        <Fragment>
          <CargoNeedLoadDateConfirm
            id={cargo.id}
            transparent={true}
          />
        </Fragment>
      ) : (
        <Fragment>
          {cargo.needTerminal ? (
            <Fragment>
              {cargo.activePoOrders.length === 0 ? (
                <Fragment>
                  <CargoCarriersOrder id={cargo.id} />
                </Fragment>
              ) : (
                <Fragment>
                  {cargo.activePoOrders.map((co) => {
                    
                    return (
                      <div key={"carrier-order-" + co.id}>
                        <CargoPoSendBtn
                          id={co.id}
                          schema={"carriers-order"}
                        />
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {!cargo.kzCarriersOrder ? (
                <Fragment>
                  <CargoCarriersOrder id={cargo.id} />
                </Fragment>
              ) : (
                <Fragment>
                  <CargoPoSendBtn
                      id={cargo.kzCarriersOrder.id}
                      schema={"kz-carriers-order"}
                    />
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
