import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useTranslation } from 'react-i18next';
import UserFullName from '../../../_generated/_custom/models-cache-data/User/UserFullName';
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../../_generated/_custom/config/NaeSPermissions';
import BottomWidgetResponsiblePersonsWorkersChange from './BottomWidgetResponsiblePersonsWorkersChange';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

export default function BottomWidgetResponsiblePersons(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }

    return (
        <div className={"space-y-1 text-sm text-gray-400 mt-2"}>
            {!!element.expeditor && element.expeditor.id > 0 &&
                <div>{t('Transport operations manager (execution)')}: <UserFullName id={element.expeditor.id} /></div>
            }
            {!!element.expeditorCalculation && element.expeditorCalculation.id > 0 &&
                <div>{t('Transport operations manager (quotation)')}: <UserFullName id={element.expeditorCalculation.id} /></div>
            }
            {!!element.operational && element.operational.id > 0 &&
                <div>{t('Document processing specialist')}: <UserFullName id={element.operational.id} /></div>
            }
            <CheckUserPermissionComponent
                permissions={[
                    ENaeSPermissions.UserAdmin,
                    ENaeSPermissions.UserHeadOfLogistics,
                    ENaeSPermissions.UserOperationalPro,

                ]}
            >
                <BottomWidgetResponsiblePersonsWorkersChange id={props.id} />
            </CheckUserPermissionComponent>
        </div>
    )
}
