import { ORM } from "redux-orm";
import AccountingDiscrepanciesModel from "./AccountingDiscrepanciesModel"
import AdrClassModel from "./AdrClassModel"
import AttorneyModel from "./AttorneyModel"
import CalculationModel from "./CalculationModel"
import CallLogModel from "./CallLogModel"
import CarServiceActModel from "./CarServiceActModel"
import CarServiceActProductModel from "./CarServiceActProductModel"
import CarServiceActServiceModel from "./CarServiceActServiceModel"
import CarServiceClientModel from "./CarServiceClientModel"
import CarServiceClientCarModel from "./CarServiceClientCarModel"
import CarServiceClientContactModel from "./CarServiceClientContactModel"
import CarServiceInvoiceIncomingModel from "./CarServiceInvoiceIncomingModel"
import CarServiceInvoiceIncomingProductModel from "./CarServiceInvoiceIncomingProductModel"
import CarServicePaymentModel from "./CarServicePaymentModel"
import CarServiceProductModel from "./CarServiceProductModel"
import CarServiceProductBarcodeModel from "./CarServiceProductBarcodeModel"
import CarServiceProductCostListModel from "./CarServiceProductCostListModel"
import CarServiceProductGroupModel from "./CarServiceProductGroupModel"
import CarServiceServiceModel from "./CarServiceServiceModel"
import CarServiceStockItemModel from "./CarServiceStockItemModel"
import CarServiceSupplierModel from "./CarServiceSupplierModel"
import CarServiceSupplierContactModel from "./CarServiceSupplierContactModel"
import CargoModel from "./CargoModel"
import CargoContactSellerLogModel from "./CargoContactSellerLogModel"
import CargoDescriptionModel from "./CargoDescriptionModel"
import CargoHoldModel from "./CargoHoldModel"
import CargoInsuranceModel from "./CargoInsuranceModel"
import CargoLoadingCalculationModel from "./CargoLoadingCalculationModel"
import CargoLoadingCalculationNotesSuggestModel from "./CargoLoadingCalculationNotesSuggestModel"
import CargoOperationConfirmationModel from "./CargoOperationConfirmationModel"
import CargoOperationConfirmationChangesModel from "./CargoOperationConfirmationChangesModel"
import CargoSpecificactionModel from "./CargoSpecificactionModel"
import CargoStatusModel from "./CargoStatusModel"
import CarrierModel from "./CarrierModel"
import CarriersOrderModel from "./CarriersOrderModel"
import CarriersOrderPaymentTermModel from "./CarriersOrderPaymentTermModel"
import CarriersOrderPriceChangeModel from "./CarriersOrderPriceChangeModel"
import ClientModel from "./ClientModel"
import ClientContractModel from "./ClientContractModel"
import ClientFilialModel from "./ClientFilialModel"
import ClientStatusModel from "./ClientStatusModel"
import ClientStatusDataModel from "./ClientStatusDataModel"
import ClientTop20Model from "./ClientTop20Model"
import CommentIkNotesModel from "./CommentIkNotesModel"
import CommentLoadingModel from "./CommentLoadingModel"
import CommentOperationModel from "./CommentOperationModel"
import CommentProcessingModel from "./CommentProcessingModel"
import CommissionExpenseModel from "./CommissionExpenseModel"
import CompanyModel from "./CompanyModel"
import ContactModel from "./ContactModel"
import ContactDuplicateModel from "./ContactDuplicateModel"
import ContractTermModel from "./ContractTermModel"
import CountryModel from "./CountryModel"
import CountryGeographyGroupModel from "./CountryGeographyGroupModel"
import CountryLogisticGroupModel from "./CountryLogisticGroupModel"
import CountryRegionModel from "./CountryRegionModel"
import CurrencyModel from "./CurrencyModel"
import DriverModel from "./DriverModel"
import EtransportTruckModel from "./EtransportTruckModel"
import ExtraCostCargoModel from "./ExtraCostCargoModel"
import ExtraCostCommonModel from "./ExtraCostCommonModel"
import ExtraCostGarantModel from "./ExtraCostGarantModel"
import ExtraCostRailwayTarifModel from "./ExtraCostRailwayTarifModel"
import ExtraCostTerminalModel from "./ExtraCostTerminalModel"
import FilesManagerFileModel from "./FilesManagerFileModel"
import FilesManagerFolderModel from "./FilesManagerFolderModel"
import FilterChangeModel from "./FilterChangeModel"
import InsuranceRatesModel from "./InsuranceRatesModel"
import InventoryModel from "./InventoryModel"
import InvoiceIncomingModel from "./InvoiceIncomingModel"
import InvoiceIncomingSepaExportModel from "./InvoiceIncomingSepaExportModel"
import InvoiceOutgoingModel from "./InvoiceOutgoingModel"
import InvoiceOutgoingCreditRequestModel from "./InvoiceOutgoingCreditRequestModel"
import InvoiceOutgoingPriceChangeModel from "./InvoiceOutgoingPriceChangeModel"
import KzCarriersOrderModel from "./KzCarriersOrderModel"
import KzCarriersOrderLoadDateChangeModel from "./KzCarriersOrderLoadDateChangeModel"
import KzCarriersOrderLocationModel from "./KzCarriersOrderLocationModel"
import KzEx1FilesSendModel from "./KzEx1FilesSendModel"
import KzFtlCalculationModel from "./KzFtlCalculationModel"
import KzInspectionModel from "./KzInspectionModel"
import KzLocationSuggestModel from "./KzLocationSuggestModel"
import LeadLostReasonModel from "./LeadLostReasonModel"
import LeadLostRecordModel from "./LeadLostRecordModel"
import LeadSourceModel from "./LeadSourceModel"
import LeadSourceCategoryModel from "./LeadSourceCategoryModel"
import LoadAddressModel from "./LoadAddressModel"
import NoteModel from "./NoteModel"
import OilChangeModel from "./OilChangeModel"
import OutgoingDocumentsChangePurposeModel from "./OutgoingDocumentsChangePurposeModel"
import PaymentModel from "./PaymentModel"
import PhotoAlbumModel from "./PhotoAlbumModel"
import PoCancelReasonModel from "./PoCancelReasonModel"
import PriceListFtlModel from "./PriceListFtlModel"
import PriceListGarantModel from "./PriceListGarantModel"
import PriceListLtlModel from "./PriceListLtlModel"
import PriceListLtlLineModel from "./PriceListLtlLineModel"
import PriceListRailwayCarriageModel from "./PriceListRailwayCarriageModel"
import PriceListRailwayContainerModel from "./PriceListRailwayContainerModel"
import QueueModel from "./QueueModel"
import RepairTensionBearingsModel from "./RepairTensionBearingsModel"
import RepairWaterPompModel from "./RepairWaterPompModel"
import SalaryPointCostModel from "./SalaryPointCostModel"
import SalesOrderModel from "./SalesOrderModel"
import SalesOrderPaymentDatesModel from "./SalesOrderPaymentDatesModel"
import SalesOrderPriceChangeModel from "./SalesOrderPriceChangeModel"
import SenderModel from "./SenderModel"
import SupplierCustomsModel from "./SupplierCustomsModel"
import SupplierExtraCostsModel from "./SupplierExtraCostsModel"
import SupplierRailwayModel from "./SupplierRailwayModel"
import TaskModel from "./TaskModel"
import TaskCategoryModel from "./TaskCategoryModel"
import TerminalModel from "./TerminalModel"
import TerminalContactModel from "./TerminalContactModel"
import TrailerModel from "./TrailerModel"
import TruckModel from "./TruckModel"
import TruckLocationModel from "./TruckLocationModel"
import TruckOwnerModel from "./TruckOwnerModel"
import TyreCheckModel from "./TyreCheckModel"
import UnloadAddressModel from "./UnloadAddressModel"
import UserModel from "./UserModel"
import UserCountryLogisticGroupRelModel from "./UserCountryLogisticGroupRelModel"
import UserLeadCategoryRelModel from "./UserLeadCategoryRelModel"
import UserPointsLogModel from "./UserPointsLogModel"
import UserPointsSettingsModel from "./UserPointsSettingsModel"
import ViltClientModel from "./ViltClientModel"
import ViltContactModel from "./ViltContactModel"
import ViltPaymentModel from "./ViltPaymentModel"
import ViltPurchaseOrderModel from "./ViltPurchaseOrderModel"
import ViltSalesOrderModel from "./ViltSalesOrderModel"
import ViltTripModel from "./ViltTripModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    AccountingDiscrepanciesModel,
    AdrClassModel,
    AttorneyModel,
    CalculationModel,
    CallLogModel,
    CarServiceActModel,
    CarServiceActProductModel,
    CarServiceActServiceModel,
    CarServiceClientModel,
    CarServiceClientCarModel,
    CarServiceClientContactModel,
    CarServiceInvoiceIncomingModel,
    CarServiceInvoiceIncomingProductModel,
    CarServicePaymentModel,
    CarServiceProductModel,
    CarServiceProductBarcodeModel,
    CarServiceProductCostListModel,
    CarServiceProductGroupModel,
    CarServiceServiceModel,
    CarServiceStockItemModel,
    CarServiceSupplierModel,
    CarServiceSupplierContactModel,
    CargoModel,
    CargoContactSellerLogModel,
    CargoDescriptionModel,
    CargoHoldModel,
    CargoInsuranceModel,
    CargoLoadingCalculationModel,
    CargoLoadingCalculationNotesSuggestModel,
    CargoOperationConfirmationModel,
    CargoOperationConfirmationChangesModel,
    CargoSpecificactionModel,
    CargoStatusModel,
    CarrierModel,
    CarriersOrderModel,
    CarriersOrderPaymentTermModel,
    CarriersOrderPriceChangeModel,
    ClientModel,
    ClientContractModel,
    ClientFilialModel,
    ClientStatusModel,
    ClientStatusDataModel,
    ClientTop20Model,
    CommentIkNotesModel,
    CommentLoadingModel,
    CommentOperationModel,
    CommentProcessingModel,
    CommissionExpenseModel,
    CompanyModel,
    ContactModel,
    ContactDuplicateModel,
    ContractTermModel,
    CountryModel,
    CountryGeographyGroupModel,
    CountryLogisticGroupModel,
    CountryRegionModel,
    CurrencyModel,
    DriverModel,
    EtransportTruckModel,
    ExtraCostCargoModel,
    ExtraCostCommonModel,
    ExtraCostGarantModel,
    ExtraCostRailwayTarifModel,
    ExtraCostTerminalModel,
    FilesManagerFileModel,
    FilesManagerFolderModel,
    FilterChangeModel,
    InsuranceRatesModel,
    InventoryModel,
    InvoiceIncomingModel,
    InvoiceIncomingSepaExportModel,
    InvoiceOutgoingModel,
    InvoiceOutgoingCreditRequestModel,
    InvoiceOutgoingPriceChangeModel,
    KzCarriersOrderModel,
    KzCarriersOrderLoadDateChangeModel,
    KzCarriersOrderLocationModel,
    KzEx1FilesSendModel,
    KzFtlCalculationModel,
    KzInspectionModel,
    KzLocationSuggestModel,
    LeadLostReasonModel,
    LeadLostRecordModel,
    LeadSourceModel,
    LeadSourceCategoryModel,
    LoadAddressModel,
    NoteModel,
    OilChangeModel,
    OutgoingDocumentsChangePurposeModel,
    PaymentModel,
    PhotoAlbumModel,
    PoCancelReasonModel,
    PriceListFtlModel,
    PriceListGarantModel,
    PriceListLtlModel,
    PriceListLtlLineModel,
    PriceListRailwayCarriageModel,
    PriceListRailwayContainerModel,
    QueueModel,
    RepairTensionBearingsModel,
    RepairWaterPompModel,
    SalaryPointCostModel,
    SalesOrderModel,
    SalesOrderPaymentDatesModel,
    SalesOrderPriceChangeModel,
    SenderModel,
    SupplierCustomsModel,
    SupplierExtraCostsModel,
    SupplierRailwayModel,
    TaskModel,
    TaskCategoryModel,
    TerminalModel,
    TerminalContactModel,
    TrailerModel,
    TruckModel,
    TruckLocationModel,
    TruckOwnerModel,
    TyreCheckModel,
    UnloadAddressModel,
    UserModel,
    UserCountryLogisticGroupRelModel,
    UserLeadCategoryRelModel,
    UserPointsLogModel,
    UserPointsSettingsModel,
    ViltClientModel,
    ViltContactModel,
    ViltPaymentModel,
    ViltPurchaseOrderModel,
    ViltSalesOrderModel,
    ViltTripModel,
);

export default orm;