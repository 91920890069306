import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { groupMap, uniqueArray } from "../../core/components/utils/mapUtils";
import moment from "moment";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { checkUserManager } from "../../_generated/_custom/config/NaeSPermissions";
import { useUserHookNae } from "../../_generated/_custom/hooks/useUserHookNae";
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTable, { TableSize, usePrint, useQuery } from "../../core/old-ui/OldTable";
import OldThead from "../../core/old-ui/OldThead";
import OldTbody from "../../core/old-ui/OldTbody";
import { FieldDateRange, FieldLabel, WideRow } from '@newageerp/v3.bundles.form-bundle'

import { ITableLocationStateDates } from "../../_generated/v3/utils";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle'
import { SelectFieldSchema } from "@newageerp/v3.bundles.app-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { voxCallCodes } from "@newageerp/v3.bundles.voximplant-bundle";
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface IData {
  duration: number;
  startDate: string;
  from: string;
  to: string;
  record: string;

  active: number;
  notactive: number;
  activeMin: number;
  active3Min: number;
  active6Min: number;
  active10Min: number;
  active10plusMin: number;
}

var enumerateDaysBetweenDates = function (
  _startDate: string,
  _endDate: string
) {
  const startDate = moment(_startDate);
  const endDate = moment(_endDate);

  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
};

var enumerateDaysBetweenDatesWoSaSu = function (
  _startDate: string,
  _endDate: string
) {
  const startDate = moment(_startDate);
  const endDate = moment(_endDate);

  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    const isSat = now.format('E') === '6';
    const isSun = now.format('E') === '7';
    if (!isSat && !isSun) {
      dates.push(now.format("YYYY-MM-DD"));
    }
    now.add(1, "days");
  }
  return dates;
};

export default function CallReports() {
  const { getTabFromSchemaAndType } = useUIBuilder();

  const { t } = useTranslation();

  const isPrint = usePrint();

  const [filterUserId, setFilterUserId] = useState(0);

  const query = useQuery();
  const queryManager = query.get("manager");

  const [addAverage, setAddAverage] = useState(
    query.get("addAverage") === "true"
  );
  const [addLastPeriod, setAddLastPeriod] = useState(
    query.get("addLastPeriod") === "true"
  );
  const [separateByDate, setSeparateByDate] = useState(
    query.get("separateByDate") === "true"
  );

  const [groupByHour, setGroupByHour] = useState(
    query.get("groupByHour") === "true"
  );
  const toggleGroupByHour = () => setGroupByHour(!groupByHour);

  const { userState } = useTemplatesCore()
  const isManager =
    checkUserManager(userState) || !!queryManager || filterUserId > 0;
  const isManagerPerm = checkUserManager(userState);

  const userId = filterUserId
    ? filterUserId
    : queryManager
      ? queryManager
      : userState.id;

  const user = useUserHookNae(userId);

  const dateNow = moment().format("YYYY-MM-DD");
  const defaultDates = {
    dateFrom: dateNow,
    dateTo: dateNow,
  };
  if (query.get("dateFrom")) {
    // @ts-ignore
    defaultDates.dateFrom = query.get("dateFrom");
  }
  if (query.get("dateTo")) {
    // @ts-ignore
    defaultDates.dateTo = query.get("dateTo");
  }

  const prevDays: ITableLocationStateDates = {
    dateFrom: moment(defaultDates.dateFrom)
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    dateTo: moment(defaultDates.dateTo)
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
  };

  const [dates, setDates] =
    React.useState<ITableLocationStateDates>(defaultDates);

  const WrapCard = isPrint ? OldTransparentCardCompact : WhiteCard;

  const daysDiff = enumerateDaysBetweenDates(dates.dateFrom, dates.dateTo);

  const daysDiffWoSaSun = enumerateDaysBetweenDatesWoSaSu(dates.dateFrom, dates.dateTo);

  return (
    <Fragment>
      <MainToolbarTitle title={t("Work effectivity report")} />

      <div className={"space-y-2"}>
        {isPrint && isManager && !!user && (
          <TextCardTitle>
            {user.fullName}
          </TextCardTitle>
        )}
        {!isPrint && (
          <WrapCard title={t("Filter")}>
            <FieldDateRange dates={dates} setDates={setDates} />

            {!isManagerPerm &&
              <WideRow
                label={<FieldLabel>{t("User")}</FieldLabel>}
                control={
                  <div className="flex gap-2">
                    <SelectFieldSchema
                      tab={getTabFromSchemaAndType('user', 'main')}
                      schema={"user"}
                      value={{ id: filterUserId }}
                      onChange={(val) => setFilterUserId(val.id)}
                      className={"w-60"}
                      addEmpty={true}
                    />

                    {filterUserId > 0 && (
                      <MainButton color={"sky"} onClick={() => setFilterUserId(0)}>
                        {t("Show all")}
                      </MainButton>
                    )}
                  </div>
                } />
            }
            <div className="flex gap-2 items-center">
              <MainButton color={groupByHour ? "teal" : "blue"} onClick={toggleGroupByHour}>
                {t("Show hourly")}
              </MainButton>

              <MainButton onClick={() => setAddAverage(!addAverage)} color={addAverage ? "teal" : "blue"}>
                {t("Show average")}
              </MainButton>


              {daysDiffWoSaSun.length > 1 && (

                <MainButton onClick={() => setSeparateByDate(!separateByDate)} color={addAverage ? "teal" : "blue"}>
                  {t("Padalinti per dienas")}
                </MainButton>

              )}
            </div>

          </WrapCard>
        )}

        <WrapCard key={"manager-" + userId}>
          {separateByDate ? (
            daysDiffWoSaSun.map((d: string, index: number) => {
              return (
                <Fragment>
                  <TextCardTitle>{d}</TextCardTitle>

                  <DrawDataTable
                    dates={{
                      dateFrom: d,
                      dateTo: d,
                    }}
                    isManager={isManager}
                    groupByHour={groupByHour}
                    managerId={userId}
                    printOnLoad={isPrint && index === d.length - 1}
                  />
                </Fragment>
              );
            })
          ) : (
            <Fragment>
              <TextCardTitle>{t("Details")}</TextCardTitle>

              <DrawDataTable
                dates={dates}
                isManager={isManager}
                groupByHour={groupByHour}
                managerId={userId}
                printOnLoad={isPrint}
              />
            </Fragment>
          )}

          {addAverage && (
            <Fragment>
              <TextCardTitle>{t("Average")}</TextCardTitle>
              <DrawDataTable
                dates={dates}
                isManager={isManager}
                groupByHour={false}
                managerId={userId}
                divider={daysDiffWoSaSun.length}
              />
            </Fragment>
          )}

          {groupByHour && (
            <Fragment>
              <div className="flex items-center gap-2">
                <TextCardTitle>{t("Total")}</TextCardTitle>
                <div>
                  {dates?.dateFrom}-{dates?.dateTo}
                </div>
              </div>

              <DrawDataTable
                dates={dates}
                isManager={isManager}
                groupByHour={false}
                managerId={userId}
              />
            </Fragment>
          )}

          {addLastPeriod && (
            <Fragment>
              <div className="flex items-center gap-2">
                <TextCardTitle>{t("Total")}</TextCardTitle>
                <div>
                  {prevDays?.dateFrom}-{prevDays?.dateTo}
                </div>
              </div>

              <DrawDataTable
                dates={prevDays}
                isManager={isManager}
                groupByHour={false}
                managerId={userId}
              />
            </Fragment>
          )}
        </WrapCard>
      </div>
    </Fragment>
  );
}

interface DataTableProps {
  dates: ITableLocationStateDates | undefined;
  isManager: boolean;
  managerId: number | string;
  groupByHour: boolean;
  divider?: number;
  printOnLoad?: boolean;
}

const DrawDataTable = (props: DataTableProps) => {
  const [printed, setPrinted] = useState(false);

  const { dates, isManager, managerId, groupByHour, divider = 1 } = props;

  const isPrint = usePrint();
  const { t } = useTranslation();

  const [getCallLogData, getCallLogDataParams] = useUList(
    NaeSSchemaMap.CallLog.schema,
    ["id", "duration", "stopReason", "fromNumber", "date"]
  );

  const [getMailsData, getMailsDataParams] = useUList(
    NaeSSchemaMap.Mail.schema,
    ["id", "creator.fullName", "createdAt"]
  );
  const [getCalculationData, getCalculationDataParams] = useUList(
    "calculation",
    ["id", "creator.fullName", "purpose", "createdAt"]
  );

  useEffect(() => {
    if (!!dates) {
      getCallLogData(
        [
          {
            classicMode: true,
            and: isManager
              ? [
                ["i.date", "dgte", dates.dateFrom],
                ["i.date", "dlte", dates.dateTo],
                ["i.creator", "=", managerId],
              ]
              : [
                ["i.date", "dgte", dates.dateFrom],
                ["i.date", "dlte", dates.dateTo],
              ],
          },
        ],
        1,
        10000000
      );
      getMailsData(
        [
          {
            classicMode: true,
            and: isManager
              ? [
                ["i.parentSchema", "eq", "client"],
                ["i.createdAt", "dgte", dates.dateFrom],
                ["i.createdAt", "dlte", dates.dateTo],
                ["i.creator", "=", managerId],
              ]
              : [
                ["i.parentSchema", "eq", "client"],
                ["i.createdAt", "dgte", dates.dateFrom],
                ["i.createdAt", "dlte", dates.dateTo],
              ],
          },
        ],
        1,
        10000
      );
      getCalculationData(
        [
          {
            classicMode: true,
            and: isManager
              ? [
                ["i.createdAt", "dgte", dates.dateFrom],
                ["i.createdAt", "dlte", dates.dateTo],
                ["i.creator", "=", managerId],
                ["i.status", "!=", 900],
              ]
              : [
                ["i.createdAt", "dgte", dates.dateFrom],
                ["i.createdAt", "dlte", dates.dateTo],
                ["i.status", "!=", 900],
              ],
          },
        ],
        1,
        10000
      );
    }
  }, [dates, managerId, isManager]);

  const convertStopReason = (el: any) =>
    el.stopReason in voxCallCodes ? voxCallCodes[el.stopReason] : el.stopReason;

  const data = getCallLogDataParams.data.data;
  const groupedData = groupMap(data, (el) =>
    groupByHour
      ? isManager
        ? moment(el.date).format("HH")
        : `${el.fromNumber} ${moment(el.date).format("HH")}`
      : el.fromNumber
  );
  const stopReasons = groupMap(data, convertStopReason);

  const groupedMailsData = groupMap(getMailsDataParams.data.data, (el) =>
    groupByHour
      ? isManager
        ? moment(el.createdAt).format("HH")
        : `${el.creator ? el.creator.fullName : ""} ${moment(
          el.createdAt
        ).format("HH")}`
      : el.creator
        ? el.creator.fullName
        : ""
  );
  const groupedCalculationData = groupMap(
    getCalculationDataParams.data.data,
    (el) =>
      groupByHour
        ? isManager
          ? moment(el.createdAt).format("HH")
          : `${el.creator ? el.creator.fullName : ""} ${moment(
            el.createdAt
          ).format("HH")}`
        : el.creator
          ? el.creator.fullName
          : ""
  );

  const groupedDataKeys: any[] = uniqueArray([
    ...Object.keys(groupedData),
    ...Object.keys(groupedMailsData),
    ...Object.keys(groupedCalculationData),
  ]);
  groupedDataKeys.sort();

  const showFirstColumn = !(isManager && !groupByHour);

  const defTextSize = isPrint ? TableSize.sm : TableSize.base;

  const parseNumber = (num: number) => {
    if (divider > 1) {
      return (Math.round((num / divider) * 100) / 100).toFixed(2);
    }
    return num.toFixed(2);
  };

  useEffect(() => {
    if (
      getCallLogDataParams.data.data.length > 0 &&
      isPrint &&
      !printed &&
      props.printOnLoad
    ) {
      setTimeout(() => {
        window.print();
      }, 800);
      setPrinted(true);
    }
  }, [isPrint, getCalculationDataParams.data, printed, props.printOnLoad]);

  return (
    <OldTable
      thead={
        <OldThead
          className="table-header-group"
          columns={[
            {
              content: isManager ? t("Hours") : t("Name, Surname"),
              props: { className: "text-left border-r-4", size: defTextSize },
            },
            {
              content: t("For commercial offer"),
              props: { className: "text-left", size: defTextSize },
            },
            {
              content: t("For order execution"),
              props: { className: "text-left border-r-4", size: defTextSize },
            },
            {
              content: t("Presentations"),
              props: { className: "text-left border-r-4", size: defTextSize },
            },
            {
              content: t("Total, duration"),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("0-1 min."),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("1-3 min."),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("3-6 min."),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("6-10 min."),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("10+ min."),
              props: { className: "text-right border-r-4", size: defTextSize },
            },
            {
              content: t("Total, calls"),
              props: { className: "text-right", size: defTextSize },
            },
            {
              content: t("Called in"),
              props: { className: "text-right", size: defTextSize },
            },
            ...Object.keys(stopReasons)
              .filter((s) => !!s)
              .map((stopReason) => {
                return {
                  content: stopReason,
                  props: { className: "text-right", size: defTextSize },
                };
              }),
          ].slice(showFirstColumn ? 0 : 1)}
        />
      }
      tbody={
        <OldTbody
          data={groupedDataKeys}
          callback={(item: any, index: number) => {
            const _data: IData[] = groupedData[item] ? groupedData[item] : [];
            const _mailData: any = groupedMailsData[item]
              ? groupedMailsData[item]
              : [];
            const _calculationData: any = groupedCalculationData[item]
              ? groupedCalculationData[item]
              : [];

            const secondsFromStart = _data.map((a) => a.duration).reduce((a, b) => a + b, 0) / divider;
            const hoursFromStart = Math.floor(secondsFromStart / 3600);
            const minutesFromStart = Math.floor((secondsFromStart - (hoursFromStart * 3600)) / 60);
            const secondsLeft = (secondsFromStart - (hoursFromStart * 3600) - (minutesFromStart * 60)).toFixed(0);


            return {
              className: index % 2 === 0 ? "bg-gray-100" : "",
              columns: [
                {
                  content: item,
                  props: {
                    className: "text-left border-r-4",
                    size: defTextSize,
                  },
                },
                {
                  content: parseNumber(
                    _calculationData.filter((a: any) => a.purpose === 10).length
                  ),
                  props: { className: "text-left", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _calculationData.filter(
                      (a: any) => a.purpose === 20 || a.purpose === 30
                    ).length
                  ),
                  props: {
                    className: "text-left border-r-4",
                    size: defTextSize,
                  },
                },
                {
                  content: parseNumber(_mailData.length),
                  props: {
                    className: "text-left border-r-4",
                    size: defTextSize,
                  },
                },
                {
                  content: `${hoursFromStart.toFixed(0).padStart(2, '0')}:${minutesFromStart.toFixed(0).padStart(2, '0')}:${secondsLeft}`,
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter((a: any) => a.duration > 0 && a.duration <= 60)
                      .length
                  ),
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter(
                      (a: any) => a.duration > 60 && a.duration <= 180
                    ).length
                  ),
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter(
                      (a: any) => a.duration > 180 && a.duration <= 360
                    ).length
                  ),
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter(
                      (a: any) => a.duration > 360 && a.duration <= 600
                    ).length
                  ),
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter((a: any) => a.duration > 600).length
                  ),
                  props: {
                    className: "text-right border-r-4",
                    size: defTextSize,
                  },
                },
                {
                  content: parseNumber(_data.length),
                  props: { className: "text-right", size: defTextSize },
                },
                {
                  content: parseNumber(
                    _data.filter((a: any) => a.duration > 0).length
                  ),
                  props: { className: "text-right", size: defTextSize },
                },
                ...Object.keys(stopReasons)
                  .filter((s) => !!s)
                  .map((stopReason) => {
                    return {
                      content: _data.filter(
                        (a: any) => convertStopReason(a) === stopReason
                      ).length / divider,
                      props: { className: "text-right", size: defTextSize },
                    };
                  }),
              ].slice(showFirstColumn ? 0 : 1),
            };
          }}
        />
      }
    />
  );
};

const OldTransparentCardCompact = (props: any) => {
  return <WhiteCard bgColor="transparent" {...props} />
}