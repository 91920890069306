import React, { useState, Fragment, useEffect } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import DebtsReports from '../../Reports/DebtReports/DebtsReports';
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";

const localStorageKey = "SalesOrderUnpayedOrders";

export default function SalesOrderUnpayedOrders() {
  const { t } = useTranslation();

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  function reset() {
    setSeconds(0);
    setIsActive(false);
  }

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const currentDate = moment().format("YYYY-MM-DD");

  const [showPopup, setShowPopup] = useState(
    window.localStorage.getItem(localStorageKey) !== currentDate
  );

  const closePopup = () => {
    reset();
    window.localStorage.setItem(localStorageKey, currentDate);
    setShowPopup(false);
  };

  if (!showPopup) {
    return <Fragment />;
  }

  return (
    <Popup isPopup={true} onClick={() => { }}  title={""}>
      <div
        style={{ width: 1200 }}
        className={"mx-auto"}
      >
        <div className={"text-center my-2"}>
          {seconds < 30 ? (
            <Fragment>
              <span className={"text-2xl text-blue-500"}>
                {`Check customer debts. You can close the window after ${(30 - seconds)} seconds.`}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <MainButton
                className={"w-full"}
                onClick={closePopup}
              >
                {t("Close")}
              </MainButton>
            </Fragment>
          )}
        </div>

        <div>
          <DebtsReports missHeader={true} />
        </div>
      </div>
    </Popup>
  );
}
