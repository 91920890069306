import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorAdrClassNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IAdrClassModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
  }

export const IAdrClassFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name"
];

export function useAdrClassHookNae(id: number) : IAdrClassModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorAdrClassNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "adr-class",
        fields: IAdrClassFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
