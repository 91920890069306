
import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";

export const NaePaths = [
    {
        "id": "saveCargoPenalties",
        "method": "post",
        "path": "/app/cargo/saveCargoPenalties",
        "parameters": []
    },
    {
        "id": "getSalesOrderCosts",
        "method": "post",
        "path": "/app/costs/getSalesOrderCosts",
        "parameters": []
    },
    {
        "id": "getKzCarriersOrderCosts",
        "method": "post",
        "path": "/app/costs/getKzCarriersOrderCosts",
        "parameters": []
    },
    {
        "id": "getKzCarriersOrdersCosts",
        "method": "post",
        "path": "/app/costs/getKzCarriersOrdersCosts",
        "parameters": []
    },
    {
        "id": "CUSTOMmoveLeadSourceClients",
        "method": "post",
        "path": "/app/custom/LeadSource/moveLeadSourceClients",
        "parameters": []
    },
    {
        "id": "pingtest",
        "method": "get",
        "path": "/app/tmp/ping",
        "parameters": []
    },
    {
        "id": "VOXsearchByPeriod",
        "method": "post",
        "path": "/app/Vox/searchByPeriod",
        "parameters": []
    },
    {
        "id": "VOXsearchByClient",
        "method": "post",
        "path": "/app/Vox/searchByClient",
        "parameters": []
    },
    {
        "id": "VOXsearchByClientFromLog",
        "method": "post",
        "path": "/app/Vox/searchByClientFromLog",
        "parameters": []
    },
    {
        "id": "VOXgetToken",
        "method": "post",
        "path": "/app/Vox/getToken",
        "parameters": []
    },
    {
        "id": "AppPluginsCalculationRecalculateRecalculate",
        "method": "post",
        "path": "/app/plugins/CalculationRecalculate/recalculate",
        "parameters": []
    },
    {
        "id": "AppPluginsCarServiceActIssueInvoice",
        "method": "post",
        "path": "/app/plugins/CarServiceAct/issueInvoice",
        "parameters": []
    },
    {
        "id": "AppPluginsCargoMoveToSoMove",
        "method": "post",
        "path": "/app/plugins/CargoMoveToSo/moveSo",
        "parameters": []
    },
    {
        "id": "AppPluginsCargoOperationConfirmationReset",
        "method": "post",
        "path": "/app/plugins/CargoOperationConfirmation/reset",
        "parameters": []
    },
    {
        "id": "AppPluginsCargoOperationConfirmationCreate",
        "method": "post",
        "path": "/app/plugins/CargoOperationConfirmation/create",
        "parameters": []
    },
    {
        "id": "pluginsCarrierFtlUserReserve",
        "method": "post",
        "path": "/app/plugins/CarrierFtlUser/reserve",
        "parameters": []
    },
    {
        "id": "pluginsCarrierFtlUserRelease",
        "method": "post",
        "path": "/app/plugins/CarrierFtlUser/release",
        "parameters": []
    },
    {
        "id": "AppPluginsCarrierMerge",
        "method": "post",
        "path": "/app/plugins/CarrierMerge/merge",
        "parameters": []
    },
    {
        "id": "AppPluginsClientAssign",
        "method": "post",
        "path": "/app/plugins/ClientAssign/assign",
        "parameters": []
    },
    {
        "id": "AppPluginsClientImport",
        "method": "post",
        "path": "/app/plugins/ClientImport/doImport",
        "parameters": []
    },
    {
        "id": "AppPluginsClientMerge",
        "method": "post",
        "path": "/app/plugins/ClientMerge/merge",
        "parameters": []
    },
    {
        "id": "ClientNeedBusinessDevResetContactsNotFound",
        "method": "post",
        "path": "/app/plugins/ClientNeedBusinessDev/resetContactsNotFound",
        "parameters": []
    },
    {
        "id": "NeedBusinessDevClientUnAssign",
        "method": "post",
        "path": "/app/plugins/ClientNeedBusinessDev/unAssign",
        "parameters": []
    },
    {
        "id": "AppPluginsClientOwnersResetOwner",
        "method": "post",
        "path": "/app/plugins/ClientOwnersReset/unAssign",
        "parameters": []
    },
    {
        "id": "AppPluginsClientOwnersMoveOwnerClient",
        "method": "post",
        "path": "/app/plugins/ClientOwnersReset/moveOwnerClient",
        "parameters": []
    },
    {
        "id": "AppPluginsClientOwnersResetOwnerClient",
        "method": "post",
        "path": "/app/plugins/ClientOwnersReset/unAssignClient",
        "parameters": []
    },
    {
        "id": "AppPluginsClientOwnersResetOwnerLead",
        "method": "post",
        "path": "/app/plugins/ClientOwnersReset/unAssignLead",
        "parameters": []
    },
    {
        "id": "AppPluginsClientOwnersMoveOwnerLead",
        "method": "post",
        "path": "/app/plugins/ClientOwnersReset/moveOwnerLead",
        "parameters": []
    },
    {
        "id": "pluginsClientStatusGetReports",
        "method": "post",
        "path": "/app/plugins/ClientStatus/getReports",
        "parameters": []
    },
    {
        "id": "pluginsClientTop20MoveTop20",
        "method": "post",
        "path": "/app/plugins/ClientTop20/moveTop20",
        "parameters": []
    },
    {
        "id": "pluginsClientTop20GetTop20",
        "method": "post",
        "path": "/app/plugins/ClientTop20/getTop20",
        "parameters": []
    },
    {
        "id": "pluginsDebtDiscussedAddDebtDiscussed",
        "method": "post",
        "path": "/app/plugins/DebtDiscussed/addDebtDiscussed",
        "parameters": []
    },
    {
        "id": "pluginsFinvaldaExportExport",
        "method": "post",
        "path": "/app/plugins/FinvaldExport/export",
        "parameters": []
    },
    {
        "id": "pluginsKzArrivedAtLoadArrived",
        "method": "post",
        "path": "/app/plugins/KzArrivedAtLoad/arrived",
        "parameters": []
    },
    {
        "id": "pluginsKzFtlFtlWinner",
        "method": "post",
        "path": "/app/plugins/KzFtl/ftlWinner",
        "parameters": []
    },
    {
        "id": "onPurchaseOrderCreateCallback",
        "method": "post",
        "path": "/app/plugins/PoFtl/onPurchaseOrderCreateCallback",
        "parameters": []
    },
    {
        "id": "pluginsPoFtlFtlArchive",
        "method": "post",
        "path": "/app/plugins/PoFtl/ftlArchive",
        "parameters": []
    },
    {
        "id": "pluginsPoFtlFtlWinner",
        "method": "post",
        "path": "/app/plugins/PoFtl/ftlWinner",
        "parameters": []
    },
    {
        "id": "pluginsPoFtlFtlLastCall",
        "method": "post",
        "path": "/app/plugins/PoFtl/lastCall",
        "parameters": []
    },
    {
        "id": "pluginsPriceListLtlGenerate",
        "method": "post",
        "path": "/app/plugins/PriceListLtl/generate",
        "parameters": []
    },
    {
        "id": "pluginsPriceListLtlEmpty",
        "method": "post",
        "path": "/app/plugins/PriceListLtl/empty",
        "parameters": []
    },
    {
        "id": "AddToSalesOrderFileProcessor",
        "method": "post",
        "path": "/app/Plugins/SalesOrderBundleCache/add",
        "parameters": []
    },
    {
        "id": "pluginsSalesOrderConfirmationSendToConfirm",
        "method": "post",
        "path": "/app/plugins/SalesOrderConfirmation/sendToConfirm",
        "parameters": []
    },
    {
        "id": "pluginsSalesOrderFullCostRecaclulateOrderFullCost",
        "method": "post",
        "path": "/app/plugins/SalesOrderFullCost/recaclulateOrderFullCost",
        "parameters": []
    },
    {
        "id": "pluginsSalesOrderFullCostRecaclulateOrderListFullCost",
        "method": "post",
        "path": "/app/plugins/SalesOrderFullCost/recaclulateOrderListFullCost",
        "parameters": []
    },
    {
        "id": "AppPluginsSepaExportAddInvoice",
        "method": "post",
        "path": "/app/plugins/SepaExport/addInvoice",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudSoContacts",
        "method": "post",
        "path": "/app/plugins/TagsContacts/soContacts",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudClientContacts",
        "method": "post",
        "path": "/app/plugins/TagsContacts/clientContacts",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCarServiceService",
        "method": "post",
        "path": "/app/plugins/Tags/serviceServices",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudPaymentTerms",
        "method": "post",
        "path": "/app/plugins/Tags/paymentTerms",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCommentLoading",
        "method": "post",
        "path": "/app/plugins/Tags/commentLoading",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCommentIkNotes",
        "method": "post",
        "path": "/app/plugins/Tags/commentIkNotes",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCommentProcessing",
        "method": "post",
        "path": "/app/plugins/Tags/commentProcessing",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCommentOperation",
        "method": "post",
        "path": "/app/plugins/Tags/commentOperation",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudLoadingCalculationsNotesSuggest",
        "method": "post",
        "path": "/app/plugins/Tags/loadingCalculationsNotesSuggest",
        "parameters": []
    },
    {
        "id": "AppPluginsUserLtlFtlRelToggleLtl",
        "method": "post",
        "path": "/app/public/plugins/UserLtlFtlRel/toggleLtl",
        "parameters": []
    },
    {
        "id": "AppPluginsUserLtlFtlRelToggleFtl",
        "method": "post",
        "path": "/app/public/plugins/UserLtlFtlRel/toggleFtl",
        "parameters": []
    },
    {
        "id": "AppPluginsViltSalesOrderIssueInvoice",
        "method": "post",
        "path": "/app/plugins/ViltSalesOrder/issueInvoice",
        "parameters": []
    },
    {
        "id": "NAEauthGetProfile",
        "method": "post",
        "path": "/app/nae-core/auth/get",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderList",
        "method": "post",
        "path": "/app/nae-core/config-builder/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderSave",
        "method": "post",
        "path": "/app/nae-core/config-builder/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderRemove",
        "method": "post",
        "path": "/app/nae-core/config-builder/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsList",
        "method": "post",
        "path": "/app/nae-core/config-defaults/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsSave",
        "method": "post",
        "path": "/app/nae-core/config-defaults/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsRemove",
        "method": "post",
        "path": "/app/nae-core/config-defaults/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditList",
        "method": "post",
        "path": "/app/nae-core/config-edit/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditSave",
        "method": "post",
        "path": "/app/nae-core/config-edit/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditRemove",
        "method": "post",
        "path": "/app/nae-core/config-edit/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabList",
        "method": "post",
        "path": "/app/nae-core/config-tabs/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabSave",
        "method": "post",
        "path": "/app/nae-core/config-tabs/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabRemove",
        "method": "post",
        "path": "/app/nae-core/config-tabs/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewList",
        "method": "post",
        "path": "/app/nae-core/config-view/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewSave",
        "method": "post",
        "path": "/app/nae-core/config-view/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewRemove",
        "method": "post",
        "path": "/app/nae-core/config-view/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetList",
        "method": "post",
        "path": "/app/nae-core/config-widgets/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetSave",
        "method": "post",
        "path": "/app/nae-core/config-widgets/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetRemove",
        "method": "post",
        "path": "/app/nae-core/config-widgets/removeConfig",
        "parameters": []
    },
    {
        "id": "NAEUExport",
        "method": "post",
        "path": "/app/nae-core/export/doExport",
        "parameters": []
    },
    {
        "id": "NAEfileRemove",
        "method": "post",
        "path": "/app/nae-core/files/remove",
        "parameters": []
    },
    {
        "id": "NAEfilesList",
        "method": "post",
        "path": "/app/nae-core/files/list",
        "parameters": []
    },
    {
        "id": "NAEfilesMultipleList",
        "method": "post",
        "path": "/app/nae-core/files/multipleList",
        "parameters": []
    },
    {
        "id": "NAEUImport",
        "method": "post",
        "path": "/app/nae-core/import/mainImport",
        "parameters": []
    },
    {
        "id": "NAEmailsGetData",
        "method": "post",
        "path": "/app/nae-core/mails/getData",
        "parameters": []
    },
    {
        "id": "NAEmailsSend",
        "method": "post",
        "path": "/app/nae-core/mails/send",
        "parameters": []
    },
    {
        "id": "NAENotesReadAll",
        "method": "post",
        "path": "/app/nae-core/plugins/notes/readAllMessages",
        "parameters": []
    },
    {
        "id": "NAEPing",
        "method": "get",
        "path": "/app/nae-core/u/ping",
        "parameters": []
    },
    {
        "id": "NAEUMultipleListForModels",
        "method": "post",
        "path": "/app/nae-core/u/getMultipleForModel",
        "parameters": []
    },
    {
        "id": "NAEUMultipleList",
        "method": "post",
        "path": "/app/nae-core/u/getMultiple",
        "parameters": []
    },
    {
        "id": "NAEUCCaheList",
        "method": "post",
        "path": "/app/nae-core/u/getCache",
        "parameters": []
    },
    {
        "id": "NAEUListByToken",
        "method": "post",
        "path": "/app/nae-core/u/getByToken",
        "parameters": []
    },
    {
        "id": "NAEUList",
        "method": "post",
        "path": "/app/nae-core/u/get/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSave",
        "method": "post",
        "path": "/app/nae-core/u/save/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSaveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/saveMultiple",
        "parameters": []
    },
    {
        "id": "NAEURemove",
        "method": "post",
        "path": "/app/nae-core/u/remove/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEURemoveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/removeMultiple",
        "parameters": []
    },
    {
        "id": "NAEXlsxToArray",
        "method": "post",
        "path": "/app/nae-core/xlsx/toArray",
        "parameters": []
    }
]
export const NaePathsMap = {
    "get": {
        "pingtest": "/app/tmp/ping",
        "NAEPing": "/app/nae-core/u/ping"
    },
    "post": {
        "saveCargoPenalties": "/app/cargo/saveCargoPenalties",
        "getSalesOrderCosts": "/app/costs/getSalesOrderCosts",
        "getKzCarriersOrderCosts": "/app/costs/getKzCarriersOrderCosts",
        "getKzCarriersOrdersCosts": "/app/costs/getKzCarriersOrdersCosts",
        "CUSTOMmoveLeadSourceClients": "/app/custom/LeadSource/moveLeadSourceClients",
        "VOXsearchByPeriod": "/app/Vox/searchByPeriod",
        "VOXsearchByClient": "/app/Vox/searchByClient",
        "VOXsearchByClientFromLog": "/app/Vox/searchByClientFromLog",
        "VOXgetToken": "/app/Vox/getToken",
        "AppPluginsCalculationRecalculateRecalculate": "/app/plugins/CalculationRecalculate/recalculate",
        "AppPluginsCarServiceActIssueInvoice": "/app/plugins/CarServiceAct/issueInvoice",
        "AppPluginsCargoMoveToSoMove": "/app/plugins/CargoMoveToSo/moveSo",
        "AppPluginsCargoOperationConfirmationReset": "/app/plugins/CargoOperationConfirmation/reset",
        "AppPluginsCargoOperationConfirmationCreate": "/app/plugins/CargoOperationConfirmation/create",
        "pluginsCarrierFtlUserReserve": "/app/plugins/CarrierFtlUser/reserve",
        "pluginsCarrierFtlUserRelease": "/app/plugins/CarrierFtlUser/release",
        "AppPluginsCarrierMerge": "/app/plugins/CarrierMerge/merge",
        "AppPluginsClientAssign": "/app/plugins/ClientAssign/assign",
        "AppPluginsClientImport": "/app/plugins/ClientImport/doImport",
        "AppPluginsClientMerge": "/app/plugins/ClientMerge/merge",
        "ClientNeedBusinessDevResetContactsNotFound": "/app/plugins/ClientNeedBusinessDev/resetContactsNotFound",
        "NeedBusinessDevClientUnAssign": "/app/plugins/ClientNeedBusinessDev/unAssign",
        "AppPluginsClientOwnersResetOwner": "/app/plugins/ClientOwnersReset/unAssign",
        "AppPluginsClientOwnersMoveOwnerClient": "/app/plugins/ClientOwnersReset/moveOwnerClient",
        "AppPluginsClientOwnersResetOwnerClient": "/app/plugins/ClientOwnersReset/unAssignClient",
        "AppPluginsClientOwnersResetOwnerLead": "/app/plugins/ClientOwnersReset/unAssignLead",
        "AppPluginsClientOwnersMoveOwnerLead": "/app/plugins/ClientOwnersReset/moveOwnerLead",
        "pluginsClientStatusGetReports": "/app/plugins/ClientStatus/getReports",
        "pluginsClientTop20MoveTop20": "/app/plugins/ClientTop20/moveTop20",
        "pluginsClientTop20GetTop20": "/app/plugins/ClientTop20/getTop20",
        "pluginsDebtDiscussedAddDebtDiscussed": "/app/plugins/DebtDiscussed/addDebtDiscussed",
        "pluginsFinvaldaExportExport": "/app/plugins/FinvaldExport/export",
        "pluginsKzArrivedAtLoadArrived": "/app/plugins/KzArrivedAtLoad/arrived",
        "pluginsKzFtlFtlWinner": "/app/plugins/KzFtl/ftlWinner",
        "onPurchaseOrderCreateCallback": "/app/plugins/PoFtl/onPurchaseOrderCreateCallback",
        "pluginsPoFtlFtlArchive": "/app/plugins/PoFtl/ftlArchive",
        "pluginsPoFtlFtlWinner": "/app/plugins/PoFtl/ftlWinner",
        "pluginsPoFtlFtlLastCall": "/app/plugins/PoFtl/lastCall",
        "pluginsPriceListLtlGenerate": "/app/plugins/PriceListLtl/generate",
        "pluginsPriceListLtlEmpty": "/app/plugins/PriceListLtl/empty",
        "AddToSalesOrderFileProcessor": "/app/Plugins/SalesOrderBundleCache/add",
        "pluginsSalesOrderConfirmationSendToConfirm": "/app/plugins/SalesOrderConfirmation/sendToConfirm",
        "pluginsSalesOrderFullCostRecaclulateOrderFullCost": "/app/plugins/SalesOrderFullCost/recaclulateOrderFullCost",
        "pluginsSalesOrderFullCostRecaclulateOrderListFullCost": "/app/plugins/SalesOrderFullCost/recaclulateOrderListFullCost",
        "AppPluginsSepaExportAddInvoice": "/app/plugins/SepaExport/addInvoice",
        "AppPluginsTagCloudSoContacts": "/app/plugins/TagsContacts/soContacts",
        "AppPluginsTagCloudClientContacts": "/app/plugins/TagsContacts/clientContacts",
        "AppPluginsTagCloudCarServiceService": "/app/plugins/Tags/serviceServices",
        "AppPluginsTagCloudPaymentTerms": "/app/plugins/Tags/paymentTerms",
        "AppPluginsTagCloudCommentLoading": "/app/plugins/Tags/commentLoading",
        "AppPluginsTagCloudCommentIkNotes": "/app/plugins/Tags/commentIkNotes",
        "AppPluginsTagCloudCommentProcessing": "/app/plugins/Tags/commentProcessing",
        "AppPluginsTagCloudCommentOperation": "/app/plugins/Tags/commentOperation",
        "AppPluginsTagCloudLoadingCalculationsNotesSuggest": "/app/plugins/Tags/loadingCalculationsNotesSuggest",
        "AppPluginsUserLtlFtlRelToggleLtl": "/app/public/plugins/UserLtlFtlRel/toggleLtl",
        "AppPluginsUserLtlFtlRelToggleFtl": "/app/public/plugins/UserLtlFtlRel/toggleFtl",
        "AppPluginsViltSalesOrderIssueInvoice": "/app/plugins/ViltSalesOrder/issueInvoice",
        "NAEauthGetProfile": "/app/nae-core/auth/get",
        "NaeConfigBuilderList": "/app/nae-core/config-builder/listConfig",
        "NaeConfigBuilderSave": "/app/nae-core/config-builder/saveConfig",
        "NaeConfigBuilderRemove": "/app/nae-core/config-builder/removeConfig",
        "NaeConfigDefaultsList": "/app/nae-core/config-defaults/listConfig",
        "NaeConfigDefaultsSave": "/app/nae-core/config-defaults/saveConfig",
        "NaeConfigDefaultsRemove": "/app/nae-core/config-defaults/removeConfig",
        "NaeConfigEditList": "/app/nae-core/config-edit/listConfig",
        "NaeConfigEditSave": "/app/nae-core/config-edit/saveConfig",
        "NaeConfigEditRemove": "/app/nae-core/config-edit/removeConfig",
        "NaeConfigTabList": "/app/nae-core/config-tabs/listConfig",
        "NaeConfigTabSave": "/app/nae-core/config-tabs/saveConfig",
        "NaeConfigTabRemove": "/app/nae-core/config-tabs/removeConfig",
        "NaeConfigViewList": "/app/nae-core/config-view/listConfig",
        "NaeConfigViewSave": "/app/nae-core/config-view/saveConfig",
        "NaeConfigViewRemove": "/app/nae-core/config-view/removeConfig",
        "NaeConfigWidgetList": "/app/nae-core/config-widgets/listConfig",
        "NaeConfigWidgetSave": "/app/nae-core/config-widgets/saveConfig",
        "NaeConfigWidgetRemove": "/app/nae-core/config-widgets/removeConfig",
        "NAEUExport": "/app/nae-core/export/doExport",
        "NAEfileRemove": "/app/nae-core/files/remove",
        "NAEfilesList": "/app/nae-core/files/list",
        "NAEfilesMultipleList": "/app/nae-core/files/multipleList",
        "NAEUImport": "/app/nae-core/import/mainImport",
        "NAEmailsGetData": "/app/nae-core/mails/getData",
        "NAEmailsSend": "/app/nae-core/mails/send",
        "NAENotesReadAll": "/app/nae-core/plugins/notes/readAllMessages",
        "NAEUMultipleListForModels": "/app/nae-core/u/getMultipleForModel",
        "NAEUMultipleList": "/app/nae-core/u/getMultiple",
        "NAEUCCaheList": "/app/nae-core/u/getCache",
        "NAEUListByToken": "/app/nae-core/u/getByToken",
        "NAEUList": "/app/nae-core/u/get/{schema}",
        "NAEUSave": "/app/nae-core/u/save/{schema}",
        "NAEUSaveMultiple": "/app/nae-core/u/saveMultiple",
        "NAEURemove": "/app/nae-core/u/remove/{schema}",
        "NAEURemoveMultiple": "/app/nae-core/u/removeMultiple",
        "NAEXlsxToArray": "/app/nae-core/xlsx/toArray"
    }
}

export const NaeApiFunctions = {
    'saveCargoPenalties': (data: any) => {
        const url = '/app/cargo/saveCargoPenalties';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'getSalesOrderCosts': (data: any) => {
        const url = '/app/costs/getSalesOrderCosts';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'getKzCarriersOrderCosts': (data: any) => {
        const url = '/app/costs/getKzCarriersOrderCosts';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'getKzCarriersOrdersCosts': (data: any) => {
        const url = '/app/costs/getKzCarriersOrdersCosts';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'CUSTOMmoveLeadSourceClients': (data: any) => {
        const url = '/app/custom/LeadSource/moveLeadSourceClients';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pingtest': () => {
        const url = '/app/tmp/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'VOXsearchByPeriod': (data: any) => {
        const url = '/app/Vox/searchByPeriod';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'VOXsearchByClient': (data: any) => {
        const url = '/app/Vox/searchByClient';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'VOXsearchByClientFromLog': (data: any) => {
        const url = '/app/Vox/searchByClientFromLog';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'VOXgetToken': (data: any) => {
        const url = '/app/Vox/getToken';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCalculationRecalculateRecalculate': (data: any) => {
        const url = '/app/plugins/CalculationRecalculate/recalculate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCarServiceActIssueInvoice': (data: any) => {
        const url = '/app/plugins/CarServiceAct/issueInvoice';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCargoMoveToSoMove': (data: any) => {
        const url = '/app/plugins/CargoMoveToSo/moveSo';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCargoOperationConfirmationReset': (data: any) => {
        const url = '/app/plugins/CargoOperationConfirmation/reset';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCargoOperationConfirmationCreate': (data: any) => {
        const url = '/app/plugins/CargoOperationConfirmation/create';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsCarrierFtlUserReserve': (data: any) => {
        const url = '/app/plugins/CarrierFtlUser/reserve';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsCarrierFtlUserRelease': (data: any) => {
        const url = '/app/plugins/CarrierFtlUser/release';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsCarrierMerge': (data: any) => {
        const url = '/app/plugins/CarrierMerge/merge';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientAssign': (data: any) => {
        const url = '/app/plugins/ClientAssign/assign';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientImport': (data: any) => {
        const url = '/app/plugins/ClientImport/doImport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientMerge': (data: any) => {
        const url = '/app/plugins/ClientMerge/merge';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'ClientNeedBusinessDevResetContactsNotFound': (data: any) => {
        const url = '/app/plugins/ClientNeedBusinessDev/resetContactsNotFound';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NeedBusinessDevClientUnAssign': (data: any) => {
        const url = '/app/plugins/ClientNeedBusinessDev/unAssign';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientOwnersResetOwner': (data: any) => {
        const url = '/app/plugins/ClientOwnersReset/unAssign';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientOwnersMoveOwnerClient': (data: any) => {
        const url = '/app/plugins/ClientOwnersReset/moveOwnerClient';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientOwnersResetOwnerClient': (data: any) => {
        const url = '/app/plugins/ClientOwnersReset/unAssignClient';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientOwnersResetOwnerLead': (data: any) => {
        const url = '/app/plugins/ClientOwnersReset/unAssignLead';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsClientOwnersMoveOwnerLead': (data: any) => {
        const url = '/app/plugins/ClientOwnersReset/moveOwnerLead';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsClientStatusGetReports': (data: any) => {
        const url = '/app/plugins/ClientStatus/getReports';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsClientTop20MoveTop20': (data: any) => {
        const url = '/app/plugins/ClientTop20/moveTop20';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsClientTop20GetTop20': (data: any) => {
        const url = '/app/plugins/ClientTop20/getTop20';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDebtDiscussedAddDebtDiscussed': (data: any) => {
        const url = '/app/plugins/DebtDiscussed/addDebtDiscussed';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsFinvaldaExportExport': (data: any) => {
        const url = '/app/plugins/FinvaldExport/export';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsKzArrivedAtLoadArrived': (data: any) => {
        const url = '/app/plugins/KzArrivedAtLoad/arrived';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsKzFtlFtlWinner': (data: any) => {
        const url = '/app/plugins/KzFtl/ftlWinner';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'onPurchaseOrderCreateCallback': (data: any) => {
        const url = '/app/plugins/PoFtl/onPurchaseOrderCreateCallback';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsPoFtlFtlArchive': (data: any) => {
        const url = '/app/plugins/PoFtl/ftlArchive';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsPoFtlFtlWinner': (data: any) => {
        const url = '/app/plugins/PoFtl/ftlWinner';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsPoFtlFtlLastCall': (data: any) => {
        const url = '/app/plugins/PoFtl/lastCall';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsPriceListLtlGenerate': (data: any) => {
        const url = '/app/plugins/PriceListLtl/generate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsPriceListLtlEmpty': (data: any) => {
        const url = '/app/plugins/PriceListLtl/empty';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AddToSalesOrderFileProcessor': (data: any) => {
        const url = '/app/Plugins/SalesOrderBundleCache/add';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsSalesOrderConfirmationSendToConfirm': (data: any) => {
        const url = '/app/plugins/SalesOrderConfirmation/sendToConfirm';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsSalesOrderFullCostRecaclulateOrderFullCost': (data: any) => {
        const url = '/app/plugins/SalesOrderFullCost/recaclulateOrderFullCost';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsSalesOrderFullCostRecaclulateOrderListFullCost': (data: any) => {
        const url = '/app/plugins/SalesOrderFullCost/recaclulateOrderListFullCost';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsSepaExportAddInvoice': (data: any) => {
        const url = '/app/plugins/SepaExport/addInvoice';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudSoContacts': (data: any) => {
        const url = '/app/plugins/TagsContacts/soContacts';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudClientContacts': (data: any) => {
        const url = '/app/plugins/TagsContacts/clientContacts';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCarServiceService': (data: any) => {
        const url = '/app/plugins/Tags/serviceServices';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudPaymentTerms': (data: any) => {
        const url = '/app/plugins/Tags/paymentTerms';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCommentLoading': (data: any) => {
        const url = '/app/plugins/Tags/commentLoading';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCommentIkNotes': (data: any) => {
        const url = '/app/plugins/Tags/commentIkNotes';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCommentProcessing': (data: any) => {
        const url = '/app/plugins/Tags/commentProcessing';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCommentOperation': (data: any) => {
        const url = '/app/plugins/Tags/commentOperation';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudLoadingCalculationsNotesSuggest': (data: any) => {
        const url = '/app/plugins/Tags/loadingCalculationsNotesSuggest';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsUserLtlFtlRelToggleLtl': (data: any) => {
        const url = '/app/public/plugins/UserLtlFtlRel/toggleLtl';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsUserLtlFtlRelToggleFtl': (data: any) => {
        const url = '/app/public/plugins/UserLtlFtlRel/toggleFtl';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsViltSalesOrderIssueInvoice': (data: any) => {
        const url = '/app/plugins/ViltSalesOrder/issueInvoice';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEauthGetProfile': (data: any) => {
        const url = '/app/nae-core/auth/get';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderList': (data: any) => {
        const url = '/app/nae-core/config-builder/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderSave': (data: any) => {
        const url = '/app/nae-core/config-builder/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderRemove': (data: any) => {
        const url = '/app/nae-core/config-builder/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsList': (data: any) => {
        const url = '/app/nae-core/config-defaults/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsSave': (data: any) => {
        const url = '/app/nae-core/config-defaults/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsRemove': (data: any) => {
        const url = '/app/nae-core/config-defaults/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditList': (data: any) => {
        const url = '/app/nae-core/config-edit/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditSave': (data: any) => {
        const url = '/app/nae-core/config-edit/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditRemove': (data: any) => {
        const url = '/app/nae-core/config-edit/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabList': (data: any) => {
        const url = '/app/nae-core/config-tabs/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabSave': (data: any) => {
        const url = '/app/nae-core/config-tabs/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabRemove': (data: any) => {
        const url = '/app/nae-core/config-tabs/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewList': (data: any) => {
        const url = '/app/nae-core/config-view/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewSave': (data: any) => {
        const url = '/app/nae-core/config-view/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewRemove': (data: any) => {
        const url = '/app/nae-core/config-view/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetList': (data: any) => {
        const url = '/app/nae-core/config-widgets/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetSave': (data: any) => {
        const url = '/app/nae-core/config-widgets/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetRemove': (data: any) => {
        const url = '/app/nae-core/config-widgets/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUExport': (data: any) => {
        const url = '/app/nae-core/export/doExport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfileRemove': (data: any) => {
        const url = '/app/nae-core/files/remove';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesList': (data: any) => {
        const url = '/app/nae-core/files/list';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesMultipleList': (data: any) => {
        const url = '/app/nae-core/files/multipleList';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUImport': (data: any) => {
        const url = '/app/nae-core/import/mainImport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsGetData': (data: any) => {
        const url = '/app/nae-core/mails/getData';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsSend': (data: any) => {
        const url = '/app/nae-core/mails/send';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAENotesReadAll': (data: any) => {
        const url = '/app/nae-core/plugins/notes/readAllMessages';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEPing': () => {
        const url = '/app/nae-core/u/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'NAEUMultipleListForModels': (data: any) => {
        const url = '/app/nae-core/u/getMultipleForModel';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUMultipleList': (data: any) => {
        const url = '/app/nae-core/u/getMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUCCaheList': (data: any) => {
        const url = '/app/nae-core/u/getCache';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUListByToken': (data: any) => {
        const url = '/app/nae-core/u/getByToken';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUList': (data: any,schema: string) => {
        const url = '/app/nae-core/u/get/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSave': (data: any,schema: string) => {
        const url = '/app/nae-core/u/save/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSaveMultiple': (data: any) => {
        const url = '/app/nae-core/u/saveMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemove': (data: any,schema: string) => {
        const url = '/app/nae-core/u/remove/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemoveMultiple': (data: any) => {
        const url = '/app/nae-core/u/removeMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEXlsxToArray': (data: any) => {
        const url = '/app/nae-core/xlsx/toArray';
        
        
                    return axiosInstance.post(url, data);
                    
    },

        }