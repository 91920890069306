import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorInvoiceOutgoingPriceChangeNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IInvoiceOutgoingPriceChangeModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      invoiceOutgoing: {
              id: number,
            },
      salesOrder: {
              id: number,
            },
  }

export const IInvoiceOutgoingPriceChangeFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "invoiceOutgoing.id",
    "salesOrder.id"
];

export function useInvoiceOutgoingPriceChangeHookNae(id: number) : IInvoiceOutgoingPriceChangeModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorInvoiceOutgoingPriceChangeNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "invoice-outgoing-price-change",
        fields: IInvoiceOutgoingPriceChangeFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
