import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../../../../_generated/_custom/models-cache-data/types';
import { useCalculationHookNae } from '../../../../../../_generated/_custom/hooks/useCalculationHookNae';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserAdmin, checkUserManager } from '../../../../../../_generated/_custom/config/NaeSPermissions';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

export default function CalculationCancelWidget(props: PropsId) {
    const element = useCalculationHookNae(props.id);

    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);
    const isManager = checkUserManager(userState);

    const [saveData,] = useUSave("calculation");
    const cancelCalculation = () => {
        saveData({ status: 900 }, element.id)
    }

    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }
    if (!(isAdmin || isManager)) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />
    }
    let disabledContent = '';
    if (!!element.salesOrder) {
        disabledContent = 'It is impossible to cancel the calculation, since an order already exists';
    }

    return (
        <MenuItem
            iconName={"ban"}
            onClick={cancelCalculation}
            textColor={"red"}
            confirmation={true}
            isDisabled={!!disabledContent}
            tooltipContent={disabledContent}
        >
            {t("Cancel calculation")}
        </MenuItem>
    )
}
