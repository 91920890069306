import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';

export default function CalculationSerialNumber(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.serialNumber}
        </Fragment>
    )
}
