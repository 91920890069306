import React, { Fragment, useEffect } from "react";
import OperativeAdminCalculations from "./OperativeAdminCalculations";
import OperativeAdminConfirm from "./OperativeAdminConfirm";
import OperativeAdminPriceChanges from "./OperativeAdminPriceChanges";
import OperativeAdminNeedDocs from './OperativeAdminNeedDocs';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { useTranslation } from 'react-i18next';

export default function DashboardOperativeAdmin() {
  const { t } = useTranslation();

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t(''));
  }, []);

  return (
    <Fragment>
      <div className={"grid grid-cols-1 gap-2"}>

        <OperativeAdminCalculations />

        <OperativeAdminConfirm />

        <OperativeAdminPriceChanges />

        <OperativeAdminNeedDocs serviceCooNew={true} serviceCooRenew={true} serviceInsurance={true}/>

      </div>
    </Fragment>
  );
}
