import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import WorkDirectionIn from "./WorkDirectionIn";
import WorkDirectionOut from "./WorkDirectionOut";

interface Props {
  element: any,
  updateElement: (key: string, val: any) => void,
}

export default function CustomWorkDirectionField(props: Props) {
  const {t} = useTranslation();

  const [selectedIn, setSelectedIn] = useState<number[]>(
    props.element.unloadingCountries
  );
  const toggleSelectedIn = (id: number) => {
    if (selectedIn.indexOf(id) >= 0) {
      setSelectedIn(selectedIn.filter((i) => i !== id));
    } else {
      setSelectedIn([...selectedIn, id]);
    }
  };
  const [selectedOut, setSelectedOut] = useState<number[]>(
    props.element.loadingCountries
  );
  const toggleSelectedOut = (id: number) => {
    if (selectedOut.indexOf(id) >= 0) {
      setSelectedOut(selectedOut.filter((i) => i !== id));
    } else {
      setSelectedOut([...selectedOut, id]);
    }
  };

  useEffect(() => {
    if (props.element.unloadingCountries.length != selectedIn.length) {
      props.updateElement("unloadingCountries", selectedIn);
    }
  }, [selectedIn]);
  useEffect(() => {
    if (props.element.loadingCountries.length != selectedOut.length) {
      props.updateElement("loadingCountries", selectedOut);
    }
  }, [selectedOut]);

  return (
    <Fragment>
      <div className={"w-full"}>
        <p className="font-bold">{t('Directions')}</p>
        <div className={"grid grid-cols-2 gap-2"}>
          <div className={"w-full h-80 overflow-auto"}>
            <WorkDirectionOut
              {...props}
              selected={selectedOut}
              toggleSelected={toggleSelectedOut}
            />
          </div>
          <div className={"w-full h-80 overflow-auto"}>
            <WorkDirectionIn
              {...props}
              selected={selectedIn}
              toggleSelected={toggleSelectedIn}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
