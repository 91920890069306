import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { useTranslation } from 'react-i18next';
import { MenuItemWithEdit } from '@newageerp/v3.bundles.modal-bundle';

export default function SalesOrderContactChange(props: PropsId) {
    const { t } = useTranslation();
    const element = useSalesOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }
    if (element.status <= 0) {
        return <Fragment />
    }

    return (
        <MenuItemWithEdit
            elementId={props.id}
            schema={"sales-order"}
            forcePopup={true}
            type={"contactChange"}
            iconName="edit"
        >
            {t("Update contact person")}
        </MenuItemWithEdit>
    )
}
