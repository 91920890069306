import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../../../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";
import { useSalesOrderHookNae } from '../../../../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { SFSOpenEditModalWindow } from '@newageerp/v3.bundles.popup-bundle'

export default function SalesOrderActWidget(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    const openPopup = () => {
        SFSOpenEditModalWindow(
            "sales-order",
            element.id,
            "act-info"
        )
    }

    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }
    if (element.status <= 0) {
        return <Fragment />
    }

    return (
        <MenuItem
            onClick={openPopup}
            iconName={"edit"}
        >
            {t("Change invoice/act ratio")}
        </MenuItem>
    )
}
