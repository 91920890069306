import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CargoLogisticLink from "../../../components/cargo/CargoLogisticLink";
import OperationalCargoReturnSuccess from "../../Cargo/Operational/OperationalCargoReturnSuccess";
import OperationalCargoReturnText from "../../Cargo/Operational/OperationalCargoReturnText";
import SenderName from '../../../_generated/_custom/models-cache-data/Sender/SenderName';
import UserFullName from '../../../_generated/_custom/models-cache-data/User/UserFullName';
import SalesOrderClientOrFilialName from '../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName';
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { checkUserOperationalPro } from "../../../_generated/_custom/config/NaeSPermissions";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import CargoSellerDidEx from "../../../Plugins/Cargo/CargoSellerDidEx";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";
import CargoWarehouseExOrder from "../../../Plugins/Cargo/CargoWarehouseExOrder";


let reloadTimeout: any = -1;

type Props = {
  userId: number
}

export default function OperativeCargoUrgent(props: Props) {
  const { t } = useTranslation();

  const { userState } = useTemplatesCore()
  const isOperationalPro = checkUserOperationalPro(userState);

  const [getData, getDataParams] = useUList<PropsId>("cargo", ['id']);
  const loadData = () => {
    let filter: any = [
      {
        "or": [
          ['i.operativeCardIndex', '=', 5, true],
          ['i.operativeCardIndex', '=', 6, true]
        ]
      },
      ['i.status', '!=', 900, true],
      ['i.operational', '=', props.userId, true]
    ];
    let mainFilter: any = { "and": filter };
    if (isOperationalPro) {
      mainFilter = {
        "or": [
          { "and": filter },
          {
            "and": [
              ['i.operativeCardIndex', '=', 7, true],
              ['i.status', '!=', 900, true]
            ]
          }
        ]
      }
    }

    getData(
      [
        mainFilter
      ],
      1,
      200,
      [
        { key: 'i.serialNumber', 'value': 'ASC' }
      ]
    )
  }
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-operativeCardIndex-5",
      "cargo-operativeCardIndex-5",
      reloadData
    );
    SocketService.addCallback(
      "cargo-operativeCardIndex-6",
      "cargo-operativeCardIndex-6",
      reloadData
    );
    SocketService.addCallback(
      "cargo-operativeCardIndex-7",
      "cargo-operativeCardIndex-7",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-operativeCardIndex-5",
        "cargo-operativeCardIndex-5"
      );
      SocketService.removeCallback(
        "cargo-operativeCardIndex-6",
        "cargo-operativeCardIndex-6"
      );
      SocketService.removeCallback(
        "cargo-operativeCardIndex-7",
        "cargo-operativeCardIndex-7"
      );
    };
  }, []);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Urgent tasks")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Sales order")}</Th>
                <Th>{t("Seller")}</Th>
                <Th>{t("Consignee")}</Th>
                <Th>{t("Transport operations manager")}</Th>
                <Th>{t("Sales manager")}</Th>
                <Th>{t('Who will prepare EX?')}</Th>
                <Th>{t("Comments")}</Th>
                <Th>{t("Is the task completed")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {getDataParams.data.data.map((el: PropsId, index: number) => {
                return <OperativeCargoNewLine key={`cargo-${el.id}`} id={el.id} />;
              })}
            </tbody>
          }
        />
      </WhiteCard>

    </Fragment>
  );
}


const OperativeCargoNewLine = (props: PropsId) => {
  const el = useCargoHookNae(props.id);
  if (!el) {
    return <Fragment />;
  }
  return (
    <tr>
      <Td>
        <CargoLogisticLink id={el.id} />
      </Td>
      <Td><DfValueView path="cargo.salesOrder.serialNumber" link={true} id={el.id} /></Td>
      <Td>{el.seller && <DfValueView path="sender.name" link={true} id={el.seller.id} />}</Td>
      <Td>
        {el.salesOrder && (
          <SalesOrderClientOrFilialName id={el.salesOrder.id} />
        )}
      </Td>
      <Td>{!!el.expeditor && <UserFullName id={el.expeditor.id} />}</Td>
      <Td>{!!el.creator && <UserFullName id={el.creator.id} />}</Td>
      <Td>
        <CargoSellerDidEx id={el.id} />
        <CargoWarehouseExOrder id={el.id} />
      </Td>
      <Td>
        <OperationalCargoReturnText id={el.id} />
      </Td>
      <Td>
        <OperationalCargoReturnSuccess id={el.id} />
      </Td>
    </tr>
  );
};