import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorViltTripNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IViltTripModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
  }

export const IViltTripFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status"
];

export function useViltTripHookNae(id: number) : IViltTripModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorViltTripNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "vilt-trip",
        fields: IViltTripFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
