import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

type Props = {
    link?: boolean,
} & PropsId;

export default function SalesOrderClientOrFilialName(props: Props) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.clientFilial ? <DfValueView link={props.link} path='client-filial.name' id={element.clientFilial.id} /> : (element.client ? <DfValueView link={props.link} path='client.name' id={element.client.id} /> : "")}
        </Fragment>
    )
}
