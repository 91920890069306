import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useClientHookNae } from '../../hooks/useClientHookNae';

export default function ClientName(props: PropsId) {
    const { id } = props;

    const client = useClientHookNae(id);
    if (!client) {
        return <Fragment />
    }

    return (
        <Fragment>
            {client.name}
        </Fragment>
    )
}
