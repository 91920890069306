import React, { Fragment } from "react";
import { PropsId } from "../types";
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { getPropertyEnumLabel } from "../../../v3/utils";

export default function CargoRailwayType(props: PropsId) {
  const element = useCargoHookNae(props.id);
  if (!element) {
    return <Fragment />;
  }
  if (element.typeOut !== "train") {
    return <Fragment />;
  }
  return (
    <Fragment>
      {getPropertyEnumLabel(
        "cargo",
        "railwayType",
        element.railwayType
      )}
    </Fragment>
  );
}
