import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import React, { Fragment } from 'react'
import { Template, TemplatesParser, useTemplatesCore } from '@newageerp/v3.templates.templates-core'
import { getSchemaTitle } from '../../../_generated/v3/utils';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import FtlCalculationSuggestions from '../Ftl/FtlCalculationSuggestions';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { checkUserHeadOfLogisticsHelper, CheckUserPermissionComponent, ENaeSPermissions } from '../../../_generated/_custom/config/NaeSPermissions';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTranslation } from 'react-i18next';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

type Props = {
  id: number,
  leftContent: Template[]
}

export default function IKCalculationsBottomWidget(props: Props) {
  const { t } = useTranslation();
  const element = useKzCarriersOrderHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);

  const [doReq, doReqParams] = useUSave(NaeSSchemaMap.KzCarriersOrder.schema)

  const doAction = () => {
    doReq(
      {
        needFtlSupport: element.needFtlSupport === 0 ? 10 : 0
      },
      props.id
    )
  }


  if (!element) {
    return <Fragment />
  }
  if (element.scopes.indexOf('type-auto') === -1 && element.scopes.indexOf('type-other') === -1) {
    return <Fragment />
  }

  return (
    <Fragment>
      <div className='flex gap-2'>
        <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserHeadOfLogistics]}>
          <MainButton iconName='hands-helping' onClick={doAction} color={element.needFtlSupport ? 'slate' : 'teal'}>
            {t('Share with FTL expeditor')}
          </MainButton>
        </CheckUserPermissionComponent>
        <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserHeadOfLogistics]}>
          <MainButton color='sky' iconName='edit' onClick={() => SFSOpenEditModalWindowProps({ schema: NaeSSchemaMap.KzCarriersOrder.schema, id: props.id, type: 'notesForFtlCalculation' })}>
            {t('Comment for FTL expeditor')}
          </MainButton>
        </CheckUserPermissionComponent>
        <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserHeadOfLogistics]}>
          <MainButton color='sky' iconName='edit' onClick={() => SFSOpenEditModalWindowProps({ schema: NaeSSchemaMap.KzCarriersOrder.schema, id: props.id, type: 'expeditorFtl' })}>
            {t('FTL expeditor')}
          </MainButton>
        </CheckUserPermissionComponent>
      </div>
      <div className='flex gap-4'>
        <WhiteCard isCompact={true} title={getSchemaTitle(NaeSSchemaMap.KzFtlCalculation.schema, true)}>
          <TemplatesParser
            templates={props.leftContent}
          />
        </WhiteCard>
        {!isTrackingSpecialist &&
          <WhiteCard isCompact={true}>
            <FtlCalculationSuggestions id={props.id} />
          </WhiteCard>
        }
      </div>
    </Fragment>
  )
}
