import React, {Fragment} from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoValueWithCurrency(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment/>
    }
    if (!element.cargoValueCurrency) {
        return <Fragment/>
    }
    return (
        <Fragment>
            {element.cargoValue.toFixed(2)} {element.cargoValueCurrency.currency}
        </Fragment>
    )
}
