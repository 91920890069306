import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzEx1FilesSendNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IKzEx1FilesSendModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const IKzEx1FilesSendFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useKzEx1FilesSendHookNae(id: number) : IKzEx1FilesSendModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorKzEx1FilesSendNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "kz-ex-1-files-send",
        fields: IKzEx1FilesSendFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
