import React from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';

interface Props {
    fieldKey: string,
}

export default function SoClientNameTableWithId(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    if (element.calculation && element.calculation.client) {
        return (
            <RsButton
                schema='client'
                id={element.calculation.client.id}
                button={{
                    children: element[props.fieldKey],
                    color: 'white',
                    skipPadding: true,
                }}
                defaultClick='main'
            />
        )
    }
    return element[props.fieldKey];
}
