import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useClientHookNae } from '../../../_generated/_custom/hooks/useClientHookNae';
import { usePrint } from '../../../core/old-ui/OldTable';

interface Props extends PropsId {
    children: any,
    index: number,
}

export default function DebtReportsClientTr(props: Props) {
    const { children, index, id } = props;
    const isPrint = usePrint();

    const client = useClientHookNae(id);
    if (!client) {
        return <Fragment />
    }

    let bgColor = "";
    if (isPrint) {
        bgColor = "";
    }
    if (client.isDebtDiscussedActiveNow) {
        bgColor = (index % 2 === 0 ? "bg-green-100" : "bg-green-50");
    }

    return (
        <tr className={bgColor}>
            {children}
        </tr>
    )
}
