import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";
import { checkUserAdmin, checkUserForwardingManager } from '../../_generated/_custom/config/NaeSPermissions';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";

export default function KzLoadDatesChangeWidget(props: PropsId) {
  const element = useKzCarriersOrderHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isFm = checkUserForwardingManager(userState);

  const { t } = useTranslation();

  const onEdit = () => {
    if (element.status === 0) {
      SFSOpenEditModalWindowProps({
        id: element.id,
        schema: NaeSSchemaMap.KzCarriersOrder.schema,
        type: "load-date-change",
      });
    } else {
      SFSOpenEditModalWindowProps({
        id: "new",
        schema: NaeSSchemaMap.KzCarriersOrderLoadDateChange.schema,
        options: {
          createOptions: {
            convert: {
              schema: NaeSSchemaMap.KzCarriersOrder.schema,
              element: { id: element.id },
            },
          },
        },
      });
    }
  };

  let disabled = true;
  if (element.creator.id === userState.id || isAdmin || isFm) {
    disabled = false;
  }

  if (!element) {
    return <Fragment />
  }

  return (
    <Fragment>
      <MenuItem iconName={"edit"} onClick={onEdit} isDisabled={disabled}>
        {t("Update loading dates")}
      </MenuItem>
    </Fragment>
  );
}
