import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function CarriersOrderRequiredWidget() {
  const { data: tData } = useTemplatesLoader();
  const { saveError, element } = tData;
  const { t } = useTranslation();

  const openContactPopup = (id: number) => {
    SFSOpenEditModalWindowProps({
      id: id,
      schema: NaeSSchemaMap.Contact.schema
    })
  }
  const openCarrierPopup = (id: number) => {
    SFSOpenEditModalWindowProps({
      id: id,
      schema: NaeSSchemaMap.Carrier.schema
    })
  }

  if (saveError) {
    let errorText = saveError.response.data.description;

    if (
      errorText &&
      errorText[0] === "{" &&
      errorText[errorText.length - 1] === "}"
    ) {
      const er = JSON.parse(errorText);
      if (er.type === "carriers-order-contact-error") {
        return (
          <Fragment>
            <MainButton onClick={() => openContactPopup(element.contact.id)} iconName={"edit"} className={"w-full"} color={'blue'}>
              {t('Update contact person')}
            </MainButton>

          </Fragment>
        );
      }
      if (er.type === "carriers-order-client-error") {
        return (
          <Fragment>
            <MainButton onClick={() => openCarrierPopup(element.client.id)} iconName={"edit"} className={"w-full"} color={'blue'}>

              {t('Update carrier')}
            </MainButton>

          </Fragment >
        );
      }
    }
  }
  return <Fragment />;
}
