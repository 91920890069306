import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCarriersOrderNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICarriersOrderModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      status: number,
      carNumber: string,
      serialNumber: string,
      client: {
              id: number,
            },
      contact: {
              id: number,
            },
      cargo: {
              id: number,
            },
      responseDocsFinish: boolean,
      contactedCarrier: boolean,
      loadDate: string,
      unloadDate: string,
      company: {
              id: number,
                    name: string,
            },
      total: number,
      currency: {
              id: number,
                    currency: string,
            },
      creator: {
              id: number,
            },
      carriersOrderExpense: {
              id: number,
                    downtime: number,
                    additionalDriveIn: number,
            },
      dateChangesCount: number,
  }

export const ICarriersOrderFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "status",
    "carNumber",
    "serialNumber",
    "client.id",
    "contact.id",
    "cargo.id",
    "responseDocsFinish",
    "contactedCarrier",
    "loadDate",
    "unloadDate",
    "company.id",
    "company.name",
    "total",
    "currency.id",
    "currency.currency",
    "carNumber",
    "creator.id",
    "carriersOrderExpense.id",
    "carriersOrderExpense.downtime",
    "carriersOrderExpense.additionalDriveIn",
    "dateChangesCount"
];

export function useCarriersOrderHookNae(id: number) : ICarriersOrderModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCarriersOrderNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "carriers-order",
        fields: ICarriersOrderFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
