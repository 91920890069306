import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CargoOperativeAdminCalculationFinish from "../../../components/cargo/CargoOperativeAdminCalculationFinish";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { Compact, CompactRow, FieldTextarea, Label } from "@newageerp/v3.bundles.form-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

export default function OperativeAdminClm(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const { userState } = useTemplatesCore()

  const { t } = useTranslation();

  if (!element) {
    return <Fragment />;
  }
  if (!element.needLdm) {
    return <Fragment />;
  }
  if (!element.calculation) {
    return <Fragment />;
  }

  return (
    <WhiteCard isCompact={true} title={t("CLM calculation")}>

      {!!element.notesOperations && (
        <AlertWidget color="slate" isCompact={true}>
          <p className={"strong"}>{t("Quotation notes")}</p>
          <DfValueView path="cargo.notesOperations" id={element.id} />
        </AlertWidget>
      )}

      <MainEditContentInline
        schema={"cargo"}
        type={"cargo-cdm"}
        id={element.id.toString()}
        isCompact={true}
      />

      <div className="grid grid-cols-2 gap-2">
        <CargoOperativeAdminCalculationFinish id={element.id} />

        <ReturnBlock id={props.id} />
      </div>
    </WhiteCard>
  );
}

const ReturnBlock = (props: PropsId) => {
  const [showPopup, setShowPopup] = useState(false);
  const [comment, setComment] = useState('');

  const element = useCargoHookNae(props.id);

  const [doReq, doReqParams] = useUSave("cargo");
  const [doReqCalc, doReqCalcParams] = useUSave("calculation");

  useEffect(() => {
    setComment('');
  }, [showPopup]);

  const onSave = async () => {
    await doReq({
      addNote: {
        content: `${comment}`,
        notify: [
          element.creator.id
        ]
      }
    }, element.id)
    await doReqCalc({
      status: 0
    }, element.calculation.id)
    setShowPopup(false);
  }

  const isLoading = doReqParams.loading || doReqCalcParams.loading;

  return (
    <Fragment>
      <MainButton iconName="rotate-left" color="amber" onClick={() => setShowPopup(true)}>
        Return
      </MainButton>
      <Popup isPopup={showPopup} onClick={() => setShowPopup(false)} title="Return note">
        <Compact>
          <CompactRow
            label={<Label>Comment</Label>}
            control={<FieldTextarea
              className="w-[300px]"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />}
          />
          <MainButton loading={isLoading} iconName="save" color="sky" onClick={() => onSave()}>Save</MainButton>
        </Compact>
      </Popup>
    </Fragment>
  )
}