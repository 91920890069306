
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'

interface Props {
    onYes: () => void,
    onLookFurther: () => void,
    onNo: () => void,

    text: string,
}

export function CargoLoadDateConfirmation(props: Props) {
    const { t } = useTranslation();
    return (
        <WhiteCard className={classNames(
            'border-2 border-orange-500'
        )}
            isCompact={true}
            title={t('Do you confirm?')}>

            <p className='text-sm'>{props.text}</p>
            <div className='flex gap-2'>
                <MainButton color="teal" className='w-full' confirmation={true} onClick={props.onYes}>{t('Yes')}</MainButton>

                <MainButton color={"blue"}
                    className='w-full'
                    confirmation={{
                        children: t('The cariiers order will be cancelled'),
                        onYes: () => { }
                    }} onClick={props.onLookFurther}>{t('Search further')}</MainButton>

                <MainButton color={"orange"} className='w-full' confirmation={{
                    children: t('The sales order will be cancelled'),
                    onYes: () => { }

                }} onClick={props.onNo}>{t('No')}</MainButton>

            </div>

        </WhiteCard>
    )
}
