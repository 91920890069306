import React, { Fragment } from 'react'
import { PropsId } from '../types';
import UserFullName from '../User/UserFullName';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoExpeditorFullName(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.expeditor) {
        return <Fragment />
    }
    return (
        <Fragment>
            <UserFullName id={element.expeditor.id} />
        </Fragment>
    )
}
