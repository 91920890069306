import React, { Fragment } from 'react'
import { PropsId } from '../types';
import ContactFullName from '../Contact/ContactFullName';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderUnloadResponsiblePersonOrContactFullName(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (element.unloadResponsiblePerson) {
        return (
            <ContactFullName id={element.unloadResponsiblePerson.id} />
        )
    }
    if (element.contact) {
        return (
            <ContactFullName id={element.contact.id} />
        )
    }
    return <Fragment />
}
