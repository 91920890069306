import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { MainToolbarTitle } from "@newageerp/v3.bundles.layout-bundle";
import OperativeAdminNeedDocs from '../OperationalAdmin/OperativeAdminNeedDocs';

export default function TrackingSpecialistDocsPage() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <MainToolbarTitle title='Insurance' />
            <OperativeAdminNeedDocs serviceCooNew={false} serviceCooRenew={false} serviceInsurance={true} />
        </Fragment>
    )
}
