import React, { Fragment } from "react";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import SalesOrderSerialNumberButtonLink from '../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderSerialNumberButtonLink';
import CalculationSerialNumberButtonLink from '../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberButtonLink';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useNaeRecord } from "@newageerp/v3.app.mvc.record-provider";


export default function CartoParentLinks(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const { viewStackIndex } = useNaeRecord();

  // console.log('CartoParentLinks', viewStackIndex, element);

  if (!element) {
    return <Fragment/>
  }
  if (viewStackIndex !== 1) {
    return <Fragment />;
  }

  const calculation = element.calculation;
  const salesOrder = element.salesOrder;

  return (
    <div className="grid grid-cols-1 gap-2">
      {!!calculation && (
        <CalculationSerialNumberButtonLink id={calculation.id} />
      )}
      {!!salesOrder && (
        <SalesOrderSerialNumberButtonLink id={salesOrder.id} />
      )}
    </div>
  );
}
