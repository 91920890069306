import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICargoModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      loadingAddress: string,
      loadDate: string,
      loadDateSeller: string,
      files: any,
      outgoingDocumentsChange: number,
      needEx1T1File: boolean,
      alcoSubType: number,
      specCargo: number,
      places: string,
      dimensions: string,
      weight: string,
      ldm: number,
      allCargoPlaces: number,
      cbm: number,
      allCargoWeight: number,
      outgoingDocumentsChangePurpose: {
              id: number,
            },
      stock: string,
      status: number,
      expeditor: {
              id: number,
            },
      expeditorCalculation: {
              id: number,
            },
      operational: {
              id: number,
            },
      creator: {
              id: number,
            },
      otherCargosSerialNumbers: string,
      typeIn: string,
      typeOut: string,
      type: number,
      operativeCardIndex: number,
      needTerminal: boolean,
      cargoValue: number,
      cargoValueCurrency: {
              id: number,
                    currency: string,
            },
      unloadWarehouseDate: string,
      poUnloadDateStr: string,
      needAlkoGlueWithStamps: number,
      needAlkoGlueWithStampsType: number,
      needAlkoGlueWithStampsType2: number,
      needAlkoGlueWithStampsType3: number,
      ldmCalculated: boolean,
      needLdm: boolean,
      ldmCalculationType: number,
      salesOrder: {
              id: number,
                      address: {
              id: number,
                },
            },
      calculation: {
              id: number,
                      address: {
              id: number,
                },
            },
      seller: {
              id: number,
            },
      loadAddress: {
              id: number,
            },
      loadAddressAdditional: {
              id: number,
            },
      loadAddressAdditional2: {
              id: number,
            },
      terminal: {
              id: number,
            },
      terminalManual: number,
      name: string,
      cargoDescription: {
              id: number,
                    name: string,
            },
      tnvedCodes: string,
      tnvedCodesOperational: string,
      tnvedCodesForCalc: string,
      hasTnved: boolean,
      loadingCountry: {
              id: number,
            },
      loadingCountryRegion: {
              id: number,
            },
      contact: {
              id: number,
            },
      railwayType: number,
      customsCode: number,
      commentProcessing: string,
      commentLoading: string,
      preciousMetals: number,
      contactedSeller: boolean,
      cargoNotes: string,
      filesSentToTerminal: boolean,
      needTemperatureMode: number,
      temperatureSettings: string,
      contactedSellerTime: string,
      checks: any,
      kzCarriersOrder: {
              id: number,
            },
      ftlType: number,
      activePoOrders: ChildId[],
      loadingInfoReturnText: string,
      loadDateDiffManagerNeedConfirm: boolean,
      needLoadDateConfirm: boolean,
      poLoadDateStr: string,
      otherTerminalIds: any,
      notesOperations: string,
      notesLogistic: string,
      logisticCardIndex: number,
      isFtlCarLoaded: boolean,
      beforeChange: any,
      extraServiceDeliveryClient: number,
      referenceNumber: string,
      activeCargoLoadingCalculations: ChildId[],
      activeCargoLoadingCalculationsOut: ChildId[],
      deletedCargoLoadingCalculations: ChildId[],
      cargoCalculationCompleted: boolean,
      badKzCarriersDate: boolean,
      cargoSortString: string,
      needIE599File: boolean,
      needMakeEx: boolean,
      calculationPoints: number,
      onRoadPoints: number,
      extraServiceCooSertificateRenew: number,
      extraServiceCooSertificateRenewDocsHandled: boolean,
      extraServiceCooSertificateNew: number,
      extraServiceCooSertificateNewDocsHandled: boolean,
      ftlAutoType: number,
      loadingAddressFullWoSeller: string,
      soClientId: number,
      unloadRegion: string,
      soClientFilialName: string,
      extraServiceCraneHandling: number,
      adrClass: {
              id: number,
            },
      cargoStatus: {
              id: number,
            },
      loadingAddressAddCargoStatusCalc: number,
      loadingAddressFull: string,
      loadingPriceCalculated: boolean,
      unloadCountryId: number,
      countryForSearch: number,
      loadDateSellerFact: string,
      lastCallDate: string,
      sellerJson: any,
      senderMakeEx: number,
      ftlOverloadType: number,
      extraServiceDeliveryClientAddress: string,
      needAlkoGlueWithStampsBottles: number,
      loadingExactAddress: string,
      carriersOrders: ChildId[],
      senderMakeExCost: number,
      clientCountryId: number,
      ftlCalculationStop: any,
      ownerCompanyId: number,
      calculatedStatus: number,
      fillErrors: any,
      minimumSpecificationStatus: number,
      extraCostCargos: ChildId[],
      sticker: {
              id: number,
            },
      unloadShortAddress: string,
      plannedKzDateStr: string,
      cargoHold: {
              id: number,
            },
      cargoInsurance: {
              id: number,
            },
      cargoValueEur: number,
      operationConfirmation: {
              id: number,
            },
      dpsConfirmationChanges: ChildId[],
      cargoCheckForDualUse: number,
      seaContainerType: number,
      aviaGoodsLoadingType: number,
      cargoDocumentsCollected: number,
      expeditorCalculationOut: {
              id: number,
            },
      unloadCountryIdOut: number,
      countryForSearchOut: number,
      loadingPriceCalculatedOut: boolean,
      cargoExOrder: {
              id: number,
            },
  }

export const ICargoFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "loadingAddress",
    "loadDate",
    "loadDateSeller",
    "files",
    "outgoingDocumentsChange",
    "needEx1T1File",
    "alcoSubType",
    "specCargo",
    "places",
    "dimensions",
    "weight",
    "ldm",
    "allCargoPlaces",
    "cbm",
    "allCargoWeight",
    "outgoingDocumentsChangePurpose.id",
    "stock",
    "status",
    "expeditor.id",
    "expeditorCalculation.id",
    "operational.id",
    "creator.id",
    "otherCargosSerialNumbers",
    "typeIn",
    "typeOut",
    "type",
    "operativeCardIndex",
    "needTerminal",
    "cargoValue",
    "cargoValueCurrency.id",
    "cargoValueCurrency.currency",
    "unloadWarehouseDate",
    "poUnloadDateStr",
    "needAlkoGlueWithStamps",
    "needAlkoGlueWithStampsType",
    "needAlkoGlueWithStampsType2",
    "needAlkoGlueWithStampsType3",
    "ldmCalculated",
    "needLdm",
    "ldmCalculationType",
    "salesOrder.id",
    "salesOrder.address.id",
    "calculation.id",
    "calculation.address.id",
    "seller.id",
    "loadAddress.id",
    "loadAddressAdditional.id",
    "loadAddressAdditional2.id",
    "terminal.id",
    "terminalManual",
    "name",
    "cargoDescription.id",
    "cargoDescription.name",
    "tnvedCodes",
    "tnvedCodesOperational",
    "tnvedCodesForCalc",
    "hasTnved",
    "loadingCountry.id",
    "loadingCountryRegion.id",
    "contact.id",
    "railwayType",
    "customsCode",
    "commentProcessing",
    "commentLoading",
    "preciousMetals",
    "contactedSeller",
    "cargoNotes",
    "filesSentToTerminal",
    "needTemperatureMode",
    "temperatureSettings",
    "contactedSellerTime",
    "checks",
    "kzCarriersOrder.id",
    "ftlType",
    "activePoOrders:id",
    "loadingInfoReturnText",
    "loadDateDiffManagerNeedConfirm",
    "needLoadDateConfirm",
    "poLoadDateStr",
    "otherTerminalIds",
    "notesOperations",
    "notesLogistic",
    "logisticCardIndex",
    "isFtlCarLoaded",
    "beforeChange",
    "extraServiceDeliveryClient",
    "referenceNumber",
    "activeCargoLoadingCalculations:id",
    "activeCargoLoadingCalculationsOut:id",
    "deletedCargoLoadingCalculations:id",
    "cargoCalculationCompleted",
    "badKzCarriersDate",
    "cargoSortString",
    "needIE599File",
    "needMakeEx",
    "calculationPoints",
    "onRoadPoints",
    "extraServiceCooSertificateRenew",
    "extraServiceCooSertificateRenewDocsHandled",
    "extraServiceCooSertificateNew",
    "extraServiceCooSertificateNewDocsHandled",
    "ftlAutoType",
    "loadingAddressFullWoSeller",
    "soClientId",
    "unloadRegion",
    "soClientFilialName",
    "extraServiceCraneHandling",
    "adrClass.id",
    "cargoStatus.id",
    "loadingAddressAddCargoStatusCalc",
    "loadingAddressFull",
    "loadingPriceCalculated",
    "unloadCountryId",
    "countryForSearch",
    "loadDateSellerFact",
    "lastCallDate",
    "sellerJson",
    "senderMakeEx",
    "ftlOverloadType",
    "extraServiceDeliveryClientAddress",
    "needAlkoGlueWithStampsBottles",
    "loadingExactAddress",
    "carriersOrders:id",
    "senderMakeExCost",
    "clientCountryId",
    "ftlCalculationStop",
    "ownerCompanyId",
    "calculatedStatus",
    "fillErrors",
    "minimumSpecificationStatus",
    "extraCostCargos:id",
    "sticker.id",
    "unloadShortAddress",
    "plannedKzDateStr",
    "cargoHold.id",
    "cargoInsurance.id",
    "cargoValueEur",
    "operationConfirmation.id",
    "dpsConfirmationChanges:id",
    "cargoCheckForDualUse",
    "seaContainerType",
    "aviaGoodsLoadingType",
    "cargoDocumentsCollected",
    "expeditorCalculationOut.id",
    "unloadCountryIdOut",
    "countryForSearchOut",
    "loadingPriceCalculatedOut",
    "cargoExOrder.id"
];

export function useCargoHookNae(id: number) : ICargoModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCargoNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "cargo",
        fields: ICargoFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
