import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useTranslation } from 'react-i18next';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from '../../../../core/old-ui/OldBadge';

export default function CalculationCanSeparateCargosBadge(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    const { t } = useTranslation();

    if (!element) {
        return <Fragment />
    }
    if (element.canSeparateCargos !== 10) {
        return <Fragment />
    }
    return (
        <OldBadge bgColor={BadgeBgColor.purple} brightness={200} size={BadgeSize.xs} className='w-32'>
            {t('galima išvežti atskirai')}
        </OldBadge>
    )
}
