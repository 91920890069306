import React, { useEffect, Fragment } from "react";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { useTranslation } from "react-i18next";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";

import SalesOrderClientFilialName from "../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientFilialName";
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import SalesOrderCreatorFullName from "../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderCreatorFullName";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

let reloadTimeout: any = -1;

export default function OperativeAdminConfirm() {
  const { t } = useTranslation();

  const [getData, getDataParams] = useUList<PropsId>(
    "sales-order",
    ["id"]
  );

  const loadData = () => {
    getData(
      [
        {
          and: [["i.status", "=", 5, true]],
        },
      ],
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  useEffect(() => {
    SocketService.subscribe("operative-sales-order-dashboard");
    return () => {
      SocketService.unsubscribe("operative-sales-order-dashboard");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "operative-sales-order-dashboard",
      "operative-sales-order-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "operative-sales-order-dashboard",
        "operative-sales-order-dashboard"
      );
    };
  }, []);

  return (
    <WhiteCard isCompact={true} title={t("Need confirmation")}>
      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Order")}</Th>
              <Th>{t("Responsible")}</Th>
              <Th>{t("Client")}</Th>
              <Th className={"text-right"}>{t("Price")}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {getDataParams.data.data.map((el: PropsId) => {
              return <TableLine key={"so-" + el.id} id={el.id} />;
            })}
          </tbody>
        }
      />
    </WhiteCard>
  );
}

const TableLine = (props: PropsId) => {
  const { t } = useTranslation();

  const element = useSalesOrderHookNae(props.id);

  if (!element) {
    return <Fragment />;
  }
  return (
    <tr>
      <Td>
        <DfValueView path="sales-order.serialNumber" id={element.id} link={true} />
      </Td>
      <Td>
        <SalesOrderCreatorFullName id={element.id} />
      </Td>
      <Td>
        <SalesOrderClientFilialName id={element.id} />
      </Td>
      <Td>
        <DfValueView path="sales-order.totalWithCurrency" id={element.id} />
      </Td>
    </tr>
  );
};
