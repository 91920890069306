import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import moment from "moment";
import KzLoadDateWithChanges from "../../../KzCarriersOrder/Components/KzLoadDateWithChanges";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { useCargoHookNae } from "../../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";

interface Props {
  data: IData[];
}

interface IData {
  salesOrder: {
    id: number;
    isContactDataOk: boolean;
    confirmCancelText: string;
    serialNumber: string;
    status: number;
    hasLoadedCargo: boolean;
    files: any;
    isNeedInvoiceFile: boolean,
    isNeedContractFile: boolean,
  };
  id: number;
  serialNumber: string;
  kzCarriersOrder: {
    id: number,
    status: number;
  };
  status: number;
  files: any;
  outgoingDocumentsChange: number;
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  },
  beforeChange: any;
  soClientJson: {
    id: number;
    name: string;
    filialName: string;
  };
  kzCarriersOrderJson: {
    creatorFullName: string;
    carNumber: string;
    driver: string;
    loadDate: string;
    status15Date: string;
    status: number;
    dateChanges: any;
    id: number;
  };
}

export default function ManagerOrderWaitingLoadKz(props: Props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Planned for transit")}>


        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Order")}</Th>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Client")}</Th>
                <Th>{t('Seller')}</Th>
                <Th>{t('Loading Country')}</Th>
                <Th>{t("Responsible")}</Th>
                <Th>{t("Transport")}</Th>
                <Th>{t("Loading date")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data.filter(c => c.kzCarriersOrder.status !== 15).sort((a, b) => {
                const aDateStr = !!a.kzCarriersOrderJson.loadDate ? moment(a.kzCarriersOrderJson.loadDate).format("YYYY-MM-DD") : '';
                const bDateStr = !!b.kzCarriersOrderJson.loadDate ? moment(b.kzCarriersOrderJson.loadDate).format("YYYY-MM-DD") : '';

                var x = aDateStr.toLowerCase();
                var y = bDateStr.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              }).map((c: IData) => {
                return (
                  <Line c={c} key={"order-" + c.id} />
                );
              })}
            </tbody>
          }
        />
      </WhiteCard>

      <WhiteCard isCompact={true} title={t("Processing")}>


        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Order")}</Th>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Client")}</Th>
                <Th>{t('Seller')}</Th>
                <Th>{t('Loading Country')}</Th>
                <Th>{t("Responsible")}</Th>
                <Th>{t("Transport")}</Th>
                <Th>{t("Loading date")}</Th>
                <Th>{t("Processing date")}</Th>
                <Th>{t("Tracking")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data.filter(c => c.kzCarriersOrder.status === 15).sort((a, b) => {
                const aDateStr = !!a.kzCarriersOrderJson.loadDate ? moment(a.kzCarriersOrderJson.loadDate).format("YYYY-MM-DD") : '';
                const bDateStr = !!b.kzCarriersOrderJson.loadDate ? moment(b.kzCarriersOrderJson.loadDate).format("YYYY-MM-DD") : '';

                var x = aDateStr.toLowerCase();
                var y = bDateStr.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              }).map((c: IData) => {
                return (
                  <Line c={c} key={"order-" + c.id} />
                );
              })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}

const Line = (props: {
  c: IData
}) => {
  const { c } = props;
  const element = useCargoHookNae(c.id);
  const kz = useKzCarriersOrderHookNae(element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1)
  if (!element) {
    return <Fragment />
  }
  if (!kz) {
    return <Fragment />
  }
  return (
    <tr>
      <Td>
        <SalesOrderDashboardLink element={c.salesOrder} />
      </Td>
      <Td>
        <SalesOrderDashboardLink element={c.salesOrder} cargo={c} />
      </Td>
      <Td>
        <SalesOrderClientWithLinkFilialName id={c.salesOrder.id} />
      </Td>
      <Td>
        <DfValueView path="cargo.seller.name" id={c.id} />
      </Td>
      <Td>
        <DfValueView path="cargo.seller.country.nameEn" id={c.id} />
      </Td>
      <Td>{kz.doer ? <DfValueView id={kz.id} path="kz-carriers-order.doer.fullName" /> : <DfValueView id={kz.id} path="kz-carriers-order.creator.fullName" />}</Td>
      <Td>
        <DfValueView path="kz-carriers-order.carNumber" id={kz.id} />
        <hr />
        {kz.driver}
      </Td>
      <Td>
        <KzLoadDateWithChanges
          status={kz.status}
          loadDate={kz.loadDate}
          status15Date={kz.status15Date}
          id={kz.id}
        />
      </Td>
      {kz.status === 15 && <Fragment>
        <Td>{moment(kz.status15Date).format("YYYY-MM-DD")}</Td>
        <Td>{kz.location}</Td>
        </Fragment>}
    </tr>
  )
}
