import React, { Fragment } from 'react'
import { PropsId } from '../types';
import TerminalName from '../Terminal/TerminalName';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';

export default function CargoTerminalOrDirect(props: PropsId) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {!!element.terminal ? <TerminalName id={element.terminal.id} /> : "Tiesiogiai"}
        </Fragment>
    )
}
