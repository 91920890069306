import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorExtraCostRailwayTarifNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IExtraCostRailwayTarifModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const IExtraCostRailwayTarifFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useExtraCostRailwayTarifHookNae(id: number) : IExtraCostRailwayTarifModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorExtraCostRailwayTarifNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "extra-cost-railway-tarif",
        fields: IExtraCostRailwayTarifFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
