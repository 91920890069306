import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface Props extends PropsId {
  sm?: boolean;
}
export default function CargoOperativeAdminCalculationFinish(props: Props) {
  const element = useCargoHookNae(props.id);
  const { t } = useTranslation();
  const [saveData, saveDataParams] = useUSave("cargo");

  const doSave = (data: any) => {
    saveData(data, props.id);
  }

  if (!element) {
    return <Fragment />
  }


  if (element.ldmCalculated) {
    return <Fragment />;
  }

  if (!(element.ldm > 0 && element.ldmCalculationType > 0)) {
    return <Fragment />;
  }

  return (

    <MainButton
      confirmation={true}
      color={"teal"}

      className={
        "w-full"
      }
      iconName={"check"}
      loading={saveDataParams.loading}
      onClick={() =>
        doSave({
          ldmCalculated: true,
        })
      }
    >
      {t('Complete')}
    </MainButton>

  );
}
