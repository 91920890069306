import React from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props {
    fieldKey: string,
}

export default function OrdersClient(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    return (
        <div>OrdersClient</div>
    )
}
