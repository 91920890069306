import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorClientNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IClientModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
      filialsCount: number,
      clientStatus: {
              id: number,
            },
      defaultLang: string,
      fullClientPhones: any,
      phones: any,
      contactsToUse: ChildId[],
      doer: {
              id: number,
            },
      needBusinessDev: boolean,
      sendToBusinessDev: number,
      isDebtDiscussedActiveNow: boolean,
      country: {
              id: number,
            },
      leadSource: {
              id: number,
                      category: {
              id: number,
                },
            },
      needBusinessDevDelay: boolean,
      needBusinessDevDelayCount: number,
      status: number,
      priority: number,
      tracking: {
              id: number,
            },
      doneActSend: {
              id: number,
            },
      orgDocumentSend: {
              id: number,
            },
      address: string,
  }

export const IClientFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name",
    "filialsCount",
    "clientStatus.id",
    "defaultLang",
    "fullClientPhones",
    "phones",
    "contactsToUse:id",
    "doer.id",
    "needBusinessDev",
    "sendToBusinessDev",
    "isDebtDiscussedActiveNow",
    "country.id",
    "leadSource.id",
    "leadSource.category.id",
    "needBusinessDevDelay",
    "needBusinessDevDelayCount",
    "status",
    "priority",
    "tracking.id",
    "doneActSend.id",
    "orgDocumentSend.id",
    "address"
];

export function useClientHookNae(id: number) : IClientModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorClientNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "client",
        fields: IClientFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
