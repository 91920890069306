import React, { Fragment } from 'react'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTranslation } from 'react-i18next';
import { NaeSSchemaMap } from '../../../../../_generated/_custom/config/NaeSSchema';
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../../../../_generated/_custom/config/NaeSPermissions';
import { toast } from '@newageerp/v3.templates.templates-core';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

interface Props {
    fieldKey: string,
}

export default function Actions(props: Props) {
    const { t } = useTranslation();
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    const [doSave] = useUSave(NaeSSchemaMap.InvoiceOutgoingCreditRequest.schema);
    const onAccept = () => {
        doSave({
            status: 10,
        }, element.id).then(() => {
            toast.success("Saved");
        })
    }
    const onDeny = () => {
        SFSOpenEditModalWindowProps({
            schema: NaeSSchemaMap.InvoiceOutgoingCreditRequest.schema,
            type: 'deny',
            id: element.id,
            onSaveCallback: (_el, back) => {
                doSave({
                    status: 20,
                }, element.id).then(() => {
                    toast.success("Saved");
                });
                back();
            }
        })
    }

    if (element.status !== 0) {
        return <Fragment />
    }

    return (
        <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserFinances, ENaeSPermissions.UserAdmin]}>
            <div className='grid gap-2 grid-cols-2'>
                {element.total < 0 &&
                    <MainButton confirmation={true} onClick={onAccept}>{t('Accept')}</MainButton>
                }
                <MainButton color='red' onClick={onDeny}>{t('Deny')}</MainButton>
            </div>
        </CheckUserPermissionComponent>
    )
}
