import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';


export default function KzOrderCarNumberWithInfo(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            <p><DfValueView path="kz-carriers-order.carNumber" id={props.id} /></p>
            {element.transportType === 10 &&
                <p>
                    <DfValueView path="kz-carriers-order.transportAutoType" id={props.id} />
                </p>
            }
            {element.transportType === 10 &&
                <p>
                    <DfValueView path="kz-carriers-order.transportGuaranteeType" id={props.id} />
                </p>
            }
        </Fragment>
    )
}
