import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CargoSenderMailBtn from "../../../Plugins/Cargo/CargoSenderMailBtn";

import LogisticStatus3Return from "../../../components/cargo/Components/LogisticStatus3Return";
import CargoSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndNotes";
import CargoLoadingAddress from "../../../_generated/_custom/models-cache-data/Cargo/CargoLoadingAddress";

import CargoCalculationReturnLogistic from "../../Calculation/Components/CargoCalculationReturnLogistic";
import CargoCalculationSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Cargo/CargoCalculationSerialNumberWithLinkAndNotes";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import CargoContactedSellerTime from "../../../Plugins/Cargo/CargoContactedSellerTime";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

interface IData {
  id: number;
}

let timeoutCalcCargoReloadDashboard: any = -1;

type Props = {
  userId: number,
}

export default function LogisticCalculationNeedInfo(props: Props) {
  const { t } = useTranslation();

  const {userState} = useTemplatesCore()

  const [getData, getDataParams] = useUList<IData>("cargo", ['id']);

  const loadData = () => {
    getData(
      [
        {
          and: [
            ["i.expeditorCalculation", "=", props.userId, true],
            ["i.calculation.status", "=", 3, true],
            ["i.status", "!=", 900, true],
          ],
        },
      ],
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
   
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(timeoutCalcCargoReloadDashboard);
    timeoutCalcCargoReloadDashboard = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("calc-cargo-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("calc-cargo-dashboard-" + userState.id);
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "calc-cargo-reload-dashboard",
      "calc-cargo-reload-dashboard-info",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calc-cargo-reload-dashboard",
        "calc-cargo-reload-dashboard-info"
      );
    };
  }, []);

  const isData =
    getDataParams &&
    getDataParams.data &&
    getDataParams.data.data &&
    getDataParams.data.data.length > 0;
  

  let dataToShow: IData[] = isData ? getDataParams.data.data : [];

  if (dataToShow.length === 0) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <WhiteCard title={t("Needs inquiry from Seller")} isCompact={true}>
        <Table
          thead={
            <thead>
              <Th>{t("Cargo")}</Th>
              <Th>{t("Quotation")}</Th>
              <Th>{t("Responsible person")}</Th>
              <Th>{t("Loading")}</Th>
              <Th></Th>
              <Th></Th>
            </thead>
          }
          tbody={
            <tbody>
              {dataToShow.map((cargo) => {
                return (
                  <tr key={`order-${cargo.id}`}>
                    <Td>
                      <CargoSerialNumberWithLinkAndNotes id={cargo.id} />
                    </Td>
                    <Td>
                      <CargoCalculationSerialNumberWithLinkAndNotes
                        id={cargo.id}
                        hideLink={true}
                      />
                    </Td>
                    <Td>
                      <DfValueView path="cargo.creator.fullName" id={cargo.id} />
                    </Td>
                    <Td>
                      <CargoLoadingAddress id={cargo.id} />
                    </Td>
                    <Td>
                      <CargoCalculationReturnLogistic id={cargo.id} />
                    </Td>
                    <Td>
                      <Fragment>
                        <CargoContactedSellerTime id={cargo.id} />

                        <CargoSenderMailBtn ids={[cargo.id]} sm={true} />

                        <LogisticStatus3Return
                          id={cargo.id}
                          sm={true}
                          hideNotes={true}
                        />
                      </Fragment>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}
