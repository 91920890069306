import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorTruckOwnerNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITruckOwnerModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
  }

export const ITruckOwnerFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name"
];

export function useTruckOwnerHookNae(id: number) : ITruckOwnerModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTruckOwnerNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "truck-owner",
        fields: ITruckOwnerFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
