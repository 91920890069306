import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from '../../../../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { useCalculationHookNae } from '../../../../../../_generated/_custom/hooks/useCalculationHookNae';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserManager } from "../../../../../../_generated/_custom/config/NaeSPermissions";
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function CalculationEditAfter(props: PropsId) {
  const element = useCalculationHookNae(props.id);

  const { t } = useTranslation();
  const [saveData, saveDataParams] = useUSave("calculation");

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);

  const doSave = () => {
    if (saveDataParams.loading) return;
    saveData({ status: 0 }, element.id);
  }

  if (!element) {
    return <Fragment />
  }

  if (element.status === 0 || element.salesOrder) {
    return <Fragment />
  }
  if (element.status === 900) {
    return <Fragment />
  }
  if (!(isAdmin || isManager)) {
    return <Fragment />
  }

  return (
    <Fragment>
      <MenuItem
        iconName={"history"}
        onClick={doSave}
        confirmation={{
          title: 'Are yoy sure?',
          onYes: doSave,
          children: t("If the data changes, the calculation is returned to the status being prepared. Do you want to do this?")
        }}
      >
        {t("Restart calculation")}
      </MenuItem>
    </Fragment>
  );
}
