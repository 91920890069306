import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzFtlCalculationNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IKzFtlCalculationModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      total: number,
      creator: {
              id: number,
            },
      currency: {
              id: number,
                    currency: string,
            },
  }

export const IKzFtlCalculationFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "total",
    "creator.id",
    "currency.id",
    "currency.currency"
];

export function useKzFtlCalculationHookNae(id: number) : IKzFtlCalculationModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorKzFtlCalculationNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "kz-ftl-calculation",
        fields: IKzFtlCalculationFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
