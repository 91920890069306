import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getPropertyTitleForSchema } from '../../../_generated/v3/utils';

interface Props extends PropsId {
    canSeeUnloadInfo: boolean
}

export default function CargoBottomNotes(props: Props) {
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <div className='space-y-2'>
            {!!element.notesLogistic && (
                <div>
                    <p className='font-medium'>{getPropertyTitleForSchema("cargo", "notesLogistic")}</p>
                    <p>
                        <DfValueView path="cargo.notesLogistic" id={props.id} />
                    </p>
                </div>
            )}
            {!!element.notesOperations && props.canSeeUnloadInfo && (
                <div>
                    <p className='font-medium'>{getPropertyTitleForSchema("cargo", "notesOperations")}</p>
                    <p>
                        <DfValueView path="cargo.notesOperations" id={props.id} />
                    </p>
                </div>
            )}
        </div>
    )
}
