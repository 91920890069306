import React, { Fragment } from "react";
import moment from "moment";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from "../../../core/old-ui/OldBadge";

export default function Status30DateComponent(props: PropsId) {
  const element = useKzCarriersOrderHookNae(props.id);

  if (!element) {
    return <Fragment />
  }

  const diffDays = moment
    .duration(moment().diff(moment(element.status30Date)))
    .asDays();
  if (diffDays >= 4) {
    return (
      <OldBadge size={BadgeSize.sm} bgColor={BadgeBgColor.red} brightness={200}>
        {element.status30Date}
      </OldBadge>
    );
  }
  return <Fragment>{element.status30Date}</Fragment>;
}
