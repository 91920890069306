import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorTrailerNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ITrailerModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      truckOwner: {
              id: number,
            },
      number: string,
  }

export const ITrailerFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "truckOwner.id",
    "number"
];

export function useTrailerHookNae(id: number) : ITrailerModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorTrailerNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "trailer",
        fields: ITrailerFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
