import { useTemplatesLoader, useTranslation } from '@newageerp/v3.templates.templates-core';
import React, { Fragment } from 'react'
import { useUnloadAddressHookNae } from '../../../hooks/useUnloadAddressHookNae';

interface Props {

}

export default function UnloadAddress(props: Props) {
    const { t } = useTranslation();
    const { data: tData } = useTemplatesLoader();

    const element = useUnloadAddressHookNae(!!tData.element && !!tData.element.address ? tData.element.address.id : -1);
    if (!element) {
        return <Fragment />
    }

    const extra = [];

    if (element.canUnload === 0) {
        extra.push('Unloading');
    }
    if (element.canAvia === 0) {
        extra.push('Avia');
    }
    if (element.canCarriage === 0) {
        extra.push('Vagonai');
    }
    if (element.canContainer === 0) {
        extra.push('Konteineris');
    }
    if (!!element.priceListLtl) {
        extra.push('LTL');
    }
    if (!!element.priceListFtl) {
        extra.push('FTL');
    }

    return (
        <p>
            {tData.element.address.fullAddress} {extra.length > 0 && <span className={tData.element.isCorrectUnloadAddress ? 'text-teal-700' : 'text-red-700'}>({extra.join(', ')})</span>}
        </p>
    )
}
