import { Model, fk, many, attr } from "redux-orm";
import { NaeSSchemaMap } from "../config/NaeSSchema";

class CarriersOrderModel extends Model {
  static reducer(action, CarriersOrderModel, session) {
    switch (action.type) {
      case "UPSERT_" + NaeSSchemaMap.CarriersOrder.schema:
        action.payload.forEach((payload) => {
          const dbData = CarriersOrderModel.withId(payload.id);

          const upsertData = payload;
          if (dbData) {
            const keys = Object.keys(upsertData);
            let needChange = false;
            keys.map((key) => {
              if (
                JSON.stringify(upsertData[key]) !== JSON.stringify(dbData[key])
              ) {
                needChange = true;
              }
            });

            if (!needChange) {
              return;
            }
          }

          CarriersOrderModel.upsert(payload);
        });
        break;
    }
    // Return value is ignored.
    return session.state;
  }

  toString() {
    return `${this.id}`;
  }
  // Declare any static or instance methods you need.
}
CarriersOrderModel.modelName = "CarriersOrderModel";

CarriersOrderModel.fields = {
  id: attr(),
};

export default CarriersOrderModel;
