import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "AccountingDiscrepancies",
        "schema": "accounting-discrepancies",
        "title": "Accounting discrepancies",
        "titlePlural": "Accounting discrepancies",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdrClass",
        "schema": "adr-class",
        "title": "ADR class",
        "titlePlural": "ADR classes"
    },
    {
        "className": "Attorney",
        "schema": "attorney",
        "title": "Power of attorney",
        "titlePlural": "Power of attorney",
        "required": [
            "dueDate",
            "terminal",
            "sender"
        ]
    },
    {
        "className": "Bank",
        "schema": "bank",
        "title": "Bank",
        "titlePlural": "Banks",
        "required": [],
        "scopes": []
    },
    {
        "className": "BaseEntity",
        "schema": "base-entity",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Bookmark",
        "schema": "bookmark",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Calculation",
        "schema": "calculation",
        "title": "Quotation",
        "titlePlural": "Quotations",
        "required": [
            "purpose",
            "client",
            "address"
        ]
    },
    {
        "className": "CalculationCostHistory",
        "schema": "calculation-cost-history",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CallLog",
        "schema": "call-log",
        "title": "Call",
        "titlePlural": "Calls"
    },
    {
        "className": "CarServiceAct",
        "schema": "car-service-act",
        "title": "Sales Orders",
        "titlePlural": "Sales Orders",
        "required": [
            "client",
            "contact",
            "car",
            "mileage",
            "workList"
        ]
    },
    {
        "className": "CarServiceActProduct",
        "schema": "car-service-act-product",
        "title": "Act product",
        "titlePlural": "Act products"
    },
    {
        "className": "CarServiceActService",
        "schema": "car-service-act-service",
        "title": "Act service",
        "titlePlural": "Act services"
    },
    {
        "className": "CarServiceClient",
        "schema": "car-service-client",
        "title": "Client",
        "titlePlural": "Clients",
        "required": [
            "name",
            "code",
            "country",
            "address",
            "vatNumber"
        ]
    },
    {
        "className": "CarServiceClientCar",
        "schema": "car-service-client-car",
        "title": "Clients vehicle database",
        "titlePlural": "Clients vehicle database"
    },
    {
        "className": "CarServiceClientContact",
        "schema": "car-service-client-contact",
        "title": "Client contact",
        "titlePlural": "Client contacts"
    },
    {
        "className": "CarServiceInvoiceIncoming",
        "schema": "car-service-invoice-incoming",
        "title": "Purchase Invoices",
        "titlePlural": "Purchase Invoices",
        "required": [
            "serialNumber",
            "date",
            "payToDate",
            "supplier",
            "totalVat"
        ]
    },
    {
        "className": "CarServiceInvoiceIncomingProduct",
        "schema": "car-service-invoice-incoming-product",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CarServiceInvoiceOutgoing",
        "schema": "car-service-invoice-outgoing",
        "title": "Invoice (outgoing)",
        "titlePlural": "Invoices (outgoing)"
    },
    {
        "className": "CarServiceInvoiceOutgoingProduct",
        "schema": "car-service-invoice-outgoing-product",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CarServiceInvoiceOutgoingService",
        "schema": "car-service-invoice-outgoing-service",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CarServiceInvoiceReturn",
        "schema": "car-service-invoice-return",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CarServiceInvoiceReturnProduct",
        "schema": "car-service-invoice-return-product",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "CarServicePayment",
        "schema": "car-service-payment",
        "title": "Payment",
        "titlePlural": "Payments"
    },
    {
        "className": "CarServiceProduct",
        "schema": "car-service-product",
        "title": "Items",
        "titlePlural": "Items",
        "required": [
            "title",
            "productGroup"
        ]
    },
    {
        "className": "CarServiceProductBarcode",
        "schema": "car-service-product-barcode",
        "title": "Product barcode",
        "titlePlural": "Product barcodes",
        "required": [
            "barcode",
            "code",
            "product",
            "supplier"
        ]
    },
    {
        "className": "CarServiceProductCostList",
        "schema": "car-service-product-cost-list",
        "title": "Profit settings",
        "titlePlural": "Profit settings"
    },
    {
        "className": "CarServiceProductGroup",
        "schema": "car-service-product-group",
        "title": "Items group",
        "titlePlural": "Items groups"
    },
    {
        "className": "CarServiceService",
        "schema": "car-service-service",
        "title": "Names of service works",
        "titlePlural": "Names of service works"
    },
    {
        "className": "CarServiceStockItem",
        "schema": "car-service-stock-item",
        "title": "Stock items history",
        "titlePlural": "Stock items history"
    },
    {
        "className": "CarServiceSupplier",
        "schema": "car-service-supplier",
        "title": "Supplier",
        "titlePlural": "Suppliers"
    },
    {
        "className": "CarServiceSupplierContact",
        "schema": "car-service-supplier-contact",
        "title": "Supplier contact",
        "titlePlural": "Supplier contacts"
    },
    {
        "className": "Cargo",
        "schema": "cargo",
        "title": "Cargo",
        "titlePlural": "Cargo",
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "CargoContactSellerLog",
        "schema": "cargo-contact-seller-log",
        "title": "Contact seller log",
        "titlePlural": "Contact seller log",
        "required": [
            "comment"
        ],
        "scopes": []
    },
    {
        "className": "CargoDescription",
        "schema": "cargo-description",
        "title": "Cargo description",
        "titlePlural": "Cargo description",
        "scopes": [
            "allow-create-user-admin"
        ]
    },
    {
        "className": "CargoExOrder",
        "schema": "cargo-ex-order",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoHold",
        "schema": "cargo-hold",
        "title": "Postpone delivery",
        "titlePlural": "Postpone delivery",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoInsurance",
        "schema": "cargo-insurance",
        "title": "Insurance",
        "titlePlural": "Insurances",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoLoadingCalculation",
        "schema": "cargo-loading-calculation",
        "title": "Purchase quotation",
        "titlePlural": "Purchase quotations",
        "required": [
            "client",
            "contact",
            "total"
        ]
    },
    {
        "className": "CargoLoadingCalculationNotesSuggest",
        "schema": "cargo-loading-calculation-notes-suggest",
        "title": "Purchase quotations suggest",
        "titlePlural": "Purchase quotations suggests",
        "required": [
            "title",
            "notes",
            "role"
        ],
        "scopes": []
    },
    {
        "className": "CargoOperationConfirmation",
        "schema": "cargo-operation-confirmation",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoOperationConfirmationChanges",
        "schema": "cargo-operation-confirmation-changes",
        "title": "Document correction summary",
        "titlePlural": "Document correction summary",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoRailway",
        "schema": "cargo-railway",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoSpecificaction",
        "schema": "cargo-specificaction",
        "title": "Cargo specification",
        "titlePlural": "Cargo specifications",
        "required": [],
        "scopes": []
    },
    {
        "className": "CargoStatus",
        "schema": "cargo-status",
        "title": "Cargo status",
        "titlePlural": "Cargo statuses"
    },
    {
        "className": "CargoSticker",
        "schema": "cargo-sticker",
        "title": "Cargo need marking?",
        "titlePlural": "Cargo need marking?",
        "required": [],
        "scopes": []
    },
    {
        "className": "Carrier",
        "schema": "carrier",
        "title": "Carrier",
        "titlePlural": "Carriers",
        "required": [
            "name",
            "country",
            "address",
            "code",
            "vatNumber"
        ]
    },
    {
        "className": "CarriersOrder",
        "schema": "carriers-order",
        "title": "Purchase order (PO)",
        "titlePlural": "Purchase orders (PO)",
        "required": [
            "client",
            "contact",
            "loadDate",
            "unloadDate",
            "total",
            "paymentTerms",
            "carNumber"
        ],
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "CarriersOrderExpense",
        "schema": "carriers-order-expense",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "CarriersOrderLoadDateChange",
        "schema": "carriers-order-load-date-change",
        "title": "",
        "titlePlural": "",
        "required": [
            "loadDate",
            "unloadDate",
            "comment"
        ],
        "scopes": []
    },
    {
        "className": "CarriersOrderPaymentTerm",
        "schema": "carriers-order-payment-term",
        "title": "Payment terms for orders",
        "titlePlural": "Payment terms for orders",
        "required": [
            "name",
            "nameRu",
            "nameEn"
        ]
    },
    {
        "className": "CarriersOrderPriceChange",
        "schema": "carriers-order-price-change",
        "title": "Carriers order price change",
        "titlePlural": "Carriers order price changes",
        "required": [
            "total",
            "currency",
            "comment"
        ],
        "scopes": []
    },
    {
        "className": "CarriersOrderProblem",
        "schema": "carriers-order-problem",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Client",
        "schema": "client",
        "title": "Client",
        "titlePlural": "Clients",
        "required": [
            "name",
            "country"
        ],
        "scopes": [
            "allow-create-user-admin",
            "allow-create-user-chief-sales-officer"
        ]
    },
    {
        "className": "ClientAllStats",
        "schema": "client-all-stats",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ClientCategory",
        "schema": "client-category",
        "title": "Client category",
        "titlePlural": "Client categories"
    },
    {
        "className": "ClientContract",
        "schema": "client-contract",
        "title": "Contract",
        "titlePlural": "Contracts"
    },
    {
        "className": "ClientDoneJobsAct",
        "schema": "client-done-jobs-act",
        "title": "",
        "titlePlural": "",
        "required": [
            "emails"
        ],
        "scopes": []
    },
    {
        "className": "ClientFilial",
        "schema": "client-filial",
        "title": "Alternative name",
        "titlePlural": "Alternative names",
        "required": [
            "name",
            "vatNumber",
            "code",
            "country",
            "address"
        ]
    },
    {
        "className": "ClientOriginalDocuments",
        "schema": "client-original-documents",
        "title": "",
        "titlePlural": "",
        "required": [
            "address"
        ],
        "scopes": []
    },
    {
        "className": "ClientStatus",
        "schema": "client-status",
        "title": "Client status",
        "titlePlural": "Client statuses"
    },
    {
        "className": "ClientStatusData",
        "schema": "client-status-data",
        "title": "Client status changes",
        "titlePlural": "Client statuses changes"
    },
    {
        "className": "ClientTop20",
        "schema": "client-top20",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ClientTracking",
        "schema": "client-tracking",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ClientYearStats",
        "schema": "client-year-stats",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "CommentIkNotes",
        "schema": "comment-ik-notes",
        "title": "IK notes suggestion",
        "titlePlural": "IK notes suggestion",
        "required": [
            "name"
        ],
        "scopes": []
    },
    {
        "className": "CommentLoading",
        "schema": "comment-loading",
        "title": "Information for warehouse (loading)",
        "titlePlural": "Information for warehouse (loading)",
        "required": [
            "name"
        ]
    },
    {
        "className": "CommentOperation",
        "schema": "comment-operation",
        "title": "Reasons for documents correction",
        "titlePlural": "Reasons for documents correction",
        "required": [
            "name"
        ]
    },
    {
        "className": "CommentProcessing",
        "schema": "comment-processing",
        "title": "Information for warehouse (documents)",
        "titlePlural": "Information for warehouse (documents)",
        "required": [
            "name"
        ]
    },
    {
        "className": "CommissionExpense",
        "schema": "commission-expense",
        "title": "Commission expense",
        "titlePlural": "Commission expenses",
        "required": [
            "date",
            "total"
        ]
    },
    {
        "className": "Company",
        "schema": "company",
        "title": "Company preferences",
        "titlePlural": "Company preferences",
        "scopes": [
            "allow-create-user-admin"
        ]
    },
    {
        "className": "Contact",
        "schema": "contact",
        "title": "Contact",
        "titlePlural": "Contacts",
        "required": [
            "firstName"
        ]
    },
    {
        "className": "ContactDuplicate",
        "schema": "contact-duplicate",
        "title": "Contact duplicate",
        "titlePlural": "Contact duplicate",
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "ContractTerm",
        "schema": "contract-term",
        "title": "Terms of payment for transportation",
        "titlePlural": "Terms of payment for transportation",
        "required": [
            "name"
        ]
    },
    {
        "className": "Country",
        "schema": "country",
        "title": "Country",
        "titlePlural": "Countries",
        "scopes": [
            "disable-user-manager-create"
        ]
    },
    {
        "className": "CountryGeographyGroup",
        "schema": "country-geography-group",
        "title": "Country geography group",
        "titlePlural": "Country geography groups"
    },
    {
        "className": "CountryLogisticGroup",
        "schema": "country-logistic-group",
        "title": "Country logistic group",
        "titlePlural": "Country logistic groups"
    },
    {
        "className": "CountryRegion",
        "schema": "country-region",
        "title": "Country region",
        "titlePlural": "Country regions",
        "scopes": [
            "disable-user-manager-create"
        ]
    },
    {
        "className": "Currency",
        "schema": "currency",
        "title": "Currency",
        "titlePlural": "Currencies"
    },
    {
        "className": "CurrencyRate",
        "schema": "currency-rate",
        "title": "Currency rate",
        "titlePlural": "Currencies rates"
    },
    {
        "className": "Driver",
        "schema": "driver",
        "title": "Driver",
        "titlePlural": "Drivers"
    },
    {
        "className": "EtransportTruck",
        "schema": "etransport-truck",
        "title": "E-Transportas",
        "titlePlural": "E-Transportas"
    },
    {
        "className": "ExtraCost",
        "schema": "extra-cost",
        "title": "Extra costs",
        "titlePlural": "Extra costs"
    },
    {
        "className": "ExtraCostCargo",
        "schema": "extra-cost-cargo",
        "title": "Extra costs",
        "titlePlural": "Extra costs",
        "required": [
            "serialNumber",
            "type",
            "date",
            "total",
            "costSupplier",
            "invoiceIssueDate",
            "invoiceDate",
            "attachments"
        ]
    },
    {
        "className": "ExtraCostCommon",
        "schema": "extra-cost-common",
        "title": "Extra costs",
        "titlePlural": "Extra costs",
        "required": [
            "serialNumber",
            "date",
            "total",
            "invoiceIssueDate",
            "invoiceDate",
            "costSupplier",
            "attachments"
        ]
    },
    {
        "className": "ExtraCostGarant",
        "schema": "extra-cost-garant",
        "title": "Garant procedure extra cost",
        "titlePlural": "Garant procedure extra cost",
        "required": [
            "serialNumber",
            "date",
            "total",
            "costSupplier",
            "invoiceIssueDate",
            "invoiceDate",
            "attachments"
        ]
    },
    {
        "className": "ExtraCostRailwayTarif",
        "schema": "extra-cost-railway-tarif",
        "title": "Railway Tarif",
        "titlePlural": "Railway Tarifs",
        "required": [
            "costSupplier",
            "attachments"
        ]
    },
    {
        "className": "ExtraCostTerminal",
        "schema": "extra-cost-terminal",
        "title": "Terminal costs",
        "titlePlural": "Terminal costs",
        "required": [
            "terminal",
            "serialNumber",
            "date",
            "total",
            "invoiceIssueDate",
            "invoiceDate",
            "attachments"
        ]
    },
    {
        "className": "File",
        "schema": "file",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "FilesManagerFile",
        "schema": "files-manager-file",
        "title": "File",
        "titlePlural": "Files"
    },
    {
        "className": "FilesManagerFolder",
        "schema": "files-manager-folder",
        "title": "Folder",
        "titlePlural": "Folders",
        "required": [
            "folderName"
        ]
    },
    {
        "className": "FilterChange",
        "schema": "filter-change",
        "title": "Filters change",
        "titlePlural": "Filters changes"
    },
    {
        "className": "FollowUp",
        "schema": "follow-up",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "InsuranceRates",
        "schema": "insurance-rates",
        "title": "Insurance",
        "titlePlural": "Insurance"
    },
    {
        "className": "Inventory",
        "schema": "inventory",
        "title": "Inventory",
        "titlePlural": "Inventories"
    },
    {
        "className": "InvoiceIncoming",
        "schema": "invoice-incoming",
        "title": "Invoice",
        "titlePlural": "Invoices",
        "required": [
            "invoiceIssueDate",
            "invoiceDate",
            "total",
            "serialNumber",
            "attachments"
        ]
    },
    {
        "className": "InvoiceIncomingSepaExport",
        "schema": "invoice-incoming-sepa-export",
        "title": "Bank Export",
        "titlePlural": "Bank Exports",
        "required": [],
        "scopes": []
    },
    {
        "className": "InvoiceOutgoing",
        "schema": "invoice-outgoing",
        "title": "Invoice",
        "titlePlural": "Invoices"
    },
    {
        "className": "InvoiceOutgoingCreditRequest",
        "schema": "invoice-outgoing-credit-request",
        "title": "Credit invoice issue approval",
        "titlePlural": "Credit invoice issue approval",
        "required": [
            "issueReason",
            "invoiceOutgoing"
        ],
        "scopes": []
    },
    {
        "className": "InvoiceOutgoingPriceChange",
        "schema": "invoice-outgoing-price-change",
        "title": "Issued invoices price change",
        "titlePlural": "Issued invoices price changes",
        "required": [
            "total",
            "currency",
            "note"
        ],
        "scopes": []
    },
    {
        "className": "KzCarriersOrder",
        "schema": "kz-carriers-order",
        "title": "Purchase order (IK)",
        "titlePlural": "Purchase orders (IK)",
        "scopes": [
            "disable-user-head-of-logistics-helper-create",
            "disable-user-forwarding-manager-create",
            "disable-user-operational-create"
        ]
    },
    {
        "className": "KzCarriersOrderLoadDateChange",
        "schema": "kz-carriers-order-load-date-change",
        "title": "",
        "titlePlural": "",
        "required": [
            "loadDate",
            "unloadDate",
            "comment"
        ]
    },
    {
        "className": "KzCarriersOrderLocation",
        "schema": "kz-carriers-order-location",
        "title": "Location history",
        "titlePlural": "Location history",
        "required": [],
        "scopes": []
    },
    {
        "className": "KzCarriersOrderProblem",
        "schema": "kz-carriers-order-problem",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "KzEx1FilesSend",
        "schema": "kz-ex-1-files-send",
        "title": "EX1 files send",
        "titlePlural": "EX1 files send"
    },
    {
        "className": "KzEx1FilesSend",
        "schema": "kz-ex1-files-send",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "KzFtlCalculation",
        "schema": "kz-ftl-calculation",
        "title": "IK quotation",
        "titlePlural": "IK quotations",
        "required": [
            "date",
            "client",
            "contact",
            "total"
        ]
    },
    {
        "className": "KzInspection",
        "schema": "kz-inspection",
        "title": "Inspection",
        "titlePlural": "Inspections",
        "required": [
            "startDate"
        ],
        "scopes": []
    },
    {
        "className": "KzLocationSuggest",
        "schema": "kz-location-suggest",
        "title": "Location suggest",
        "titlePlural": "Location suggests",
        "required": [],
        "scopes": []
    },
    {
        "className": "LeadLostReason",
        "schema": "lead-lost-reason",
        "title": "Reason for potential rejection",
        "titlePlural": "Reason for potential rejection",
        "required": [
            "title"
        ]
    },
    {
        "className": "LeadLostRecord",
        "schema": "lead-lost-record",
        "title": "Reason for rejection",
        "titlePlural": "Reason for rejection"
    },
    {
        "className": "LeadSource",
        "schema": "lead-source",
        "title": "Potential groups",
        "titlePlural": "Potential groups"
    },
    {
        "className": "LeadSourceCategory",
        "schema": "lead-source-category",
        "title": "Potential groups category",
        "titlePlural": "Potential groups category"
    },
    {
        "className": "LoadAddress",
        "schema": "load-address",
        "title": "Load address",
        "titlePlural": "Load addresses",
        "required": [
            "country",
            "address"
        ]
    },
    {
        "className": "Mail",
        "schema": "mail",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Note",
        "schema": "note",
        "title": "Comment",
        "titlePlural": "Comments"
    },
    {
        "className": "Notification",
        "schema": "notification",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "OilChange",
        "schema": "oil-change",
        "title": "Oil change",
        "titlePlural": "Oil changes"
    },
    {
        "className": "OutgoingDocumentsChangePurpose",
        "schema": "outgoing-documents-change-purpose",
        "title": "Documents change reason",
        "titlePlural": "Documents change reason"
    },
    {
        "className": "Payment",
        "schema": "payment",
        "title": "Payment",
        "titlePlural": "Payments",
        "required": [
            "bank"
        ]
    },
    {
        "className": "PhotoAlbum",
        "schema": "photo-album",
        "title": "Photo",
        "titlePlural": "Photos"
    },
    {
        "className": "PoCancelReason",
        "schema": "po-cancel-reason",
        "title": "PO cancel reason",
        "titlePlural": "PO cancel reason",
        "required": [
            "name"
        ]
    },
    {
        "className": "PriceListFtl",
        "schema": "price-list-ftl",
        "title": "Full truck load (FTL)",
        "titlePlural": "Full truck load (FTL)"
    },
    {
        "className": "PriceListGarant",
        "schema": "price-list-garant",
        "title": "Guarantee procedures",
        "titlePlural": "Guarantee procedures"
    },
    {
        "className": "PriceListLtl",
        "schema": "price-list-ltl",
        "title": "Less than truckload (LTL)",
        "titlePlural": "Less than truckload (LTL)"
    },
    {
        "className": "PriceListLtlLine",
        "schema": "price-list-ltl-line",
        "title": "Less than truckload (LTL) line",
        "titlePlural": "Less than truckload (LTL) line"
    },
    {
        "className": "PriceListRailwayCarriage",
        "schema": "price-list-railway-carriage",
        "title": "Wagons",
        "titlePlural": "Wagons"
    },
    {
        "className": "PriceListRailwayContainer",
        "schema": "price-list-railway-container",
        "title": "Containers",
        "titlePlural": "Containers"
    },
    {
        "className": "Purpose",
        "schema": "purpose",
        "title": "Purpose",
        "titlePlural": "Purposes"
    },
    {
        "className": "RepairTensionBearings",
        "schema": "repair-tension-bearings",
        "title": "Tension bearings",
        "titlePlural": "Tension bearings"
    },
    {
        "className": "RepairWaterPomp",
        "schema": "repair-water-pomp",
        "title": "Water pump",
        "titlePlural": "Water pump"
    },
    {
        "className": "SalaryPointCost",
        "schema": "salary-point-cost",
        "title": "Administrative extra cost",
        "titlePlural": "Administrative extra costs"
    },
    {
        "className": "SalesOrder",
        "schema": "sales-order",
        "title": "Sales order",
        "titlePlural": "Sales orders",
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "SalesOrderPaymentDates",
        "schema": "sales-order-payment-dates",
        "title": "Payment terms",
        "titlePlural": "Payment terms"
    },
    {
        "className": "SalesOrderPriceChange",
        "schema": "sales-order-price-change",
        "title": "Order price change",
        "titlePlural": "Order price changes",
        "required": [
            "total",
            "note",
            "currency"
        ],
        "scopes": []
    },
    {
        "className": "SalesOrderTracking",
        "schema": "sales-order-tracking",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Sender",
        "schema": "sender",
        "title": "Seller",
        "titlePlural": "Sellers",
        "required": [
            "name",
            "country",
            "address"
        ]
    },
    {
        "className": "SerialNumber",
        "schema": "serial-number",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "Status",
        "schema": "status",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "SupplierCustoms",
        "schema": "supplier-customs",
        "title": "Guarantee procedures",
        "titlePlural": "Guarantee procedures",
        "required": [
            "name"
        ]
    },
    {
        "className": "SupplierExtraCosts",
        "schema": "supplier-extra-costs",
        "title": "Extra costs supplier",
        "titlePlural": "Extra costs suppliers",
        "required": [
            "name"
        ]
    },
    {
        "className": "SupplierRailway",
        "schema": "supplier-railway",
        "title": "Railway tariff",
        "titlePlural": "Railway tariff",
        "required": [
            "name",
            "country",
            "address",
            "code"
        ]
    },
    {
        "className": "Task",
        "schema": "task",
        "title": "Task",
        "titlePlural": "Tasks",
        "required": [
            "description",
            "dueDate",
            "doer",
            "taskCategory"
        ]
    },
    {
        "className": "TaskCategory",
        "schema": "task-category",
        "title": "Task category",
        "titlePlural": "Task categories"
    },
    {
        "className": "Terminal",
        "schema": "terminal",
        "title": "Terminal",
        "titlePlural": "Terminals",
        "required": [
            "attorneyFile"
        ]
    },
    {
        "className": "TerminalContact",
        "schema": "terminal-contact",
        "title": "Terminal contact",
        "titlePlural": "Terminal contacts",
        "required": [],
        "scopes": []
    },
    {
        "className": "Tracking",
        "schema": "tracking",
        "title": "Tracking",
        "titlePlural": "Tracking"
    },
    {
        "className": "Trailer",
        "schema": "trailer",
        "title": "Trailer",
        "titlePlural": "Trailers",
        "required": [
            "truckOwner"
        ]
    },
    {
        "className": "Truck",
        "schema": "truck",
        "title": "Truck",
        "titlePlural": "Trucks",
        "required": [
            "number"
        ]
    },
    {
        "className": "TruckLocation",
        "schema": "truck-location",
        "title": "Location link",
        "titlePlural": "Location links"
    },
    {
        "className": "TruckOwner",
        "schema": "truck-owner",
        "title": "Truck owner",
        "titlePlural": "Truck owners"
    },
    {
        "className": "TyreCheck",
        "schema": "tyre-check",
        "title": "Tyres check",
        "titlePlural": "Tyres check"
    },
    {
        "className": "UnloadAddress",
        "schema": "unload-address",
        "title": "Unload address",
        "titlePlural": "Unload addresses",
        "scopes": [
            "disable-user-manager-create"
        ]
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users",
        "scopes": [
            "disable-create"
        ]
    },
    {
        "className": "UserCountryLogisticGroupRel",
        "schema": "user-country-logistic-group-rel",
        "title": "Logistic group assignment",
        "titlePlural": "Logistic group assignment"
    },
    {
        "className": "UserDpsSettings",
        "schema": "user-dps-settings",
        "title": "DPS settings",
        "titlePlural": "DPS settings",
        "required": [],
        "scopes": []
    },
    {
        "className": "UserLeadCategoryRel",
        "schema": "user-lead-category-rel",
        "title": "Potential assignment",
        "titlePlural": "Potential assignment"
    },
    {
        "className": "UserManagerSettings",
        "schema": "user-manager-settings",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "UserPointsLog",
        "schema": "user-points-log",
        "title": "User points log",
        "titlePlural": "User points log",
        "required": [],
        "scopes": []
    },
    {
        "className": "UserPointsSettings",
        "schema": "user-points-settings",
        "title": "Point setting",
        "titlePlural": "Point settings",
        "required": [
            "date",
            "logisticPoints",
            "operativePoints",
            "workingDays"
        ],
        "scopes": []
    },
    {
        "className": "UserTomSettings",
        "schema": "user-tom-settings",
        "title": "Tom settings",
        "titlePlural": "Tom settings",
        "required": [],
        "scopes": []
    },
    {
        "className": "ViltClient",
        "schema": "vilt-client",
        "title": "Client",
        "titlePlural": "Clients"
    },
    {
        "className": "ViltContact",
        "schema": "vilt-contact",
        "title": "Clients contacts",
        "titlePlural": "Clients contacts"
    },
    {
        "className": "ViltPayment",
        "schema": "vilt-payment",
        "title": "Payment",
        "titlePlural": "Payments"
    },
    {
        "className": "ViltPurchaseOrder",
        "schema": "vilt-purchase-order",
        "title": "Purchase order",
        "titlePlural": "Purchase orders",
        "required": [
            "client",
            "contact",
            "cargoInfo",
            "carNumber",
            "loadDate",
            "loadingPlace",
            "unloadDate",
            "unloadingPlace",
            "paymentTerms",
            "notes",
            "total"
        ]
    },
    {
        "className": "ViltSalesOrder",
        "schema": "vilt-sales-order",
        "title": "Sales Orders",
        "titlePlural": "Sales Orders",
        "required": [
            "client",
            "contact",
            "loadingPlace",
            "unloadingPlace",
            "total",
            "currency",
            "trip",
            "trailer",
            "loadDate",
            "unloadDate",
            "guaranteeProcedure",
            "clientOrderNumber"
        ]
    },
    {
        "className": "ViltTrip",
        "schema": "vilt-trip",
        "title": "Trip",
        "titlePlural": "Trips",
        "required": [
            "company",
            "truck"
        ]
    }
]
        export const NaeSSchemaMap = {
    "AdrClass": {
        "className": "AdrClass",
        "schema": "adr-class"
    },
    "BaseEntity": {
        "className": "BaseEntity",
        "schema": "base-entity"
    },
    "Bookmark": {
        "className": "Bookmark",
        "schema": "bookmark"
    },
    "Calculation": {
        "className": "Calculation",
        "schema": "calculation"
    },
    "CalculationCostHistory": {
        "className": "CalculationCostHistory",
        "schema": "calculation-cost-history"
    },
    "Cargo": {
        "className": "Cargo",
        "schema": "cargo"
    },
    "CargoDescription": {
        "className": "CargoDescription",
        "schema": "cargo-description"
    },
    "CargoLoadingCalculation": {
        "className": "CargoLoadingCalculation",
        "schema": "cargo-loading-calculation"
    },
    "CargoStatus": {
        "className": "CargoStatus",
        "schema": "cargo-status"
    },
    "Carrier": {
        "className": "Carrier",
        "schema": "carrier"
    },
    "CarriersOrder": {
        "className": "CarriersOrder",
        "schema": "carriers-order"
    },
    "CarriersOrderPaymentTerm": {
        "className": "CarriersOrderPaymentTerm",
        "schema": "carriers-order-payment-term"
    },
    "Client": {
        "className": "Client",
        "schema": "client"
    },
    "ClientCategory": {
        "className": "ClientCategory",
        "schema": "client-category"
    },
    "ClientFilial": {
        "className": "ClientFilial",
        "schema": "client-filial"
    },
    "CommentLoading": {
        "className": "CommentLoading",
        "schema": "comment-loading"
    },
    "CommentOperation": {
        "className": "CommentOperation",
        "schema": "comment-operation"
    },
    "CommentProcessing": {
        "className": "CommentProcessing",
        "schema": "comment-processing"
    },
    "CommissionExpense": {
        "className": "CommissionExpense",
        "schema": "commission-expense"
    },
    "Company": {
        "className": "Company",
        "schema": "company"
    },
    "Contact": {
        "className": "Contact",
        "schema": "contact"
    },
    "ContractTerm": {
        "className": "ContractTerm",
        "schema": "contract-term"
    },
    "Country": {
        "className": "Country",
        "schema": "country"
    },
    "CountryGeographyGroup": {
        "className": "CountryGeographyGroup",
        "schema": "country-geography-group"
    },
    "CountryLogisticGroup": {
        "className": "CountryLogisticGroup",
        "schema": "country-logistic-group"
    },
    "CountryRegion": {
        "className": "CountryRegion",
        "schema": "country-region"
    },
    "Currency": {
        "className": "Currency",
        "schema": "currency"
    },
    "CurrencyRate": {
        "className": "CurrencyRate",
        "schema": "currency-rate"
    },
    "Driver": {
        "className": "Driver",
        "schema": "driver"
    },
    "EtransportTruck": {
        "className": "EtransportTruck",
        "schema": "etransport-truck"
    },
    "ExtraCost": {
        "className": "ExtraCost",
        "schema": "extra-cost"
    },
    "ExtraCostCargo": {
        "className": "ExtraCostCargo",
        "schema": "extra-cost-cargo"
    },
    "ExtraCostCommon": {
        "className": "ExtraCostCommon",
        "schema": "extra-cost-common"
    },
    "ExtraCostGarant": {
        "className": "ExtraCostGarant",
        "schema": "extra-cost-garant"
    },
    "ExtraCostRailwayTarif": {
        "className": "ExtraCostRailwayTarif",
        "schema": "extra-cost-railway-tarif"
    },
    "ExtraCostTerminal": {
        "className": "ExtraCostTerminal",
        "schema": "extra-cost-terminal"
    },
    "File": {
        "className": "File",
        "schema": "file"
    },
    "FilterChange": {
        "className": "FilterChange",
        "schema": "filter-change"
    },
    "FollowUp": {
        "className": "FollowUp",
        "schema": "follow-up"
    },
    "InsuranceRates": {
        "className": "InsuranceRates",
        "schema": "insurance-rates"
    },
    "Inventory": {
        "className": "Inventory",
        "schema": "inventory"
    },
    "KzCarriersOrder": {
        "className": "KzCarriersOrder",
        "schema": "kz-carriers-order"
    },
    "LeadLostReason": {
        "className": "LeadLostReason",
        "schema": "lead-lost-reason"
    },
    "LeadLostRecord": {
        "className": "LeadLostRecord",
        "schema": "lead-lost-record"
    },
    "LeadSource": {
        "className": "LeadSource",
        "schema": "lead-source"
    },
    "LeadSourceCategory": {
        "className": "LeadSourceCategory",
        "schema": "lead-source-category"
    },
    "LoadAddress": {
        "className": "LoadAddress",
        "schema": "load-address"
    },
    "Mail": {
        "className": "Mail",
        "schema": "mail"
    },
    "Note": {
        "className": "Note",
        "schema": "note"
    },
    "Notification": {
        "className": "Notification",
        "schema": "notification"
    },
    "OilChange": {
        "className": "OilChange",
        "schema": "oil-change"
    },
    "OutgoingDocumentsChangePurpose": {
        "className": "OutgoingDocumentsChangePurpose",
        "schema": "outgoing-documents-change-purpose"
    },
    "Payment": {
        "className": "Payment",
        "schema": "payment"
    },
    "PhotoAlbum": {
        "className": "PhotoAlbum",
        "schema": "photo-album"
    },
    "PoCancelReason": {
        "className": "PoCancelReason",
        "schema": "po-cancel-reason"
    },
    "PriceListFtl": {
        "className": "PriceListFtl",
        "schema": "price-list-ftl"
    },
    "PriceListGarant": {
        "className": "PriceListGarant",
        "schema": "price-list-garant"
    },
    "PriceListLtl": {
        "className": "PriceListLtl",
        "schema": "price-list-ltl"
    },
    "PriceListLtlLine": {
        "className": "PriceListLtlLine",
        "schema": "price-list-ltl-line"
    },
    "PriceListRailwayCarriage": {
        "className": "PriceListRailwayCarriage",
        "schema": "price-list-railway-carriage"
    },
    "PriceListRailwayContainer": {
        "className": "PriceListRailwayContainer",
        "schema": "price-list-railway-container"
    },
    "SalesOrder": {
        "className": "SalesOrder",
        "schema": "sales-order"
    },
    "Sender": {
        "className": "Sender",
        "schema": "sender"
    },
    "SerialNumber": {
        "className": "SerialNumber",
        "schema": "serial-number"
    },
    "Status": {
        "className": "Status",
        "schema": "status"
    },
    "SupplierCustoms": {
        "className": "SupplierCustoms",
        "schema": "supplier-customs"
    },
    "SupplierExtraCosts": {
        "className": "SupplierExtraCosts",
        "schema": "supplier-extra-costs"
    },
    "SupplierRailway": {
        "className": "SupplierRailway",
        "schema": "supplier-railway"
    },
    "Task": {
        "className": "Task",
        "schema": "task"
    },
    "TaskCategory": {
        "className": "TaskCategory",
        "schema": "task-category"
    },
    "Terminal": {
        "className": "Terminal",
        "schema": "terminal"
    },
    "Tracking": {
        "className": "Tracking",
        "schema": "tracking"
    },
    "Trailer": {
        "className": "Trailer",
        "schema": "trailer"
    },
    "Truck": {
        "className": "Truck",
        "schema": "truck"
    },
    "TyreCheck": {
        "className": "TyreCheck",
        "schema": "tyre-check"
    },
    "UnloadAddress": {
        "className": "UnloadAddress",
        "schema": "unload-address"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "UserCountryLogisticGroupRel": {
        "className": "UserCountryLogisticGroupRel",
        "schema": "user-country-logistic-group-rel"
    },
    "UserLeadCategoryRel": {
        "className": "UserLeadCategoryRel",
        "schema": "user-lead-category-rel"
    },
    "SalaryPointCost": {
        "className": "SalaryPointCost",
        "schema": "salary-point-cost"
    },
    "InvoiceIncoming": {
        "className": "InvoiceIncoming",
        "schema": "invoice-incoming"
    },
    "InvoiceOutgoing": {
        "className": "InvoiceOutgoing",
        "schema": "invoice-outgoing"
    },
    "Attorney": {
        "className": "Attorney",
        "schema": "attorney"
    },
    "KzCarriersOrderLoadDateChange": {
        "className": "KzCarriersOrderLoadDateChange",
        "schema": "kz-carriers-order-load-date-change"
    },
    "CallLog": {
        "className": "CallLog",
        "schema": "call-log"
    },
    "ClientStatus": {
        "className": "ClientStatus",
        "schema": "client-status"
    },
    "ContactDuplicate": {
        "className": "ContactDuplicate",
        "schema": "contact-duplicate"
    },
    "CarServiceAct": {
        "className": "CarServiceAct",
        "schema": "car-service-act"
    },
    "CarServiceActProduct": {
        "className": "CarServiceActProduct",
        "schema": "car-service-act-product"
    },
    "CarServiceActService": {
        "className": "CarServiceActService",
        "schema": "car-service-act-service"
    },
    "CarServiceClient": {
        "className": "CarServiceClient",
        "schema": "car-service-client"
    },
    "CarServiceInvoiceIncoming": {
        "className": "CarServiceInvoiceIncoming",
        "schema": "car-service-invoice-incoming"
    },
    "CarServiceInvoiceOutgoing": {
        "className": "CarServiceInvoiceOutgoing",
        "schema": "car-service-invoice-outgoing"
    },
    "CarServiceProduct": {
        "className": "CarServiceProduct",
        "schema": "car-service-product"
    },
    "CarServiceService": {
        "className": "CarServiceService",
        "schema": "car-service-service"
    },
    "CarServiceStockItem": {
        "className": "CarServiceStockItem",
        "schema": "car-service-stock-item"
    },
    "CarServiceSupplier": {
        "className": "CarServiceSupplier",
        "schema": "car-service-supplier"
    },
    "CarServiceInvoiceIncomingProduct": {
        "className": "CarServiceInvoiceIncomingProduct",
        "schema": "car-service-invoice-incoming-product"
    },
    "CarServiceInvoiceOutgoingProduct": {
        "className": "CarServiceInvoiceOutgoingProduct",
        "schema": "car-service-invoice-outgoing-product"
    },
    "CarServiceInvoiceOutgoingService": {
        "className": "CarServiceInvoiceOutgoingService",
        "schema": "car-service-invoice-outgoing-service"
    },
    "RepairTensionBearings": {
        "className": "RepairTensionBearings",
        "schema": "repair-tension-bearings"
    },
    "RepairWaterPomp": {
        "className": "RepairWaterPomp",
        "schema": "repair-water-pomp"
    },
    "KzEx1FilesSend": {
        "className": "KzEx1FilesSend",
        "schema": "kz-ex1-files-send"
    },
    "Purpose": {
        "className": "Purpose",
        "schema": "purpose"
    },
    "ClientContract": {
        "className": "ClientContract",
        "schema": "client-contract"
    },
    "TruckOwner": {
        "className": "TruckOwner",
        "schema": "truck-owner"
    },
    "KzFtlCalculation": {
        "className": "KzFtlCalculation",
        "schema": "kz-ftl-calculation"
    },
    "CarServiceClientContact": {
        "className": "CarServiceClientContact",
        "schema": "car-service-client-contact"
    },
    "CarServiceClientCar": {
        "className": "CarServiceClientCar",
        "schema": "car-service-client-car"
    },
    "CarServiceSupplierContact": {
        "className": "CarServiceSupplierContact",
        "schema": "car-service-supplier-contact"
    },
    "CarServiceProductGroup": {
        "className": "CarServiceProductGroup",
        "schema": "car-service-product-group"
    },
    "CarServiceProductBarcode": {
        "className": "CarServiceProductBarcode",
        "schema": "car-service-product-barcode"
    },
    "CarServiceProductCostList": {
        "className": "CarServiceProductCostList",
        "schema": "car-service-product-cost-list"
    },
    "CarServiceInvoiceReturnProduct": {
        "className": "CarServiceInvoiceReturnProduct",
        "schema": "car-service-invoice-return-product"
    },
    "CarServiceInvoiceReturn": {
        "className": "CarServiceInvoiceReturn",
        "schema": "car-service-invoice-return"
    },
    "ViltContact": {
        "className": "ViltContact",
        "schema": "vilt-contact"
    },
    "ViltClient": {
        "className": "ViltClient",
        "schema": "vilt-client"
    },
    "ViltSalesOrder": {
        "className": "ViltSalesOrder",
        "schema": "vilt-sales-order"
    },
    "ViltTrip": {
        "className": "ViltTrip",
        "schema": "vilt-trip"
    },
    "CarServicePayment": {
        "className": "CarServicePayment",
        "schema": "car-service-payment"
    },
    "ViltPayment": {
        "className": "ViltPayment",
        "schema": "vilt-payment"
    },
    "TruckLocation": {
        "className": "TruckLocation",
        "schema": "truck-location"
    },
    "ViltPurchaseOrder": {
        "className": "ViltPurchaseOrder",
        "schema": "vilt-purchase-order"
    },
    "ClientStatusData": {
        "className": "ClientStatusData",
        "schema": "client-status-data"
    },
    "SalesOrderPaymentDates": {
        "className": "SalesOrderPaymentDates",
        "schema": "sales-order-payment-dates"
    },
    "FilesManagerFolder": {
        "className": "FilesManagerFolder",
        "schema": "files-manager-folder"
    },
    "FilesManagerFile": {
        "className": "FilesManagerFile",
        "schema": "files-manager-file"
    },
    "TerminalContact": {
        "className": "TerminalContact",
        "schema": "terminal-contact"
    },
    "SalesOrderPriceChange": {
        "className": "SalesOrderPriceChange",
        "schema": "sales-order-price-change"
    },
    "InvoiceOutgoingPriceChange": {
        "className": "InvoiceOutgoingPriceChange",
        "schema": "invoice-outgoing-price-change"
    },
    "UserPointsSettings": {
        "className": "UserPointsSettings",
        "schema": "user-points-settings"
    },
    "UserPointsLog": {
        "className": "UserPointsLog",
        "schema": "user-points-log"
    },
    "InvoiceOutgoingCreditRequest": {
        "className": "InvoiceOutgoingCreditRequest",
        "schema": "invoice-outgoing-credit-request"
    },
    "InvoiceIncomingSepaExport": {
        "className": "InvoiceIncomingSepaExport",
        "schema": "invoice-incoming-sepa-export"
    },
    "CargoSpecificaction": {
        "className": "CargoSpecificaction",
        "schema": "cargo-specificaction"
    },
    "CargoSticker": {
        "className": "CargoSticker",
        "schema": "cargo-sticker"
    },
    "SalesOrderTracking": {
        "className": "SalesOrderTracking",
        "schema": "sales-order-tracking"
    },
    "ClientDoneJobsAct": {
        "className": "ClientDoneJobsAct",
        "schema": "client-done-jobs-act"
    },
    "ClientOriginalDocuments": {
        "className": "ClientOriginalDocuments",
        "schema": "client-original-documents"
    },
    "ClientTracking": {
        "className": "ClientTracking",
        "schema": "client-tracking"
    },
    "ClientYearStats": {
        "className": "ClientYearStats",
        "schema": "client-year-stats"
    },
    "ClientAllStats": {
        "className": "ClientAllStats",
        "schema": "client-all-stats"
    },
    "UserTomSettings": {
        "className": "UserTomSettings",
        "schema": "user-tom-settings"
    },
    "KzInspection": {
        "className": "KzInspection",
        "schema": "kz-inspection"
    },
    "CargoHold": {
        "className": "CargoHold",
        "schema": "cargo-hold"
    },
    "KzLocationSuggest": {
        "className": "KzLocationSuggest",
        "schema": "kz-location-suggest"
    },
    "CargoInsurance": {
        "className": "CargoInsurance",
        "schema": "cargo-insurance"
    },
    "UserDpsSettings": {
        "className": "UserDpsSettings",
        "schema": "user-dps-settings"
    },
    "KzCarriersOrderProblem": {
        "className": "KzCarriersOrderProblem",
        "schema": "kz-carriers-order-problem"
    },
    "CarriersOrderProblem": {
        "className": "CarriersOrderProblem",
        "schema": "carriers-order-problem"
    },
    "CarriersOrderExpense": {
        "className": "CarriersOrderExpense",
        "schema": "carriers-order-expense"
    },
    "CarriersOrderPriceChange": {
        "className": "CarriersOrderPriceChange",
        "schema": "carriers-order-price-change"
    },
    "CargoRailway": {
        "className": "CargoRailway",
        "schema": "cargo-railway"
    },
    "UserManagerSettings": {
        "className": "UserManagerSettings",
        "schema": "user-manager-settings"
    },
    "Bank": {
        "className": "Bank",
        "schema": "bank"
    },
    "CargoOperationConfirmation": {
        "className": "CargoOperationConfirmation",
        "schema": "cargo-operation-confirmation"
    },
    "CargoOperationConfirmationChanges": {
        "className": "CargoOperationConfirmationChanges",
        "schema": "cargo-operation-confirmation-changes"
    },
    "AccountingDiscrepancies": {
        "className": "AccountingDiscrepancies",
        "schema": "accounting-discrepancies"
    },
    "CarriersOrderLoadDateChange": {
        "className": "CarriersOrderLoadDateChange",
        "schema": "carriers-order-load-date-change"
    },
    "CargoContactSellerLog": {
        "className": "CargoContactSellerLog",
        "schema": "cargo-contact-seller-log"
    },
    "ClientTop20": {
        "className": "ClientTop20",
        "schema": "client-top20"
    },
    "CargoLoadingCalculationNotesSuggest": {
        "className": "CargoLoadingCalculationNotesSuggest",
        "schema": "cargo-loading-calculation-notes-suggest"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "CommentIkNotes": {
        "className": "CommentIkNotes",
        "schema": "comment-ik-notes"
    },
    "KzCarriersOrderLocation": {
        "className": "KzCarriersOrderLocation",
        "schema": "kz-carriers-order-location"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "CargoExOrder": {
        "className": "CargoExOrder",
        "schema": "cargo-ex-order"
    }
}