import React, { Fragment } from 'react'
import { PropsId } from '../types';
import ContactFullNameWithLink from '../Contact/ContactFullNameWithLink';
import { useCarriersOrderHookNae } from '../../hooks/useCarriersOrderHookNae';

export default function CarriersOrderContactNameWithLink(props: PropsId) {
    const element = useCarriersOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (!element.contact) {
        return <Fragment />
    }
    return (
        <ContactFullNameWithLink id={element.contact.id} />
    )
}
