import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { checkUserLogisticFtl } from "../../../../../_generated/_custom/config/NaeSPermissions";
import { useCargoHookNae } from '../../../../../_generated/_custom/hooks/useCargoHookNae';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButtonWithSave } from "@newageerp/v3.bundles.buttons-bundle";

interface Props {
  cargoId: number;
  calcType: string,
}

export default function FinishBtn(props: Props) {
  const { cargoId } = props;
  const element = useCargoHookNae(cargoId);

  const { userState } = useTemplatesCore()
  const isFtlLogistic = checkUserLogisticFtl(userState);

  const ftlCalculationStop = { ...element.ftlCalculationStop };
  ftlCalculationStop[userState.id] = 1;

  const activeCargoLoadingCalculations = props.calcType === 'out' ? element.activeCargoLoadingCalculationsOut : element.activeCargoLoadingCalculations;
  const loadingFinishKey = props.calcType === 'out' ? 'loadingPriceCalculatedOut' : 'loadingPriceCalculated';

  const saveParams = isFtlLogistic ? { ftlCalculationStop } : {
    [loadingFinishKey]: true,
    _events: [
      'evt.userpoints.onCalcFinish',
    ]
  };

  const { t } = useTranslation();

  let disabledMessage = "";
  let disabledColor: any = undefined;
  if (activeCargoLoadingCalculations.length === 0 && !isFtlLogistic) {
    disabledMessage = t("Enter the rates to complete the quotation");
  }
  if (element[loadingFinishKey]) {
    disabledMessage = t("The quotation has already been completed");
    disabledColor = "success";
  }

  if (!element) {
    return <Fragment />
  }

  const disabled = !!disabledMessage;

  return (
    <Fragment>
      <MainButtonWithSave
        elementId={cargoId}
        saveData={saveParams}
        schema={"cargo"}
        className="w-full"
        iconName="check"
        confirmation={true}
        color="teal"
        disabled={disabled}
        disabledContent={{
          children: disabledMessage,
          color: disabledColor,
        }}
      >
        {t("Complete the quotation")}
      </MainButtonWithSave>
    </Fragment>
  );
}
