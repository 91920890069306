import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useClientHookNae } from '../../../_generated/_custom/hooks/useClientHookNae';
import { useLeadSourceHookNae } from '../../../_generated/_custom/hooks/useLeadSourceHookNae';
import { useLeadSourceCategoryHookNae } from '../../../_generated/_custom/hooks/useLeadSourceCategoryHookNae';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { AlertWidget } from '@newageerp/v3.bundles.widgets-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { Compact, CompactRow, FieldTextarea, Label } from '@newageerp/v3.bundles.form-bundle';
import { Tooltip } from '@newageerp/v3.bundles.badges-bundle';

export default function ManagerClientDecline(props: PropsId) {
    const [showPopup, setShowPopup] = useState(false);

    const [comment, setComment] = useState('');

    const element = useClientHookNae(props.id);
    const leadSource = useLeadSourceHookNae(!!element && element.leadSource ? element.leadSource.id : -1);
    const leadSourceCategory = useLeadSourceCategoryHookNae(!!leadSource && leadSource.category ? leadSource.category.id : -1);

    const [doSave, doSaveParams] = useUSave(NaeSSchemaMap.Client.schema);

    const { t } = useTranslation();

    const sendToBussinessDev = async () => {
        await doSave({
            needBusinessDev: true,
            mergeMode: true,
            skipRequiredCheck: true,
            needBusinessDevNote: comment,
        }, element.id);
        setShowPopup(false);
    }

    if (!element) return <Fragment />
    if (!element.doer) {
        return <Fragment />
    }

    const canSendToBusinessDev = leadSourceCategory && leadSourceCategory.sendToBusinessDev === 10;

    return (
        <Fragment>
            {element.needBusinessDev &&
                <AlertWidget color='warning' isCompact={true}>
                    {t('Contacts in search')}
                </AlertWidget>}

            {!element.needBusinessDev && canSendToBusinessDev && <Fragment>
                <MainButton iconName="id-card" onClick={() => setShowPopup(true)} color="pink">
                    {t('No contacts, search is required')}
                </MainButton>

            </Fragment>
            }

            <Popup isPopup={showPopup} title="Search is required">
                <Compact>
                    <CompactRow
                        label={<Label>Please fill in details <Tooltip text='If there is website where contacts can be bought please copy a link. If no, please mention any information which can help in search' /></Label>}
                        control={<FieldTextarea className='w-[300px]' value={comment} onChange={e => setComment(e.target.value)} />}
                    />
                    <div className='flex gap-2 justify-end'>
                        <MainButton iconName="ban" onClick={() => setShowPopup(false)} color="slate">
                            {t('Cancel')}
                        </MainButton>

                        <MainButton iconName="id-card" onClick={sendToBussinessDev} color="sky" loading={doSaveParams.loading}>
                            {t('Send')}
                        </MainButton>
                    </div>
                </Compact>
            </Popup>

        </Fragment>
    )
}
