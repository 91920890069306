import React, { Fragment } from 'react'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTranslation } from 'react-i18next';
import { toast, useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../config/NaePaths';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';

interface Props {
    fieldKey: string,
}

export default function UnAssignClienClient(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];
    const listDataSource = useListDataSource();

    const { t } = useTranslation();

    const [unAssignClient, unAssignClientParams] = useURequest<number>(
        NaePathsMap.post["AppPluginsClientOwnersResetOwnerClient"]
    );

    const removeUnAssign = () => {
        unAssignClient({
            doerId: element.id
        }).then(() => {
            listDataSource.data.reload.do();
            toast.success(
                "The job has been sent for processing. You will receive a notification after its completion."
              );
        });
    };

    if (unAssignClientParams.loading) {
        return <Fragment />
    }

    return (
        <MainButton onClick={removeUnAssign} confirmation={true} color={"pink"} iconName={"layer-minus"}>
            {t("Remove clients")}
        </MainButton>
    )
}
