import React, { Fragment } from "react";
import moment from "moment";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from "../../../core/old-ui/OldBadge";

export default function KzLoadDate(props: PropsId) {
  const element = useKzCarriersOrderHookNae(props.id);
  
  if (!element || !element.loadDate) {
    return <Fragment />;
  }
  const loadDateM = moment(element.loadDate);
  const loadDateS = loadDateM.format("YYYY-MM-DD");

  const nowM = moment();
  const nowS = nowM.format("YYYY-MM-DD");

  if (element.status >= 15) {
    return <Fragment>{loadDateS}</Fragment>;
  }

  if (loadDateS === nowS) {
    return (
      <OldBadge
        size={BadgeSize.sm}
        brightness={200}
        bgColor={BadgeBgColor.yellow}
      >
        {loadDateS}
      </OldBadge>
    );
  }
  if (loadDateM < nowM) {
    return (
      <OldBadge
        size={BadgeSize.sm}
        brightness={200}
        bgColor={BadgeBgColor.red}
      >
        {loadDateS}
      </OldBadge>
    );
  }

  return <Fragment>{loadDateS}</Fragment>;
}
