import React, { Fragment } from 'react'
import CargoRailwayType from '../../../../../_generated/_custom/models-cache-data/Cargo/CargoRailwayType';
import { PropsId } from '../../../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

export default function RailwayOrderTransportInfo(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);

    if (!element) {
        return <Fragment/>
    }

    return (
        <Fragment>
            {element.transportType !== 20 &&
                <p>
                    <DfValueView path="kz-carriers-order.transportType" id={element.id}/>
                </p>
            }
            {element.transportType === 20 && element.cargos.length > 0 && (
                <p>
                    <CargoRailwayType id={element.cargos[0].id} />
                </p>
            )}
            {element.transportType === 20 && <p>{element.carNumber}</p>}
        </Fragment>
    )
}
