interface PropsIsHotCalculationDelayed {
  status25LastChangeDiffDays: number;
}

export const isHotCalculationDelayed = (el: PropsIsHotCalculationDelayed) => {
  return !!el && el.status25LastChangeDiffDays > 14;
};

interface PropsIsCalculatedCalculationDelayed {
  status10LastChangeDiffDays: number;
}

export const isCalculatedCalculationDelayed = (
  el: PropsIsCalculatedCalculationDelayed,
) => {
  return !!el && el.status10LastChangeDiffDays > 14;
};
