import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { useTranslation } from 'react-i18next';

interface Props {
    fieldKey: string,
}

export default function UnloadAddressBadge(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const {t} = useTranslation();

    return (
        <div className={"space-y-2"}>
            {element.canUnload === 0 && (
                <div className='text-teal-500'>{t('Unloading')}</div>
            )}
            {element.recommended === 0 && (
                <div className='text-teal-500'>{t('Recommended')}</div>
            )}

            {element.canAvia === 0 && (
                <div className='text-blue-500'>{t('Avia')}</div>
            )}
            {element.canCarriage === 0 && (
                <div className='text-purple-500'>{t('Vagonai')}</div>
            )}
            {element.canContainer === 0 && (
                <div className='text-purple-500'>{t('Konteineris')}</div>
            )}
            {!!element.priceListLtl && (
                <div className='text-sky-500'>{t('LTL')}</div>
            )}
            {!!element.priceListFtl && (
                <div className='text-cyan-500'>{t('FTL')}</div>
            )}
        </div>
    )
}
