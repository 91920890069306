import React from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types'
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

export default function SoReloadFileToolbar(props: PropsId) {
    const [reloadFile, reloadFileParams] = useURequest(
        NaePathsMap.post["AddToSalesOrderFileProcessor"]
    );

    const onClick = async () => {
        await reloadFile({ id: props.id })
    }

    return (
        <MenuItem confirmation={true} onClick={onClick} iconName='rotate'>Reload saved files</MenuItem>
    )
}
