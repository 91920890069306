import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { NaeSSchemaMap } from '../../../config/NaeSSchema';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

interface Props {
    fieldKey: string,
}

export default function Confirmation(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    const [doSave, saveParams] = useUSave(NaeSSchemaMap.AccountingDiscrepancies.schema);
    const { data } = useListDataSource();

    const onSave = async () => {
        await doSave(
            {
                confirmed: true
            },
            element.id
        );
        data.reload.do();
    }

    if (element.confirmed) {
        return <Fragment />
    }

    return (
        <MainButton color='teal' onClick={onSave} iconName='save' loading={saveParams.loading}>Confirm</MainButton>
    )
}
