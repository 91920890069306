import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzInspectionNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IKzInspectionModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      startDate: string,
      finishDate: string,
  }

export const IKzInspectionFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "startDate",
    "finishDate"
];

export function useKzInspectionHookNae(id: number) : IKzInspectionModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorKzInspectionNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "kz-inspection",
        fields: IKzInspectionFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
