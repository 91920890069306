import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCarServiceClientCarNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICarServiceClientCarModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const ICarServiceClientCarFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useCarServiceClientCarHookNae(id: number) : ICarServiceClientCarModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCarServiceClientCarNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "car-service-client-car",
        fields: ICarServiceClientCarFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
