import React, { Fragment, useEffect } from 'react'
import OperativeAdminNeedDocs from '../OperationalAdmin/OperativeAdminNeedDocs'
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";


export default function AccountingNeedDocsPage() {
    const { t } = useTranslation();
    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
    useEffect(() => {
        setToolbarTitle(t(''));
    }, []);

    return (
        <Fragment>
            <OperativeAdminNeedDocs serviceCooNew={true} serviceCooRenew={true} serviceInsurance={true}/>
        </Fragment>
    )
}
