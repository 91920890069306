import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { Fragment, useState } from "react";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";

type CargoContactedSellerTimeBtnProps = {
    ids: number[],
}
export const CargoContactedSellerTimeBtn = (props: CargoContactedSellerTimeBtnProps) => {
    const [datesPopup, setDatesPopup] = useState(false);

    const onLoadLoadingDatesHistory = () => {
        setDatesPopup(true);
    }

    return (
        <Fragment>

            <ToolbarButton
                iconName='list'
                onClick={onLoadLoadingDatesHistory}
                bgColor='bg-transparent'
                small={true}
            />


            {props.ids.length > 0 &&
                <CargoContactedSellerTimePopup
                    ids={props.ids}
                    visible={datesPopup}
                    onClose={() => setDatesPopup(false)}
                />
            }
        </Fragment>
    )
}

type CargoContactedSellerTimePopupProps = {
    visible: boolean,
    onClose: () => void,
    ids: number[]
}

export const CargoContactedSellerTimePopup = (props: CargoContactedSellerTimePopupProps) => {
    return <Popup title="Contact log" onClick={props.onClose} isPopup={props.visible}>
        <TemplatesLoader
            templateName="PageInlineList"
            data={{
                schema: NaeSSchemaMap.CargoContactSellerLog.schema,
                type: "main-popup",
                extraFilters: [{
                    "and": [
                        ['i.cargo.id', 'in', props.ids, true]
                    ]
                }]
            }}
        />
    </Popup>
}