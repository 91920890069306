import React, { Fragment } from 'react'
import { PropsId } from '../../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../../_generated/_custom/hooks/useCargoHookNae';
import { useTerminalHookNae } from '../../../../../_generated/_custom/hooks/useTerminalHookNae';
import { useCalculationHookNae } from '../../../../../_generated/_custom/hooks/useCalculationHookNae';
import { useUnloadAddressHookNae } from '../../../../../_generated/_custom/hooks/useUnloadAddressHookNae';
import { useCargoStatusHookNae } from '../../../../../_generated/_custom/hooks/useCargoStatusHookNae';
import { useAdrClassHookNae } from '../../../../../_generated/_custom/hooks/useAdrClassHookNae';

interface IData {
    id: number,
    loadingAddressFull: string,
    loadingAddressAddCargoStatusCalc: number,

    places: string,
    dimensions: string,
    weight: string,

    cargoStatus: {
        name: string,
        fullNameRu: string,
        fullNameEn: string,
        fullName: string,
    },

    specCargo: number,
    needTemperatureMode: number,

    temperatureSettings: string,

    adrClass: {
        name: string,
    },
    extraServiceCraneHandling: number,

    terminalJson: {
        fullAddress: string,
    },
    soUnloadAddressJson: {
        fullAddress: string,
    }
}
interface Props {
    cargo: IData
}

export default function TextForCopy(props: PropsId) {
    const cargo = useCargoHookNae(props.id);
    const terminal = useTerminalHookNae(cargo && cargo.terminal ? cargo.terminal.id : -1);
    const calculation = useCalculationHookNae(cargo && cargo.calculation ? cargo.calculation.id : -1);
    const unloadAddress = useUnloadAddressHookNae(calculation && calculation.address ? calculation.address.id : -1);
    const cargoStatus = useCargoStatusHookNae(cargo && cargo.cargoStatus ? cargo.cargoStatus.id : -1);
    const cargoAdr = useAdrClassHookNae(cargo && cargo.adrClass ? cargo.adrClass.id : -1);

    if (!cargo || !calculation) {
        return <Fragment />
    }

    const placesA = cargo.places.split("\n");
    const dimensionsA = cargo.dimensions.split("\n");
    const weightsA = cargo.weight.split("\n");

    return (
        <Fragment>
            <div
                id={"text-skype-lt-" + cargo.id}
                style={{ position: "absolute", top: -5000 }}
            >
                <div>Pakrovimas: {cargo.loadingAddressFull}</div>
                <div>Vietų skaičius / Išmatavimai / Svoris: </div>
                {placesA.map((d: string, index: number) => {
                    return (
                        <div key={"text-skype-lt-" + cargo.id + "-" + index}>{`${d} / ${dimensionsA[index]} / ${weightsA.length > index ? `${weightsA[index]}kg` : ""
                            }`}</div>
                    );
                })}

                {!cargoStatus || cargoStatus.name === "Nežinau" || cargo.loadingAddressAddCargoStatusCalc !== 10 ? (
                    ""
                ) : (
                    <div>{cargoStatus.fullName}</div>
                )}
                {cargo.specCargo === 10 && <div>Krovinys - Alkoholis</div>}
                {cargo.specCargo === 30 && <div>Krovinys su fitosanitarinių sertifikatų</div>}
                {(cargo.needTemperatureMode === 10 ||
                    cargo.needTemperatureMode === 20) && (
                        <div>Temperatūrinis režimas: {cargo.temperatureSettings}</div>
                    )}
                {!!cargoAdr && cargoAdr.name !== "Not dangerous" && (
                    <div>ADR {cargoAdr.name}</div>
                )}
                {cargo.extraServiceCraneHandling === 10 && (
                    <div>pakrovimas per viršu nes pakraus/iškraus kranu</div>
                )}
                <div>
                    Iškrovimas:{" "}
                    {!!terminal
                        ? terminal.fullAddress
                        : unloadAddress
                            ? unloadAddress.fullAddress
                            : ""}
                </div>
            </div>

            <div
                id={"text-skype-ru-" + cargo.id}
                style={{ position: "absolute", top: -5000 }}
            >
                <div>Погрузка: {cargo.loadingAddressFull}</div>
                <div>Количество / Размеры / Вес: </div>
                {placesA.map((d: string, index: number) => {
                    return (
                        <div key={"text-skype-lt-" + cargo.id + "-" + index}>{`${d} / ${dimensionsA[index]} / ${weightsA.length > index ? `${weightsA[index]}kg` : ""
                            }`}</div>
                    );
                })}

                {!cargoStatus || cargoStatus.name === "Nežinau" || cargo.loadingAddressAddCargoStatusCalc !== 10 ? (
                    ""
                ) : (
                    <div>{cargoStatus.fullNameRu}</div>
                )}
                {cargo.specCargo === 10 && <div>Груз - Алкоголь</div>}
                {cargo.specCargo === 30 && <div>Груз с фитосанитарным сертификатом</div>}
                {(cargo.needTemperatureMode === 10 ||
                    cargo.needTemperatureMode === 20) && (
                        <div>Температурный режим: {cargo.temperatureSettings}</div>
                    )}
                {!!cargoAdr && cargoAdr.name !== "Not dangerous" && (
                    <div>ADR {cargoAdr.name}</div>
                )}
                {cargo.extraServiceCraneHandling === 10 && (
                    <div>верхняя погрузка (погрузка-выгрузка краном)</div>
                )}
                <div>
                    Выгрузка:{" "}
                    {!!terminal
                        ? terminal.fullAddress
                        : unloadAddress
                            ? unloadAddress.fullAddress
                            : ""}
                </div>
            </div>

            <div
                id={"text-skype-en-" + cargo.id}
                style={{ position: "absolute", top: -5000 }}
            >
                <div>Loading place: {cargo.loadingAddressFull}</div>
                <div>Quantity / Dimensions / Weight: </div>
                {placesA.map((d: string, index: number) => {
                    return (
                        <div key={"text-skype-lt-" + cargo.id + "-" + index}>{`${d} / ${dimensionsA[index]} / ${weightsA.length > index ? `${weightsA[index]}kg` : ""
                            }`}</div>
                    );
                })}

                {!cargoStatus || cargoStatus.name === "Nežinau" || cargo.loadingAddressAddCargoStatusCalc !== 10 ? (
                    ""
                ) : (
                    <div>Krovinio statusas: {cargoStatus.fullNameEn}</div>
                )}
                {cargo.specCargo === 10 && <div>Cargo - Alcohol</div>}
                {cargo.specCargo === 30 && <div>Cargo with phytosanitary certificate</div>}
                {(cargo.needTemperatureMode === 10 ||
                    cargo.needTemperatureMode === 20) && (
                        <div>Temperature mode: {cargo.temperatureSettings}</div>
                    )}
                {!!cargoAdr && cargoAdr.name !== "Not dangerous" && (
                    <div>ADR {cargoAdr.name}</div>
                )}
                {cargo.extraServiceCraneHandling === 10 && (
                    <div>Loading over the top (with crane)</div>
                )}
                <div>
                    Unloading place:{" "}
                    {!!terminal
                        ? terminal.fullAddress
                        : unloadAddress
                            ? unloadAddress.fullAddress
                            : ""}
                </div>
            </div>
        </Fragment>
    )
}
