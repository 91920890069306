import { cargoVisibility } from "./fieldsVisibility/cargo.visibility";
import { checkUserLogistic, checkUserLogisticFtl, checkUserAdmin, checkUserFinances, checkUserLogisticSea, checkUserLogisticTrain } from '../config/NaeSPermissions';
import { NaeSSchemaMap } from "../config/NaeSSchema";

export interface FieldVisibilityParams {
  schema: string;
  type?: string;
  isEdit: boolean;
  element: any;
  user: any;
  parentElement?: any;
  hasChanges?: boolean;
  updateElement?: (key: string, val: any) => void;
}

export interface IFieldVisibility {
  [key: string]: {
    [key: string]: (props: FieldVisibilityParams) => boolean;
  };
}

export const resetFieldsToDefValues = (
  schema: string,
  element: any,
  hiddenFields: string[],
  updateElementBatch: (updates: any) => void,
) => {
  if (!element) {
    return;
  }

  if (!!fieldsResetToDefFields[schema]) {
    const defFieldsSet: any = fieldsResetToDefFields[schema];
    const defFields = Object.keys(defFieldsSet);
    const elementFields = Object.keys(element);

    const _el: any = {};

    hiddenFields.forEach((k: string) => {
      if (
        elementFields.indexOf(k) >= 0 &&
        defFields.indexOf(k) >= 0 &&
        element[k] !== defFieldsSet[k]
      ) {
        _el[k] = defFieldsSet[k];
        console.log("zzz", k, defFieldsSet[k]);
      }
    });
    if (Object.keys(_el).length > 0) {
      updateElementBatch(_el);
    }
  }
};

export const fieldsResetToDefFields : any = {
  "sales-order": {
    loadAddress: null,
    contact: null,
    clientFilial: null,
    canSeparateCargos: 0,
  },
  "calculation": {
    canSeparateCargos: 0,
    clientFilial: null,
  },
  "cargo": {
    dimensions: "",
    weight: "",
    places: "",
    seller: null,
    loadAddress: null,
    loadAddressAdditional: null,
    loadAddressAdditional2: null,
    customsCode: 0,
    senderMakeEx: 0,
    senderMakeFito: 0,
    senderMakeExCost: 10,
    commentProcessing: "",
    commentLoading: "",
    outgoingDocumentsChange: 0,
    outgoingDocumentsChangeType: 0,
    outgoingDocumentsChangePurpose: null,
    specCargo: 0,
    needAlkoGlueWithStamps: 0,
    needAlkoGlueWithStampsBottles: 0,
    needAlkoGlueWithStampsType: 0,
    needAlkoGlueWithStampsType2: 0,
    needAlkoGlueWithStampsType3: 0,
    needTemperatureMode: 0,
    alcoSubType: 0,
    temperatureSettings: "",
    ftlType: 0,
    ftlAutoType: 0,
    ftlOverloadType: 0,
    modalType: 0,
    railwayType: 0,
    senderTransportType: 0,
    preciousMetals: 10,
    tnvedCodes: "",
    onlyFullWeight: 0,
    extraServiceDeliveryClient: 0,
    extraServiceDeliveryClientAddress: "",
    extraServiceCraneHandling: 0,
    extraServiceCooSertificateRenew: 0,
    extraServiceCooSertificateNew: 0,
    notesLogistic: "",
    notesOperations: "",
  },
};

export const fieldVisibility: IFieldVisibility = {
  [NaeSSchemaMap.CargoRailway.schema]: {
    'platformRent': (props: FieldVisibilityParams) => {
      return !props.element.platformRentNeeded;
    },
    'platformRentCurrency': (props: FieldVisibilityParams) => {
      return !props.element.platformRentNeeded;
    }
  },
  [NaeSSchemaMap.Terminal.schema]: {
    includeToAutomaticPayments: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    accountantChecked: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    code: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    vatNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    bankAccountNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    paymentDelay: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
  },
  [NaeSSchemaMap.Carrier.schema]: {
    includeToAutomaticPayments: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    accountantChecked: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    code: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    vatNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    bankAccountNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    paymentDelay: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
  },
  [NaeSSchemaMap.SupplierCustoms.schema]: {
    includeToAutomaticPayments: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    accountantChecked: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    code: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    vatNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    bankAccountNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    paymentDelay: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
  },
  [NaeSSchemaMap.SupplierExtraCosts.schema]: {
    includeToAutomaticPayments: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    accountantChecked: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    code: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    vatNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    bankAccountNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    paymentDelay: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
  },
  [NaeSSchemaMap.SupplierRailway.schema]: {
    includeToAutomaticPayments: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    accountantChecked: (props: FieldVisibilityParams) => {
      return !(checkUserAdmin(props.user) || checkUserFinances(props.user));
    },
    code: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    vatNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    bankAccountNumber: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
    paymentDelay: (props: FieldVisibilityParams) => {
      return props.element.accountantChecked;
    },
  },
  [NaeSSchemaMap.Truck.schema]: {
    tachographCheck: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.tachographCheckHas;
    },
    tachographCheckFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.tachographCheckHas;
    },

    licenceValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.licenceValidHas;
    },
    licenceValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.licenceValidHas;
    },

    technicalInspectionValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.technicalInspectionValidHas;
    },
    technicalInspectionValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.technicalInspectionValidHas;
    },

    insuranceKzValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceKzValidHas;
    },
    insuranceKzValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceKzValidHas;
    },

    insuranceEuValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceEuValidHas;
    },
    insuranceEuValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceEuValidHas;
    },

    insuranceByValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceByValidHas;
    },
    insuranceByValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceByValidHas;
    },

    insuranceLvValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceLvValidHas;
    },
    insuranceLvValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceLvValidHas;
    },

    insuranceRuValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceRuValidHas;
    },
    insuranceRuValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceRuValidHas;
    },
  },

  [NaeSSchemaMap.Trailer.schema]: {
    certificateOfSuitabilityValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.certificateOfSuitabilityValidHas;
    },
    certificateOfSuitabilityValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.certificateOfSuitabilityValidHas;
    },

    technicalInspectionValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.technicalInspectionValidHas;
    },
    technicalInspectionValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.technicalInspectionValidHas;
    },

    insuranceKzValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceKzValidHas;
    },
    insuranceKzValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceKzValidHas;
    },

    insuranceEuValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceEuValidHas;
    },
    insuranceEuValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceEuValidHas;
    },

    insuranceByValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceByValidHas;
    },
    insuranceByValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceByValidHas;
    },

    insuranceLvValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceLvValidHas;
    },
    insuranceLvValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.insuranceLvValidHas;
    },
  },
  "driver": {
    passportValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.passportValidHas;
    },
    passportValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.passportValidHas;
    },

    adrCertificateDate: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.adrCertificateDateHas;
    },
    adrCertificateDateFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.adrCertificateDateHas;
    },

    code95Date: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.code95DateHas;
    },
    code95DateFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.code95DateHas;
    },

    visaValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.visaValidHas;
    },
    visaValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.visaValidHas;
    },

    driverLicenceValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.driverLicenceValidHas;
    },
    driverLicenceValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.driverLicenceValidHas;
    },

    driverLicence95Valid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.driverLicence95ValidHas;
    },
    driverLicence95ValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.driverLicence95ValidHas;
    },

    attorneyValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.attorneyValidHas;
    },
    attorneyValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.attorneyValidHas;
    },

    temporaryAdmissionValid: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.temporaryAdmissionValidHas;
    },
    temporaryAdmissionValidFile: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.temporaryAdmissionValidHas;
    },
  },
  "sales-order": {
    clientFilial: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.client || element.client.filialsCount === 0;
    },
    contact: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.client;
    },
    canSeparateCargos: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.allCargosOneUnload === 10;
    },
    paymentTermDays: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.paymentTermType === 30;
    },
  },
  [NaeSSchemaMap.Contact.schema]: {
    skype: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.carrier;
    },
    recommended: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.carrier;
    },
    transportServices: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.carrier;
    },
    loadingCountries: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.carrier;
    },
  },
  [NaeSSchemaMap.Client.schema]: {
    priority: (props: FieldVisibilityParams) => {
      const { element } = props;
      const isPotencial =
        element.clientStatus &&
        element.clientStatus.titleManager.toLowerCase() === "potencialus";
      return !isPotencial;
    },
    clientStatus: (props: FieldVisibilityParams) => {
      const { element } = props;
      const isPotencial =
        element.clientStatus &&
        element.clientStatus.titleManager.toLowerCase() === "potencialus";
      return isPotencial;
    },
    paymentTermDays: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.paymentTermType === 30;
    },
    contractNumber: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.contractType === 10;
    },
  },
  [NaeSSchemaMap.CarriersOrder.schema]: {
    transportType: (props: FieldVisibilityParams) => {
      const { user } = props;
      if (checkUserLogistic(user)) {
        return true;
      }
      if (checkUserLogisticSea(user)) {
        return true;
      }
      if (checkUserLogisticFtl(user)) {
        return true;
      }
      return false;
    },
    loadDate: (props: FieldVisibilityParams) => {
      const { element, isEdit } = props;
      console.log({isEdit})
      if (element.status > 0 && isEdit) {
        return true;
      }
      return false;
    },
    unloadDate: (props: FieldVisibilityParams) => {
      const { element, isEdit } = props;
      if (element.status > 0 && isEdit) {
        return true;
      }
      return false;
    },
  },
  [NaeSSchemaMap.KzCarriersOrder.schema]: {
    'railwayTariffPayer': (props: FieldVisibilityParams) => {
      const { user, element } = props;
      if (element.transportType !== 20) {
        return true;
      }
      return false;
    },
    
    transportType: (props: FieldVisibilityParams) => {
      const { user } = props;
      if (checkUserLogistic(user)) {
        return true;
      }
      if (checkUserLogisticTrain(user)) {
        return true;
      }
      if (checkUserLogisticSea(user)) {
        return true;
      }
      if (checkUserLogisticFtl(user)) {
        return true;
      }
      return false;
    },
    transportAutoType: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 10;
    },
    customsClearance: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.isFtlDirect;
    },
    // carNumber: (props: FieldVisibilityParams) => {
    //   const { element } = props;
    //   return !(element.transportType === 10 || element.transportType === 50);
    // },
    transportGuaranteeType: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 10;
    },
    vehicleStatus: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 10;
    },
    driver: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !(element.transportType === 10 || element.transportType === 50);
    },
    trip: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 10;
    },

    transportRailwayType: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 20;
    },
    railwayNumber: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 20;
    },
    dimensions: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 50;
    },
    places: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.transportType !== 50;
    },
    loadDate: (props: FieldVisibilityParams) => {
      const { element, isEdit } = props;
      if (element.status > 0 && isEdit) {
        return true;
      }
      return false;
    },
    unloadDate: (props: FieldVisibilityParams) => {
      const { element, isEdit } = props;
      if (element.status > 0 && isEdit) {
        return true;
      }
      return false;
    },
  },
  "calculation": {
    clientFilial: (props: FieldVisibilityParams) => {
      const { element } = props;
      return !element.client || element.client.filialsCount === 0;
    },
    cargos: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.purpose === 0;
    },
    "hint:Choose quotation purpose": (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.purpose > 0;
    },
    canSeparateCargos: (props: FieldVisibilityParams) => {
      const { element } = props;
      return element.allCargosOneUnload === 10;
    },
  },
  "cargo": cargoVisibility,
  [NaeSSchemaMap.Sender.schema]: {
    'expeditor': (props: FieldVisibilityParams) => {
      const { user } = props;
      return !(checkUserAdmin(user));
    },
  },
  [NaeSSchemaMap.InvoiceIncoming.schema]: {
    'carrier': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'invoice' || type === 'penalty' || type === 'railway_platform_rent') {
        return false;
      }
      return true;
    },
    'costSupplierRailway': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'railway') {
        return false;
      }
      return true;
    },
    'terminal': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'terminal') {
        return false;
      }
      return true;
    },
    'costSupplier': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'garant') {
        return false;
      }
      return true;
    },
    'costSupplierExtra': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'extra' || type === 'cargo_invoice') {
        return false;
      }
      return true;
    },
    'cargoType': (
      props: FieldVisibilityParams,
    ) => {
      const {element} = props;
      const {type} = element;
      if (type === 'cargo_invoice') {
        return false;
      }
      return true;
    },
  },
};
