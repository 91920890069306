import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorTerminalNae } from "../../../_generated/_custom/models/ormSelectors";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { checkUserAdmin, checkUserChiefSalesOfficer, checkUserHeadOfLogistics } from '../../../_generated/_custom/config/NaeSPermissions';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { ITerminalModelNae } from "../../../_generated/_custom/hooks/useTerminalHookNae";
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { getPropertyTitleForSchema } from "../../../_generated/v3/utils";
import { FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function CargoTerminalWidget(props: PropsId) {
  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isChiefSalesOfficer = checkUserChiefSalesOfficer(userState);
  const isHeadOfLogistics = checkUserHeadOfLogistics(userState);

  const element = useCargoHookNae(props.id);
  const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);
  const salesOrder = useSalesOrderHookNae(element && element.salesOrder ? element.salesOrder.id : -1);

  const terminals = useSelector(state => SelectorTerminalNae(state));

  const [newTerminalId, setNewTerminalId] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const toggleShowPopup = () => setShowPopup(!showPopup);

  const onCancel = () => {
    setNewTerminalId(0);
    toggleShowPopup();
  }

  const cargos = salesOrder ? salesOrder.cargos.map(c => c.id) : (calculation ? calculation.cargos.map(c => c.id) : []);

  const { t } = useTranslation();

  const [saveData, saveDataParams] = useUSave("cargo");
  const doSave = (data: any, cargoId: number) => {
    return saveData(data, cargoId);
  };

  if (!element) {
    return <Fragment />;
  }
  if (!element.needTerminal) {
    return <Fragment />;
  }
  const canChangeTerminal = ((calculation && calculation.status === 0) || (salesOrder && element.status < 10)) || isAdmin || isChiefSalesOfficer || isHeadOfLogistics;

  if (!canChangeTerminal) {
    return <Fragment />;
  }
  if (element.status === 900) {
    return <Fragment />;
  }

  const setTerminalForOthers = () => {
    element.otherTerminalIds.forEach((_cargoId: number) => {
      doSave(
        {
          terminalManual: 10,
          terminal: {
            id: element.terminal.id
          },
        },
        _cargoId
      );
    });
  };

  const setTerminalForAll = () => {
    const promises = cargos.map((_cargoId: number) => {
      return doSave(
        {
          terminalManual: 10,
          terminal: {
            id: newTerminalId,
          },
        },
        _cargoId
      );
    });
    Promise.all(promises).then(() => {
      toggleShowPopup();
    })
  };

  const resetTerminalForAll = () => {
    const promises = cargos.map((_cargoId: number) => {
      return doSave(
        {
          terminalManual: 0,
          terminal: {
            id: newTerminalId,
          },
        },
        _cargoId
      );
    });
    Promise.all(promises).then(() => {

    })
  };

  return (
    <Fragment>

      <Fragment>

        <AlertWidget isCompact={true} color={element.terminalManual > 0 ? "info" : "warning"}>
          <div className="space-y-2">
            {/* {!!terminal && <p className={"strong"}>{terminal.name}</p>} */}
            <p>
              {element.terminalManual > 0
                ? t(
                  "Sistema išrenka terminalą automatiškai pagal atitinkama krovinio specifika. Patikrinkite ar tikrai Jūsų pasirinktas terminalas galės atlikti forminimo/perkrovimo darbus šiam kroviniui"
                )
                : t("Warehouse was selected automatically")}
            </p>
            {element.terminalManual > 0 &&
              element.otherTerminalIds.length > 0 &&
              !saveDataParams.loading && (

                <MainButton onClick={setTerminalForOthers} confirmation={true} color="slate">
                  {t("Nustatyti terminalą kitiems kroviniams")}
                </MainButton>

              )
            }
            {element.terminalManual > 0 && (calculation && calculation.status === 0) &&
              <MainButton confirmation={true} color={"slate"} onClick={resetTerminalForAll}>
                {t('Nustatyti automatiškai')}
              </MainButton>
            }
          </div>
        </AlertWidget>


        <MainButton
          className={"w-full"}
          color={"bluelight"}
          onClick={toggleShowPopup}
          iconName={"edit"}
        >
          {t("Change terminal")}
        </MainButton>

        <Popup isPopup={showPopup} onClick={toggleShowPopup} title={t('Terminal change')}>
          <div className="space-y-4 pb-40">
            <AlertWidget color="info" title={t('Are you sure you want to change the terminal?')}>
              {t(`The terminal will be replaced for all ${salesOrder ? 'order' : 'quatation'} cargos`)}
            </AlertWidget>

            <div className={"space-y-1"}>
              <FieldLabel>{t(getPropertyTitleForSchema("cargo", 'terminal'))}</FieldLabel>
              <FieldSelect
                value={newTerminalId}
                onChange={setNewTerminalId}
                options={terminals.sort((a: ITerminalModelNae, b: ITerminalModelNae) => a.name > b.name ? 1 : -1).map(
                  (terminal: ITerminalModelNae) => ({
                    value: terminal.id,
                    label: terminal.name
                    // extraData: terminal
                  }
                  ))}
              // components={{ Option: CustomOption }}
              />
            </div>
            <div className={"grid grid-cols-2 gap-2"}>
              <MainButton color="blue" onClick={setTerminalForAll} iconName={"save"} loading={saveDataParams.loading}>{t('Save')}</MainButton>
              <MainButton color="slate" onClick={onCancel}>{t('Cancel')}</MainButton>
            </div>

          </div>
        </Popup>

      </Fragment>


    </Fragment >
  );
}
