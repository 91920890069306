import React, { Fragment } from 'react'
import NEWidgetFiles from '../../../core/components/widgets/NEWidgetFiles';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserAdmin, checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';

export default function CalculationCargoTnvedWidget(props: PropsId) {
  const element = useCalculationHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);

  if (!element) {
    return <Fragment />
  }
  if (element.status !== 0) {
    return <Fragment />
  }
  if (element.cargos.length === 0) {
    return <Fragment />
  }
  if (!(isAdmin || isManager)) {
    return <Fragment />
  }

  return <CalculationCargoTnvedWidgetInner id={element.cargos[0].id} />
}

const CalculationCargoTnvedWidgetInner = (props: PropsId) => {
  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const cargo = useCargoHookNae(props.id);

  if (!cargo) {
    return <Fragment />
  }

  if (
    cargo.typeOut === "train" &&
    // element.allCargosOneUnload === 10 &&
    cargo.hasTnved
  ) {
    return (
      <Fragment>
        <WhiteCard isCompact={true}>
          <MainEditContentInline
            schema={"cargo"}
            type={"cargo-tnved"}
            id={cargo.id.toString()}
            isCompact={true}
          />
        </WhiteCard>


        <NEWidgetFiles
          schema={'cargo'}
          element={{ id: cargo.id }}
          options={{
            type: 500,
            title: "TNVED Failai",
            hideDeletedFiles: !isAdmin,
          }}
        />
      </Fragment>
    );
  }
  return <Fragment />

}