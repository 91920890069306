import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from 'recoil';
import OperativeCargoNew from "./OperativeCargoNew";
import OperativeCargoOnRoad from "./OperativeCargoOnRoad";
import OperativeCargoPlanned from "./OperativeCargoPlanned";
import OperativeCargoStock from "./OperativeCargoStock";
import OperativeCargoUrgent from "./OperativeCargoUrgent";
import { checkUserOperationalPro } from '../../../_generated/_custom/config/NaeSPermissions';
import OperativePro from "./OperativePro";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

type Props = {
  userId: number
}

export default function OperativeCargoWrapper(props: Props) {
  const { t } = useTranslation();

  const { userState } = useTemplatesCore()
  const isOperationalPro = checkUserOperationalPro(userState);

  useEffect(() => {
    const subscribeLogisticCardIndex = "cargo-operativeCardIndex";

    SocketService.subscribe(subscribeLogisticCardIndex);
    return () => {
      SocketService.unsubscribe(subscribeLogisticCardIndex);
    };
  }, []);

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t(''));
  }, []);


  return (
    <Fragment>

      <div className={"space-y-2"}>

        <OperativeCargoUrgent userId={props.userId} />

        <OperativeCargoNew userId={props.userId} />

        <OperativeCargoPlanned userId={props.userId} />

        <OperativeCargoStock userId={props.userId} />

        <OperativeCargoOnRoad userId={props.userId} />

        {isOperationalPro && (
          <Fragment>
            <TextCardTitle>{t("Documents verification")}</TextCardTitle>
            <OperativePro />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
