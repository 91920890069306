import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { useCargoOperationConfirmationHookNae } from "../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";

export default function CargoLogisticDocsChange(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const confirmElement = useCargoOperationConfirmationHookNae(element && element.operationConfirmation ? element.operationConfirmation.id : -1)

  const kzOrder = useKzCarriersOrderHookNae(
    element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1
  );
  const kzOrderStatus = kzOrder ? kzOrder.status : -1;

  const { t } = useTranslation();

  const [doReq, doReqParams] = useURequest(NaePathsMap.post.AppPluginsCargoOperationConfirmationReset);
  const doReset = () => {
    doReq({id: element.id});
  }

  if (!element) {
    return <Fragment />;
  }
  const incomingDocExists = !!element.files["10"] && element.files["10"] > 0;

  const isOperationActive = element.operationConfirmation;
  const isKzCarriersOrderFormation = !!element.kzCarriersOrder && kzOrderStatus >= 15;

  let tooltipContent = undefined;

  if (!isOperationActive) {
    tooltipContent = t('Dokumentai neišsiųsti forminimui');
  }
  if (!incomingDocExists) {
    tooltipContent = t('Seller\'s documents were not uploaded');
  }
  if (isKzCarriersOrderFormation) {
    tooltipContent = t('Krovinys jau forminasi');
  }
  return (
    <MenuItem
      isDisabled={!!tooltipContent}
      iconName={"file"}
      iconLoading={doReqParams.loading}
      onClick={doReset}
      tooltipContent={tooltipContent}
      confirmation={true}
    >
      {t("Seller's documents have been changed")}
    </MenuItem>
  );
}
