
import React from 'react';
import App from './_generated/v3/app/App';

import {createRoot} from 'react-dom/client';

import './index.css';

import 'react-quill/dist/quill.snow.css'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file


const root = createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <App />
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );