import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useClientFilialHookNae } from '../../hooks/useClientFilialHookNae';

export default function ClientFilialName(props: PropsId) {
    const element = useClientFilialHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.name}
        </Fragment>
    )
}
