import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { Float } from '@newageerp/v3.bundles.data-bundle'

interface Props {
    fieldKey: string,
}

export default function DebtRed(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element: item } = tData;
    const value = item[props.fieldKey];

    if (value > 0) {
        return <div className='text-red-500 text-right'>
            <Float value={value} />
        </div>
    }

    return (<div className='text-right'>
        <Float value={value} />
        </div>
    )
}
