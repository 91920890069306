import React, { Fragment } from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function CalculationActiveCargoLoadingCalculations(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    return (
        <Fragment>
            <div className={"grid grid-cols-3"}>
                {element.activeCargoLoadingCalculations.map((i: any) => {
                    return (
                        <Fragment>
                            <div>{i.serialNumber}</div>
                            <div>{i.client.name}</div>
                            <div>
                                {i.total} {i.currency.currency}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </Fragment>
    );
}
