import moment from "moment";
import { ICargoModelNae } from "../_generated/_custom/hooks/useCargoHookNae";

const checkIsAnyLogistic = (userState: any) => {
  return (
    userState.scopes.indexOf("user-logistic") >= 0 ||
    userState.scopes.indexOf("user-logistic-sea") >= 0 ||
    userState.scopes.indexOf("user-logistic-train") >= 0 ||
    userState.scopes.indexOf("user-logistic-ftl") >= 0 ||
    userState.scopes.indexOf("user-logistic-avia") >= 0
  );
};


interface FreeCargoLeftBorderCargoIdProps {
  id: number,
}
interface FreeCargoLeftBorderCargoProps {
  soClientId: number,
  salesOrder: FreeCargoLeftBorderCargoIdProps
}

const freeCargoLeftBorder = (
  cargo: FreeCargoLeftBorderCargoProps,
  cargos: FreeCargoLeftBorderCargoProps[],
  cargoIndex: number,
) => {
  let rowLeftBorder = "";
  if (!!cargos && cargos.length > 0) {
    const _cargoIndex = cargoIndex ? cargoIndex : 0;
    const prevCargo: FreeCargoLeftBorderCargoProps | null = _cargoIndex > 0 ? cargos[_cargoIndex - 1] : null;
    const nextCargo: FreeCargoLeftBorderCargoProps | null =
      _cargoIndex + 1 < cargos.length ? cargos[_cargoIndex + 1] : null;

    const prevCargoSoClient: number = prevCargo
      ? prevCargo.soClientId
      : 0;
    const nextCargoSoClient: number = nextCargo
      ? nextCargo.soClientId
      : 0;
    const cargoSoClient : number = cargo.soClientId;

    const prevCargoSo: FreeCargoLeftBorderCargoIdProps | null = prevCargo ? prevCargo.salesOrder : null;
    const nextCargoSo: FreeCargoLeftBorderCargoIdProps | null = nextCargo ? nextCargo.salesOrder : null;
    const cargoSo = cargo.salesOrder;

    const isPrevSo = prevCargoSo && prevCargoSo.id === cargoSo.id;
    const isNextSo = nextCargoSo && nextCargoSo.id === cargoSo.id;

    const isPrevClient = prevCargoSoClient === cargoSoClient;
    const isNextClient = nextCargoSoClient === cargoSoClient;

    if (isPrevSo) {
      rowLeftBorder = "border-left-3-info";
      if (!isNextSo) {
        rowLeftBorder += " border-bottom-2-info";
      }
    } else if (isNextSo) {
      rowLeftBorder = "border-left-3-info";
    } else if (isPrevClient) {
      rowLeftBorder = "border-left-3-yellow";
      if (!isNextClient) {
        rowLeftBorder += " border-bottom-2-yellow";
      }
    } else if (isNextClient) {
      rowLeftBorder = "border-left-3-yellow";
    }
  }
  return rowLeftBorder;
};

export {
  checkIsAnyLogistic,
  freeCargoLeftBorder,
};

interface ISortCargo {
  soClientJson: {
    clientFilialName: string;
  };
  salesOrderJson: {
    serialNumber: string;
  };
  serialNumber: string;
}

export const kzSortCargo = (a: ICargoModelNae, b: ICargoModelNae) => {
  return a.cargoSortString < b.cargoSortString
    ? -1
    : a.cargoSortString > b.cargoSortString
    ? 1
    : 0;
};
