import React, { Fragment } from 'react'
import { PropsId } from '../types';
import ContactPhones from '../Contact/ContactPhones';
import { useSalesOrderHookNae } from '../../hooks/useSalesOrderHookNae';

export default function SalesOrderUnloadResponsiblePersonOrContactPhones(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (element.unloadResponsiblePerson) {
        return (
            <ContactPhones id={element.unloadResponsiblePerson.id} />
        )
    }
    if (element.contact) {
        return (
            <ContactPhones id={element.contact.id} />
        )
    }
    return <Fragment />
}
