import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import CalculationCreateOrder from './CalculationCreateOrder';
import SalesOrderSerialNumberButtonLink from '../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderSerialNumberButtonLink';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from '../../../_generated/_custom/models/ormSelectors';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { ICargoModelNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { AlertWidget } from '@newageerp/v3.bundles.widgets-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { checkUserAdmin, checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';

interface IDataCargo {
    id: number,
    needAlkoGlueWithStamps: number,
    needAlkoGlueWithStampsType: number,
    needAlkoGlueWithStampsType2: number,
    needAlkoGlueWithStampsType3: number,
    serialNumber: string,
}
interface IDataSo {
    id: number,
    serialNumber: string,
}
interface IData {
    id: number,
    status: number,
    cargos: IDataCargo[],
    purpose: number,
    total: number,
    salesOrders: IDataSo[]
}

const defObject: IData = {
    id: 0,
    status: 0,
    total: 0,
    purpose: 0,
    cargos: [
        {
            id: 0,
            needAlkoGlueWithStamps: 0,
            needAlkoGlueWithStampsType: 0,
            needAlkoGlueWithStampsType2: 0,
            needAlkoGlueWithStampsType3: 0,
            serialNumber: '',
        }
    ],
    salesOrders: [
        {
            id: 0,
            serialNumber: '',
        }
    ]
}

interface Props {
    elementId: number,
}

export default function CalculationSalesOrders(props: PropsId) {
    const element = useCalculationHookNae(props.id);
    const elementCargos = element ? element.cargos.map(el => el.id) : [-1];
    // @ts-ignore
    const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);
    const isManager = checkUserManager(userState);

    const { t } = useTranslation();

    const openPopup = (cargoId: number) => {
        SFSOpenEditModalWindowProps({
            id: cargoId,
            schema: "cargo",
            type: 'manager-errors-glue-with-stamps'
        })
    }

    if (!element) {
        return <Fragment />
    }
    if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
        return <Fragment />
    }
    if (!(isAdmin || isManager)) {
        return <Fragment />
    }

    if (element.status >= 10 && element.purpose === 20 && element.total > 0) {
        if (!element.salesOrder) {
            const errorsGlueWithStamps: ICargoModelNae[] = [];

            cargos.forEach((cargo: ICargoModelNae) => {
                if (cargo.needAlkoGlueWithStamps === 10) {
                    if (
                        cargo.needAlkoGlueWithStampsType === 0 ||
                        cargo.needAlkoGlueWithStampsType2 === 0 ||
                        cargo.needAlkoGlueWithStampsType3 === 0
                    ) {
                        errorsGlueWithStamps.push(cargo);
                    }
                }
            });

            if (errorsGlueWithStamps.length > 0) {
                return (
                    <Fragment>
                        <AlertWidget color={"danger"}>
                            <div>{t("Pasirinkite kokią paslaugą reikia atlikti prie:")}</div>
                            <div className={"flex flex-wrap gap-2 mt-2"}>
                                {errorsGlueWithStamps.map((cargo: ICargoModelNae) => {
                                    return (
                                        <MainButton key={"btn-" + cargo.id} color={"white"} onClick={() => openPopup(cargo.id)}>
                                            {cargo.serialNumber}
                                        </MainButton>
                                    )
                                })}
                            </div>
                        </AlertWidget>
                    </Fragment>
                );
            } else {
                return (<CalculationCreateOrder id={element.id} />);
            }
        }
    }

    return <Fragment />
}
