import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useTranslation } from 'react-i18next';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from '../../../../core/old-ui/OldBadge';
import { useCargoHoldHookNae } from '../../hooks/useCargoHoldHookNae';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoHoldUnloadBadge(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const elementHold = useCargoHoldHookNae(element && element.cargoHold ? element.cargoHold.id : -1);

    const { t } = useTranslation();
    if (!element) {
        return <Fragment />
    }

    const isPostpone = !!elementHold && elementHold.status === 0;

    if (!isPostpone) {
        return <Fragment />
    }

    return (
        <StatusWidget color="orange" smallPadding={true}>
            {t('Postponed delivery')}
        </StatusWidget>
    )
}
