import React, { Fragment } from 'react';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export const checkUserPermission = (userState: any, permission: string) => {
    return userState.scopes.indexOf(permission) >= 0;
};

interface ICheckUserPermissionComponent {
    children: any,
    permissions?: ENaeSPermissions[],
    permissionsStr?: string,
}
export const CheckUserPermissionComponent = (props: ICheckUserPermissionComponent) => {
    const {userState} = useTemplatesCore()

    const permissions = props.permissions?props.permissions:(props.permissionsStr?JSON.parse(props.permissionsStr):undefined);

    if (!permissions) {
      return <Fragment />
    }

    let isOk = false;
    permissions.forEach((permission: ENaeSPermissions) => {
        if (checkUserPermission(userState, permission)) {
            isOk = true;
        }
    });
    if (!isOk) {
        return <Fragment />
    }
    return props.children;
}

export const checkDefault = (userState: any) => {
    return userState.scopes.indexOf("default") >= 0;
};
export const checkUserAccounting = (userState: any) => {
    return userState.scopes.indexOf("user-accounting") >= 0;
};
export const checkUserAdmin = (userState: any) => {
    return userState.scopes.indexOf("user-admin") >= 0;
};
export const checkUserLogistic = (userState: any) => {
    return userState.scopes.indexOf("user-logistic") >= 0;
};
export const checkUserLogisticFtl = (userState: any) => {
    return userState.scopes.indexOf("user-logistic-ftl") >= 0;
};
export const checkUserLogisticAvia = (userState: any) => {
    return userState.scopes.indexOf("user-logistic-avia") >= 0;
};
export const checkUserManager = (userState: any) => {
    return userState.scopes.indexOf("user-manager") >= 0;
};
export const checkUserOperational = (userState: any) => {
    return userState.scopes.indexOf("user-operational") >= 0;
};
export const checkUserOperationalPro = (userState: any) => {
    return userState.scopes.indexOf("user-operational-pro") >= 0;
};
export const checkUserBusinessDev = (userState: any) => {
    return userState.scopes.indexOf("user-business-dev") >= 0;
};
export const checkUserTransportManager = (userState: any) => {
    return userState.scopes.indexOf("user-transport-manager") >= 0;
};
export const checkUserHeadOfLogisticsHelper = (userState: any) => {
    return userState.scopes.indexOf("user-head-of-logistics-helper") >= 0;
};
export const checkUserHeadOfLogistics = (userState: any) => {
    return userState.scopes.indexOf("user-head-of-logistics") >= 0;
};
export const checkUserFinances = (userState: any) => {
    return userState.scopes.indexOf("user-finances") >= 0;
};
export const checkUserForwardingManager = (userState: any) => {
    return userState.scopes.indexOf("user-forwarding-manager") >= 0;
};
export const checkUserChiefSalesOfficer = (userState: any) => {
    return userState.scopes.indexOf("user-chief-sales-officer") >= 0;
};
export const checkUserHeadOfTransport = (userState: any) => {
    return userState.scopes.indexOf("user-head-of-transport") >= 0;
};
export const checkUserLogisticSea = (userState: any) => {
    return userState.scopes.indexOf("user-logistic-sea") >= 0;
};
export const checkUserLogisticTrain = (userState: any) => {
    return userState.scopes.indexOf("user-logistic-train") >= 0;
};export enum ENaeSPermissions {
Default = "default",
UserAccounting = "user-accounting",
UserAdmin = "user-admin",
UserLogistic = "user-logistic",
UserLogisticFtl = "user-logistic-ftl",
UserLogisticAvia = "user-logistic-avia",
UserManager = "user-manager",
UserOperational = "user-operational",
UserOperationalPro = "user-operational-pro",
UserBusinessDev = "user-business-dev",
UserTransportManager = "user-transport-manager",
UserHeadOfLogisticsHelper = "user-head-of-logistics-helper",
UserHeadOfLogistics = "user-head-of-logistics",
UserFinances = "user-finances",
UserForwardingManager = "user-forwarding-manager",
UserChiefSalesOfficer = "user-chief-sales-officer",
UserHeadOfTransport = "user-head-of-transport",
UserLogisticSea = "user-logistic-sea",
UserLogisticTrain = "user-logistic-train",}
export const NaeSPermissions = [
    {
        "key": "default",
        "slug": "default",
        "title": "default"
    },
    {
        "key": "userAccounting",
        "slug": "user-accounting",
        "title": "Office Manager"
    },
    {
        "key": "userAdmin",
        "slug": "user-admin",
        "title": "Admin"
    },
    {
        "key": "userLogistic",
        "slug": "user-logistic",
        "title": "Transport Operations Manager (LTL)"
    },
    {
        "key": "userLogisticFtl",
        "slug": "user-logistic-ftl",
        "title": "Transport Operations Manager (FTL)"
    },
    {
        "key": "userLogisticAvia",
        "slug": "user-logistic-avia",
        "title": "Multimodal Operations Manager (Railroad)"
    },
    {
        "key": "userManager",
        "slug": "user-manager",
        "title": "Sales Manager"
    },
    {
        "key": "userOperational",
        "slug": "user-operational",
        "title": "Document Processing Specialist"
    },
    {
        "key": "userOperationalPro",
        "slug": "user-operational-pro",
        "title": "Head of Document Processing Department"
    },
    {
        "key": "userBusinessDev",
        "slug": "user-business-dev",
        "title": "Database Administrator"
    },
    {
        "key": "userTransportManager",
        "slug": "user-transport-manager",
        "title": "Transporto vadybininkas"
    },
    {
        "key": "userHeadOfLogisticsHelper",
        "slug": "user-head-of-logistics-helper",
        "title": "Operations Department Administrator"
    },
    {
        "key": "userHeadOfLogistics",
        "slug": "user-head-of-logistics",
        "title": "Chief Operations Officer"
    },
    {
        "key": "userFinances",
        "slug": "user-finances",
        "title": "Buhalteris"
    },
    {
        "key": "userForwardingManager",
        "slug": "user-forwarding-manager",
        "title": "Forwarding manager"
    },
    {
        "key": "userChiefSalesOfficer",
        "slug": "user-chief-sales-officer",
        "title": "Chief Sales Officer"
    },
    {
        "key": "userHeadOfTransport",
        "slug": "user-head-of-transport",
        "title": "Head of transport"
    },
    {
        "key": "userLogisticSea",
        "slug": "user-logistic-sea",
        "title": "Multimodal Operations Manager (AVIA + SEA)"
    },
    {
        "key": "userLogisticTrain",
        "slug": "user-logistic-train",
        "title": "Multimodal Operations Manager (Railroad)"
    }
]