import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { AlertWidget, transformErrorAlert } from "@newageerp/v3.bundles.widgets-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

export default function SalesOrderManagerStatus(props: PropsId) {
  const element = useSalesOrderHookNae(props.id);

  const { t } = useTranslation();

  const [sendConfirmation, confirmationParams] = useURequest(NaePathsMap.post['pluginsSalesOrderConfirmationSendToConfirm']);
  const doSendConfirmation = () => {
    sendConfirmation({
      id: props.id
    })
  }

  const [saveData, saveDataParams] = useUSave("sales-order");
  const doSave = (data: any) => {
    if (saveDataParams.loading) return;
    saveData(data, element.id);
  }

  const doNext = () => {
    doSave({ status: 5, addNote: { content: "Išsiųsta patvirtinimui" } });
  };

  const doBack = () => {
    doSave({ status: 0, addNote: { content: "Grąžinta į rengiamą" } });
  };

  if (!element) {
    return <Fragment />
  }
  if (!(element.status === 0 || element.status === 5)) {
    return <Fragment />
  }

  const confirmFields = !!element.address;

  const hasNotCalculatedCargo = element.hasNotCalculatedCargos;

  if (!confirmFields) {
    return (
      <AlertWidget color="danger" isCompact={true}>
        {t("The unloading information must be entered with the order")}
      </AlertWidget>
    )
  } else if (hasNotCalculatedCargo) {
    return (
      <AlertWidget color="danger" isCompact={true}>
        {t("The order has uncalculated cargos")}
      </AlertWidget>
    );
  }

  return (
    <Fragment>


      {element.status === 0 ? (
        <Fragment>
          {!!element.confirmCancelText && (
            <AlertWidget color="warning" isCompact={true}>
              {t("Rejection comment")}: {element.confirmCancelText}
            </AlertWidget>
          )}

          <MainButton
            iconName="hourglass-half"
            loading={confirmationParams.loading}
            confirmation={true}
            onClick={doSendConfirmation}
            className={"w-full"}
          >
            {t("Send for confirmation")}
          </MainButton>
        </Fragment>
      ) : (
        <MainButton iconName="hourglass-half" color="amber" className={"w-full"} loading={saveDataParams.loading} confirmation={true} onClick={doBack}>
          {t("Return")}
        </MainButton>
      )}

      {!!saveDataParams.error && <AlertWidget color='danger'>
        {transformErrorAlert(saveDataParams.error)}
      </AlertWidget>}
      {!!confirmationParams.error && <AlertWidget color='danger'>
        {transformErrorAlert(confirmationParams.error)}
      </AlertWidget>}
    </Fragment>
  );
}
