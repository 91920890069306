import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { getPropertyTitleForSchema } from "../../../_generated/v3/utils";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface Props extends PropsId {
  hideNotes?: boolean;
  sm?: boolean;
}

interface IData {
  checks: any;
  id: number;
}

export default function LogisticStatus3Return(props: Props) {
  const element = useCargoHookNae(props.id);
  const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);
  const { sm, hideNotes } = props;

  const [saveData, saveDataParams] = useUSave(
    "cargo",
  );

  const { t } = useTranslation();

  if (!element || !calculation) {
    return <Fragment />
  }
  if (!element.contactedSeller) {
    return <Fragment />
  }

  const status3ok = element.checks.status3ok;

  const doSave = () => {
    saveData({ returnCalculation: true, }, element.id);
  };

  return (
    <Fragment>
      {status3ok === false && !hideNotes && (
        <AlertWidget color="info" isCompact={true}>
          <div className="font-semibold">{t("Enter to continue working:")}</div>
          {t(getPropertyTitleForSchema("cargo", 'places'))}
          <br />
          {t(getPropertyTitleForSchema("cargo", 'dimensions'))}
          <br />
          {t(getPropertyTitleForSchema("cargo", 'weight'))}
          <br />
          {t(getPropertyTitleForSchema("cargo", 'senderMakeEx'))}
          {calculation.purpose === 30 && <Fragment>
            <br />
            {t(getPropertyTitleForSchema("cargo", 'loadDateSeller'))}
          </Fragment>}
          {/* <br />
          {t("Actual ready date")} */}
        </AlertWidget>

      )}

      {status3ok === true && (

        <MainButton
          className={"w-full"}
          color={"teal"}
          iconName={"check-double"}
          loading={saveDataParams.loading}
          onClick={doSave}
          confirmation={true}
        >
          {t("Data filled, send it to the sales manager")}
        </MainButton>

      )}
    </Fragment>
  );
}
