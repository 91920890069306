import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzLocationSuggestNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IKzLocationSuggestModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      text: string,
  }

export const IKzLocationSuggestFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "text"
];

export function useKzLocationSuggestHookNae(id: number) : IKzLocationSuggestModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorKzLocationSuggestNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "kz-location-suggest",
        fields: IKzLocationSuggestFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
