import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoLoadingCalculationNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ICargoLoadingCalculationModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      client: {
              id: number,
            },
      contact: {
              id: number,
            },
      notes: string,
      total: number,
      currency: {
              id: number,
                    currency: string,
            },
      createdAt: string,
      serialNumber: string,
      creator: {
              id: number,
            },
      cargo: {
              id: number,
            },
  }

export const ICargoLoadingCalculationFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "client.id",
    "contact.id",
    "notes",
    "total",
    "currency.id",
    "currency.currency",
    "createdAt",
    "serialNumber",
    "creator.id",
    "cargo.id"
];

export function useCargoLoadingCalculationHookNae(id: number) : ICargoLoadingCalculationModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorCargoLoadingCalculationNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "cargo-loading-calculation",
        fields: ICargoLoadingCalculationFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
